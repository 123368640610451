/* eslint-disable react/require-default-props */
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../../components/Logo';
import TopNavRightContent from './TopNavRightContent';

type TopNavProps = {
  sidebarMinimized: boolean;
  setSidebarMinimized: (minimize: boolean) => void;
  organizationUser?: OrganizationUser;
  isEnviewAdmin?: boolean;
  isOrgAdmin?: boolean;
};

const TopNav = (props: TopNavProps): ReactElement => {
  const {
    sidebarMinimized,
    setSidebarMinimized,
    organizationUser,
    isEnviewAdmin,
    isOrgAdmin,
  } = props;

  return (
    <nav className="d-flex flex-row ce-nav new-top-nav">
      {sidebarMinimized && (
        <FontAwesomeIcon
          className="mobile-menu-icon"
          color="#979797"
          icon={faBars}
          onClick={() => setSidebarMinimized(false)}
        />
      )}
      {!sidebarMinimized && (
        <FontAwesomeIcon
          className="mobile-menu-icon"
          color="#979797"
          icon={faTimes}
          onClick={() => setSidebarMinimized(true)}
        />
      )}
      <div className="branding new-branding">
        <Link to="/legislative-tracking">
          <Logo />
        </Link>
      </div>
      <TopNavRightContent
        isEnviewAdmin={isEnviewAdmin}
        isOrgAdmin={isOrgAdmin}
        organizationUser={organizationUser}
      />
    </nav>
  );
};

export default TopNav;
