import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavigationBar from '../scenes/PrimaryView/Navigation';

class FocusLayout extends Component {
  render() {
    
    const { wide } = this.props;
    const navigation =
      typeof this.props.navigation !== 'undefined' ? (
        <button type="button" className="btn btn-light" onClick={this.props.navigation.action}>
          {this.props.navigation.label}
        </button>
      ) : null;
    const { children } = this.props;
    return (
      <div className="focus-layout-container">
        <NavigationBar />
        <div className="container" style={{marginTop: '12vh'}}>
          <div className="row">
            {wide ? (
              <div className="focus-main col-md-10 offset-md-1">
                <div className="focus-navigation text-right">{navigation}</div>
                {this.props.title ? <h2 className="mb-5 text-gray">{this.props.title}</h2> : null}
                <div className="focus-content text-gray">{children}</div>
              </div>
            ) : (
              <div className="focus-main col-md-6 offset-md-3">
                {this.props.title ? (
                  <h2 className="mb-5 text-center text-gray">{this.props.title}</h2>
                ) : null}
                <div className="card">
                  <div className="card-body">{children}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="legal-footer text-center">
          <small>© Plural</small>
        </p>
      </div>
    );
  }
}

FocusLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  navigation: PropTypes.object,
  wide: PropTypes.bool,
};

export default FocusLayout;
