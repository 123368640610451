import React, { ReactElement, useEffect } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import './CommitteeView.scss';
import { getCommitteeName } from '../../helpers/CommitteesHelper';
import * as Analytics from '../../analytics/PersonAnalytics';
import { CommitteeAPI } from '../../api';
import { SEOHead } from '../../components/head/SEOHead';
import CommitteeViewNav from './CommitteeViewNav';

type CommitteeViewProps = {
  committeeId: number;
};

const CommitteeView = (props: CommitteeViewProps): ReactElement => {
  const { committeeId } = props;

  const { data: committee } = CommitteeAPI.endpoints.getCommittee.useQuery(committeeId);

  useEffect(() => {
    if (!committee) return;
    Analytics.trackViewedCommitteePage(committee);
  }, [committee]);

  const trackSourceClick = (link: string): void => {
    window.open(link);
    if (!committee) return;
    Analytics.trackCommitteeSourceClicked(committee, link);
  };

  const fullCommitteeName = committee
    ? getCommitteeName(committee, committee?.memberships[0]?.electedPosition?.chamber)
    : '';

  const getCommitteeSource = (url: string): string => {
    return url.includes('kslegislature.org/li/b') && url.slice(-1) !== '/'
      ? `${url}/`
      : url;
  };

  return (
    <div className="container-fluid committee-view p-0">
      {!committee && (
        <>
          <TopBarProgress />
          <div className="message-card">Loading...</div>
        </>
      )}

      {committee && (
        <>
          <SEOHead
            description={`Details on ${fullCommitteeName} in ${committee?.jurisdiction.name}`}
            title={`${committee?.jurisdiction.name} ${fullCommitteeName}`}
          />
          <div className="committee-header">
            <div className="row m-0">
              <div className="col-12 p-0">
                <h1>{fullCommitteeName}</h1>
                <h3 className="page-subtitle">
                  <span>{committee.jurisdiction.name}</span>
                  <span className="float-right">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="source-link"
                      onClick={() =>
                        trackSourceClick(getCommitteeSource(committee.links[0]))
                      }
                    >
                      Source Link
                    </a>{' '}
                  </span>
                </h3>
              </div>
            </div>
            <CommitteeViewNav committee={committee} />
          </div>
        </>
      )}
    </div>
  );
};

export default CommitteeView;
