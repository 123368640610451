import React from 'react';

export default () => (
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 9.96484C25 10.5195 24.582 10.9375 24.0625 10.9375H19.3437L17.0977 15.7109C16.9268 16.0674 16.5703 16.2871 16.1602 16.2434C15.7695 16.2092 15.4375 15.9309 15.3398 15.5453L12.6055 5.05312L9.66797 19.2539C9.57812 19.6797 9.21484 19.9844 8.78125 20H8.75C8.33008 20 7.95898 19.7168 7.84687 19.3117L5.53906 10.9375H0.902344C0.419922 10.9375 0 10.5195 0 10C0 9.48047 0.419922 9.0625 0.902344 9.0625H6.25C6.67148 9.0625 7.04101 9.34398 7.15312 9.75039L8.61797 15.0629L11.582 0.746875C11.6719 0.322266 12.0469 0.00976562 12.4805 0H12.5C12.925 0 13.3008 0.288086 13.4082 0.703125L16.5039 12.5742L17.9004 9.60078C18.0547 9.27344 18.3906 9.0625 18.75 9.0625H24.0625C24.582 9.0625 25 9.48047 25 9.96484Z"
      fill="#27A4CC"
    />
  </svg>
);
