import React from 'react';

export default () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.34375 1.64412L6.00042 7.30078L11.6571 1.64412L10.7144 0.700783L6.00042 5.41545L1.28642 0.700783L0.34375 1.64412Z"
      fill="#5F5F5F"
    />
  </svg>
);
