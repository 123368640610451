type LocationMapProperties = {
  longitude: number;
  latitude: number;
  zoom?: number;
};

const JurisdictionCoordinates = new Map<string, LocationMapProperties>([
  ['USA', { longitude: -100, latitude: 40, zoom: 3 }],
  ['AL', { longitude: -86.7911, latitude: 32.806671, zoom: 5.6 }],
  ['AK', { longitude: -152.404419, latitude: 61.370716, zoom: 3.5 }],
  ['AZ', { longitude: -111.431221, latitude: 34.168219 }],
  ['AR', { longitude: -91.831833, latitude: 34.751928 }],
  ['CA', { longitude: -119.681564, latitude: 37.271875, zoom: 5 }],
  ['CO', { longitude: -105.311104, latitude: 38.997934 }],
  ['CT', { longitude: -72.755371, latitude: 41.518784, zoom: 7 }],
  ['DE', { longitude: -75.507141, latitude: 39.145251, zoom: 7.3 }],
  ['DC', { longitude: -77.014567, latitude: 38.899349, zoom: 8 }],
  ['FL', { longitude: -81.686783, latitude: 27.986279, zoom: 5.4 }],
  ['GA', { longitude: -83.243074, latitude: 32.940619, zoom: 5.7 }],
  ['HI', { longitude: -157.498337, latitude: 21.094318 }],
  ['ID', { longitude: -114.478828, latitude: 44.240459, zoom: 5.4 }],
  ['IL', { longitude: -88.986137, latitude: 40.349457, zoom: 5.1 }],
  ['IN', { longitude: -86.258278, latitude: 39.849426 }],
  ['IA', { longitude: -93.210526, latitude: 42.011539 }],
  ['KS', { longitude: -98.485939, latitude: 38.5266 }],
  ['KY', { longitude: -84.670067, latitude: 37.66814 }],
  ['LA', { longitude: -91.867805, latitude: 31.169546 }],
  ['ME', { longitude: -69.381927, latitude: 45.193947, zoom: 5.5 }],
  ['MD', { longitude: -76.802101, latitude: 39.063946, zoom: 6.8 }],
  ['MA', { longitude: -71.530106, latitude: 42.230171, zoom: 7.2 }],
  ['MI', { longitude: -84.536095, latitude: 43.326618, zoom: 5.5 }],
  ['MN', { longitude: -93.900192, latitude: 45.694454, zoom: 5 }],
  ['MS', { longitude: -89.678696, latitude: 32.741646 }],
  ['MO', { longitude: -92.458024, latitude: 38.456085, zoom: 5.4 }],
  ['MT', { longitude: -110.454353, latitude: 46.921925, zoom: 5.2 }],
  ['NE', { longitude: -99.868082, latitude: 41.52537 }],
  ['NG', { longitude: 7.57, latitude: 8.7244, zoom: 4.8 }],
  ['NV', { longitude: -117.055374, latitude: 38.613515, zoom: 5 }],
  ['NH', { longitude: -71.563896, latitude: 43.792492, zoom: 6.5 }],
  ['NJ', { longitude: -74.521011, latitude: 40.298904, zoom: 6.6 }],
  ['NM', { longitude: -106.248482, latitude: 34.240515, zoom: 5.3 }],
  ['NY', { longitude: -74.948051, latitude: 42.165726 }],
  ['NC', { longitude: -79.806419, latitude: 35.630066 }],
  ['ND', { longitude: -99.784012, latitude: 47.528912, zoom: 5.7 }],
  ['OH', { longitude: -82.764915, latitude: 40.388783 }],
  ['OK', { longitude: -96.928917, latitude: 35.565342 }],
  ['OR', { longitude: -122.070938, latitude: 44.572021 }],
  ['PA', { longitude: -77.309755, latitude: 41.090752, zoom: 6.2 }],
  ['PR', { longitude: -66.4001, latitude: 18.2208, zoom: 8.4 }],
  ['RI', { longitude: -71.51178, latitude: 41.680893, zoom: 8.3 }],
  ['SC', { longitude: -80.945007, latitude: 33.856892 }],
  ['SD', { longitude: -99.438828, latitude: 44.299782, zoom: 5.7 }],
  ['TN', { longitude: -86.692345, latitude: 35.747845 }],
  ['TX', { longitude: -97.563461, latitude: 31.254487, zoom: 4.5 }],
  ['UT', { longitude: -111.862434, latitude: 39.550032, zoom: 5.4 }],
  ['VT', { longitude: -72.710686, latitude: 43.845876, zoom: 6.4 }],
  ['VA', { longitude: -78.169968, latitude: 37.769337 }],
  ['WA', { longitude: -121.490494, latitude: 47.400902 }],
  ['WV', { longitude: -80.954453, latitude: 38.491226 }],
  ['WI', { longitude: -89.616508, latitude: 44.268543 }],
  ['WY', { longitude: -107.30249, latitude: 42.755966 }],
  ['ZA', { longitude: 28.0473, latitude: -26.2041, zoom: 5 }],
]);

export default JurisdictionCoordinates;
