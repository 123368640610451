/* eslint-disable react/jsx-max-depth */
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { TeamAPI } from '../../../api';
import { g as abilityGlossary } from '../../../config/ability';
import { checkPermission } from '../../../dux';
import { Thunk } from '../../../dux/@types';
import { setMinimizeSidebar } from '../../../dux/NavDux';
import { mobileMediaQuery } from '../../../helpers/mediaQueries';
import ExportButtonSection from './ExportButtonSection';
import './NavRedesign.scss';
import BillsSidebarSection from './SidebarSections/BillsSidebarSection';
import ExploreSidebarSection from './SidebarSections/ExploreSidebarSection';
import OrgSidebarSection from './SidebarSections/OrgSidebarSection';
import SearchSidebarSection from './SidebarSections/SearchSidebarSection';
import SavedSearchesSidebarSection from './SidebarSections/SearchesSidebarSection';
import TagsSidebarSection from './SidebarSections/TagsSidebarSection';
import WorkspaceSidebarSection from './SidebarSections/WorkspaceSidebarSection';

type NewPrimarySidebarProps = {
  isMinimized: boolean;
  minimize: () => void;
};

const PrimarySidebar = (props: NewPrimarySidebarProps): ReactElement => {
  const { isMinimized, minimize } = props;
  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);
  const dispatch = useDispatch();

  const teams = useMemo(
    () => (teamMemberships || []).map((tm) => tm.team),
    [teamMemberships],
  );

  // TODO: Use redux state to set minimized open/closed
  // Easier to do once we remove old sidebar
  const minimizeIfMobile = useCallback(() => {
    if (mobileMediaQuery.matches) {
      dispatch(setMinimizeSidebar(true));
      minimize();
    }
  }, [minimize]);

  const canViewSavedNavGroup = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(
        abilityGlossary.VIEW,
        abilityGlossary.SIDEBAR_SEARCH_NAV_GROUP,
        true,
      ),
    );
  };

  /* Refs and callbacks to enable workarounds for z-index in SingleSelectDropdown:
  When orgs/workspaces have long names, we want the sidebar content to overlay on top
  of the main page content. Unfortunately, using relative positioning on the dropdown
  menus ceates a new Stacking Context, rendering our z-index declarations moot. There's a
  workaound for this implemented in SingleSelectDropdown using position:static on the container
  and position:absolute on the menu, but it requires these refs to exist and be passed as props */
  const BUTTON_DROPDOWN_OFFSET = 19;
  const orgDropdownRef = useRef<HTMLDivElement>(null);
  const workspaceDropdownRef = useRef<HTMLDivElement>(null);

  const [orgDropdownPosition, setOrgDropdownPosition] = useState<number>(-1);
  const [workspaceDropdownPosition, setWorkspaceDropdownPosition] =
    useState<number>(-1);

  const handleScroll = (): void => {
    if (orgDropdownRef.current) {
      setOrgDropdownPosition(
        orgDropdownRef.current.getBoundingClientRect().bottom - BUTTON_DROPDOWN_OFFSET,
      );
    }
    if (workspaceDropdownRef.current) {
      setWorkspaceDropdownPosition(
        workspaceDropdownRef.current.getBoundingClientRect().bottom -
          BUTTON_DROPDOWN_OFFSET,
      );
    }
  };

  return (
    <nav
      className={`${isMinimized ? 'sidebar-minimized' : ''}`}
      id="new-primary-sidebar"
      onScroll={handleScroll}
    >
      <Accordion defaultActiveKey="0">
        <OrgSidebarSection
          orgDropdownPosition={orgDropdownPosition}
          orgDropdownRef={orgDropdownRef}
          workspaceDropdownPosition={workspaceDropdownPosition}
          workspaceDropdownRef={workspaceDropdownRef}
        />
        <div className="main-nav-group nav-group">
          <SearchSidebarSection onNavClick={minimizeIfMobile} />
          <WorkspaceSidebarSection onNavClick={minimizeIfMobile} />
          <ExploreSidebarSection onNavClick={minimizeIfMobile} />
        </div>
        {canViewSavedNavGroup() && (
          <div className="saved-nav-group nav-group">
            <h4 className="saved-nav-group-header">Saved</h4>
            <BillsSidebarSection onNavClick={minimizeIfMobile} />
            <TagsSidebarSection onNavClick={minimizeIfMobile} />
            <SavedSearchesSidebarSection onNavClick={minimizeIfMobile} />
          </div>
        )}
        <ExportButtonSection teams={teams} />
      </Accordion>
    </nav>
  );
};

export default PrimarySidebar;
