import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-jsonschema-form';

class MfaRegistration extends React.Component {
  componentDidMount() {
    if (!this.props.qrCodeData) {
      this.props.initializeRegistration();
    }
  }

  submitToken = (e) => {
    this.props.confirmRegistration(e.formData.token);
  };

  render() {
    if (!this.props.errorMessage && !this.props.qrCodeData) {
      return <div className="loading">Loading...</div>;
    }
    const { errorMessage } = this.props;

    let tokenForm = null;
    if (this.props.qrCodeData) {
      const schema = {
        type: 'object',
        required: ['token'],
        properties: {
          token: {
            type: 'string',
          },
        },
      };

      const uiSchema = {
        token: {
          'ui:placeholder': 'Six-digit code',
        },
      };
      tokenForm = (
        <>
          {this.props.isRequiredByAdmin && (
            <p>
              Your organization administrator requires you to register this account with
              multi-factor authentication. You can get started in 3 steps:
            </p>
          )}
          <div style={{ fontWeight: 'normal' }}>
            <p>
              1. If you do not already have an authenticator app, go to your phone's app store and
              download an authenticator app like Google Authenticator, Aegis Authenticator or
              Microsoft Authenticator.
            </p>
            <p>
              2. Using the app, scan this barcode to retrieve a six-digit code. Please note that the
              code may refresh itself after some time.
            </p>
            <p>3. Enter the code below to authenticate your secure Plural account.</p>
          </div>
          <div className="mb-3" style={{ textAlign: 'center' }}>
            <img src={this.props.qrCodeData} alt="QR Code" className="qr-code" />
          </div>
          <Form
            className="form login-form"
            showErrorList
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={this.submitToken}
            onError={() => {
              console.log('errors');
            }}
          >
            <div className="mb-3 login-btn" style={{ marginTop: '-25px' }}>
              <button id="submit-mfa-registration" type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        </>
      );
    }
    return (
      <div>
        <div>
          <h3 className="text-center mb-5">Enable multi-factor authentication</h3>
          {errorMessage && (
            <p
              className="alert alert-warning flash-error"
            // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
        </div>
        {tokenForm}
      </div>
    );
  }
}

MfaRegistration.propTypes = {
  qrCodeData: PropTypes.string,
  initializeRegistration: PropTypes.func,
  confirmRegistration: PropTypes.func,
  registrationSuccessful: PropTypes.bool,
  errorMessage: PropTypes.string,
  isRequiredByAdmin: PropTypes.bool,
};

export default MfaRegistration;
