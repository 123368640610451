import FeedbackData from '@enview/interface/types/actions/FeedbackData';
import {
  LegacyAnnotation,
  UserAction,
} from '@enview/interface/types/actions/UserAction';
import { BillAnnotation } from '@enview/interface/types/bills/BillAnnotation';
import difference from 'lodash-es/difference';
import * as Analytics from '../analytics/UserActionAnalytics';
import * as FileService from '../services';
import base, { ApiTag, PolicyAppAPIError } from './base';

const USER_ACTION_LIST_ID = 'USER_ACTION_LIST';

const convertUserActionToLegacyAnnotation = (
  userAction: Partial<UserAction>,
): Partial<LegacyAnnotation> => {
  const annotation = userAction.annotation ? { ...userAction.annotation } : {};
  const billData = userAction.billData ? { ...userAction.billData } : {};
  const personId = userAction.person ? userAction.person.id : undefined;
  const replyToId = userAction.replyTo ? userAction.replyTo.id : undefined;
  const legacyAnno = {
    ...userAction,
    ...annotation,
    ...billData,
    personId,
    replyToId,
    annotation: undefined,
    billData: undefined,
    replyTo: undefined,
  };
  return legacyAnno;
};

const trackUpdateUserAction = (
  newUserAction: Partial<UserAction>,
  oldUserAction: UserAction,
): void => {
  if (
    newUserAction.teams &&
    difference(oldUserAction.teams || [], newUserAction.teams)
  ) {
    Analytics.trackShareComment(newUserAction);
  } else if (newUserAction.isStarred !== oldUserAction.isStarred) {
    if (newUserAction.isStarred) Analytics.trackStarComment(newUserAction);
    else Analytics.trackUnstarComment(newUserAction);
  } else if (newUserAction.isArchived !== oldUserAction.isArchived) {
    if (newUserAction.isArchived) Analytics.trackArchiveComment(newUserAction);
    else Analytics.trackUnarchiveComment(newUserAction);
  } else {
    Analytics.trackEditComment(newUserAction);
  }
};

export const convertLegacyAnnotationToUserAction = (
  legacyAnno: LegacyAnnotation,
): UserAction => {
  const {
    fromLine,
    fromCharacter,
    toLine,
    toCharacter,
    selectedText,
    billId,
    bill,
    billVersionId,
    ...action
  } = legacyAnno;
  let annotation: BillAnnotation | undefined;
  if (selectedText !== null) {
    annotation = {
      fromLine: fromLine ?? '',
      fromCharacter: fromCharacter ?? 0,
      toLine: toLine ?? '',
      toCharacter: toCharacter ?? 0,
      selectedText: selectedText ?? '',
      isNewFormat: (fromLine ?? '').includes('-'),
    };
    // TODO: cleanup when we stop supporting BillTextMarkdown and old annotations.
  }
  const billData = billId
    ? {
        billId,
        billVersionId,
        bill,
      }
    : undefined;

  return {
    ...action,
    billId: billId ?? '',
    annotation,
    billData,
  };
};

const userActionAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getUserActions: build.query<
      UserAction[],
      {
        teamId?: number;
        includeBillDetails?: boolean;
        billId?: string;
        personId?: number;
      }
    >({
      query: (params) => ({ url: `/bill-annotations?`, method: 'GET', params }),
      extraOptions: { requiresAuth: true },
      transformResponse: (response: LegacyAnnotation[]) =>
        response.map((legacy) => convertLegacyAnnotationToUserAction(legacy)),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: ApiTag.USER_ACTIONS_TAG, id }) as const,
              ),
              { type: ApiTag.USER_ACTIONS_TAG, id: USER_ACTION_LIST_ID },
            ]
          : [{ type: ApiTag.USER_ACTIONS_TAG, id: USER_ACTION_LIST_ID }],
    }),

    addUserAction: build.mutation<
      UserAction,
      { userAction: Partial<UserAction>; attachedFile?: any }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { userAction, attachedFile } = arg;
        const data = convertUserActionToLegacyAnnotation(arg.userAction);
        if (attachedFile) {
          try {
            const userFileData = await FileService.uploadFile(
              attachedFile,
              data.sourceFeature || '',
              data.teams || [],
            );
            if (!data.userFileIds) {
              data.userFileIds = [];
            }
            data.userFileIds.push(userFileData.id);
            Analytics.trackUploadFile(userAction, `${userFileData.id}`);
          } catch (error) {
            return { error: error as PolicyAppAPIError };
          }
        }
        const response = await baseQuery({
          url: `/bill-annotations`,
          method: 'POST',
          data,
        });
        Analytics.trackCreateUserAction(userAction);
        return response.data
          ? {
              data: convertLegacyAnnotationToUserAction(
                response.data as LegacyAnnotation,
              ),
            }
          : { error: response.error };
      },
      invalidatesTags: [{ type: ApiTag.USER_ACTIONS_TAG, id: USER_ACTION_LIST_ID }],
    }),

    updateUserAction: build.mutation<
      UserAction,
      { newUserAction: Partial<UserAction>; oldUserAction: UserAction }
    >({
      query({ newUserAction, oldUserAction }) {
        trackUpdateUserAction(newUserAction, oldUserAction);
        const data = convertUserActionToLegacyAnnotation(newUserAction);
        return { url: `/bill-annotations/${newUserAction.id}`, method: 'PUT', data };
      },
      transformResponse: (response: LegacyAnnotation) =>
        convertLegacyAnnotationToUserAction(response),
      invalidatesTags: (result, error, { newUserAction }) => [
        { type: ApiTag.USER_ACTIONS_TAG, id: newUserAction.id },
      ],
    }),

    deleteUserAction: build.mutation<void, UserAction>({
      async queryFn(arg, api, extraOptions, baseQuery) {
        if (arg.userFiles.length > 0) {
          await FileService.deleteFile(arg.userFiles[0].id);
        }
        const response = await baseQuery({
          url: `/bill-annotations/${arg.id}`,
          method: 'DELETE',
        });
        if (response.error) {
          return { error: response.error };
        }
        Analytics.trackDeleteComment(arg);
        return { data: undefined };
      },
      invalidatesTags: (response, error, arg) => [
        { type: ApiTag.USER_ACTIONS_TAG, id: arg.id },
      ],
    }),
    sendFeedback: build.mutation<void, FeedbackData>({
      query: (data) => ({
        url: `/feedback`,
        method: 'POST',
        data: data,
      }),
      onQueryStarted: (data, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            Analytics.trackProblemReported(data);
          })
          .catch(() => {
            Analytics.trackProblemReportedError(data);
          });
      },
    }),
  }),
  overrideExisting: false,
});

export default userActionAPI;
