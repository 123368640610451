import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';

type ScrollButtonProps = {
  div: HTMLDivElement;
};

const ScrollButton = (props: ScrollButtonProps): ReactElement => {
  const { div } = props;

  const scrollToTop = (): void => {
    div.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <button className="scroll" onClick={scrollToTop} title="Back to top" type="button">
      <FontAwesomeIcon icon={faChevronUp} />
    </button>
  );
};

export default ScrollButton;
