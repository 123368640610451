import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Team } from '@enview/interface/types/Team';
import TextButton from '../Shared/Buttons/TextButton';
import { getOrgIdForTeamMode, setTeamMode } from '../../dux';
import { State } from '../../dux/@types';
import { TeamAPI } from '../../api';
import SwitchIcon from '../Shared/Icons/SwitchIcon';

type TeamEditForm = {
  name: string;
  description: string;
  isExternal: boolean;
};

type CreateEditTeamProps = {
  team?: Team;
  isTeamCreation: boolean;
  closeModal: () => void;
};

const aboutEnviewUniteUrl = 'https://help.pluralpolicy.com/a-guide-to-enview-unite';

const CreateEditTeam = (props: CreateEditTeamProps): ReactElement => {
  const { team, isTeamCreation, closeModal } = props;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  useEffect(() => register('isExternal'), []);
  const isExternalToggle = watch('isExternal', team?.isExternal || false);
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const [editTeam, { isSuccess: editSuccess }] =
    TeamAPI.endpoints.editTeam.useMutation();
  const [createTeam, { isSuccess: createSuccess, data: newTeam }] =
    TeamAPI.endpoints.createTeam.useMutation();

  const organizationId = useSelector(getOrgIdForTeamMode);
  const dispatch = useDispatch();

  const setValues = useCallback(
    (currentTeam: Team): void => {
      setValue('name', currentTeam.name);
      setValue('description', currentTeam.description);
      setValue('isExternal', currentTeam.isExternal);
    },
    [setValue],
  );

  const resetValues = useCallback((): void => {
    setValue('name', '');
    setValue('description', '');
    setValue('isExternal', false);
  }, [setValue]);

  useEffect(() => {
    if (!isTeamCreation && team) {
      setValues(team);
    }
  }, [setValues, isTeamCreation, team]);

  useEffect(() => {
    if (createSuccess && newTeam) {
      dispatch(setTeamMode(newTeam.id));
      closeModal();
      resetValues();
    }
    if (editSuccess) {
      closeModal();
      resetValues();
    }
  }, [dispatch, closeModal, resetValues, createSuccess, editSuccess, newTeam]);

  const onSubmit = (data: TeamEditForm): void => {
    const { name, description, isExternal } = data;
    const payload: Partial<Team> = {
      name,
      description,
      isExternal,
      organizationId: organizationId || orgUser?.organizations[0].id,
    };
    if (!isTeamCreation && team) {
      payload.id = team.id;
      editTeam(payload);
    }
    if (isTeamCreation) {
      createTeam(payload);
    }
  };

  return (
    <div>
      <form className="form registration-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field-header">Name</div>
        <div className="row">
          <input
            className="form-control form-field"
            name="name"
            ref={register({
              required: { value: true, message: 'Please fill out this field.' },
            })}
            type="text"
          />
        </div>
        {errors.name && (
          <div className="error alert alert-danger">{errors.name.message}</div>
        )}
        <div className="field-header">
          Description <span style={{ fontWeight: 400 }}>(optional)</span>
        </div>
        <div className="row">
          <textarea
            className="form-control form-field"
            name="description"
            ref={register({
              maxLength: {
                value: 255,
                message: 'Description cannot exceed 255 characters.',
              },
            })}
          />
        </div>
        <div className="field-header">Plural Unite?</div>
        <div className="row justify-content-between no-gutters">
          <div className="col-9 col-sm-10">
            Plural Unite workspaces are meant to be used for purposes that may be
            separate from work specific to your organization. For more information,
            see&nbsp;
            <a href={aboutEnviewUniteUrl} rel="noopener noreferrer" target="_blank">
              this article
            </a>
            .
          </div>
          <div className="d-flex col-2 col-sm-1 align-items-center">
            <SwitchIcon
              key="isExternal"
              onChange={(value) => {
                setValue('isExternal', value);
              }}
              value={isExternalToggle}
            />
          </div>
        </div>
        <div className="submit">
          <button className="btn btn-action" type="submit">
            Save
          </button>
          <TextButton label="Cancel" onClick={closeModal} />
        </div>
      </form>
    </div>
  );
};

export default CreateEditTeam;
