import { t } from 'i18next';
import { ReactElement, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TagAPI } from '../../../../api';
import TagIcon from '../../../../components/svg/TagIcon';
import { getTeamModeTeam } from '../../../../dux';
import { sortTags } from '../../../../helpers/TagHelper';
import { SortBy, SortOrder } from '../../../../models/SortOrder';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';

const TAG_SORT_ORDER = new SortOrder(SortBy.ALPHABETICAL, false);
const TAG_PATH = '/legislative-tracking/tagged-bills/';
const NO_TAGS_MESSAGE = 'No tags';

const TagsSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;
  const { data: allTags } = TagAPI.endpoints.getTags.useQuery(undefined);
  const team = useSelector(getTeamModeTeam);
  const tags = useMemo(
    () => allTags?.filter((tag) => tag.teamId === team?.id),
    [team, allTags],
  );

  const sortedTags = sortTags(tags ?? [], TAG_SORT_ORDER);
  const mappedTags = sortedTags.map(
    (tag): ReactElement => (
      <NavLink
        activeClassName="active-nav-link"
        className="body-link sub-item"
        key={tag.id}
        onClick={onNavClick}
        to={`${TAG_PATH}${tag.id}`}
      >
        <span>{tag.name.replace('#', '')}</span>
        <br />
      </NavLink>
    ),
  );

  const getCardBody = (): ReactElement => {
    return <>{mappedTags?.length ? mappedTags : NO_TAGS_MESSAGE}</>;
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      customGateTooltipContent={
        <Trans
          components={{
            tagLink: <a href={t('urls.tags')} target="_blank" />,
            gate: <p className="tooltip-header" />,
          }}
          i18nKey="featureGating.tooltipText.tagsSidebar"
          key="featureGating.tooltipText.tagsSidebar"
        />
      }
      eventKey="TAGS"
      header="Tags"
      icon={<TagIcon />}
    />
  );
};

export default TagsSidebarSection;
