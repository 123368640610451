import {
  Acknowledgement,
  SessionBillDataQualityReport,
} from '@enview/interface/types/BillDataQuality';
import { PolicyArea } from '@enview/interface/types/PolicyArea';
import { Organization } from '@enview/interface/types/Organization';
import { UserInvitation } from '@enview/interface/types/OrganizationUser';
import { JurisdictionWithAccounts } from '@enview/interface/src/types/Jurisdiction';
import { Duration, MissingBillTime } from '../../models/BillDataQuality';

// ACTIONS
export const SET_ORGANIZATIONS = 'admin/setOrganizations';
export const SET_ORGANIZATION_CREATED = 'admin/setOrganizationCreated';
export const SET_SELECTED_ORGANIZATION = 'admin/setSelectedOrganization';
export const SET_PENDING_INVITES = 'admin/setPendingInvites';
export const SET_MISSING_BILL_TIMES_REPORT = 'admin/setMissingBillTimesReport';
export const EXPORT_USERS_TO_AIRTABLE = 'admin/exportUsersTpAirtable';
export const CLEAR_MISSING_BILL_TIMES_REPORT = 'admin/clearMissingBillTimesReport';
export const SET_USER_MASQUERADE_LINK = 'admin/setUserMasqueradeLink';
export const CLEAR_USER_MASQUERADE_LINK = 'admin/clearUserMasqueradeLink';
export const SET_RESET_PASSWORD_LINK = 'admin/setResetPasswordLink';
export const CLEAR_RESET_PASSWORD_LINK = 'admin/clearResetPasswordLink';
export const SET_BDQ_REPORT = 'admin/setBDQReport';
export const SET_BDQ_DEFECT_ACK = 'admin/setBDQDefectAck';
export const SET_SITE_WIDE_POLICY_AREAS = 'admin/setSiteWidePolicyAreas';
export const SET_JURISDICTIONS_WITH_ACCOUNT_ACCESS =
  'admin/setJurisdictionsWithAccountAccess';

export type AdminState = {
  organizations: Organization[];
  organizationCreated?: {
    organizationId: number;
    userInvite: string;
  };
  selectedOrganization: Organization;
  pendingInvites: UserInvitation[];
  missingBillTimesReport: MissingBillTime[];
  sourceScrapeRunTimes: Duration[];
  verificationTimes: Duration[];
  masqueradeLink: string;
  resetPasswordLink: string;
  bdqReports: SessionBillDataQualityReport[];
  bdqAcknowledgements: Acknowledgement[];
  siteWidePolicyAreas: PolicyArea[];
  jurisdictionsWithAccountAccess?: JurisdictionWithAccounts[];
};

type SetOrganizationsAction = {
  type: typeof SET_ORGANIZATIONS;
  organizations: Organization[];
};

type SetOrganizationCreatedAction = {
  type: typeof SET_ORGANIZATION_CREATED;
  organizationCreated: {
    organizationId: number;
    userInvite: string;
  };
};

type SetSelectedOrganizationAction = {
  type: typeof SET_SELECTED_ORGANIZATION;
  selectedOrganization: Organization;
};

type SetPendingInvitesAction = {
  type: typeof SET_PENDING_INVITES;
  pendingInvites: UserInvitation[];
};

type SetMissingBillTimesReportAction = {
  type: typeof SET_MISSING_BILL_TIMES_REPORT;
  missingBillTimesReport: MissingBillTime[];
  sourceScrapeRunTimes: Duration[];
  verificationTimes: Duration[];
};

type ClearMissingBillTimesReportAction = {
  type: typeof CLEAR_MISSING_BILL_TIMES_REPORT;
};

type SetMasqueradeLinkAction = {
  type: typeof SET_USER_MASQUERADE_LINK;
  masqueradeLink: string;
};

type ClearMasqueradeLinkAction = {
  type: typeof CLEAR_USER_MASQUERADE_LINK;
};

type SetResetPasswordLinkAction = {
  type: typeof SET_RESET_PASSWORD_LINK;
  resetPasswordLink: string;
};

type ClearResetPasswordLinkAction = {
  type: typeof CLEAR_RESET_PASSWORD_LINK;
};

type SetBDQReportAction = {
  type: typeof SET_BDQ_REPORT;
  bdqReports: SessionBillDataQualityReport[];
  bdqAcknowledgements: Acknowledgement[];
};

type SetBDQDefectAckAction = {
  type: typeof SET_BDQ_DEFECT_ACK;
  acknowledgement: Acknowledgement;
};

type SetSiteWidePolicyAreasAction = {
  type: typeof SET_SITE_WIDE_POLICY_AREAS;
  policyAreas: PolicyArea[];
};

type SetJurisdictionsWithAccountAccess = {
  type: typeof SET_JURISDICTIONS_WITH_ACCOUNT_ACCESS;
  jurisdictionsWithAccountAccess: JurisdictionWithAccounts[];
};

export type Action =
  | SetOrganizationsAction
  | SetOrganizationCreatedAction
  | SetPendingInvitesAction
  | SetSelectedOrganizationAction
  | SetMissingBillTimesReportAction
  | ClearMissingBillTimesReportAction
  | SetMasqueradeLinkAction
  | ClearMasqueradeLinkAction
  | SetResetPasswordLinkAction
  | ClearResetPasswordLinkAction
  | SetBDQReportAction
  | SetBDQDefectAckAction
  | SetSiteWidePolicyAreasAction
  | SetJurisdictionsWithAccountAccess;
