import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from '../../dux/@types';
import TextButton from '../Shared/Buttons/TextButton';
import { logout } from '../../dux';

const MasqueradeUserSessionOn = (): ReactElement | null => {
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const isMasqueradeSession = useSelector(
    (state: State) => state.authentication.isMasqueradeSession,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (): void => {
    dispatch(logout());
    history.replace('/');
  };
  if (isMasqueradeSession && organizationUser) {
    return (
      <div className="warning-banner alert-warning">
        <p className="warning-text">
          Masquerading as <span className="email">{organizationUser.email}</span>
          .
          <TextButton
            className="accept-btn"
            label="Log out"
            onClick={() => handleLogout()}
          />
        </p>
      </div>
    );
  }
  return null;
};

export default MasqueradeUserSessionOn;
