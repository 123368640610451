import React from 'react';

export default (props) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: '100%' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8824 4H10.1176V10.1177H4V13.8824H10.1176V20H13.8824V13.8824H20V10.1177H13.8824V4Z"
      fill="#C4C4C4"
    />
  </svg>
);
