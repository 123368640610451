import JurisdictionAbbreviationMap from '@enview/interface/types/JurisdictionAbbreviationMap';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import Jurisdictions from '../../../scenes/Jurisdictions/Jurisdictions';
import BreadcrumbGroup, {
  BreadcrumbItem,
} from '../../Shared/Breadcrumbs/BreadcrumbGroup';
import JurisdictionHearings from '../Hearings/JurisdictionHearings';
import JurisdictionCommittees from '../JurisdictionCommittees';
import JurisdictionLegislators from '../JurisdictionLegislators';
import '../Jurisdictions.scss';
import JurisdictionOverviewCard from './JurisdictionOverviewCard';
import JurisdictionPageDropdown from './JurisdictionPageDropdown';
import JurisdictionSessions from '../JurisdictionSessions';
import JurisdictionBills from '../JurisdictionBills';
import { State, Thunk } from '../../../dux/@types';
import { useSelector, useDispatch } from 'react-redux';
import TeamBills from '../../TeamView/TeamBills';
import { checkPermission } from '../../../dux';
import { g as abilityGlossary } from '../../../config/ability';
import { BillSearchView } from '@enview/interface/types/BillSearch';

type JurisdictionOverviewProps = {
  jurisAbbr?: string;
};

const JurisdictionPage = (props: JurisdictionOverviewProps): ReactElement => {
  const dispatch = useDispatch();
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const billSearchView =
    useSelector((state: State) => state.account.billSearchView) ?? BillSearchView.Card;
  const canViewTeams = (): Thunk<boolean> => {
    return dispatch(checkPermission(abilityGlossary.VIEW, abilityGlossary.TEAM, true));
  };
  const { jurisAbbr = '' } = props;
  const { t } = useTranslation();

  let jurisKey: keyof typeof JurisdictionAbbreviationMap | undefined;
  let jurisdictionName = '';
  if (jurisAbbr) {
    jurisKey = jurisAbbr.toUpperCase() as keyof typeof JurisdictionAbbreviationMap;
    jurisdictionName = JurisdictionAbbreviationMap[jurisKey];
  }

  const jurisdictionBreadCrumbs: BreadcrumbItem[] = [
    {
      to: t('jurisdictions.urls.basePath'),
      label: t('jurisdictions.breadcrumbs.exploreTitle'),
    },
    {
      to: t('jurisdictions.urls.pathPrefix', { jurisAbbr }),
      label: jurisdictionName,
    },
    {
      to: t('jurisdictions.urls.committees', { jurisAbbr }),
      label: t('jurisdictions.breadcrumbs.committeesTitle'),
    },
    {
      to: t('jurisdictions.urls.legislators', { jurisAbbr }),
      label: t('jurisdictions.breadcrumbs.legislatorsTitle'),
    },
    {
      to: t('jurisdictions.urls.hearings', { jurisAbbr }),
      label: t('jurisdictions.breadcrumbs.hearingsTitle'),
    },
    {
      to: t('jurisdictions.urls.bills', { jurisAbbr }),
      label: t('jurisdictions.breadcrumbs.billsTitle'),
    },
  ];

  return (
    <div className="jurisdiction-page-wrapper">
      <div className="row">
        <BreadcrumbGroup breadcrumbItems={jurisdictionBreadCrumbs} />
        <JurisdictionPageDropdown jurisAbbr={jurisKey} />
      </div>
      <div>
        {jurisKey && (
          <>
            <JurisdictionOverviewCard jurisAbbr={jurisKey} />
            <Switch>
              <Route
                path={t('jurisdictions.urls.legislators', {
                  jurisAbbr,
                })}
                render={() => (
                  <JurisdictionLegislators jurisAbbr={jurisKey as string} />
                )}
              />
              <Route
                path={t('jurisdictions.urls.committees', {
                  jurisAbbr,
                })}
                render={() => <JurisdictionCommittees jurisAbbr={jurisKey as string} />}
              />
              <Route
                path={t('jurisdictions.urls.hearings', {
                  jurisAbbr,
                })}
                render={() => <JurisdictionHearings jurisAbbr={jurisKey as string} />}
              />
              <Route
                path={t('jurisdictions.urls.bills', {
                  jurisAbbr,
                })}
                render={() => {
                  return orgUser && canViewTeams() ? (
                    <TeamBills
                      filterToJurisdictions={[jurisAbbr]}
                      noBillsMessage="Your team has not tracked any bills yet in this jurisdiction."
                      orgUser={orgUser}
                      showJurisdictionFilter={false}
                      view={billSearchView}
                    />
                  ) : (
                    <JurisdictionBills jurisAbbr={jurisAbbr} />
                  );
                }}
              />
              <Route
                exact
                path={t('jurisdictions.urls.pathPrefix', {
                  jurisAbbr,
                })}
                render={() => <JurisdictionSessions jurisAbbr={jurisKey as string} />}
              />
            </Switch>
          </>
        )}
        {!jurisKey && <Jurisdictions />}
      </div>
      <div>
        {/* TODO: Add JurisdictionBillsPreview here */}
        {/* TODO: Add JurisdictionLegislatorsPreview here */}
        {/* TODO: Add JurisdictionCommitteesPreview here */}
      </div>
    </div>
  );
};

export default JurisdictionPage;
