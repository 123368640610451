import React, { ReactElement } from 'react';
import { PersonAPI } from '../../api';
import './Jurisdictions.scss';
import LegislatorCardView from '../Committee/LegislatorCardView';
import { SEOHead } from '../../components/head/SEOHead';
import STATES from '../../helpers/states';

type JurisdictionLegislatorsProps = {
  jurisAbbr: string;
};

const JurisdictionLegislators = (props: JurisdictionLegislatorsProps): ReactElement => {
  const { jurisAbbr } = props;
  const {
    data: legislators,
    isFetching,
    isError,
  } = PersonAPI.endpoints.getPersonsByJurisdiction.useQuery(jurisAbbr);
  if (isFetching) {
    return <span>Loading legislators...</span>;
  }
  if (isError) {
    return <span>Error loading legislators!</span>;
  }
  const stateName = STATES[jurisAbbr.toUpperCase()];
  return (
    <>
      <SEOHead
        description={`details on legislators in ${stateName}`}
        title={`${stateName} Legislators`}
      />
      <h2 className="jurisdiction-tab-heading">{`${legislators?.length} Legislators`}</h2>
      <LegislatorCardView legislators={legislators} />
    </>
  );
};

export default JurisdictionLegislators;
