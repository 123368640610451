import { connect } from 'react-redux';
import MfaRegistration from '../components/MfaRegistration';
import { initializeMfaRegistration, confirmMfaRegistration } from '../dux';

export const mapStateToProps = (state) => {
  return {
    qrCodeData: state.authentication.mfaQrCodeData,
    registrationSuccessful: state.authentication.mfaRegistrationSuccessful,
    errorMessage: state.authentication.mfaRegistrationErrorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    initializeRegistration: () => {
      dispatch(initializeMfaRegistration());
    },
    confirmRegistration: (token) => {
      dispatch(confirmMfaRegistration(token));
    },
  };
};

const MfaRegistrationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MfaRegistration);

export default MfaRegistrationContainer;
