import { BillStatus } from '@enview/interface/types/Bill';
import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';
import { translateBillStatusForBadge } from '../../helpers/BillHelper';

type BillStatusBadgeProps = {
  bill: Bill;
  isCompactMode?: boolean;
};

const BillStatusBadge = (props: BillStatusBadgeProps): ReactElement => {
  const { bill, isCompactMode } = props;
  const status = (bill.status as BillStatus) ?? BillStatus.INTRODUCED;

  return (
    <button
      className={`btn btn-sm btn-secondary toggle bill-status-toggle ${status?.toLowerCase()} ${
        isCompactMode ? 'compact' : ''
      }`}
      disabled
      type="button"
    >
      {translateBillStatusForBadge(status)}
    </button>
  );
};

export default BillStatusBadge;
