import { Tag } from '@enview/interface/types/tags/Tag';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import * as Analytics from '../analytics/TagAnalytics';
import { State } from '../dux/@types';
import base, { ApiTag } from './base';

const TAG_LIST_ID = 'TAG_LIST';

const trackTagActivity = (tag: Partial<Tag>, orgUser?: OrganizationUser): void => {
  if (tag.id) {
    if (tag.isPublic) {
      Analytics.trackTagPublished(
        tag.name!,
        tag.teamId!,
        tag.description,
        tag.isPublic,
        orgUser,
      );
    } else {
      Analytics.trackTagUpdated(
        tag.name!,
        tag.teamId!,
        tag.description,
        tag.isPublic,
        orgUser,
      );
    }
  } else {
    Analytics.trackTagCreated(
      tag.name!,
      tag.teamId!,
      tag.description,
      tag.isPublic,
      orgUser,
    );
  }
};

const tagAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<Tag[], void>({
      query: () => ({ url: `/organization-user/tags`, method: 'GET' }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: ApiTag.TAG_TAG, id }) as const),
              { type: ApiTag.TAG_TAG, id: TAG_LIST_ID },
            ]
          : [{ type: ApiTag.TAG_TAG, id: TAG_LIST_ID }],
    }),

    saveTag: build.mutation<Tag, Partial<Tag>>({
      query: (tag) =>
        tag.id
          ? { url: `/tags/${tag.id}`, method: 'PUT', data: tag }
          : { url: `/tags`, method: 'POST', data: tag },
      invalidatesTags: (result) =>
        result
          ? [
              { type: ApiTag.USER_ACTIONS_TAG, id: result.id },
              { type: ApiTag.TAG_TAG, id: TAG_LIST_ID },
            ]
          : [{ type: ApiTag.TAG_TAG, id: TAG_LIST_ID }],
      onQueryStarted: (tag, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        trackTagActivity(tag, orgUser);
      },
    }),

    deleteTag: build.mutation<void, Tag>({
      query: ({ id }) => ({ url: `/tags/${id}`, method: 'DELETE' }),
      onQueryStarted: (tag, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        Analytics.trackTagDeleted(
          tag.name,
          tag.teamId,
          tag.description,
          tag.isPublic,
          orgUser,
        );
      },
      invalidatesTags: (response, error, { id }) => [{ type: ApiTag.TAG_TAG, id }],
    }),
    getPublicTag: build.query<Tag, number>({
      query: (tagId) => ({ url: `/tags/${tagId}`, method: 'GET' }),
      providesTags: (response) =>
        response ? [{ type: ApiTag.TAG_TAG, id: response.id }] : [],
    }),
  }),
  overrideExisting: false,
});

export default tagAPI;
