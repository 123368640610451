import assign from 'lodash-es/assign';
import { Action, State } from '../@types';
import {
  CLEAR_SELECTED_TEAMS_ACTION,
  SET_SELECTED_TEAMS_ACTION,
  TeamSelectorState,
} from './types';

// REDUCER
export default function reducer(
  state: TeamSelectorState,
  action: Action,
): TeamSelectorState {
  switch (action.type) {
    case SET_SELECTED_TEAMS_ACTION:
      return assign({}, state, {
        selectedTeamIds: action.teamIds,
      });
    case CLEAR_SELECTED_TEAMS_ACTION:
      return assign({}, state, {
        selectedTeamIds: undefined,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
export const setSelectedTeamIds = (teamIds: number[]): Action => {
  return {
    type: SET_SELECTED_TEAMS_ACTION,
    teamIds,
  };
};

export const clearSelectedTeamIds = (): Action => {
  return {
    type: CLEAR_SELECTED_TEAMS_ACTION,
  };
};

// SELECTORS
export const getSelectedTeamIds = (state: State): number[] => {
  return state.teamSelectors.selectedTeamIds || [];
};
