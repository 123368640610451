import { SearchActionFilter } from '@enview/interface/types/Bill';
import { SavedSearch } from '@enview/interface/types/BillSearch';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Bill, BillDetailed } from '@enview/interface/types/bills/Bill';
import { BillAlias } from '@enview/interface/types/bills/BillSummary';
import { Tag } from '@enview/interface/types/tags/Tag';
import { track } from './SegmentAnalytics';

enum AnalyticsEvent {
  BILLS_SEARCHED = 'Bills Searched',
  BILL_TRACKED = 'Bill Tracked',
  BILL_UNTRACKED = 'Bill Untracked',
  BILL_BULK_TRACKED = 'Bulk Bills Tracked',
  BILL_BULK_UNTRACKED = 'Bulk Bills Untracked',
  BILL_TEAM_TRACKED = 'Bill Team Tracked',
  BILL_SHARED = 'Bill Shared',
  BILL_GLOBAL_SEARCH = 'Global Bill Search Performed',
  BILL_TAGGED = 'Bill Tagged',
  BILL_UNTAGGED = 'Bill Untagged',
  BILL_BULK_TAGGED = 'Bulk Bills Tagged',
  BILL_BULK_UNTAGGED = 'Bulk Bills Untagged',
  BILL_VERSION_VIEWED = 'Bill Version Viewed',
  BILL_SUMMARY = 'AI Bill Summary Viewed',
  BILL_VERSION_TO_VERSION = 'AI Bill Version to Version Summary Viewed',
  ADD_BILL_ALIAS = 'Add Bill Alias',
  UPDATE_BILL_ALIAS = 'Update Bill Alias',
  DELETE_BILL_ALIAS = 'Delete Bill Alias',
  SIMILAR_BILLS_RESULT_VIEWED = 'Viewed similar bill result',
  MOMENTUM_FEEDBACK = 'Momentum Feedback',
  IDENTICAL_BILL_FEEDBACK = 'Identical Bill Feedback',
  SAVED_SEARCH_QUERY = 'Saved Search Query',
  BILL_AUTO_SUMMARY_FEEDBACK = 'Bill Auto Summary Feedback',
  EOSR_AUTO_SUMMARY_FEEDBACK = 'EOSR Auto Summary Feedback',
  BILL_VERSION_TO_VERSION_SUMMARY_FEEDBACK = 'Bill Version to Version Summary Feedback',
  BILL_POSITION_ADDED = 'Bill Position Added',
  BILL_POSITION_PUBLISHED = 'Bill Position Published',
  BILL_POSITION_UPDATED = 'Bill Position Updated',
  BILL_POSITION_DELETED = 'Bill Position Deleted',
}

const PRIVATE = 'private';
const FEDERAL = 'federal';

export const trackBillsSearched = (
  jurisdictionFilters: string[],
  sessionFilters: string[],
  statusFilters: string[],
  teamId: number,
  query?: string,
  orgUser?: OrganizationUser,
  chamber?: string,
  includeTrackedBills?: boolean,
  onlyShowMomentumBills?: boolean,
  legislativeType?: string[],
  billAction?: SearchActionFilter,
  insightsPolicyTopic?: string[],
  savedSearch?: SavedSearch,
): void => {
  const statuses = statusFilters.toString();

  track(AnalyticsEvent.BILLS_SEARCHED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    keywords: query || '',
    teamIds: teamId,
    states: jurisdictionFilters.join(', '),
    jurisdictions: jurisdictionFilters.join(', '),
    sessions: sessionFilters.join(', '),
    legislativeType: legislativeType ? legislativeType.join(', ') : '',
    statuses,
    chamber,
    includeTrackedBills,
    onlyShowMomentumBills,
    billAction,
    insightsPolicyTopic,
    isSavedSearch: !!savedSearch,
    savedSearchName: savedSearch ? savedSearch.name : undefined,
  });
};

export const savedSearchQuery = (
  jurisdictionFilters: string[],
  sessionFilters: string[],
  statusFilters: string[],
  includeTrackedBills?: boolean,
  billAction?: SearchActionFilter,
  keywords?: string,
  chamber?: string,
  legislativeType?: string[],
): void => {
  track(AnalyticsEvent.SAVED_SEARCH_QUERY, {
    keywords: keywords || '',
    jurisdictions: jurisdictionFilters.join(', '),
    sessions: sessionFilters.join(', '),
    includeTrackedBills,
    billAction,
    chamber,
    legislativeType: legislativeType ? legislativeType.join(', ') : '',
    status: statusFilters.join(', '),
  });
};

export const trackBillTracked = (
  bill: Bill,
  teamIds: number[],
  orgUser?: OrganizationUser,
): void => {
  const event =
    teamIds && teamIds.length > 0
      ? AnalyticsEvent.BILL_TEAM_TRACKED
      : AnalyticsEvent.BILL_TRACKED;
  track(event, {
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: teamIds.join(','),
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    hasMomentum: !!bill.salienceScore,
  });
};

export const trackBulkBillsTracked = (
  bills: Bill[],
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_BULK_TRACKED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    billCount: bills.length,
    teamId: teamId,
    billIds: bills.map((bill) => bill.id).join(','),
    states: Array.from(new Set(bills.map((bill) => bill.state))).join(','),
  });
};

export const trackBillUntracked = (
  bill: Bill,
  teamIds: number[],
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_UNTRACKED, {
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: teamIds.join(','),
  });
};

export const trackBulkBillsUntracked = (
  bills: Bill[],
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_BULK_UNTRACKED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    billCount: bills.length,
    teamId: teamId,
    billIds: bills.map((bill) => bill.id).join(','),
    states: Array.from(new Set(bills.map((bill) => bill.state))).join(','),
  });
};

export const trackBillShared = (
  emails: string[],
  bill: Bill,
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_SHARED, {
    recipientEmails: emails.join(','),
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: teamId,
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
  });
};

export const trackGlobalBillSearched = (
  versionId: number,
  query: string,
  teamId: number,
  bill?: BillDetailed,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_GLOBAL_SEARCH, {
    versionId,
    query,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: teamId,
    billId: bill?.id,
    state: bill?.state,
    jurisdiction: bill ? bill.state || FEDERAL : undefined,
  });
};

export const trackBillTagged = (
  tag: Tag,
  bill: Bill,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_TAGGED, {
    tagName: tag.name,
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: PRIVATE, // TODO: update when tags team behavior works,
  });
};

export const trackBulkBillsTagged = (
  bills: Bill[],
  tag: Tag,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_BULK_TAGGED, {
    tagName: tag.name,
    billCount: bills.length,
    billIds: bills.map((bill) => bill.id).join(','),
    states: Array.from(new Set(bills.map((bill) => bill.state))).join(','),
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: PRIVATE,
  });
};

export const trackBillUntagged = (
  tag: Tag,
  bill: Bill,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_UNTAGGED, {
    tagName: tag.name,
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: PRIVATE, // TODO: update when tags team behavior works,
  });
};

export const trackBulkBillsUntagged = (
  bills: Bill[],
  tag: Tag,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_BULK_UNTAGGED, {
    tagName: tag.name,
    billCount: bills.length,
    billIds: bills.map((bill) => bill.id).join(','),
    states: Array.from(new Set(bills.map((bill) => bill.state))).join(','),
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: PRIVATE,
  });
};

export const addBillAlias = (
  bill: Bill,
  alias: Partial<BillAlias>,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.ADD_BILL_ALIAS, {
    alias: alias.alias,
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: alias.teamId,
  });
};

export const updateBillAlias = (
  bill: Bill,
  alias: Partial<BillAlias>,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.UPDATE_BILL_ALIAS, {
    alias: alias.alias,
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: alias.teamId,
  });
};

export const deleteBillAlias = (
  bill: Bill,
  alias: BillAlias,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.DELETE_BILL_ALIAS, {
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamIds: alias.teamId,
  });
};

export const trackBillVersionViewed = (
  bill: BillDetailed,
  value: string,
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.BILL_VERSION_VIEWED, {
    billId: bill.id,
    state: bill.state,
    jurisdiction: bill.state || FEDERAL,
    username: orgUser ? orgUser.email : 'Not logged in',
    teamId,
    value,
  });
};

export const trackSimilarBillsResultViewed = (
  billId: string,
  jurisdiction: string,
  query: string,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.SIMILAR_BILLS_RESULT_VIEWED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    billId,
    query,
    jurisdiction,
  });
};

export const trackMomentumFeedback = (billId: string, wasHelpful: boolean): void => {
  track(AnalyticsEvent.MOMENTUM_FEEDBACK, {
    billId,
    wasHelpful,
  });
};

export const trackIdenticalBillFeedback = (
  originalBillId: string,
  originalBillVersion: number,
  recommendedBillId: string,
  recommendedBillVersion: number,
  wasHelpful: boolean,
): void => {
  track(AnalyticsEvent.IDENTICAL_BILL_FEEDBACK, {
    originalBillId,
    originalBillVersion,
    recommendedBillId,
    recommendedBillVersion,
    wasHelpful,
  });
};

export const trackBillSummary = (billId: string, tab: string): void => {
  track(AnalyticsEvent.BILL_SUMMARY, {
    billId,
    tab,
  });
};

export const trackBillVersionToVersionSummaryViewed = (
  billId: string,
  billVersion1: number,
  billVersion2: number,
): void => {
  track(AnalyticsEvent.BILL_VERSION_TO_VERSION, {
    billId,
    billVersion1,
    billVersion2,
  });
};

export const trackBillAutoSummaryFeedback = (
  billId: string,
  billVersion: number,
  isAccurate: boolean,
  summaryText: string,
): void => {
  track(AnalyticsEvent.BILL_AUTO_SUMMARY_FEEDBACK, {
    billId,
    billVersion,
    isAccurate,
    summaryText,
  });
};

export const trackBillVersionToVersionSummaryFeedback = (
  billId: string,
  billVersion1: number,
  billVersion2: number,
  isAccurate: boolean,
  summaryText: string,
): void => {
  track(AnalyticsEvent.BILL_VERSION_TO_VERSION_SUMMARY_FEEDBACK, {
    billId,
    billVersion1,
    billVersion2,
    isAccurate,
    summaryText,
  });
};

export const trackEndOfSessionReportFeedback = (
  jurisdictionAbbreviation: string,
  insightsPolicyTopicName: string,
  isAccurate: boolean,
  summaryText: string,
): void => {
  track(AnalyticsEvent.EOSR_AUTO_SUMMARY_FEEDBACK, {
    jurisdictionAbbreviation,
    insightsPolicyTopicName,
    isAccurate,
    summaryText,
  });
};

export const trackBillPositionViewed = (billId: string): void => {
  track(AnalyticsEvent.BILL_POSITION_ADDED, {
    billId,
  });
};

export const trackBillPositionAdded = (
  billId: string,
  hasStance: boolean,
  hasComment: boolean,
  isPublic: boolean,
): void => {
  track(AnalyticsEvent.BILL_POSITION_ADDED, {
    billId,
    hasStance,
    hasComment,
    isPublic,
  });
};

export const trackBillPositionUpdated = (
  billId: string,
  hasStance: boolean,
  hasComment: boolean,
  isPublic: boolean,
): void => {
  track(AnalyticsEvent.BILL_POSITION_UPDATED, {
    billId,
    hasStance,
    hasComment,
    isPublic,
  });
};

export const trackBillPositionDeleted = (billId: string): void => {
  track(AnalyticsEvent.BILL_POSITION_DELETED, {
    billId,
  });
};
