import React from 'react';

/* This component is intended to be a convenient way to add an image to a component
when linking to an image file that is included in the repository. Useful for logo images,
banners, and small icon images that can't be rendered as an SVG. Assumes that the fileName
passed as a prop corresponds to a file within the public/images directory of this package.

The PUBLIC_URL environment variable controls the "Root URL" of the deployed site, and will
be prefixed to the img src
*/

type ImageProps = {
    id?: string;
    className?: string;
    fileName: string;
    alt: string;
    height?: number;
    width?: number;
  };

const ROOT_URL = process.env.PUBLIC_URL || '';

const Image = (props: ImageProps) => (
    <img
    id={props.id}
    className={props.className}
    src={`${ROOT_URL}/images/${props.fileName}`}
    alt={props.alt}
    height={props.height}
    width={props.width}
     />
);

export default Image;
