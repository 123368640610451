/* eslint-disable react/jsx-max-depth */
import { Bill, BillAction, BillSponsor } from '@enview/interface/types/bills/Bill';
import { BillSummary } from '@enview/interface/types/bills/BillSummary';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BillAPI } from '../../api';
import { getTeamMode } from '../../dux';
import { State } from '../../dux/@types';
import featureFlags, { FrontendFeature } from '../../featureFlags';
import { convertIdToAbbreviatedTitle, getBillAlias } from '../../helpers/BillHelper';
import { SortBy, SortOrder } from '../../models/SortOrder';
import BillTagButton from '../Shared/BillTagging/BillTagButton';
import BillTrackingButton from '../Shared/BillTracking/BillTrackingButton';
import BillBulkActionsDropdown from '../Shared/DropDowns/BillBulkActionsDropdown';
import BillViewDropdown from '../Shared/DropDowns/BillViewDropdown';
import BillStatusBadge from './BillStatusBadge';
import NoBillResults from './NoBillResults';
import BillPosition from '../Shared/BillPosition/BillPosition';
import Info from '../../components/svg/InfoCircleIcon';
import {
  democratPartyIdentifier,
  partyString,
  republicanPartyIdentifier,
} from '../../utils';
import { IllinoisKeyActions } from '@enview/interface/utils/Language';
import { getMostRecentAction, renderBillActionDate } from '../../helpers/BillHelper';

export interface SortableDataTableColumn extends IDataTableColumn {
  sortKey?: SortBy;
}

type TabularBillSearchResultProps = {
  params: any;
  searching: boolean;
  setSearching: (arg: boolean) => void;
  query: any;
  setQuery: (arg: any) => void;
};

// TODO: Refactor to use modular BillTable component
const TabularBillSearchResults = (
  props: TabularBillSearchResultProps,
): ReactElement => {
  const { params, searching, setSearching, query, setQuery } = props;
  const [selectedRows, setSelectedRows] = useState([] as Bill[]);
  const [loadingPage, setLoadingPage] = useState(true);
  const { data: results, isSuccess } = BillAPI.endpoints.searchBills.useQuery(params);
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const serverSideSortingEnabled =
    orgUser &&
    featureFlags.isFeatureEnabledForUser(
      FrontendFeature.ServerSideBillTableSorting,
      orgUser,
    );

  const [tooltipAnchor, setTooltipAnchor] = useState('');
  const [tipContent, setTipContent] = useState<ReactElement<any>>(<></>);
  const [showTooltip, setShowTooltip] = useState(false);

  const [sort, setSort] = useState<SortOrder>(
    new SortOrder(SortBy.RECENT_ACTIVITY, false),
  );

  const handleSortChange = useCallback(
    (column: SortableDataTableColumn, sortDirection: 'desc' | 'asc') => {
      const sortColumn = column.sortKey ?? SortBy.RECENT_ACTIVITY;
      const sortOrder = new SortOrder(sortColumn, sortDirection === 'desc');
      if (isEqual(sort, sortOrder)) return;
      setLoadingPage(true);
      setSort(sortOrder);
      setQuery({
        order: sortOrder.getQueryParam(),
      });
    },
    [sort, setQuery],
  );

  useEffect(() => {
    if (query.pageSize) {
      return;
    }
    setQuery({ pageSize: 10 });
  }, [setQuery]);

  const teamModeId = useSelector(getTeamMode);

  const isIllinoisSearch: boolean = useMemo(
    () =>
      params?.criteria?.jurisdictions?.length > 0 &&
      params.criteria?.jurisdictions?.every((jur: string) => jur === 'il'),
    [params],
  );

  const handleSelectedRowsChange = useCallback(
    (state: { selectedRows: Bill[] }) => {
      setSelectedRows(state.selectedRows);
    },
    [setSelectedRows],
  );

  const handleSelectableRowsSelected = useCallback(
    (row: Bill) => {
      return selectedRows.some((selectedRow) => selectedRow.id === row.id);
    },
    [setSelectedRows],
  );

  const handlePageChange = useCallback((pageParam: number) => {
    setLoadingPage(true);
    setQuery({ page: pageParam });
  }, []);

  const handlePageSizeChange = useCallback((pageSizeParam: number) => {
    setLoadingPage(true);
    setQuery({ pageSize: pageSizeParam });
  }, []);

  useEffect(() => {
    setSearching(false);
    if (results) setLoadingPage(false);
    setSelectedRows((prevSelectedRows) => {
      const updatedRowSelections =
        results?.data?.filter((result) => {
          return prevSelectedRows.some((selectedRow) => {
            return result.id === selectedRow.id;
          });
        }) ?? [];
      return updatedRowSelections;
    });
  }, [results]);

  const getMostRecentKeyAction = (bill: BillSummary) => {
    const sortedActions = bill.actions ? [...bill.actions].sort(sortActions) : [];
    // We're looking for an action on this bill whose description starts with one of the
    // strings in the IllinoisKeyActions list
    const latestKeyAction = sortedActions.find((action) =>
      IllinoisKeyActions.some((keyAction) => action.description.startsWith(keyAction)),
    );
    if (!latestKeyAction) return <></>;
    const actionDate = renderBillActionDate(latestKeyAction);

    let chamber;
    if (latestKeyAction.chamber === 'upper') {
      chamber = 'Senate';
    } else if (latestKeyAction.chamber === 'lower') {
      chamber = 'House';
    }

    const keyActionId = 'latest-key-action' + bill.sourceBillId;

    const latestKeyActionContent = chamber
      ? `${actionDate} - ${chamber} - ${latestKeyAction.description}`
      : `${actionDate} - ${latestKeyAction.description}`;

    const handleKeyActionFocus = () => {
      setTipContent(<p>{latestKeyActionContent}</p>);
      setTooltipAnchor(keyActionId);
      setShowTooltip(true);
    };

    return (
      <p
        className="latest-key-action"
        id={keyActionId}
        onClick={handleKeyActionFocus}
        onMouseEnter={handleKeyActionFocus}
      >
        {latestKeyActionContent}
      </p>
    );
  };

  const sortActions = (a: BillAction, b: BillAction): number => {
    const dateDiff = moment.utc(b.actionDate).diff(moment.utc(a.actionDate));
    if (dateDiff === 0) {
      return b.order - a.order;
    }
    return dateDiff;
  };

  const showTableResults = !searching && isSuccess && results && results.count > 0;

  const handleTooltipContentClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const tooltipContent = (
    primarySponsors: BillSponsor[],
    coSponsors: BillSponsor[],
  ) => {
    return (
      <div className="sponsors-tooltip" onClick={handleTooltipContentClick}>
        <span className="tooltip-header">Primary sponsors:</span>
        <ul>
          {primarySponsors.map((sponsor, index) => (
            <li key={`primary-${index}`}>
              {sponsor.person?.id ? (
                <Link to={`/person/${sponsor.person?.id}`}>
                  {`${sponsor.name}${partyString(sponsor)}`}
                </Link>
              ) : (
                `${sponsor.name}${partyString(sponsor)}`
              )}
            </li>
          ))}
        </ul>
        {!!coSponsors.length && (
          <>
            <span className="tooltip-header">Co-sponsors:</span>
            <ul>
              {coSponsors.map((sponsor, index) => (
                <li key={`co-${index}`}>
                  {sponsor.person?.id ? (
                    <Link to={`/person/${sponsor.person?.id}`}>
                      {`${sponsor.name}${partyString(sponsor)}`}
                    </Link>
                  ) : (
                    `${sponsor.name}${partyString(sponsor)}`
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  const renderBillSponsors = (bill: Bill) => {
    const { sponsors } = bill;
    if (!sponsors) return undefined;

    const currentSponsors = sponsors.filter((s) => !s.isRemoved);
    const primarySponsors = currentSponsors.filter((s) => s.isPrimary);
    const coSponsors = currentSponsors.filter((s) => !s.isPrimary && !s.isRemoved);

    if (!primarySponsors.length) return undefined;

    const tooltipId = `table-tooltip-${bill.id}`;

    const handleSponsorFocus = () => {
      setTipContent(tooltipContent(primarySponsors, coSponsors));
      setTooltipAnchor(tooltipId);
      setShowTooltip(true);
    };

    return (
      <p
        className="bill-sponsor-table-cell"
        id={tooltipId}
        key={bill.id}
        onClick={handleSponsorFocus}
        onMouseEnter={handleSponsorFocus}
      >
        <span>{primarySponsors[0].name + partyString(primarySponsors[0])}</span>
        <Info className="ml-1" color="#4a4a4a" size={16} />
      </p>
    );
  };

  const columns: SortableDataTableColumn[] = useMemo(() => {
    const baseColumn: SortableDataTableColumn[] = [
      {
        name: 'Tracking',
        sortable: false,
        ignoreRowClick: true,
        minWidth: '110px',
        style: {
          paddingLeft: '0px',
        },
        cell: (bill: Bill) => (
          <BillTrackingButton bill={bill} hasStatusIndicator isCompactMode />
        ),
      },
      {
        name: 'Tags',
        selector: 'tags',
        ignoreRowClick: true,
        right: true,
        width: '50px',
        cell: (bill: Bill) => {
          return useMemo(
            () => <BillTagButton bill={bill} hasStatusIndicator />,
            [bill.tags],
          );
        },
      },
      {
        name: 'Position',
        selector: 'position',
        ignoreRowClick: true,
        right: true,
        width: '80px',
        cell: (bill: Bill) => <BillPosition bill={bill} />,
      },
      {
        name: 'Bill ID',
        selector: 'id',
        sortable: true,
        maxWidth: '200px',
        sortKey: SortBy.ALPHANUMERICAL,
        style: {
          padding: '0px 12px',
        },
        cell(bill: Bill) {
          const title = convertIdToAbbreviatedTitle(bill.id);
          const billAlias = getBillAlias(bill.userAliases, teamModeId);
          return (
            <Link
              className="bill-link"
              to={`/legislative-tracking/bill/details/${bill.id}`}
            >
              {title}
              {billAlias ? `(${billAlias.alias})` : ''}
            </Link>
          );
        },
      },
      {
        name: 'Bill Title',
        selector: 'name',
        sortable: true,
        minWidth: '200px',
        sortKey: SortBy.BILL_TITLE,
        style: {
          padding: '0px 10px',
        },
        cell: (bill: Bill) => {
          // Need to use cell here to apply the custom wrap behavior
          return bill.name;
        },
      },
      {
        name: 'Bill Sponsors',
        selector: 'sponsors',
        sortable: false,
        minWidth: '150px',
        maxWidth: '300px',
        style: { position: 'relative', zIndex: 2 },
        cell: (bill: Bill) => renderBillSponsors(bill),
      },
      {
        name: '(D)',
        selector: 'democrats',
        sortable: false,
        minWidth: '50px',
        maxWidth: '75px',
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        cell: (bill: Bill) => {
          const { sponsors } = bill;
          if (!sponsors) return undefined;
          const count = sponsors.filter(
            (sponsor) => partyString(sponsor) === democratPartyIdentifier,
          ).length;
          return <p className="text-center">{count}</p>;
        },
      },
      {
        name: '(R)',
        selector: 'republican',
        sortable: false,
        minWidth: '50px',
        maxWidth: '75px',
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        cell: (bill: Bill) => {
          const { sponsors } = bill;
          if (!sponsors) return undefined;
          const count = sponsors.filter(
            (sponsor) => partyString(sponsor) === republicanPartyIdentifier,
          ).length;
          return <p className="text-center">{count}</p>;
        },
      },
      {
        name: 'Bill Status',
        selector: 'status',
        sortable: true,
        ignoreRowClick: true,
        button: true,
        minWidth: '130px',
        maxWidth: '130px',
        sortKey: SortBy.BILL_STATUS,
        cell: (bill: Bill) => {
          return (
            <div>
              <BillStatusBadge bill={bill} isCompactMode />
            </div>
          );
        },
      },
      {
        name: 'Session',
        selector: 'session.displayName',
        sortable: true,
        maxWidth: '150px',
        wrap: true,
        sortKey: SortBy.BILL_SESSION,
      },
      {
        name: 'Latest Action',
        selector: 'action_description',
        sortable: false,
        maxWidth: '150px',
        cell: (bill: BillSummary) => {
          return getMostRecentAction(bill)?.description;
        },
      },
      {
        name: 'Action Date',
        selector: 'action_date',
        sortable: true,
        minWidth: '115px',
        maxWidth: '150px',
        sortKey: SortBy.RECENT_ACTIVITY,
        cell: (bill: BillSummary) => {
          const recentAction = getMostRecentAction(bill);
          return recentAction ? renderBillActionDate(recentAction, 'short') : '';
        },
      },
      {
        name: 'Committees',
        selector: 'committeeMentions',
        maxWidth: '300px',
        wrap: true,
        cell: (bill: BillSummary) => {
          return bill.committeeMentions.map((x: { name: string }) => x.name).join(', ');
        },
      },
    ];

    const latestKeyActionColunm = {
      name: 'Latest Key Action',
      selector: 'key-action',
      maxWidth: '200px',
      sortKey: SortBy.RECENT_ACTIVITY,
      cell: (bill: BillSummary) => {
        return getMostRecentKeyAction(bill);
      },
    };

    if (isIllinoisSearch) {
      baseColumn.splice(12, 0, latestKeyActionColunm);
    }

    return baseColumn;
  }, [isIllinoisSearch]);

  const getDefaultSort = useCallback((): {
    selector: string;
    direction: 'asc' | 'desc';
  } => {
    const sorderOrderKey = sort?.sortBy ?? SortBy.RECENT_ACTIVITY;
    const selector =
      columns
        .find((column) => column.sortKey === sorderOrderKey)
        ?.selector?.toString() ?? 'action_date';
    return { selector, direction: sort?.isReversed ? 'desc' : 'asc' };
  }, [sort, columns]);

  return (
    <>
      <div className="list results-pane">
        <div className="container result-count">
          <div className="row">
            <div className="col-lg-10 col-xs-12 result-total">
              <div className="result-dropdown-container">
                <div>{results?.count ?? 0} Results</div>
                <BillBulkActionsDropdown bills={selectedRows} />
              </div>
            </div>
            <div
              className="view-dropdown col-lg-2 col-xs-12"
              style={{ paddingLeft: '60px' }}
            >
              <BillViewDropdown />
            </div>
          </div>
          {loadingPage && (
            <div className="table-spinner-overlay">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
          {showTableResults && (
            <div className="table bill-table" id="bill-search-results-table">
              <ReactTooltip
                anchorId={tooltipAnchor}
                className="tooltip"
                clickable
                delayHide={100}
                isOpen={showTooltip}
                place="bottom"
                setIsOpen={setShowTooltip}
              >
                {tipContent}
              </ReactTooltip>
              <DataTable
                columns={columns}
                customStyles={{
                  cells: {
                    style: {
                      zIndex: 1,
                      position: 'relative',
                    },
                  },
                }}
                data={results.data}
                defaultSortAsc={getDefaultSort().direction === 'asc'}
                defaultSortField={getDefaultSort().selector}
                disabled={loadingPage}
                noContextMenu
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                onSelectedRowsChange={handleSelectedRowsChange}
                pagination
                paginationDefaultPage={query.page}
                paginationPerPage={query.pageSize}
                paginationRowsPerPageOptions={[10, 20, 50]}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnSort: true,
                }}
                paginationTotalRows={results.count}
                responsive
                selectableRowSelected={handleSelectableRowsSelected}
                selectableRows
                selectableRowsVisibleOnly
                sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
                sortServer={serverSideSortingEnabled}
                {...(serverSideSortingEnabled && { onSort: handleSortChange })}
              />
            </div>
          )}
        </div>
        {!loadingPage && (!results || results?.count == 0) && (
          <div className="container">
            <NoBillResults />
          </div>
        )}
      </div>
    </>
  );
};

export default TabularBillSearchResults;
