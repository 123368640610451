/*
 * SegmentAnalytics.ts
 * This file wraps analytics functions provided by Segment's javascript that report analytics events back to Segment
 * (which are then repeated to other 3rd-party analytics services).
 * The initial setup for window.analytics is in public/enview-segment-integration.js.
 * More info on what the various Segment events mean can be found here: https://segment.com/docs/connections/spec/
 */

const BOT_USER_AGENT_STRINGS = ['Prerender', 'HeadlessChrome'];

/* Test for bot traffic from known sources. Most importantly, as of 2023 we are
using Prerender.io to cache pages for search engines. We want to exclude visits
from their crawler or similar bot traffic from showing up in Ampitude and other
analytics tools
*/
const isLikelyABot = (): boolean => {
  for (const str of BOT_USER_AGENT_STRINGS) {
    if (navigator.userAgent.includes(str)) {
      return true;
    }
  }
  return false;
};

// Send a `track` event to Segment, exclude bot traffic
const track = (
  event: string,
  properties?: object,
  options?: SegmentAnalytics.SegmentOpts,
): void => {
  // window.analytics gets created by running Segment init, it will be nullish
  // if Segment is unavailable, no segment key available, etc
  if (!window?.analytics || isLikelyABot()) {
    return;
  }
  window.analytics.track(event, properties, options);
};

// Send a `identify` event to Segment, exclude bot traffic
const identify = (
  event: string,
  properties?: object,
  options?: SegmentAnalytics.SegmentOpts,
): void => {
  if (!window?.analytics || isLikelyABot()) {
    return;
  }
  window.analytics.identify(event, properties, options);
};

// Send a `group` event to Segment, exclude bot traffic
const group = (
  event: string,
  properties?: object,
  options?: SegmentAnalytics.SegmentOpts,
): void => {
  if (!window?.analytics || isLikelyABot()) {
    return;
  }
  window.analytics.group(event, properties, options);
};

// Send a `page` event to Segment, exclude bot traffic
const page = (
  category: string,
  name: string,
  properties?: object,
  options?: SegmentAnalytics.SegmentOpts,
): void => {
  if (!window?.analytics || isLikelyABot()) {
    return;
  }
  window.analytics.page(category, name, properties, options);
};

export { track, identify, group, page };
