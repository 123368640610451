import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SavedSearch } from '@enview/interface/types/BillSearch';
import { useDispatch, useSelector } from 'react-redux';
import { TeamAPI } from '../../../api';
import CloseIcon from '../../../components/svg/CloseIcon';
import ModalCard from './ModalCard';
import { State } from '../../../dux/@types';
import { closeEditSearchModal } from '../../../dux';

const EDIT_MODAL_TITLE = 'Rename Saved Search';
const DELETE_MODAL_TITLE = 'Delete Saved Search?';

type EditSearchForm = {
  name: string;
};

const EditArchiveSavedSearch = (): ReactElement => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const isShowingModal = useSelector(
    (state: State) => state.modals.modalOpenEditSearch,
  );
  const search = useSelector((state: State) => state.modals.modalSavedSearch);
  const editAction = useSelector((state: State) => state.modals.modalEditSearchAction);

  const isArchiving = editAction === 'archive';
  const closeModal = (): void => {
    dispatch(closeEditSearchModal());
  };

  const [editSavedSearch, { isSuccess: editSuccess, error: editError }] =
    TeamAPI.endpoints.editSavedSearch.useMutation();

  const [isFadingOut, setFadeOut] = useState(false);

  const setValues = useCallback(
    (currentSearch: SavedSearch): void => {
      setValue('name', currentSearch.name);
    },
    [setValue],
  );

  const resetValues = useCallback((): void => {
    setValue('name', '');
  }, [setValue]);

  useEffect(() => {
    if (search) {
      setValues(search);
    }
  }, [setValues, search]);

  useEffect(() => {
    if (editSuccess) {
      setTimeout(() => setFadeOut(true), 5000);
      setTimeout(() => closeModal(), 7000);
      setTimeout(() => resetValues(), 7000);
    }
  });

  // For update (parameters) actions, we can immediately fire the request with no further input
  // needed from the user
  useEffect(() => {
    if (editAction === 'update') {
      const payload: Partial<SavedSearch> = {
        id: search?.id,
        teamId: search?.teamId,
        name: getValues('name'),
        searchCriteria: search?.searchCriteria,
      };

      void editSavedSearch(payload);
    }
  }, []);

  const onSearchSave = (data: EditSearchForm): void => {
    const payload: Partial<SavedSearch> = {
      id: search?.id,
      teamId: search?.teamId,
      name: data.name,
      searchCriteria: search?.searchCriteria,
    };

    void editSavedSearch(payload);
  };

  const onSearchArchive = (): void => {
    const payload: Partial<SavedSearch> = {
      id: search?.id,
      teamId: search?.teamId,
      isArchived: true,
    };
    if (isArchiving) void editSavedSearch(payload);
  };

  const deleteModal = (
    <div id="delete-search">
      <p>
        Are you sure you want to delete this saved search filter? This cannot be undone.
      </p>
      <button
        className="btn-action red"
        disabled={editSuccess}
        onClick={onSearchArchive}
        type="submit"
      >
        Delete
      </button>
      <button className="btn-text destructive" onClick={closeModal} type="button">
        Cancel
      </button>
    </div>
  );

  const editModal = (
    <form
      className="form registration-form"
      id="edit-modal"
      onSubmit={handleSubmit(onSearchSave)}
    >
      <div className="mb-4">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="edit-name" id="edit-search-input">
          Name
        </label>
        <input
          className="form-control form-field"
          defaultValue={search?.name}
          id="edit-name"
          name="name"
          placeholder=""
          ref={register({
            required: { value: true, message: 'Please fill out this field.' },
          })}
          type="text"
        />
      </div>
      {errors.name && (
        <div className="error alert alert-danger">{errors.name.message}</div>
      )}
      <button className="btn-action" disabled={editSuccess} type="submit">
        Save
      </button>
      <button className="btn-text destructive" onClick={closeModal} type="button">
        Cancel
      </button>
    </form>
  );

  const alertClass = editError ? 'alert alert-warning' : 'alert alert-success';
  const successMessage = 'Search updated';
  const errorMessage =
    ((editError as Error) && (editError as Error).message) ||
    'An error occurred. Please try again later.';
  const showMessage = editError || !search;

  if (editSuccess) {
    let verb = 'renamed';
    if (editAction === 'archive') {
      verb = 'deleted';
    }
    if (editAction === 'update') {
      verb = 'updated';
    }
    return (
      <div
        className={isFadingOut ? 'confirmation-popup fade-out' : 'confirmation-popup'}
      >
        <div>
          <CloseIcon className="float-right" onClick={closeModal} />
        </div>
        <FontAwesomeIcon className="check-icon" color="#1AA08F" icon={faCheckCircle} />
        <h3 className="title">Success!</h3>
        <p>Your search has successfully been {verb}.</p>
      </div>
    );
  }

  if (editAction === 'update') {
    return (
      <div className="confirmation-popup">
        <p>Saving...</p>
      </div>
    );
  }

  return (
    <ModalCard
      closeModal={closeModal}
      content={
        <div>
          {showMessage ? (
            <div className={alertClass} role="alert">
              {editError ? errorMessage : successMessage}
            </div>
          ) : undefined}
          {isArchiving ? deleteModal : editModal}
        </div>
      }
      show={isShowingModal}
      title={isArchiving ? DELETE_MODAL_TITLE : EDIT_MODAL_TITLE}
    />
  );
};

export default EditArchiveSavedSearch;
