import { AccountType, Organization } from '@enview/interface/types/Organization';
import { OrganizationUser, SignupData } from '@enview/interface/types/OrganizationUser';
import isEqual from 'lodash-es/isEqual';
import BillSearchView from '../../../interface/src/types/bills/BillSearchView';
import { group, identify, track } from './SegmentAnalytics';
import {
  UserWorkspaceSettings,
  WorkspaceNotifications,
} from '@enview/interface/types/users/UserWorkspaceSettings';

enum AnalyticsEvent {
  NOTIFICATION_SETTING_UPDATED = 'Notification Setting Updated',
  WORKSPACE_NOTIFICATION_SETTING_UPDATED = 'Workspace-Specific Notification Updated',
  PASSWORD_UPDATED = 'Password Updated',
  USER_ONBOARDING_QUESTIONS_COMPLETE = 'User Onboarding Questions Complete',
  USER_PROFILE_UPDATED = 'User Profile Updated',
  POLICY_AREAS_TRACKED = 'Policy Areas Tracked',
  TRIAL_REQUEST_CREATED = 'Trial Request Created',
  TRIAL_REQUEST_ERROR = 'Trial Request Error',
  BILL_VIEW_CHANGED = 'Bill View Changed',
}
// We need to send a custom event to LinkedIn to signal that the user has
// completed the free-trial sign up process i.e. "Converted". LinkedIn's "Insight Tag"
// will be downloaded via Segment integration (managed in the Segment UI for dev and prod),
// which will add the lintrk() function to the global window object. Calling that function
// with the given conversion_id will mark the user as having completed the free trial signup
declare global {
  interface Window {
    lintrk: (arg0: string, arg1: { conversion_id: number }) => void;
    gtag: (arg0: string, arg1: string, arg2: { [index: string]: string }) => void;
  }
}
const NEW_ACCOUNT_LINKEDIN_CONVERSION_ID = 12567113;

// Similar to LinkedIn above, we send a conversion signal to Google Analytics. GA allows
// us to send arbitrary event names and attributes and mark specific events as
// "conversion events" inside the app.
const NEW_ACCOUNT_GA_CONVERSION_ID = 'free_trial_account_created';

const getUpdatedFields = (
  prevOrgUser: OrganizationUser,
  updatedOrgUser: OrganizationUser,
): {
  [key: string]: any;
} => {
  const updatedUserReg = updatedOrgUser.userRegistration;
  const prevUserReg = prevOrgUser.userRegistration;
  const updatedFields: { [key: string]: any } = {};

  // About me section
  if (prevOrgUser.firstName !== updatedOrgUser.firstName)
    updatedFields.firstName = updatedOrgUser.firstName;
  if (prevOrgUser.lastName !== updatedOrgUser.lastName)
    updatedFields.lastName = updatedOrgUser.lastName;
  if (prevOrgUser.email !== updatedOrgUser.email)
    updatedFields.email = updatedOrgUser.email;
  if (prevOrgUser.city !== updatedOrgUser.city)
    updatedFields.city = updatedOrgUser.city;
  if (prevOrgUser.state !== updatedOrgUser.state)
    updatedFields.state = updatedOrgUser.state;

  // About my work section
  if (prevOrgUser.jobTitle !== updatedOrgUser.jobTitle)
    updatedFields.jobTitle = updatedOrgUser.jobTitle;
  if (prevOrgUser.organizationType !== updatedOrgUser.organizationType)
    updatedFields.organizationType = updatedOrgUser.organizationType;
  if (prevUserReg.helpLevelNeeded !== updatedUserReg.helpLevelNeeded) {
    updatedFields.userRegistration.helpLevelNeeded = updatedUserReg.helpLevelNeeded;
  }
  if (
    prevUserReg.legislativeWorkExperience !== updatedUserReg.legislativeWorkExperience
  )
    updatedFields.legislativeWorkExperience = updatedUserReg.legislativeWorkExperience;

  // Check arrays
  const updatedPolicyAreas =
    updatedOrgUser.policyAreas && updatedOrgUser.policyAreas.map((pa) => pa.name);
  const isPolicyAreasUpdated = !isEqual(
    prevOrgUser.policyAreas,
    updatedOrgUser.policyAreas,
  );
  if (isPolicyAreasUpdated) updatedFields.policyAreas = updatedPolicyAreas;

  const updatedReasons =
    updatedUserReg.additionalData &&
    updatedUserReg.additionalData.reasonsForUsingEnview;
  const prevReasons =
    prevUserReg.additionalData && prevUserReg.additionalData.reasonsForUsingEnview;
  const isReasonsForUsingEnviewUpdated = !isEqual(prevReasons, updatedReasons);
  if (isReasonsForUsingEnviewUpdated)
    updatedFields.reasonsForUsingEnview = updatedReasons;

  return updatedFields;
};

const trackPolicyAreas = (orgUser: OrganizationUser, policyAreas: string[]): void => {
  track(AnalyticsEvent.POLICY_AREAS_TRACKED, {
    username: orgUser.email,
    policyAreas,
  });
};

export const trackNotificationSettingUpdated = (
  organizationUser: OrganizationUser,
): void => {
  track(AnalyticsEvent.NOTIFICATION_SETTING_UPDATED, {
    username: organizationUser ? organizationUser.email : 'Not logged in',
    frequency: organizationUser ? organizationUser.settings : 'Not logged in',
  });
};

export const trackUserWorkspaceNotificationSettingUpdated = (
  updatedSettings: Partial<UserWorkspaceSettings>,
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  const team = orgUser?.teamMemberships.find((tm) => tm.teamId === teamId);
  // Amplitude's js library annoyingly strips any null values from the event data,
  // but it's pretty important in this case to have a value that indicates "no preference set
  // at this level", so we'll use a sentinel value
  const DEFAULT = '(default)';
  track(AnalyticsEvent.WORKSPACE_NOTIFICATION_SETTING_UPDATED, {
    teamId,
    workspaceName: team?.team.name ?? '',
    realTimeBillNotification:
      updatedSettings[WorkspaceNotifications.BILL_REAL_TIME] ?? DEFAULT,
    dailyBillNotification:
      updatedSettings[WorkspaceNotifications.BILL_DAILY] ?? DEFAULT,
    weeklyBillNotification:
      updatedSettings[WorkspaceNotifications.BILL_WEEKLY] ?? DEFAULT,
    collaborationNotification:
      updatedSettings[WorkspaceNotifications.COLLABORATION] ?? DEFAULT,
    mentionNotification: updatedSettings[WorkspaceNotifications.MENTION] ?? DEFAULT,
  });
};

export const trackProfileUpdated = (
  prevOrgUser: OrganizationUser,
  updatedOrgUser: OrganizationUser,
  updatedSection?: string,
): void => {
  const updatedProfileProps = getUpdatedFields(prevOrgUser, updatedOrgUser);

  if (updatedProfileProps.policyAreas)
    trackPolicyAreas(updatedOrgUser, updatedProfileProps.policyAreas);

  if (Object.keys(updatedProfileProps).length > 0) {
    track(AnalyticsEvent.USER_PROFILE_UPDATED, {
      updatedSection,
      updatedFields: updatedProfileProps,
    });
  }
};

export const trackOnboardingAccountComplete = (
  organizationUser: OrganizationUser,
): void => {
  const { userRegistration } = organizationUser;
  const reasonsForUsingEnview =
    userRegistration.additionalData &&
    userRegistration.additionalData.reasonsForUsingEnview;
  const policyAreas = organizationUser.policyAreas.map((policyArea) => policyArea.name);
  trackPolicyAreas(organizationUser, policyAreas);

  track(AnalyticsEvent.USER_ONBOARDING_QUESTIONS_COMPLETE, {
    jobTitle: organizationUser.jobTitle,
    organizationType: organizationUser.organizationType,
    policyAreas,
    reasonsForUsingEnview,
    helpLevelNeeded: userRegistration.helpLevelNeeded,
    legislativeWorkExperience: userRegistration.legislativeWorkExperience,
  });
};

export const trackPasswordUpdated = (organizationUser: OrganizationUser): void => {
  track(AnalyticsEvent.PASSWORD_UPDATED, {
    username: organizationUser ? organizationUser.email : 'Not logged in',
  });
};

export const identifyUser = (organizationUser: OrganizationUser): void => {
  const { userRegistration } = organizationUser;
  const reasonsForUsingEnview =
    userRegistration.additionalData &&
    userRegistration.additionalData.reasonsForUsingEnview;
  identify(`${organizationUser.id}`, {
    id: organizationUser.id,
    firstName: organizationUser.firstName,
    lastName: organizationUser.lastName,
    username: organizationUser.email,
    email: organizationUser.email,
    city: organizationUser.city,
    state: organizationUser.state,
    company: organizationUser.organizations.map((org) => org.name).join(', '),
    isTrialOrgMember:
      organizationUser.organizations.filter(
        (org) => org.accountStatus === AccountType.Trial,
      ).length > 0,
    isProOrgMember:
      organizationUser.organizations.filter(
        (org) => org.accountStatus === AccountType.Pro,
      ).length > 0,
    isFreeOrgMember:
      organizationUser.organizations.filter(
        (org) => org.accountStatus === AccountType.Free,
      ).length > 0,
    isFreeLegacyOrgMember:
      organizationUser.organizations.filter(
        (org) => org.accountStatus === AccountType.FreeLegacy,
      ).length > 0,
    isStarterOrgMember:
      organizationUser.organizations.filter(
        (org) => org.accountStatus === AccountType.Starter,
      ).length > 0,
    isNew: organizationUser.isNew ? 'true' : 'false',
    roles: organizationUser.roles.map((r) => r.name).join(', '),
    enviewAdmin:
      organizationUser.roles &&
      organizationUser.roles.find((r) => r.name === 'enview-admin')
        ? 'true'
        : 'false',
    policyAreas: organizationUser.policyAreas.map((pa) => pa.name).join(', '),
    createdAt: organizationUser.createdAt,
    jobTitle: organizationUser.jobTitle,
    organizationType: organizationUser.organizationType,
    legislativeWorkExperience: userRegistration.legislativeWorkExperience,
    reasonsForUsingEnview,
    adminSetFields: organizationUser.adminSetFields,
  });
};

export const groupOrganization = (organizations: Organization[]): void => {
  organizations.forEach((organization) => {
    group(`${organization.id}`, {
      id: organization.id,
      name: organization.name,
      createdAt: organization.createdAt,
      accountStatus: organization.accountStatus,
      isSuspended: organization.suspended,
    });
  });
};

const stepDescriptions: { [index: number]: string } = {
  1: 'User has entered their contact info to receive a trial invite',
  2: 'User has completed sign up from a trial request invite',
};
export const trackTrialRequestProcess = (data: {
  step: number;
  firstName: string;
  lastName: string;
  email: string;
  accountType: AccountType;
}): void => {
  track(AnalyticsEvent.TRIAL_REQUEST_CREATED, {
    description: stepDescriptions[data.step],
    accountType: data.accountType,
    step: data.step,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
  });
};

const stepErrors: { [index: number]: string } = {
  1: 'User encountered a server-side error attempting to request a free trial',
  2: 'User encountered a server-side error attempting to sign up from a free trial invite',
};
export const trackTrialRequestError = (data: {
  step: number;
  firstName: string;
  lastName: string;
  email: string;
}): void => {
  track(AnalyticsEvent.TRIAL_REQUEST_ERROR, {
    description: stepErrors[data.step],
    step: data.step,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
  });
};

export const trackTrialSignUpComplete = (data: SignupData): void => {
  trackTrialRequestProcess({ step: 2, ...data });
  if (window.lintrk instanceof Function) {
    window.lintrk('track', {
      conversion_id: NEW_ACCOUNT_LINKEDIN_CONVERSION_ID,
    });
  }
  if (window.gtag instanceof Function) {
    window.gtag('event', NEW_ACCOUNT_GA_CONVERSION_ID, {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      accountType: data.accountType,
      organizationName: data.organizationName || '',
    });
  }
};

export const trackBillViewChange = (
  organizationUserId: number,
  teamId: number,
  newView: BillSearchView,
): void => {
  track(AnalyticsEvent.BILL_VIEW_CHANGED, {
    organizationUserId: organizationUserId,
    teamId: teamId,
    newView: newView,
  });
};
