import React, { ReactElement } from 'react';
import './Page.scss';
import MultipleSelectDropDown, {
  OptionType as MultipleSelectOptionType,
} from '../DropDowns/MultipleSelectDropDown';
import SortDropdown from '../DropDowns/SortDropdown';
import { SortOrder } from '../../../models/SortOrder';

type FiltersProps = {
  filterOptions: MultipleSelectOptionType<string>[];
  selectedFilters: string[];
  onFiltersChange: (selectedFilters: string[]) => void;
  sortOptions: SortOrder[];
  selectedSort: SortOrder;
  onSortSelect: (selectedSort: SortOrder) => void;
};

const Filters = (props: FiltersProps): ReactElement => {
  const {
    filterOptions,
    selectedFilters,
    sortOptions,
    selectedSort,
    onFiltersChange,
    onSortSelect,
  } = props;

  return (
    <div className="filters d-flex flex-row">
      <div className="filter" style={{ flexBasis: 0 }}>
        <MultipleSelectDropDown
          currentSelectedValues={selectedFilters}
          onChange={onFiltersChange}
          options={filterOptions}
          title="Filter"
        />
      </div>

      <div className="filter flex-grow-1" style={{ flexBasis: 0 }}>
        <SortDropdown
          setSortOrder={onSortSelect}
          sortOptions={sortOptions}
          sortOrder={selectedSort}
        />
      </div>
    </div>
  );
};

export default Filters;
