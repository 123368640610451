import { ReactElement } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useUrlCreator } from '../../../helpers/UrlHelper';
import './Breadcrumbs.scss';

export interface BreadcrumbItem {
  to: string;
  label: string;
  disabled?: boolean;
}

type BreadcrumbGroupProps = {
  breadcrumbItems: BreadcrumbItem[];
};

const BreadcrumbGroup = (props: BreadcrumbGroupProps): ReactElement => {
  const { breadcrumbItems } = props;
  const location = useLocation();
  const urlCreator = useUrlCreator();

  const renderBreadcrumbItem = (item: BreadcrumbItem, index: number): ReactElement => {
    return (
      <Breadcrumb.Item
        active={item.disabled ?? location.pathname === item.to}
        hidden={!location.pathname.startsWith(item.to)}
        href={urlCreator(`${item.to}`)}
        key={index}
      >
        {item.label}
      </Breadcrumb.Item>
    );
  };

  return <Breadcrumb>{breadcrumbItems.map(renderBreadcrumbItem)}</Breadcrumb>;
};

export default BreadcrumbGroup;
