import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface SwitchIconProps {
  id?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onDescription?: string;
  offDescription?: string;
  additionalExplanation?: string;
  disabled?: boolean;
  size?: SizeProp;
  labelClassName?: string;
}

const SwitchIcon = (props: SwitchIconProps): ReactElement => {
  const {
    id,
    value,
    onChange,
    onDescription,
    offDescription,
    additionalExplanation,
    disabled,
    labelClassName,
    size = '2x',
  } = props;

  const onClick = (): void => {
    if (!disabled) onChange(!value);
  };

  return (
    <>
      <div aria-disabled={disabled} className="switch-icon d-flex flex-row">
        <FontAwesomeIcon
          color={value ? '#1AA08F' : '#979797'}
          icon={faToggleOn}
          id={id}
          onClick={onClick}
          rotation={value ? undefined : 180}
          size={size}
        />
        {value && onDescription && (
          <div className={`switch-description ${labelClassName || ''}`}>
            {onDescription}
          </div>
        )}
        {!value && offDescription && (
          <div className={`switch-description ${labelClassName || ''}`}>
            {offDescription}
          </div>
        )}
      </div>
      {additionalExplanation && (
        <div className={`additional-explanation`}>{additionalExplanation}</div>
      )}
    </>
  );
};

export default SwitchIcon;
