import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './NeedToUpgrade.scss';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../components/svg/CloseIcon';
import Can from '../../components/Can';
import { g as abilityGlossary } from '../../config/ability';
import { State } from '../../dux/@types';
import { closeLoginRestrictionModal } from '../../dux/ModalDux';

const NeedToUpgrade = () => {
  const modalIsOpen = useSelector(
    (state: State) => state.modals.modalOpenLoginRestriction,
  );
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeLoginRestrictionModal());
  };
  const viewgradeOptions = () => (
    <Link onClick={close} to="/legislative-tracking">
      <button className="btn btn-secondary view-upgrade-option" type="button">
        View upgrade options
      </button>
    </Link>
  );
  const goBackToMyTeam = () => (
    <Link onClick={close} to="/teams">
      <button className="btn btn-secondary back-to-team" type="button">
        Go back to my Team
      </button>
    </Link>
  );
  return (
    <Modal
      className="react-modal"
      closeTimeoutMS={100}
      contentLabel="Modal"
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      overlayClassName="react-modal-overlay"
    >
      <div className="row m-0">
        <div className="col p-0">
          <span className="badge float-right" onClick={close}>
            <CloseIcon className="badge-color" />
          </span>
        </div>
      </div>
      <div className="not-login-container">
        <p className="header">Upgrade your account to try this feature</p>
        <p className="notes">
          Get extra features, including bill intelligence and unlimited team
          collaboration
        </p>
        <div className="controls">{viewgradeOptions()}</div>
        <Can I={abilityGlossary.VIEW} a={abilityGlossary.TEAM}>
          <div className="controls">{goBackToMyTeam()}</div>
        </Can>
      </div>
    </Modal>
  );
};

export default NeedToUpgrade;
