const MFA_REGISTRATION_VERIFICATION_FAILURE_MESSAGE =
  'Two-factor code verification failed. Please try again.';

const MFA_CHECK_VERIFICATION_FAILURE_MESSAGE = `Two-factor code verification
failed. Please try again. If you can not verify, you will need to contact
your organization's administrator to reset your multi-factor authentication.`;

const MFA_CHECK_LOGIN_REQUIRED_AGAIN_ERROR_MESSAGE = `You've taken too long
to verify an multi-factor authentication token. Please try logging in again
<a href="/login">here</a>. If you can not verify, you will need to contact
your organization's administrator to reset your multi-factor authentication.`;

const MFA_REGISTRATION_ONLY_ONE_DEVICE_ERROR_MESSAGE = `You currently cannot 
register more than one multi-factor authentication device.`;

const MFA_UNKNOWN_ERROR_MESSAGE = `Oops! Seems like we've found 
an issue. Please try again after refreshing the page. If this continues to be 
an issue, please email support@pluralpolicy.com and we will fix this right
away. Click <a href="/login">here</a> to go back.`;

export const getMfaErrorMessage = (
  errorCode?: string,
  onRegistration?: boolean,
): string => {
  switch (errorCode) {
    case 'mfa-token-not-valid':
      if (onRegistration) {
        return MFA_REGISTRATION_VERIFICATION_FAILURE_MESSAGE;
      }
      return MFA_CHECK_VERIFICATION_FAILURE_MESSAGE;

    case 'auth-flow-restart-required':
      return MFA_CHECK_LOGIN_REQUIRED_AGAIN_ERROR_MESSAGE;
    case 'cannot-register-more-than-one-mfa-device':
      return MFA_REGISTRATION_ONLY_ONE_DEVICE_ERROR_MESSAGE;
    default:
      return MFA_UNKNOWN_ERROR_MESSAGE;
  }
};
