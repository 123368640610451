import React, { ReactElement } from 'react';
import './DropDown.scss';
import { SortOrder } from '../../../models/SortOrder';
import SingleSelectDropDown, { OptionType } from './SingleSelectDropDown';
import SortUpIcon from '../../../components/svg/SortUpIcon';
import SortDownIcon from '../../../components/svg/SortDownIcon';

type SortDropdownProps = {
  sortOrder: SortOrder;
  sortOptions: SortOrder[];
  setSortOrder: (newSortOrder: SortOrder) => void;
};

const SortDropdown = (props: SortDropdownProps): ReactElement => {
  const { sortOrder, sortOptions, setSortOrder } = props;
  const options: OptionType<SortOrder>[] = sortOptions.map((option) => {
    return {
      label: option.sortBy,
      value: option,
      id: `${option.sortBy}${option.isReversed ? '-reverse' : ''}`,
      icon: option.isReversed ? <SortUpIcon /> : <SortDownIcon />,
    };
  });

  return (
    <div className="sort-by">
      <span
        style={{
          color: '#5F5F5F',
          paddingRight: '16px',
          fontWeight: 600,
          alignSelf: 'center',
        }}
      >
        Sort by
      </span>
      <SingleSelectDropDown
        alignRight
        defaultLabel="Sort by"
        equals={(a, b) => a.equals(b)}
        onSelect={(option) => setSortOrder(option.value)}
        options={options}
        selectedValue={sortOrder}
      />
    </div>
  );
};

export default SortDropdown;
