import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import startCase from 'lodash-es/startCase';
import { Committee, CommitteeMembership } from '@enview/interface/types/Committee';
import './CommitteeView.scss';
import { convertPartyToPartyLabel } from '../../helpers/CommitteesHelper';
import * as Analytics from '../../analytics/PersonAnalytics';
import Filters from '../Shared/PageElements/Filters';
import { SortOrder, SortBy } from '../../models/SortOrder';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';
import GridCard from '../Shared/PageElements/GridCard';
import PersonImage from '../Shared/PersonImage';

type CommitteeMemberDirectoryProps = {
  committee: Committee;
};

const COMMITTEE_SORT_OPTIONS = [
  new SortOrder(SortBy.RELEVANCE, false),
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

const CommitteeMemberDirectory = (
  props: CommitteeMemberDirectoryProps,
): ReactElement => {
  const { committee } = props;

  const [selectedSortOrder, setSelectedSortOrder] = useState(COMMITTEE_SORT_OPTIONS[0]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [committeeMembers, setCommitteeMembers] = useState<CommitteeMembership[]>(
    committee?.memberships || [],
  );

  const partyCounts: { [party: string]: number } = useMemo(() => {
    const counts: { [party: string]: number } = {};
    if (committee) {
      committee.memberships.forEach((membership: CommitteeMembership) => {
        const { party } = membership.electedPosition;
        counts[party] = counts[party] ? counts[party] + 1 : 1;
      });
      setCommitteeMembers(committee.memberships);
    }
    return counts;
  }, [committee]);

  const filterOptions: OptionType<string>[] = useMemo(() => {
    return Object.keys(partyCounts).map((party) => {
      return { value: party, label: convertPartyToPartyLabel(party, false) };
    });
  }, [partyCounts]);

  useEffect(() => {
    if (!committee) return;
    Analytics.trackViewedCommitteePage(committee);
  }, [committee]);

  useEffect(() => {
    if (!committee) return;
    let copy = [...committee.memberships];
    if (selectedFilters.length > 0) {
      copy = copy.filter((cm) => {
        return selectedFilters.includes(cm.electedPosition.party);
      });
    }
    if (selectedSortOrder.sortBy === SortBy.RELEVANCE) {
      setCommitteeMembers(copy);
      return;
    }
    setCommitteeMembers(
      copy.sort((a, b) => {
        const aPerson = a.electedPosition.person;
        const bPerson = b.electedPosition.person;
        if (selectedSortOrder.isReversed) {
          return (aPerson.familyName || aPerson.name).toLowerCase() <
            (bPerson.familyName || bPerson.name).toLowerCase()
            ? 1
            : -1;
        }
        return (aPerson.familyName || aPerson.name).toLowerCase() >
          (bPerson.familyName || bPerson.name).toLowerCase()
          ? 1
          : -1;
      }),
    );
  }, [selectedSortOrder, selectedFilters, committee]);

  return (
    <>
      <div className="committee-header">
        <div className="row m-0">
          <div className="stat">
            <div className="stat-number">{committee.memberships.length}</div>
            <div className="stat-title">Members</div>
          </div>
          {Object.keys(partyCounts).map((key) => (
            <div className="stat">
              <div className="stat-number">{partyCounts[key]}</div>
              <div className="stat-title">
                {convertPartyToPartyLabel(key, partyCounts[key] > 1)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Filters
          filterOptions={filterOptions}
          onFiltersChange={setSelectedFilters}
          onSortSelect={setSelectedSortOrder}
          selectedFilters={selectedFilters}
          selectedSort={selectedSortOrder}
          sortOptions={COMMITTEE_SORT_OPTIONS}
        />
        {committeeMembers && (
          <div className="committee-grid">
            {committeeMembers.map((member: CommitteeMembership) => {
              return (
                <GridCard
                  data={{
                    TITLE: member.electedPosition.role,
                    CHAMBER: member.electedPosition.chamber?.name || '',
                    ROW: {
                      DISTRICT: member.electedPosition.district,
                      PARTY: convertPartyToPartyLabel(
                        member.electedPosition.party,
                        false,
                      ),
                    },
                  }}
                  image={
                    <PersonImage person={member.electedPosition.person} size={56} />
                  }
                  link={`/person/${member.electedPosition.personId}`}
                  tagLabel={
                    member.role.toLowerCase() !== 'member'
                      ? startCase(member.role.toLowerCase())
                      : undefined
                  }
                  title={member.electedPosition.person.name}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CommitteeMemberDirectory;
