import { differenceInCalendarDays, format, parse } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import CustomDropdown from '../DropDowns/CustomDropdown';
import './Selector.scss';

const DATE_FMT = 'M/d/yyyy';

const isValidDate = (day: Date): boolean => {
  return !Number.isNaN(day.getTime());
};
const today = new Date();
const fromDate = new Date(2016, 1, 1);

type DayPickerInputDropdownProps = {
  selectedDate: Date | undefined;
  setSelectedDate: (arg: Date | undefined) => void;
  label: string;
  showDropdown: boolean;
  setShowDropdown: (arg: boolean) => void;
  maxDate?: Date;
  minDate?: Date;
};

/*
  This Component renders a keyboard-editable input connected to a date picker popup,
  with one underlying date value that remains in-sync bewtween the input and
  the selected date on the visual calendar. The implementation borrows heavily from the
  `useInput()` hook in `react-day-picker`, defined here:
  https://github.com/gpbl/react-day-picker/blob/3bfc4eb16/packages/react-day-picker/src/hooks/useInput/useInput.ts#L70

  This component likely depends on implementation details of `react-day-picker` and is likely
  to need updates if the API of that control changes
*/
const DayPickerInputDropdown = (props: DayPickerInputDropdownProps): ReactElement => {
  const {
    selectedDate,
    setSelectedDate,
    label,
    showDropdown,
    setShowDropdown,
    maxDate,
    minDate,
  } = props;

  // Initialize states
  const [month, setMonth] = useState(selectedDate ?? today);
  const defaultInputValue = selectedDate ? format(selectedDate, DATE_FMT) : '';
  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [defaultInputValue]);
  const reset = (): void => {
    setSelectedDate(selectedDate);
    setMonth(selectedDate ?? today);
    setInputValue(defaultInputValue ?? '');
  };

  const dropdownTargetInput = (placeholder: string): ReactElement => {
    return (
      <input
        className="date-input"
        id={`${placeholder.replace(/\s/g, '-').toLowerCase()}-filter`}
        // Special case for _required_ fields: on blur, if the value of the input is not
        // a valid date, reset the calendar and the input value.
        onBlur={(e) => {
          const day = parse(e.target.value, DATE_FMT, today);
          if (!isValidDate(day)) {
            reset();
          }
        }}
        // When changing the input field, save its value in state and check if the
        // string is a valid date. If it is a valid day, set it as selected and update
        // the calendar’s month.
        onChange={(e) => {
          setInputValue(e.target.value);
          const day = parse(e.target.value, DATE_FMT, today);
          const isBefore = fromDate && differenceInCalendarDays(fromDate, day) > 0;
          const isAfter = today && differenceInCalendarDays(day, today) > 0;
          if (!isValidDate(day) || isBefore || isAfter) {
            setSelectedDate(undefined);
            return;
          }
          setSelectedDate(day);
          setMonth(day);
        }}
        onClick={() => setShowDropdown(!showDropdown)}
        onFocus={(e) => {
          if (!e.target.value) {
            reset();
            return;
          }
          const day = parse(e.target.value, DATE_FMT, today);
          if (isValidDate(day)) {
            setMonth(day);
          }
        }}
        placeholder={placeholder}
        type="text"
        value={inputValue}
      />
    );
  };

  return (
    <CustomDropdown
      setShowDropdown={setShowDropdown}
      showDropdown={showDropdown}
      target={dropdownTargetInput(label)}
    >
      <div className="action-date-picker">
        <DayPicker
          fromDate={minDate ?? fromDate}
          month={month}
          onDayClick={(day, { selected }) => {
            if (selected) {
              setSelectedDate(undefined);
              setInputValue('');
              return;
            }
            setSelectedDate(day);
            setInputValue(day ? format(day, DATE_FMT) : '');
          }}
          onMonthChange={(m) => {
            setMonth(m);
          }}
          selected={selectedDate}
          toDate={maxDate ?? today}
        />
      </div>
    </CustomDropdown>
  );
};

export default DayPickerInputDropdown;
