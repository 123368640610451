export const SET_AUTH_ERROR = 'authentication/setAuthError';
export const LOAD_MFA_CHECK = 'authentication/loadMfaCheck';
export const LOAD_MFA_REGISTRATION_REQUIRED =
  'authentication/loadMfaRegistrationRequired';
export const MFA_REGISTRATION_INITIALIZED = 'authentication/mfaRegistrationInitialized';
export const MFA_REGISTRATION_SUCCESS = 'authentication/mfaRegistrationSuccess';
export const MFA_REGISTRATION_FAILURE = 'authentication/mfaRegistrationFailure';
export const MFA_VERIFICATION_SUCCESS = 'authentication/mfaVerificationSuccess';
export const MFA_VERIFICATION_FAILURE = 'authentication/mfaVerificationFailure';
export const LOGIN = 'authentication/login';
export const LOGIN_FAILED = 'authentication/loginFailed';
export const LOGOUT = 'authentication/logout';
export const SUSPENDED_USER_ERROR = 'authentication/suspendedUser';

export type AuthenticationState = {
  sessionId?: string;
  authErrorMessage?: string;
  needsMfaCheck?: boolean;
  mfaQrCodeData?: string;
  needsMfaRegistration?: boolean;
  showSuspendedUserMessage?: boolean;
  mfaRegistrationSuccessful?: boolean;
  mfaRegistrationErrorMessage?: string;
  mfaVerificationSuccessful?: boolean;
  mfaVerificationErrorMessage?: string;
  isMasqueradeSession?: boolean;
  rememberMe?: boolean;
  authToken?: string;
  loginError?: string;
};

type SetAuthErrorAction = {
  type: typeof SET_AUTH_ERROR;
  authErrorMessage: string;
};

type LoadMfaCheckAction = {
  type: typeof LOAD_MFA_CHECK;
};

type LoadMfaRegistrationRequiredAction = {
  type: typeof LOAD_MFA_REGISTRATION_REQUIRED;
};

type MfaRegistrationInitializedAction = {
  type: typeof MFA_REGISTRATION_INITIALIZED;
  qrCodeData: string;
};

type MfaRegistrationSuccessAction = {
  type: typeof MFA_REGISTRATION_SUCCESS;
};

type MfaRegistrationFailureAction = {
  type: typeof MFA_REGISTRATION_FAILURE;
  errorMessage: string;
};

type MfaVerificationSuccessAction = {
  type: typeof MFA_VERIFICATION_SUCCESS;
};

type MfaVerificationFailureAction = {
  type: typeof MFA_VERIFICATION_FAILURE;
  errorMessage: string;
};

type SetLoginAction = {
  type: typeof LOGIN;
  authToken: string;
  rememberMe?: boolean;
  sessionId?: string;
  isMasqueradeSession?: boolean;
};

type LoginFailedAction = {
  type: typeof LOGIN_FAILED;
  error: string;
};

type LogoutAction = {
  type: typeof LOGOUT;
};

type SuspendedUserAction = {
  type: typeof SUSPENDED_USER_ERROR;
};

export type Action =
  | SetAuthErrorAction
  | LoadMfaCheckAction
  | LoadMfaRegistrationRequiredAction
  | MfaRegistrationInitializedAction
  | MfaRegistrationSuccessAction
  | MfaRegistrationFailureAction
  | MfaVerificationSuccessAction
  | MfaVerificationFailureAction
  | SuspendedUserAction
  | SetLoginAction
  | LoginFailedAction
  | LogoutAction;
