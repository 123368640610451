import { Person } from '@enview/interface/types/Person';
import { ReactElement, useState } from 'react';
import CircleIcon from './Icons/CircleIcon';

type PersonImageProps = {
  person: Person;
  size?: number;
};

const PersonImage = (props: PersonImageProps): ReactElement => {
  const { person, size } = props;
  const [imageError, setImageError] = useState<boolean>(false);

  if (!imageError && person.sourceIds && person.sourceIds.length > 0) {
    const openStatesId = person.sourceIds.find((id) => id.startsWith('ocd-person'));
    if (!openStatesId) setImageError(true);

    const openStatesImageUrl = `https://data.openstates.org/images/small/${openStatesId}`;
    return (
      <div className="person-image">
        <img
          alt={`Headshot of ${person.name}`}
          className="w-100"
          onError={() => {
            setImageError(true);
          }}
          src={openStatesImageUrl}
        />
      </div>
    );
  }

  function getNameForIcon(personDetails: Person): string {
    // If given and family name both defined, use those fields
    if (personDetails.givenName && personDetails.familyName)
      return `${personDetails.givenName.charAt(0)}${personDetails.familyName.charAt(
        0,
      )}`;
    // If not, parse full name field
    const firstMatches = /^(\S+)/.exec(personDetails.name);
    const lastMatches = /(\S+)(,\s+\S+)*$/.exec(personDetails.name);
    if (firstMatches?.length && lastMatches?.length)
      return `${firstMatches[0].charAt(0)}${lastMatches[0].charAt(0)}`;
    return personDetails.name
      .split(' ')
      .map((n) => n.charAt(0))
      .join('');
  }

  return (
    <CircleIcon className="user" size={size || 100}>
      {getNameForIcon(person)}
    </CircleIcon>
  );
};

export default PersonImage;
