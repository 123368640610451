import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseIcon from '../../../components/svg/CloseIcon';
import './Form.scss';

type InviteEmailsFormProps = {
  initialEmails: string[];
  submitText: string;
  onSubmit: (emails: string[]) => void;
  additionalActionButtons?: ReactElement[];
};
const ICON_MAX_WIDTH = '36px';

const EmailListForm = (props: InviteEmailsFormProps) => {
  const { initialEmails, submitText, onSubmit, additionalActionButtons } = props;
  const { handleSubmit } = useForm();
  const [emails, setEmails] = useState<string[]>(initialEmails);

  const onEmailsSubmit = (): void => {
    const nonEmptyEmails = emails.filter((e) => e !== '');
    onSubmit(nonEmptyEmails);
  };

  return (
    <form className="form emails-form" onSubmit={handleSubmit(onEmailsSubmit)}>
      {emails.map((email: string, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="field-group" key={`key-${index}`}>
          <input
            className="form-control form-field"
            id={`email-${index}`}
            onChange={(e) => {
              const updatedEmails = [...emails];
              updatedEmails[index] = e.target.value;
              setEmails(updatedEmails);
            }}
            placeholder="Email address"
            type="email"
            value={email}
          />
          {index === emails.length - 1 ? (
            <FontAwesomeIcon
              className="form-field-action"
              color="#979797"
              icon={faPlus}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                setEmails([...emails, '']);
              }}
              size="sm"
              style={{ cursor: 'pointer', maxWidth: ICON_MAX_WIDTH }}
            />
          ) : (
            <CloseIcon
              className="hover-form-field-action"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                const updatedEmails = [...emails];
                updatedEmails.splice(index, 1);
                setEmails(updatedEmails);
              }}
              style={{ maxWidth: ICON_MAX_WIDTH }}
            />
          )}
        </div>
      ))}
      <div className="d-flex flex-row">
        <div className="d-flex mt-4 flex-wrap flex-grow-1 emails-form-buttons">
          {additionalActionButtons}
          <button
            className="btn btn-action btn-primary orange"
            id="email-list-submit"
            style={additionalActionButtons ? { flex: '1 0 0' } : {}}
            type="submit"
          >
            {submitText}
          </button>
        </div>
        {additionalActionButtons && <div style={{ width: ICON_MAX_WIDTH }} />}
      </div>
    </form>
  );
};

export default EmailListForm;
