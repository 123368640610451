import React, { ReactElement } from 'react';
import './BillTagging.scss';
import { Link } from 'react-router-dom';
import { Tag } from '@enview/interface/types/tags/Tag';
import CloseIcon from '../../../components/svg/CloseIcon';

const TAG_LINK = '/legislative-tracking/tagged-bills/';

type TagIndicatorProps = {
  tag: Tag;
  onRemove: (tagId: number) => void;
};

const TagIndicator = (props: TagIndicatorProps): ReactElement => {
  const { tag, onRemove } = props;
  return (
    <div className="d-flex flex-row tag-indicator">
      <Link to={`${TAG_LINK}${tag.id}`}>
        <span className="tag-indicator-label">{tag.name}</span>
      </Link>
      <div className="d-flex tag-indicator-close">
        <CloseIcon onClick={() => onRemove(tag.id)} />
      </div>
    </div>
  );
};

export default TagIndicator;
