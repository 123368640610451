import React, { ReactElement, useState } from 'react';
import CustomDropdown from '../../Shared/DropDowns/CustomDropdown';
import GatedDropdownTargetButton from './GatedDropdownTargetButton';
import { t } from 'i18next';
import MultiSelectForm from '../../Shared/Forms/MultiSelectForm';
import { OptionType } from '../../Shared/DropDowns/MultipleSelectDropDown';
import '../../Shared/Search/BillTableFilters.scss';

type BillStatusFilterDropdownProps = {
  status: string[];
  statusOptions?: OptionType<string>[];
  handleStatusFiltersChange: (updatedStatusFilters: string[]) => void;
};

const BillStatusFilterDropdown = (
  props: BillStatusFilterDropdownProps,
): ReactElement => {
  const { status, statusOptions, handleStatusFiltersChange } = props;

  const [showStatusFilter, setShowStatusFilter] = useState(false);

  const selectedStatusCount = statusOptions?.filter((statusOption) =>
    status.some((aStatus) => statusOption.value === aStatus),
  ).length;

  return (
    <div className="adv-filter filter bill-status">
      <CustomDropdown
        setShowDropdown={setShowStatusFilter}
        showDropdown={showStatusFilter}
        target={
          <GatedDropdownTargetButton
            isDisplayed={showStatusFilter}
            label={t('buttons.search.billStatus')}
            onClick={() => setShowStatusFilter(!showStatusFilter)}
            selectedCount={selectedStatusCount}
          ></GatedDropdownTargetButton>
        }
      >
        <div className="adv-search-selector">
          <MultiSelectForm
            currentSelection={status}
            options={statusOptions ?? []}
            showClearAll
            showSelectAll
            showSelectButtonsBottom
            singleColumn
            updateSelection={handleStatusFiltersChange}
          />
        </div>
      </CustomDropdown>
    </div>
  );
};

export default BillStatusFilterDropdown;
