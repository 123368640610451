import { Bill } from '@enview/interface/types/bills/Bill';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { getJurisdictionsForTeamMode } from '../../../dux';
import { State } from '../../../dux/@types';
import ConfirmActionPopover from '../Popovers/ConfirmActionPopover';
import '../Popovers/Popover.scss';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';
import BillTagContent from './BillTagContent';
import './BillTagging.scss';

type BillTagButtonProps = {
  bill: Bill;
  isDisabled?: boolean;
  hasStatusIndicator?: boolean;
  showPlaceholderText?: boolean;
};

const BillTagButton = (props: BillTagButtonProps): ReactElement => {
  const { bill, hasStatusIndicator, isDisabled, showPlaceholderText } = props;
  const isTagModalOpen = useSelector((state: State) => state.modals.modalOpenTag);
  const [isPopoverShowing, setIsPopoverShowing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [moreTags, setMoreTags] = useState(false);

  // Get jurisdictions available to this org, or to all the user's orgs
  // if the personal workspace is selected
  const allowedJurisdictions = useSelector(getJurisdictionsForTeamMode);

  const topOrgTags = useRef<null | HTMLDivElement>(null);

  const tagScroll = (): void => {
    if (topOrgTags && topOrgTags.current && moreTags) {
      topOrgTags.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(tagScroll, [moreTags]);

  const targetElement: ReactElement = (
    <div
      aria-disabled={isDisabled}
      className={`d-flex bill-tag-button ${isDisabled && 'disabled'}`}
      id={`tag-button-${bill.id}`}
      style={{ marginRight: '12px' }}
      title="Add tags"
    >
      <FontAwesomeIcon
        aria-labelledby={t('altText.tagsButton')}
        color={hasStatusIndicator && bill.tags?.length > 0 ? '#F79728' : '#979797'}
        icon={faTag}
        size="sm"
      />
      {!bill.tags?.length && showPlaceholderText && (
        <span className="empty-field">{t('bill.header.tagFieldPlaceholder')}</span>
      )}
    </div>
  );

  const jurisdictionAccess = allowedJurisdictions.find(
    (j) => bill.session.stateId === j.id,
  );

  const showPopover = isDisabled || !jurisdictionAccess ? false : isPopoverShowing;

  return (
    <>
      <ConfirmActionPopover
        align="start"
        closePopup={() => setIsPopoverShowing(false)}
        content={isPopoverShowing ? <BillTagContent billData={bill} /> : ''}
        label="Tag Bill"
        labelIcon={<FontAwesomeIcon color="#979797" icon={faTag} />}
        onClickOutside={() => {
          if (!isTagModalOpen) {
            setIsPopoverShowing(false);
          }
        }}
        positions={['bottom', 'top']}
        show={showPopover}
        style={{ minWidth: '400px' }}
        target={targetElement}
        toggleCallback={() => setIsPopoverShowing(!isPopoverShowing)}
      />
      {/* Free or logged-out users see an upgrade message */}
      {isDisabled && (
        <GateTooltip
          accountType="pro"
          anchorId={`tag-button-${bill.id}`}
          customContent={
            <Trans
              components={{
                tagLink: <a href={t('urls.tagBills')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.tagButton"
              key="featureGating.tooltipText.tagButton"
            />
          }
          featureName={GateTooltipFeature.BillTagging}
          place="right"
        />
      )}
      {/* Starter/pro users without access to the jurisdiction see a different message*/}
      {!isDisabled && !jurisdictionAccess && (
        <ReactTooltip
          anchorSelect={`#tag-button-${bill.id}`}
          className="tooltip-gate"
          clickable
          openOnClick={true}
          place="right"
        >
          <div className="tooltip-gate-content">
            <p className="tooltip-gate-text">
              Your organization does not have access to bill tagging in this
              jurisdiction
            </p>
          </div>
        </ReactTooltip>
      )}
    </>
  );
};

export default BillTagButton;
