import React, { ReactElement } from 'react';
import './Button.scss';
import ArrowDown from '../../../components/svg/ArrowDown';

type DropDownButtonProps = {
  label: string;
  dropdownDisplayed: boolean;
  onClick: () => void;
  selectedCount?: number;
  icon?: ReactElement;
  id?: string;
  tagsLabel?: JSX.Element;
  disabled?: boolean;
};

const DropDownButton = (props: DropDownButtonProps) => {
  const {
    label,
    dropdownDisplayed,
    onClick,
    selectedCount,
    icon,
    id,
    tagsLabel,
    disabled,
  } = props;
  let className = `dropdown-button ${selectedCount === 0 ? ' text-center' : ''}`;
  if (disabled) {
    className = `${className} disabled`;
  }
  return (
    <div
      aria-expanded={dropdownDisplayed}
      aria-haspopup="true"
      className={className}
      data-toggle="dropdown"
      id={id}
      onClick={disabled ? () => {} : onClick}
    >
      {icon && <div className="dropdown-icon">{icon}</div>}
      <div className="dropdown-title">{tagsLabel || label}</div>
      {selectedCount === undefined && <ArrowDown />}
      {selectedCount !== undefined && selectedCount > 0 && (
        <div className="dropdown-count">{selectedCount}</div>
      )}
    </div>
  );
};

export default DropDownButton;
