import { Committee } from '@enview/interface/types/Committee';
import { LegislativeChamber } from '@enview/interface/types/LegislativeSession';

enum ElectedPositionParty {
  DEMOCRAT = 'democratic',
  REPUBLICAN = 'republican',
}

export const convertPartyToPartyLabel = (party: string, plural: boolean): string => {
  if (!party) return '';
  switch (party.toLowerCase()) {
    case ElectedPositionParty.DEMOCRAT:
      return plural ? 'Democrats' : 'Democrat';
    case ElectedPositionParty.REPUBLICAN:
    default:
      return plural ? `${party}s` : party;
  }
};

export const getCommitteeName = (
  committee: Committee,
  chamber?: LegislativeChamber,
): string => {
  let fullName = '';
  if (chamber && !committee.name.includes(chamber.name))
    fullName = fullName.concat(chamber.name);
  if (/ on /i.test(committee.name)) {
    return fullName.length === 0
      ? committee.name
      : fullName.concat(' ', committee.name);
  }
  return fullName.concat(
    ' ',
    committee.parent?.name ? `${committee.parent?.name} ` : '',
    committee.parent || committee.parentId ? 'Subcommittee' : 'Committee',
    ' on ',
    committee.name,
  );
};

export const getCommitteeDisplayName = (committee: Committee): string => {
  if (committee.parent?.name) {
    return `${committee.parent.name} > ${committee.name}`;
  }
  return committee.name;
};
