import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '../../../components/svg/CloseIcon';
import './Page.scss';

type GridCardProps = {
  image: ReactElement;
  title: string | ReactElement;
  data: { [label: string]: string | { [label: string]: string } };
  onClose?: () => void;
  overflowMenu?: ReactElement;
  tagLabel?: string;
  link?: string;
};

const GridCard = (props: GridCardProps): ReactElement => {
  const { image, title, data, onClose, overflowMenu, tagLabel, link } = props;
  const location = useLocation();

  const getTopRightContent = (): ReactElement => {
    if (overflowMenu) {
      return <div className="row ml-auto overflow-menu">{overflowMenu}</div>;
    }
    if (onClose && !tagLabel) {
      return (
        <div className="row" onClick={onClose} style={{ marginTop: '-10px' }}>
          <CloseIcon className="ml-auto" />
        </div>
      );
    }
    return <></>;
  };

  const getRightHandTagLabel = (): ReactElement => {
    if (tagLabel && !overflowMenu) {
      return <div className="grid-card-tag">{tagLabel}</div>;
    }
    return <></>;
  };

  const getOverflowTagLabel = (): ReactElement => {
    if (overflowMenu && tagLabel)
      return (
        <div className="row">
          <div className="overflow-col col">
            <div className="grid-card-tag">{tagLabel}</div>
          </div>
        </div>
      );
    return <></>;
  };

  return (
    <div className="grid-card card">
      {getTopRightContent()}
      <div className="row m-0">
        <div className="mr-auto">{image}</div>
        <div>{getRightHandTagLabel()}</div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card-name">
            {link ? (
              <Link to={{ pathname: link, state: { fromPath: location.pathname } }}>
                {title}
              </Link>
            ) : (
              <div>{title}</div>
            )}
          </div>
        </div>
      </div>
      {getOverflowTagLabel()}
      {Object.entries(data).map(([rowLabel, rowValue]) => (
        <div className="row" key={rowLabel}>
          {typeof rowValue === 'string' ||
          (typeof rowValue === 'object' && !rowValue?.toString) ? (
            <div className="col">
              <div className="title card-label">{rowLabel.toUpperCase()}</div>
              <div className="card-value">{rowValue}</div>
            </div>
          ) : (
            Object.entries(rowValue).map(([colLabel, colValue]) => (
              <div className="col" key={colLabel}>
                <div className="title card-label">{colLabel.toUpperCase()}</div>
                <div className="card-value">{colValue}</div>
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
};

export default GridCard;
