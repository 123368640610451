import React, { ReactElement, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { BillSearchCriteria } from '@enview/interface/types/BillSearch';
import CloseIcon from '../../../components/svg/CloseIcon';
import { State } from '../../../dux/@types';
import { closeSavedSearchModal } from '../../../dux/ModalDux';
import TeamAPI from '../../../api/team';
import { getTeamMode } from '../../../dux';

const SavedSearchModal = (): ReactElement => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const teamModeId = useSelector(getTeamMode);
  const isOpen = useSelector((state: State) => state.modals.modalOpenSavedSearch);
  const close = (): void => {
    dispatch(closeSavedSearchModal());
  };

  const searchCriteria = useSelector(
    (state: State) => state.modals.modalSavedSearchCriteria,
  );

  const [createSavedSearch, { isSuccess, isLoading, isError }] =
    TeamAPI.endpoints.createSavedSearch.useMutation();

  const [isFadingOut, setFadeOut] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      // Upon successful API call, after 5 seconds set a CSS class that fades the confirmation popup,
      // and 2 seconds later close the entire modal
      setTimeout(() => setFadeOut(true), 6000);
      setTimeout(() => close(), 7000);
    }
  });
  const generateExampleText = (criteria: Partial<BillSearchCriteria>): string => {
    // Set reasonable default values for the placeholder text
    let subject = 'Bills';
    let jurisdictions = 'TX, MN, and GA';
    let sessions = '2021 - 2022';

    // If a search query is there, use it
    if (criteria.query && criteria.query.length > 0) {
      subject = `${criteria.query} bills`;
    }

    // If there are 5 or less jurisdictions selected, list them. Otherwise, show a count
    if (criteria.jurisdictions && criteria.jurisdictions.length > 5) {
      jurisdictions = `${criteria.jurisdictions.length} jurisdictions`;
    } else if (criteria.jurisdictions && criteria.jurisdictions.length > 0) {
      const abbrs = criteria.jurisdictions.map((s) => s.toUpperCase());
      jurisdictions = abbrs.join(', ');
    }

    // Show, in order or precedence:
    // 1) "Current sessions" if selected
    // 2) Number of sessions selected
    // 3) Name of the selected session if only 1
    if (
      criteria.sessionNames &&
      criteria.sessionNames.find((s) => s.toLowerCase() === 'current sessions')
    ) {
      sessions = 'Current Sessions';
    } else if (criteria.sessionNames && criteria.sessionNames.length > 1) {
      sessions = `${criteria.sessionNames.length} sessions`;
    } else if (criteria.sessionNames && criteria.sessionNames.length > 0) {
      [sessions] = criteria.sessionNames;
    }

    return `${subject} in ${jurisdictions} (${sessions})`;
  };
  const onFormSubmit = (evt: React.SyntheticEvent): void => {
    evt.preventDefault();
    void createSavedSearch({
      name,
      criteria: searchCriteria,
      teamId: teamModeId,
    });
  };

  return !isSuccess ? (
    <Modal
      className="d-flex flex-column react-modal"
      closeTimeoutMS={100}
      contentLabel="Modal"
      isOpen={isOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      overlayClassName="react-modal-overlay"
    >
      <div className="saved-search content" style={{ minWidth: 500, maxWidth: 600 }}>
        <div>
          <CloseIcon className="float-right" onClick={close} />
        </div>
        <h2 className="title">Save Search</h2>
        <form onSubmit={onFormSubmit}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="savedSearchName">Name</label>
          <input
            aria-describedby="nameExample"
            className="form-control"
            id="savedSearchName"
            onChange={(event) => {
              setName(event.target.value);
            }}
            type="text"
          />
          <div className="example" id="nameExample">
            Example: {generateExampleText(searchCriteria)}
          </div>
          <div>
            <button
              className="btn-action"
              disabled={name.length === 0 || isLoading}
              type="submit"
            >
              Save
            </button>
            <button
              className="btn-text btn-cancel"
              onClick={() => close()}
              type="button"
            >
              Cancel
            </button>
          </div>
          {isError && <div className="error-msg">Failed to save search parameters</div>}
        </form>
      </div>
    </Modal>
  ) : (
    <div className={isFadingOut ? 'confirmation-popup fade-out' : 'confirmation-popup'}>
      <div>
        <CloseIcon className="float-right" onClick={close} />
      </div>
      <FontAwesomeIcon className="check-icon" color="#1AA08F" icon={faCheckCircle} />
      <h3 className="title">Success!</h3>
      <p>Your search has successfully been saved.</p>
      <p>
        <Link className="learn-more" to="/teams/searches">
          View my saved searches
        </Link>
      </p>
    </div>
  );
};

export default SavedSearchModal;
