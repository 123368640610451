import React from 'react';

export default () => (
    <svg
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.883 7.13869C18.9553 7.35431 19.1889 7.49961 19.4503 7.49961C19.7116 7.49961 19.9452 7.35431 20.0175 7.13869L20.7849 4.87475L23.4709 4.2279C23.7267 4.16697 23.8991 3.97011 23.8991 3.7498C23.8991 3.5295 23.7267 3.33264 23.4709 3.2717L20.7849 2.62486L20.0175 0.360919C19.9452 0.145305 19.7116 0 19.4503 0C19.1889 0 18.9553 0.145305 18.883 0.360919L18.1156 2.62486L15.4296 3.2717C15.1738 3.33264 15.0014 3.5295 15.0014 3.7498C15.0014 3.97011 15.1738 4.16697 15.4296 4.2279L18.1156 4.87475L18.883 7.13869ZM12.6324 7.37774C12.143 6.53872 10.725 6.53872 10.2356 7.37774L7.66081 11.7838L1.9051 12.4915C0.815132 12.6228 0.375808 13.7571 1.16548 14.4086L5.33072 17.8397L4.35197 22.6816C4.1629 23.6003 5.30848 24.3034 6.28723 23.8675L11.4368 21.5801L16.5863 23.8675C17.5651 24.3034 18.7107 23.6003 18.5216 22.6816L17.5373 17.8397L21.7025 14.4086C22.4922 13.7571 22.0584 12.6228 20.9629 12.4915L15.2072 11.7838L12.6324 7.37774ZM24.6109 11.3994L25.2227 13.2087C25.2783 13.3774 25.4674 13.4993 25.6787 13.4993C25.89 13.4993 26.0735 13.3821 26.1347 13.2087L26.7464 11.3994L28.893 10.8838C29.0932 10.8369 29.2378 10.6776 29.2378 10.4995C29.2378 10.3213 29.0987 10.1667 28.893 10.1151L26.7464 9.5995L26.1347 7.79022C26.0791 7.62148 25.89 7.49961 25.6787 7.49961C25.4674 7.49961 25.2838 7.61679 25.2227 7.79022L24.6109 9.5995L22.4644 10.1151C22.2642 10.162 22.1196 10.3213 22.1196 10.4995C22.1196 10.6776 22.2586 10.8322 22.4644 10.8838L24.6109 11.3994Z"
            fill="#FF8427"
        />
    </svg>
);
