import React from 'react';

export default (props) => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.77412 10.9973L5.30782 12.4636V1.03504C5.30782 0.474394 4.85499 0 4.27278 0C3.69057 0 3.23774 0.45283 3.23774 1.03504V12.4636L1.77358 10.9973C1.36388 10.5876 0.716981 10.5876 0.307278 10.9973C-0.102426 11.407 -0.102426 12.0539 0.307278 12.4636L3.54178 15.6981C3.73585 15.8922 4.01617 16 4.27493 16C4.53369 16 4.81402 15.8922 5.00809 15.6981L8.24259 12.4636C8.65229 12.0539 8.65229 11.407 8.24259 10.9973C7.83073 10.5876 7.18383 10.5876 6.77412 10.9973Z"
      fill="#27A4CC"
    />
    <path
      d="M19.4537 13.9297H13.3944C12.8338 13.9297 12.3594 14.3825 12.3594 14.9647C12.3594 15.5469 12.8122 15.9998 13.3944 15.9998H19.4537C20.0144 15.9998 20.4888 15.5469 20.4888 14.9647C20.4888 14.3825 20.0359 13.9297 19.4537 13.9297Z"
      fill="#27A4CC"
    />
    <path
      d="M20.4883 1.03504C20.4883 0.474394 20.0355 0 19.4533 0H9.25379C8.69314 0 8.21875 0.45283 8.21875 1.03504C8.21875 1.61725 8.67158 2.07008 9.25379 2.07008H19.4533C20.0355 2.07008 20.4883 1.61725 20.4883 1.03504Z"
      fill="#27A4CC"
    />
    <path
      d="M19.4541 4.6582H10.6346C10.074 4.6582 9.59961 5.11103 9.59961 5.69324C9.59961 6.27545 10.0524 6.72828 10.6346 6.72828H19.4541C20.0147 6.72828 20.4891 6.27545 20.4891 5.69324C20.4891 5.11103 20.0363 4.6582 19.4541 4.6582Z"
      fill="#27A4CC"
    />
    <path
      d="M19.4529 9.29297H12.0136C11.4529 9.29297 10.9785 9.7458 10.9785 10.328C10.9785 10.8887 11.4313 11.3631 12.0136 11.3631H19.4529C20.0136 11.3631 20.4879 10.9102 20.4879 10.328C20.4879 9.7458 20.0351 9.29297 19.4529 9.29297Z"
      fill="#27A4CC"
    />
  </svg>
);
