import { UserAction } from '@enview/interface/src/types/actions/UserAction';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import FeedbackData from '@enview/interface/types/actions/FeedbackData';
import { convertIdToJurisdictionAbbreviation } from '../helpers/BillHelper';
import { track } from './SegmentAnalytics';

enum AnalyticsEvent {
  CREATE_COMMENT = 'Add Comment',
  CREATE_BILL_ANNOTATION = 'Bill Annotated',
  CREATE_BILL_TEAM_ANNOTATION = 'Bill Team Annotated',
  EDIT_COMMENT = 'Edit Comment',
  FILE_UPLOAD = 'File Upload',
  JUMP_TO_TEXT = 'Jump to Text',
  JUMP_TO_COMMENT = 'Jump to Comment',
  REPORT_PROBLEM = 'Report a Problem',
  REPORT_PROBLEM_ERROR = 'Report a Problem Error',
  SHARE_ITEM = 'Share Item',
}

export const trackCreateUserAction = (userAction: Partial<UserAction>) => {
  const isSharedWithTeams = userAction.teams && userAction.teams.length > 0;
  const jurisdiction = userAction.billData
    ? convertIdToJurisdictionAbbreviation(userAction.billData.billId)
    : null;

  let event;
  if (userAction.annotation) {
    if (isSharedWithTeams) {
      event = AnalyticsEvent.CREATE_BILL_TEAM_ANNOTATION;
    } else {
      event = AnalyticsEvent.CREATE_BILL_ANNOTATION;
    }
  } else {
    event = AnalyticsEvent.CREATE_COMMENT;
  }

  track(event, {
    type: userAction.sourceFeature,
    billId: userAction.billData ? userAction.billData.billId : null,
    state: jurisdiction,
    jurisdiction,
    username: userAction.organizationUser ? userAction.organizationUser.email : null,
    teamIds: userAction.teams ? userAction.teams.join(',') : '',
    comment: userAction.comment,
    annotation: userAction.annotation,
    isReply: !!userAction.replyTo,
    personId: userAction.person?.id || null,
    mentions: userAction.mentionUserIds,
  });
};

enum CommentUpdates {
  SHARE = 'share',
  EDIT = 'edit',
  DELETE = 'delete',
  STAR = 'star',
  UNSTAR = 'unstar',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}

export const commentActionAnalytics = (
  update: CommentUpdates,
  userAction: Partial<UserAction>,
) => {
  const jurisdiction = userAction.billData
    ? convertIdToJurisdictionAbbreviation(userAction.billData.billId)
    : null;
  track(AnalyticsEvent.EDIT_COMMENT, {
    type: userAction.sourceFeature,
    billId: userAction.billData ? userAction.billData.billId : null,
    state: jurisdiction,
    jurisdiction,
    username: userAction.organizationUser ? userAction.organizationUser.email : null,
    teamIds: userAction.teams ? userAction.teams.join(',') : '',
    comment: userAction.comment,
    annotation: userAction.annotation,
    isReply: !!userAction.replyTo,
    action: update,
    personId: userAction.person?.id || null,
    mentions: userAction.mentionUserIds,
  });
};

export const trackEditComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.EDIT, userAction);
};

export const trackShareComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.SHARE, userAction);
};

export const trackDeleteComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.DELETE, userAction);
};

export const trackStarComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.STAR, userAction);
};

export const trackUnstarComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.UNSTAR, userAction);
};

export const trackArchiveComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.ARCHIVE, userAction);
};

export const trackUnarchiveComment = (userAction: Partial<UserAction>) => {
  return commentActionAnalytics(CommentUpdates.UNARCHIVE, userAction);
};

export const trackUploadFile = (
  userAction: Partial<UserAction>,
  fileName: string,
): void => {
  const { billData } = userAction;
  const jurisdiction = billData
    ? convertIdToJurisdictionAbbreviation(billData.billId)
    : null;
  track(AnalyticsEvent.FILE_UPLOAD, {
    billId: billData ? billData.billId : null,
    state: jurisdiction,
    jurisdiction,
    username: userAction.organizationUser ? userAction.organizationUser.email : null,
    teamIds: userAction.teams ? userAction.teams.join(',') : '',
    fileName,
  });
};

export const trackJumpToText = (userAction: UserAction): void => {
  const { billData } = userAction;
  const jurisdiction = billData
    ? convertIdToJurisdictionAbbreviation(billData.billId)
    : null;
  track(AnalyticsEvent.JUMP_TO_TEXT, {
    billId: billData ? billData.billId : null,
    state: jurisdiction,
    jurisdiction,
    username: userAction.organizationUser ? userAction.organizationUser.email : null,
    teamIds: userAction.teams ? userAction.teams.join(',') : '',
    comment: userAction.comment,
  });
};

export const trackJumpToComment = (userAction: UserAction): void => {
  const { billData } = userAction;
  const jurisdiction = billData
    ? convertIdToJurisdictionAbbreviation(billData.billId)
    : null;
  track(AnalyticsEvent.JUMP_TO_COMMENT, {
    billId: billData ? billData.billId : null,
    state: jurisdiction,
    jurisdiction,
    username: userAction.organizationUser ? userAction.organizationUser.email : null,
    teamIds: userAction.teams ? userAction.teams.join(',') : '',
    comment: userAction.comment,
  });
};

export const trackProblemReported = (data: FeedbackData): void => {
  track(AnalyticsEvent.REPORT_PROBLEM, {
    elementId: data.elementId,
    organizationUserId: data.organizationUserId,
    username: data.username,
    teamId: data.teamId,
    url: data.url,
    metadata: data.metadata,
  });
};

export const trackProblemReportedError = (data: FeedbackData): void => {
  track(AnalyticsEvent.REPORT_PROBLEM_ERROR, {
    elementId: data.elementId,
    organizationUserId: data.organizationUserId,
    username: data.username,
    teamId: data.teamId,
    url: data.url,
    metadata: data.metadata,
  });
};

export const trackShare = (
  data: {
    dataType?: string;
    shareType?: 'email' | 'linkedIn' | 'twitter' | 'facebook';
    id?: string | number;
    sharedUrl: string;
  },
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.SHARE_ITEM, {
    dataType: data.dataType ?? 'bill',
    shareType: data.shareType ?? 'email',
    id: data.id,
    sharedUrl: data.sharedUrl,
    orgUsername: orgUser?.email,
  });
};
