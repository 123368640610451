import { AccountState } from './types';

const initialAccountState: AccountState = {
  organizationUser: undefined,
  organizationUsers: [],
  notifications: {
    data: [],
    meta: {
      pageSize: 0,
      total: 0,
      page: 0,
    },
  },
};

export default initialAccountState;
