export default interface FeedbackData {
  feedbackType: FeedbackType;
  elementId?: string;
  organizationUserId: number;
  username?: string;
  teamId?: number;
  url?: string;
  metadata?: unknown;
  message?: string;
}

export enum FeedbackType {
  ReportProblem = 'Problem Reported',
  BillSummaryInaccurate = 'Bill Summary Inaccurate',
  VersionToVersionSummaryInaccurate = 'Version to Version Summary Inaccurate',
  EndOfSessionReport = 'End of Session Report Inaccurate',
  Other = 'Other',
}
