export interface InsightsPolicyTopic {
  id: number;
  policyTopic: InsightsPolicyTopicType;
  insightName: string;
}

export enum InsightsPolicyTopicType {
  AGRICULTURE = 'Agriculture, Animals, Climate Change, and Natural Resources',
  APPROPRIATIONS = 'Appropriations, Budget and Taxation',
  ARTS = 'Arts, History and Tourism',
  BUSINESS = 'Business, Commerce, and Economic Development',
  CIVIL_LAW = 'Civil Law, Family Law, and Judicial Proceedings',
  CRIMINAL_JUSTICE = 'Criminal Justice, Public Safety, Law Enforcement, and Incarceration',
  EMPLOYMENT = 'Employment, Labor and Professional Development',
  EDUCATION = 'Education and Childcare',
  GOVERNMENT = 'Government and Elections',
  HEALTH = 'Health and Social Services',
  HOUSING = 'Housing, Private Property, and Land Use',
  INSURANCE = 'Insurance and Financial Services',
  MILITARY = 'Military, Veterans, and International Affairs',
  PRIVACY = 'Privacy',
  SCIENCE = 'Science and Technology',
  TRANSPORTATION = 'Transportation, Energy and Utilities',
  UNTAGGABLE = 'Untaggable',
}
