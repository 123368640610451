// eslint-disable-next-line max-classes-per-file
enum BillGroupType {
  TEAM = 'team',
  TAG = 'tag',
}

export abstract class BillGroup {
  id: number;

  type: BillGroupType;

  public static equals(a: BillGroup, b: BillGroup): boolean {
    return a.id === b.id && a.type === b.type;
  }

  protected constructor(id: number, type: BillGroupType) {
    this.id = id;
    this.type = type;
  }
}

export class TeamBillGroup extends BillGroup {
  constructor(id: number) {
    super(id, BillGroupType.TEAM);
  }
}
export class TagBillGroup extends BillGroup {
  constructor(id: number) {
    super(id, BillGroupType.TAG);
  }
}

export const splitGroupsByType = (billGroups: BillGroup[]) => {
  const teamIds: number[] = [];
  const tagIds: number[] = [];
  billGroups.forEach((filter) => {
    switch (filter.type) {
      case BillGroupType.TEAM:
        teamIds.push(filter.id);
        break;
      case BillGroupType.TAG:
        tagIds.push(filter.id);
        break;
      default:
        break;
    }
  });
  return [teamIds, tagIds];
};
