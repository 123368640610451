import React from 'react';

export default (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.35806 2.0144C4.10039 2.0144 4.70193 2.616 4.70193 3.3584C4.70193 4.1008 4.10039 4.7024 3.35806 4.7024C2.61574 4.7024 2.0142 4.1008 2.0142 3.3584C2.0142 2.616 2.61574 2.0144 3.35806 2.0144ZM5.70823 0H1.34387C0 0 0 0 0 1.344V5.7104C0 6.7184 0.0271973 6.6768 0.510349 7.1616L8.9863 15.6384C9.46945 16.1216 9.45665 16.1216 9.93981 15.6384L15.648 9.9552C16.1184 9.4848 16.1184 9.4576 15.6352 8.9888L7.14649 0.5104C6.66333 0.04 6.71613 0 5.70823 0Z"
      fill="#27A4CC"
    />
  </svg>
);
