import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TextButton from '../Buttons/TextButton';
import './Form.scss';
import { TAG_SECTION_LABEL } from '../Modals/ExportBills/ExportBillGroupSelection';

export type MultiSelectOption<T> = {
  label: string;
  value: T;
  section?: SectionHeader;
};

export type SectionHeader = string;

type MultiSelectFormProps<T> = {
  description?: string;
  singleColumn: boolean;
  options: MultiSelectOption<T>[];
  showSelectAll: boolean;
  showClearAll?: boolean;
  showSelectButtonsBottom?: boolean;
  currentSelection: T[];
  updateSelection: (values: T[]) => void;
  equals: (a: T, b: T) => boolean;
  filter?: ReactElement;
  sections?: SectionHeader[];
  selectAllLabel?: string;
  selectAllFilterFunction?: (values: T[]) => T[];
  isDisabled?: boolean;
  containerHeight?: number;
};

const SELECT_ALL = 'Select All';
const DESELECT_ALL = 'Deselect All';
const CLEAR_ALL = 'Clear All';

const MultiSelectForm = <T extends any>(
  props: MultiSelectFormProps<T>,
): ReactElement => {
  const {
    description,
    singleColumn,
    options,
    showSelectAll,
    showClearAll,
    showSelectButtonsBottom,
    currentSelection,
    updateSelection,
    equals,
    filter,
    sections,
    selectAllLabel,
    selectAllFilterFunction,
    isDisabled,
    containerHeight,
  } = props;

  const [selectedValues, setSelectedValues] = useState(currentSelection);
  const columnStyle =
    singleColumn || options.length < 10 ? { columnWidth: 'revert' } : {};

  const getAllValues = useCallback(() => {
    return options.map((o) => o.value);
  }, [options]);

  useEffect(() => {
    setSelectedValues(currentSelection);
  }, [currentSelection]);

  const sortOptionsByLabel = useMemo(() => {
    const sortedOptions = [...options].sort((a, b) => {
      if (a.section === TAG_SECTION_LABEL && b.section === TAG_SECTION_LABEL) {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      }
      return 0;
    });
    return sortedOptions;
  }, [options]);

  const handleSelectControl = (selectOption: string): void => {
    let newValues: T[] = [];
    if (selectOption === SELECT_ALL) {
      if (selectAllFilterFunction) {
        newValues = selectAllFilterFunction(getAllValues());
      } else {
        newValues = getAllValues();
      }
    }
    setSelectedValues(newValues);
    updateSelection(newValues);
  };

  const handleCheckBoxEvent = (
    event: ChangeEvent<HTMLInputElement>,
    option: MultiSelectOption<T>,
  ): void => {
    let newSelectedOptions;
    if (event.target.checked) {
      newSelectedOptions = [...selectedValues, option.value];
    } else {
      newSelectedOptions = selectedValues.filter((v) => !equals(v, option.value));
    }
    setSelectedValues(newSelectedOptions);
    updateSelection(newSelectedOptions);
  };

  const renderSelectControl = (
    label: string,
    action: string,
    disabled: boolean,
  ): ReactElement => {
    return (
      <TextButton
        className="select-control bold"
        disabled={disabled}
        id={`select-control-${label.toLowerCase().replace(' ', '-')}`}
        label={label}
        onClick={() => handleSelectControl(action)}
      />
    );
  };

  const renderOptions = (selectOptions: MultiSelectOption<T>[]): ReactElement[] => {
    return selectOptions.map((option, index) => {
      const isOptionSelected = !!selectedValues.find((v) => equals(v, option.value));
      const optionLabelId = `multi-select-form-${option.label
        .toLowerCase()
        .replace(' ', '-')}`;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="option" key={`${option.label}-${index}`}>
          <input
            checked={isOptionSelected}
            className={`checkbox ${isOptionSelected ? 'selected' : ''}`}
            disabled={isDisabled ?? false}
            id={optionLabelId}
            name={option.label}
            onChange={(e) => handleCheckBoxEvent(e, option)}
            type="checkbox"
          />
          <label className="option-label" htmlFor={optionLabelId}>
            {option.label}
          </label>
        </div>
      );
    });
  };

  const renderOptionsWithSections = (
    selectOptions: MultiSelectOption<T>[],
  ): ReactElement => {
    const optionsWithoutSections: MultiSelectOption<T>[] = [];
    const sectionOptionMap: { [label: string]: MultiSelectOption<T>[] } = {};
    // First section for options without a section provided
    sections!.forEach((section) => {
      sectionOptionMap[section] = [];
    });
    selectOptions.forEach((option) => {
      if (!option.section || !sectionOptionMap[option.section]) {
        optionsWithoutSections.push(option);
      } else {
        sectionOptionMap[option.section].push(option);
      }
    });

    return (
      <div className="section-container">
        {sections?.map((section, index) => (
          <div
            className="section-column"
            key={`${section}-${index}`}
            style={
              containerHeight
                ? {
                    maxHeight: containerHeight,
                    overflowY: 'auto',
                  }
                : {}
            }
          >
            {index === 0 && (
              <div className="section-options">
                {renderOptions(optionsWithoutSections)}
              </div>
            )}
            <div className="section-header">{section}</div>
            <div className="section-options">
              {renderOptions(sectionOptionMap[section])}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const selectAll = selectAllLabel ?? SELECT_ALL;
  const selectButtons = showSelectAll && (
    <div className={`p-0 select-control-items${filter ? ' text-right' : ''}`}>
      {renderSelectControl(
        selectAll,
        SELECT_ALL,
        options.length === selectedValues.length,
      )}
      {showClearAll
        ? renderSelectControl(CLEAR_ALL, CLEAR_ALL, selectedValues.length === 0)
        : renderSelectControl(DESELECT_ALL, DESELECT_ALL, selectedValues.length === 0)}
    </div>
  );

  return (
    <div className="d-flex flex-column multi-select-form">
      {description && <div className="description">{description}</div>}
      {filter || (!showSelectButtonsBottom && selectButtons) ? (
        <div className="d-flex flex-row pb-2">
          {filter && <div className="col-5 p-0 align-self-center">{filter}</div>}
          {!showSelectButtonsBottom && selectButtons}
        </div>
      ) : (
        ''
      )}
      <div
        className={`options ${sections ? 'd-flex align-items-start' : ''}`}
        style={columnStyle}
      >
        {sections
          ? renderOptionsWithSections(sortOptionsByLabel)
          : renderOptions(options)}
      </div>

      {showSelectButtonsBottom && selectButtons}
    </div>
  );
};

MultiSelectForm.defaultProps = {
  singleColumn: false,
  equals: (a, b) => a === b,
} as Partial<MultiSelectFormProps<any>>;

export default MultiSelectForm;
