/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-indent */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import DropDownButton from '../Buttons/DropDownButton';
import './DropDown.scss';

export type OptionType<T> = {
  label: string;
  value: T;
  id?: string;
  icon?: ReactElement;
};

type SingleSelectDropDownProps<T> = {
  defaultLabel: string;
  selectedValue: T | undefined;
  options: OptionType<T>[];
  onSelect: (option: OptionType<T>) => void;
  alignRight: boolean;
  equals: (a: T, b: T) => boolean;
  title?: string;
  description?: string;
  id?: string;
  disabled?: boolean;
  dropdownPosition?: number;
};

const SingleSelectDropDown = <T extends any>(props: SingleSelectDropDownProps<T>) => {
  const {
    alignRight,
    defaultLabel,
    description,
    equals,
    id,
    options,
    selectedValue,
    title,
    disabled,
    dropdownPosition,
  } = props;
  const node: any = useRef();
  const [displayed, setDisplayed] = useState(false);
  const positionClass = dropdownPosition ? ' position-static' : '';
  const showClass = displayed ? ' show' : '';
  let selectedOption;
  if (selectedValue !== undefined) {
    selectedOption = props.options.find((option) =>
      props.equals(option.value, selectedValue),
    );
  }
  const buttonLabel = selectedOption ? selectedOption.label : defaultLabel;

  const handleClickOutside = (e: MouseEvent) => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }
    setDisplayed(false);
  };
  useEffect(() => {
    if (displayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayed]);

  const selectHandlerFactory =
    (option: OptionType<T>, onSelect: (option: OptionType<T>) => void) =>
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      setDisplayed(false);
      onSelect(option);
    };

  const renderOption = (
    option: OptionType<T>,
    buttonProps: SingleSelectDropDownProps<T>,
  ) => {
    const indicator =
      buttonProps.selectedValue && equals(buttonProps.selectedValue, option.value);
    const value = `dropdown-single-${option.id || option.value}`.replace(/\s/g, '-');

    return (
      <a
        className={`dropdown-item ${indicator ? 'checked' : ''} ${
          option.value === 0 ? 'fixed' : ''
        }`}
        href="#"
        key={JSON.stringify(option.value)}
        onClick={selectHandlerFactory(option, buttonProps.onSelect)}
      >
        {option.icon && <div className="dropdown-item-icon">{option.icon}</div>}
        <div className="dropdown-item-text" id={value}>
          {option.label}
        </div>
      </a>
    );
  };

  const alignClass = alignRight ? ' dropdown-menu-right' : '';
  const dropdownElement = (
    <div
      aria-labelledby="dropdownMenuButton"
      className={`dropdown-menu${alignClass}${showClass}`}
    >
      {title ? <div className="title">{title}</div> : ''}
      {description ? <div className="description">{description}</div> : ''}

      {options.map((option) => renderOption(option, props))}
      {/* new workspace */}
    </div>
  );
  return (
    <div className={`dropdown${showClass}${positionClass}`} ref={node}>
      <DropDownButton
        disabled={disabled}
        dropdownDisplayed={displayed}
        icon={selectedOption ? selectedOption.icon : undefined}
        id={id}
        label={buttonLabel}
        onClick={() => setDisplayed(!displayed)}
      />
      {dropdownPosition !== undefined && (
        <div
          className="position-absolute"
          // If we don't yet have good position data, ignore
          style={dropdownPosition === -1 ? {} : { top: dropdownPosition }}
        >
          {dropdownElement}
        </div>
      )}
      {dropdownPosition === undefined && dropdownElement}
    </div>
  );
};

SingleSelectDropDown.defaultProps = {
  alignRight: false,
  equals: (a: any, b: any) => a === b,
};

export default SingleSelectDropDown;
