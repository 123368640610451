import JurisdictionAbbreviationMap from '@enview/interface/types/JurisdictionAbbreviationMap';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import JurisdictionCoordinates from './JurisdictionCoordinates';
import getMapboxToken from './utils';

type MapBoxProps = {
  jurisAbbr?: keyof typeof JurisdictionAbbreviationMap;
  center?: [number, number];
  zoom?: number;
  style?: string;
};

const MapBox = (props: MapBoxProps): ReactElement => {
  const { jurisAbbr, center, zoom, style } = props;
  const { t } = useTranslation();

  const accessToken = getMapboxToken();
  if (!accessToken) {
    return (
      <div className="mapboxgl-map">{t('jurisdictions.map.invalidMapboxToken')}</div>
    );
  }
  mapboxgl.accessToken = accessToken;

  const mapContainerRef = useRef(null);

  const getCenter = (): [number, number] => {
    let centerCoordinates: [number, number] | null | undefined = null;
    if (jurisAbbr) {
      const statecoordinates = JurisdictionCoordinates.get(jurisAbbr);
      if (statecoordinates?.latitude && statecoordinates?.longitude)
        centerCoordinates = [statecoordinates?.longitude, statecoordinates?.latitude];
    } else centerCoordinates = center;
    return centerCoordinates ?? [-74.5, 40];
  };

  const getZoom = (): number => {
    return jurisAbbr
      ? JurisdictionCoordinates.get(jurisAbbr)?.zoom ?? zoom ?? 6
      : zoom ?? 6;
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current ?? 'map-box-id',
      style: `mapbox://styles/mapbox/${style ?? `light-v11`}`,
      center: getCenter(),
      zoom: getZoom(),
    });

    return () => map.remove();
  }, [jurisAbbr]);

  return <div ref={mapContainerRef} />;
};

export default MapBox;
