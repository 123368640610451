import React from 'react';
import './Icon.scss';
import CircleIcon from './CircleIcon';

type UserIconProps = {
  firstName: string;
  lastName: string;
  size?: number;
};

const UserIcon = (props: UserIconProps) => {
  const { firstName, lastName, size } = props;
  const text = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  return (
    <CircleIcon className="user" size={size}>
      {text}
    </CircleIcon>
  );
};

export default UserIcon;
