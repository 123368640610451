import { Committee } from '@enview/interface/types/Committee';
import { ReactElement, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CommitteeAPI } from '../../api';
import { getCommitteeDisplayName } from '../../helpers/CommitteesHelper';
import { SortBy } from '../../models/SortOrder';
import MultipleSelectDropDown, {
  OptionType,
} from '../Shared/DropDowns/MultipleSelectDropDown';
import { SEOHead } from '../../components/head/SEOHead';
import STATES from '../../helpers/states';
import { SortableDataTableColumn } from '../LegislativeTracking/TabularBillSearchResults';
import { Spinner } from 'react-bootstrap';
import './Jurisdictions.scss';

type JurisdictionCommitteesProps = {
  jurisAbbr: string;
};

const CHAIR_ROLES = [
  'chair',
  'chairperson',
  'senior chair',
  'committee chair',
  'chairman',
  'chairwoman',
];

const VICE_CHAIR_ROLES = [
  'first vice chair',
  'vice-chair',
  'vice chair',
  'vice chairman',
  'vice chairperson',
  'vchairman',
  'first vice chairperson',
  'vice-chairman',
  'vice chairwoman',
  'majority vice-chair',
  'majority vice chair',
  '1st vice chair',
  'v chairman',
  'v chairwoman',
];

interface CommitteeDisplayOptions {
  includeRanking: boolean;
  includeVice: boolean;
  chamberOptions: OptionType<string>[];
}

const JurisdictionCommittees = (props: JurisdictionCommitteesProps): ReactElement => {
  const location = useLocation();
  const { jurisAbbr } = props;
  const {
    data: committeesData,
    isFetching,
    isError,
  } = CommitteeAPI.endpoints.getCommitteesByJurisdiction.useQuery(jurisAbbr);

  const [chamberFilters, setChamberFilters] = useState<string[]>([]);

  const getCommitteeSource = (url: string): string => {
    return url.includes('kslegislature.org/li/b') && url.slice(-1) !== '/'
      ? `${url}/`
      : url;
  };

  const getChamberName = (cmte: Committee) => {
    return cmte.memberships &&
      cmte.memberships.length > 0 &&
      cmte.memberships[0].electedPosition.chamber
      ? cmte.memberships[0].electedPosition.chamber?.name
      : cmte.chamber;
  };

  const getViceChair = (cmte: Committee) => {
    return cmte.memberships.find((member) =>
      VICE_CHAIR_ROLES.includes(member.role.toLowerCase()),
    )?.electedPosition.person;
  };

  const getRankingMember = (cmte: Committee) => {
    return cmte.memberships.find(
      (member) => member.role.toLowerCase().indexOf('ranking') >= 0,
    )?.electedPosition.person;
  };

  const getChair = (cmte: Committee) => {
    return cmte.memberships.find((member) =>
      CHAIR_ROLES.includes(member.role.toLowerCase()),
    )?.electedPosition.person;
  };

  const committeeDisplayOptions = useMemo<CommitteeDisplayOptions>(() => {
    const chamberOptions = new Array<OptionType<string>>();
    let includeRanking = false;
    let includeVice = false;

    if (!committeesData)
      return {
        includeRanking,
        includeVice,
        chamberOptions,
      };

    const chambers = new Set<string>();
    committeesData.forEach((cmte) => {
      const viceChair = getViceChair(cmte);
      const rankingMember = getRankingMember(cmte);

      if (viceChair) {
        includeVice = true;
      }
      if (rankingMember) {
        includeRanking = true;
      }
      const chamberName = getChamberName(cmte);
      if (!chambers.has(chamberName)) {
        chambers.add(chamberName);
        chamberOptions.push({
          value: chamberName,
          label: chamberName,
        });
      }
    });

    return {
      includeRanking,
      includeVice,
      chamberOptions,
    };
  }, [committeesData]);

  const columns: SortableDataTableColumn[] = useMemo(
    () => [
      {
        name: 'Chamber',
        selector: 'chamber',
        ignoreRowClick: true,
        maxWidth: '150px',
        style: {
          paddingLeft: '20px',
        },
        cell: (cmte: Committee) => {
          const chamber = getChamberName(cmte);
          return (
            <span className={`jurisdiction-committe-chamber pill ${cmte.chamber}`}>
              {cmte.chamber === 'legislature' ? 'joint' : chamber}
            </span>
          );
        },
      },
      {
        name: 'Name',
        selector: (row) => row.name,
        minWidth: '150px',
        sortable: true,
        sortKey: SortBy.ALPHABETICAL,
        cell: (cmte: Committee) => (
          <Link
            to={{
              pathname: `/committee/${cmte.id}`,
              // fromPath is used for Amplitude analytics to track sources on the destination page,
              // an example usage is in RootLayout.tsx
              state: { fromPath: location.pathname },
            }}
          >
            {getCommitteeDisplayName(cmte)}
          </Link>
        ),
      },
      {
        name: 'Chair',
        selector: 'chair',
        minWidth: '150px',
        cell: (cmte: Committee) => {
          const chair = getChair(cmte);
          return (
            <Link
              to={{
                pathname: `/person/${chair?.id}`,
                state: { fromPath: location.pathname },
              }}
            >
              {chair?.name}
            </Link>
          );
        },
      },
      {
        name: 'Vice Chair',
        selector: 'viceChair',
        omit: committeeDisplayOptions.includeVice,
        cell: (cmte: Committee) => {
          const viceChair = getViceChair(cmte);
          return (
            <Link
              to={{
                pathname: `/person/${viceChair?.id}`,
                state: { fromPath: location.pathname },
              }}
            >
              {viceChair?.name}
            </Link>
          );
        },
      },
      {
        name: 'Ranking Member',
        selector: 'rankingMember',
        omit: committeeDisplayOptions.includeRanking,
        cell: (cmte: Committee) => {
          const rankingMember = getRankingMember(cmte);
          return (
            <Link
              to={{
                pathname: `/person/${rankingMember?.id}`,
                state: { fromPath: location.pathname },
              }}
            >
              {rankingMember?.name}
            </Link>
          );
        },
      },
      {
        name: 'Source',
        selector: 'source',
        cell: (cmte: Committee) => {
          return (
            <div>
              {cmte.links && cmte.links.length > 0 ? (
                <a
                  href={getCommitteeSource(cmte.links[0])}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Link
                </a>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  if (isFetching) {
    return (
      <div className="table-spinner-overlay">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }
  if (isError) {
    return <span>Error loading committees!</span>;
  }
  if (!committeesData || committeesData.length === 0) {
    return (
      <div className="alert alert-secondary" role="alert">
        No committee information is currently available for this jurisdiction.
      </div>
    );
  }
  if (committeesData) {
    const filteredCommittees =
      chamberFilters.length === 0
        ? committeesData
        : committeesData.filter((cmte) => {
            const chamber = getChamberName(cmte);
            return chamberFilters.includes(chamber);
          });

    const stateName = STATES[jurisAbbr.toUpperCase()];

    return (
      <>
        <SEOHead
          description={`details on legislative committees in ${stateName}`}
          title={`${stateName} Committees`}
        />
        <div className="m-4">
          <h2>{`${filteredCommittees?.length} Committees`}</h2>
          <div className="mt-3 d-flex flex-row">
            <div className="filter" style={{ flexBasis: 0 }}>
              <MultipleSelectDropDown
                currentSelectedValues={chamberFilters}
                onChange={setChamberFilters}
                options={committeeDisplayOptions.chamberOptions}
                title="Chamber"
              />
            </div>
          </div>
        </div>

        <div className="table committee-table">
          <DataTable
            columns={columns}
            data={filteredCommittees}
            noContextMenu
            pagination
            paginationRowsPerPageOptions={[10, 20, 50]}
            responsive
            sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
          />
        </div>
      </>
    );
  }
  return <></>;
};

export default JurisdictionCommittees;
