import { Jurisdiction } from './Jurisdiction';
import { AccountType, Organization } from './Organization';
import { PolicyArea } from './PolicyArea';
import { TeamMembership } from './Team';

export enum UserRole {
  OrgUser = 'org-user',
  OrgAdmin = 'org-admin',
  EnviewAdmin = 'enview-admin',
}

export enum ReasonForUse {
  PERSONAL = 'personal',
  PROFESSIONAL = 'professional',
}

export interface OrganizationUserRole {
  id: number;
  name: UserRole;
  organizationId: number;
  displayName: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserRegistration {
  id: number;
  organizationUserId: number;
  helpLevelNeeded: string;
  legislativeWorkExperience: string;
  additionalData: { reasonsForUsingEnview: string[] };
  createdAt: Date;
  updatedAt: Date;
}

interface MfaRegistration {
  id: number;
  type: string;
  info: string;
  userId: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum EmailNotifications {
  BILL_REAL_TIME = 'realTimeBillNotification',
  BILL_DAILY = 'dailyBillNotification',
  BILL_WEEKLY = 'weeklyBillNotification',
  SEARCH_HOURLY = 'hourlySearchNotification',
  SEARCH_DAILY = 'dailySearchNotification',
  SEARCH_WEEKLY = 'weeklySearchNotification',
  BILL_RECOMMENDED = 'recommendedWeeklyBillNotification',
  COLLABORATION = 'collaborationNotification',
  MENTION = 'mentionNotification',
}

export interface UserSettings {
  id?: number;
  organizationUserId?: number;
  [EmailNotifications.BILL_REAL_TIME]: boolean;
  [EmailNotifications.BILL_DAILY]: boolean;
  [EmailNotifications.BILL_WEEKLY]: boolean;
  [EmailNotifications.SEARCH_HOURLY]: boolean;
  [EmailNotifications.SEARCH_DAILY]: boolean;
  [EmailNotifications.SEARCH_WEEKLY]: boolean;
  [EmailNotifications.BILL_RECOMMENDED]: boolean;
  [EmailNotifications.COLLABORATION]: boolean;
  [EmailNotifications.MENTION]: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export type OrganizationUser = {
  id: number;
  teamId: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  city: string;
  state: string;
  phoneNumber: string;
  suspended: boolean;
  orgSuspensions: { organizationId: number }[];
  jobTitle: string;
  organizationType: string;
  organizations: Organization[];
  roles: OrganizationUserRole[];
  policyAreas: PolicyArea[];
  teamMemberships: TeamMembership[];
  mfaRegistrations?: MfaRegistration[];
  settings: UserSettings;
  userRegistration: UserRegistration;
  credentialId?: number;
  disableSearchTip?: boolean;
  mfaEnabled?: boolean;
  isNew?: boolean;
  password?: string;
  searchJurisdictions?: Jurisdiction[];
  reasonForUse: ReasonForUse;
  adminSetFields?: AdminSetFields;
};

export type UserInvitation = {
  id?: number;
  email: string;
  firstName?: string;
  lastName?: string;
  used?: boolean;
  url?: string;
  expiration: string;
  confirmationCode?: string;

  organizationId: number;
  authorizingUserId: number;

  org?: Organization;
  createdAt: string;
  status?: string;

  accountType: AccountType;
};

export type SignupData = {
  authorizationCode: string;
  accountType: AccountType;
  firstName: string;
  lastName: string;
  email: string;
  city?: string;
  state?: string;
  phoneNumber?: string;
  reasonForUse?: ReasonForUse;
  organizationId: number;
  organizationName?: string;
  teamId: number;
  password: string;
  passwordConfirm: string;
  termsAccepted: boolean;
};

// Plural admin defined/set fields for internal use only
export type AdminSetFields = {
  reasonForUse: ReasonForUse | null;
};

export type SubscribedTeamsForUser = {
  userId: number;
  email: string;
  subscribedTeamIds: number[];
};
