/* eslint-disable react/require-default-props */
import { AccountType } from '@enview/interface/types/Organization';
import BillSearchView from '@enview/interface/types/bills/BillSearchView';
import {
  faLayerGroup as CardIcon,
  faList as TableIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { ReactElement, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDown from '../../../components/svg/ArrowDown';
import {
  g as abilityGlossary,
  determineTopUserAccountStatus,
} from '../../../config/ability';
import {
  checkPermission,
  getOrgForTeamMode,
  getTeamMode,
  updateBillSearchView,
} from '../../../dux';
import { State } from '../../../dux/@types';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';

type BillViewDropdownProps = {
  id?: string;
};

const BillViewDropdown = (props: BillViewDropdownProps): ReactElement => {
  const { id } = props;
  const [viewIcon, setViewIcon] = useState(CardIcon);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const canViewTable = () => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.TABULAR_BILL_LIST, true),
    );
  };

  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const userStatus = determineTopUserAccountStatus(orgUser);
  const starterCanShowDropdown = !!(canViewTable() && useSelector(getOrgForTeamMode));
  const canShowDropdown = !!canViewTable();

  const teamId = useSelector(getTeamMode);
  const billSearchView =
    useSelector((state: State) => state.account.billSearchView) || BillSearchView.Card;

  useEffect(() => {
    setViewIcon(billSearchView == BillSearchView.Card ? CardIcon : TableIcon);
  }, [billSearchView]);

  const handleViewChange = (newValue: BillSearchView) => {
    return () => {
      if (newValue && showDropdown) dispatch(updateBillSearchView(teamId, newValue));
    };
  };

  return (
    <>
      <Dropdown
        aria-disabled={
          userStatus == AccountType.Starter ? !starterCanShowDropdown : !canShowDropdown
        }
        id="view-dropdown-trigger"
        onToggle={(isOpen, event, metadata) => {
          if (metadata.source === 'click' && canShowDropdown) {
            setShowDropdown(!showDropdown);
          }
        }}
        show={
          userStatus == AccountType.Starter
            ? starterCanShowDropdown && showDropdown
            : canShowDropdown && showDropdown
        }
      >
        <Dropdown.Toggle
          as="span"
          className="dropdown-button"
          id={id ?? 'bill-view-dropdown'}
        >
          <FontAwesomeIcon className="dropdown-icon" icon={viewIcon} />
          <span className="centered-text">View</span>
          <ArrowDown className="dropdown-arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={handleViewChange(BillSearchView.Table)}>
            <FontAwesomeIcon className="dropdown-item-icon" icon={TableIcon} />
            <span className="dropdown-item-text">Table</span>
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleViewChange(BillSearchView.Card)}>
            <FontAwesomeIcon className="dropdown-item-icon" icon={CardIcon} />
            <span className="dropdown-item-text">Cards</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {(userStatus === AccountType.Starter
        ? !starterCanShowDropdown
        : !canShowDropdown) && (
        <GateTooltip
          accountType="pro"
          anchorId="view-dropdown-trigger"
          customContent={
            <Trans
              components={{
                viewLink: <a href={t('urls.tableView')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.billViewDropdown"
              key="featureGating.tooltipText.billViewDropdown"
            />
          }
          featureName={GateTooltipFeature.BillViewDropdown}
          place="top"
        />
      )}
    </>
  );
};

export default BillViewDropdown;
