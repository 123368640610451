import { BillDetailed } from '@enview/interface/types/bills/Bill';
import { Team } from '@enview/interface/types/Team';
import { UserAction } from '@enview/interface/types/actions/UserAction';

// STATE SLICE
export type ExportState = {
  exportBills?: BillDetailed[];
  exportTeams?: Team[];
  exportUserActions?: { [billTeamStateKey: string]: UserAction[] };
};

// ACTIONS
export const SET_EXPORT_BILLS = 'export/setBills';
export const SET_EXPORT_TEAMS = 'export/setExportTeams';
export const SET_EXPORT_BILL_TEAM_USER_ACTIONS = 'export/setBillTeamUserActions';
export const CLEAR_EXPORT_DATA = 'export/clearExportData';

type SetBillsAction = {
  type: typeof SET_EXPORT_BILLS;
  bills: BillDetailed[];
};

type SetExportTeamsAction = {
  type: typeof SET_EXPORT_TEAMS;
  teams: Team[];
};

type SetBillTeamUserActionsAction = {
  type: typeof SET_EXPORT_BILL_TEAM_USER_ACTIONS;
  billId: string;
  teamId: number;
  userActions: UserAction[];
};

type ClearExportDataAction = {
  type: typeof CLEAR_EXPORT_DATA;
};

export type Action =
  | SetBillsAction
  | SetExportTeamsAction
  | SetBillTeamUserActionsAction
  | ClearExportDataAction;
