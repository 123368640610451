import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
import {
  getSelectedTeamIds,
  setSelectedTeamIds,
  clearSelectedTeamIds,
  getTeamMode,
  getOrgIdForTeamMode,
} from '../../dux';
import { TeamAPI } from '../../api';

const SHARE_WITH = 'Share with ';

type TeamSelectorProps = {
  initialSelection?: number[];
  singleSelect: boolean;
  mention?: boolean;
};

const TeamSelector = (props: TeamSelectorProps): ReactElement => {
  const { initialSelection, singleSelect, mention } = props;
  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const teamModeId = useSelector(getTeamMode);
  const teamModeOrgId = useSelector(getOrgIdForTeamMode);
  const selectedTeamIds = useSelector(getSelectedTeamIds);
  const dispatch = useDispatch();

  const MENTION_TEAMS_TEXT = `The user(s) you've mentioned will only be able to see your note in the workspace(s) of which they are a member.`;

  const teams = useMemo(
    () =>
      (teamMemberships || [])
        .map((tm) => tm.team)
        .sort((team1, team2) => {
          const userTeamId = orgUser && orgUser.teamId;
          if (team1.id === userTeamId) {
            return -1;
          }
          if (team2.id === userTeamId) {
            return 1;
          }
          return team1.name.localeCompare(team2.name);
        })
        .filter(
          (team) =>
            team.organizationId === teamModeOrgId ||
            (orgUser && team.id === orgUser.teamId),
        ),
    [teamMemberships, teamModeOrgId],
  );

  // TODO: fix adding initialSelection to dependencies
  useEffect(() => {
    if (initialSelection) {
      dispatch(setSelectedTeamIds(initialSelection));
    } else {
      dispatch(setSelectedTeamIds([teamModeId]));
    }
    return () => {
      dispatch(clearSelectedTeamIds());
    };
  }, [dispatch, teamModeId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked, id } = event.target;
    const teamId = parseInt(id.slice(5, id.length), 10);
    if (checked) {
      dispatch(
        setSelectedTeamIds(singleSelect ? [teamId] : [...selectedTeamIds, teamId]),
      );
    } else if (!singleSelect) {
      dispatch(setSelectedTeamIds(selectedTeamIds.filter((t) => t !== teamId)));
    }
  };

  return (
    <>
      <div className="team-selector">
        {teams.map((team) => {
          const shareWith = `${
            orgUser && team.id === orgUser.teamId ? '' : SHARE_WITH
          }${team.name}`;
          const teamInputId = `team_${team.id}`;
          const type = singleSelect ? 'radio' : 'checkbox';
          return (
            <div className="anno-visibility mt-2 d-flex" key={team.id}>
              <input
                checked={selectedTeamIds.includes(team.id)}
                id={teamInputId}
                name={singleSelect ? 'singleSelect' : team.name}
                onChange={handleChange}
                type={type}
                value={team.id}
                // only disable the checkbox for the current team id and if mentions are in the comment
                disabled={mention && team.id === teamModeId ? true : false}
              />
              <label htmlFor={teamInputId}>{shareWith}</label>
            </div>
          );
        })}
      </div>
      {mention &&
        selectedTeamIds.includes(teamModeId) &&
        selectedTeamIds.length > 1 && (
          <div className="alert alert-warning warning m-1 mt-2">
            {MENTION_TEAMS_TEXT}
          </div>
        )}
    </>
  );
};

TeamSelector.defaultProps = {
  singleSelect: false,
};

export default TeamSelector;
