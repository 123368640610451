import { Committee } from '@enview/interface/types/Committee';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Person } from '@enview/interface/types/Person';
import { BillBasic } from '@enview/interface/types/bills/Bill';
import PersonVote from '@enview/interface/types/votes/PersonVote';
import { track, page } from './SegmentAnalytics';

enum AnalyticsEvent {
  OPEN_PERSON_SOURCE_LINK = 'Open Person Source Link',
  PERSON_CONTACT_INFO_CLICKED = 'Clicked Contact Info',
  VIEWED_COMMITTEE_PAGE = 'Committee',
  OPEN_COMMITTEE_SOURCE_LINK = 'Open Committee Source Link',
  VIEWED_VOTE_HISTORY = 'Viewed Vote History',
  CLICKED_BILL_NUMBER = 'Clicked Bill Number in Vote History',
}

export const trackOpenPersonSourceLink = (
  sourceLink: string,
  person: Person,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.OPEN_PERSON_SOURCE_LINK, {
    email: orgUser?.email,
    legislatorName: person.name,
    legislatorJurisdiction: person.electedPositions
      ?.map((ep) => ep.jurisdiction.name)
      .join(', '),
    sourceLink,
  });
};

export const trackPersonContactClicked = (
  isPhone: boolean,
  value: string,
  person: Person,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.PERSON_CONTACT_INFO_CLICKED, {
    email: orgUser?.email,
    legislatorName: person.name,
    legislatorJurisdiction: person.electedPositions
      ?.map((ep) => ep.jurisdiction.name)
      .join(', '),
    type: isPhone ? 'phone' : 'email',
    contactInfo: value,
  });
};

export const trackCommitteeSourceClicked = (
  committee: Committee,
  sourceLink: string,
): void => {
  track(AnalyticsEvent.OPEN_COMMITTEE_SOURCE_LINK, {
    committeeName: committee.name,
    jurisdiction: committee.jurisdiction.name,
    sourceLink,
  });
};

export const trackViewedCommitteePage = (committee: Committee): void => {
  page('Person', AnalyticsEvent.VIEWED_COMMITTEE_PAGE, {
    committeeName: committee.name,
    jurisdiction: committee.jurisdiction.name,
  });
};

export const trackViewedVoteHistory = (
  person: Person,
  orgUser?: OrganizationUser,
): void => {
  page('Person', AnalyticsEvent.VIEWED_VOTE_HISTORY, {
    email: orgUser?.email,
    legislatorName: person.name,
    legislatorId: person.id,
  });
};

export const trackClickedBillNumberInVoteHistory = (
  personVote: PersonVote,
  bill: BillBasic,
  orgUser?: OrganizationUser,
): void => {
  page('Person', AnalyticsEvent.CLICKED_BILL_NUMBER, {
    email: orgUser?.email,
    legislatorName: personVote.personName,
    legislatorId: personVote.personId,
    billNumber: bill.number,
    billName: bill.name,
  });
};
