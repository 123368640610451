import { BillSearchView } from '@enview/interface/types/BillSearch';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { getBillSearchView, getTeamMode } from '../../dux';
import { State } from '../../dux/@types';
import {
  buildParamsFromQuery,
  getQueryParamConfig,
} from '../../helpers/BillSearchHelper';
import BillSearchResult from '../LegislativeTracking/BillSearchResults';
import TabularBillSearchResults from '../LegislativeTracking/TabularBillSearchResults';

type JurisdictionBillsProps = {
  jurisAbbr: string;
};
const PAGE_SIZE = 25;

const JurisdictionBills = (props: JurisdictionBillsProps): ReactElement => {
  const { jurisAbbr } = props;
  const teamModeId = useSelector(getTeamMode);
  const dispatch = useDispatch();
  const [searching, setSearching] = useState(true);

  useEffect(() => {
    dispatch(getBillSearchView(teamModeId));
  }, [dispatch, teamModeId]);
  const billSearchView =
    useSelector((state: State) => state.account.billSearchView) ?? BillSearchView.Card;

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    ...getQueryParamConfig([jurisAbbr.toLowerCase()]),
  });
  useEffect(() => {
    setQuery({ page: query.page });
    window.scrollTo(0, 0);
  }, [query]);

  const params = useMemo(() => {
    return buildParamsFromQuery(query, teamModeId, PAGE_SIZE);
  }, [query]);

  return (
    <div className="bill-search-container">
      {searching && (
        <div className="table-spinner-overlay">
          <Spinner animation="border" role="status" />
        </div>
      )}
      <div className="results-pane list">
        {/* Card View */}
        {billSearchView === BillSearchView.Card && (
          <BillSearchResult
            params={params}
            query={query}
            searching={searching}
            setQuery={setQuery}
            setSearching={setSearching}
          />
        )}
        {/* Table View */}
        {billSearchView === BillSearchView.Table && (
          <TabularBillSearchResults
            params={params}
            query={query}
            searching={searching}
            setQuery={setQuery}
            setSearching={setSearching}
          />
        )}
      </div>
    </div>
  );
};

export default JurisdictionBills;
