import React, { CSSProperties, ReactElement } from 'react';
import './Button.scss';

type TextButtonProps = {
  id?: string;
  className?: string;
  style?: CSSProperties;
  label: string;
  onClick: () => void;
  disabled: boolean;
};

const TextButton = (props: TextButtonProps): ReactElement => {
  const { id, className, style, label, onClick, disabled } = props;
  return (
    <button
      className={`btn-text ${className ?? ''}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      style={style}
      type="button"
    >
      {label}
    </button>
  );
};

TextButton.defaultProps = {
  disabled: false,
};

export default TextButton;
