/* eslint-disable import/prefer-default-export */
const ACCOUNT_EXPIRE_MESSAGE =
  'Your login has expired. Please login again to continue.' +
  'If you were using a demo, your demo has expired - but you can still request a free trial.';
const SYSTEM_ERROR_MESSAGE =
  'A system problem occurred while trying to contact the Plural ' +
  'server. Please try again later, or contact support@pluralpolicy.com';

export const createAuthErrorMessage = (error: {
  response: { status: number };
}): string => {
  const errorCode = error.response ? error.response.status : 500;

  if (errorCode === 404) {
    return 'Your account could not be found. Try logging in again.';
  }
  if (errorCode === 401 || errorCode === 403) {
    return ACCOUNT_EXPIRE_MESSAGE;
  }
  return SYSTEM_ERROR_MESSAGE;
};
