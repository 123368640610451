// STATE SLICE
export type TeamSelectorState = {
  selectedTeamIds?: number[];
};

// ACTIONS
export const SET_SELECTED_TEAMS_ACTION = 'teamSelector/setSelectedTeamsAction';
export const CLEAR_SELECTED_TEAMS_ACTION = 'teamSelector/clearSelectedTeamsAction';

export type SetSelectedTeamsAction = {
  type: typeof SET_SELECTED_TEAMS_ACTION;
  teamIds: number[];
};

export type ClearSelectedTeamsAction = {
  type: typeof CLEAR_SELECTED_TEAMS_ACTION;
};

export type Action = SetSelectedTeamsAction | ClearSelectedTeamsAction;
