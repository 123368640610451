import React from 'react';

export default (props) => (
  <svg
    onClick={props.onClick || (() => true)}
    style={{ ...props.style }}
    className={`close-icon ${props.className ? props.className : ''}`}
    data-name="Close Icon"
    viewBox="0 0 14 14"
    height="100%"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>{props.title || 'close'}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1053.000000, -306.000000)">
        <g transform="translate(1048.000000, 301.000000)">
          <g>
            <polygon
              fill={props.fill || '#7F8FA4'}
              points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
            />
            <polygon points="0 0 24 0 24 24 0 24" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
