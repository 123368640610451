import React from 'react';

export default (props) => (
  <svg
    className="dropdown-arrow"
    width="12px"
    height="9px"
    viewBox="0 0 12 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>ic_keyboard_arrow_down</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-Used" transform="translate(-39.000000, -184.000000)">
        <g id="ic_keyboard_arrow_down" transform="translate(33.000000, 176.000000)">
          <g id="Icon-24px">
            <polygon
              id="Shape"
              fill={props.color || '#979797'}
              points="7.41 8.84 12 13.42 16.59 8.84 18 10.25 12 16.25 6 10.25"
            />
            <polygon id="Shape" points="0 0.25 24 0.25 24 24.25 0 24.25" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
