import { ReactElement, useEffect, useRef } from 'react';
import { BillAPI } from '../../api';
import { SortBy, SortByParam, SortOrder } from '../../models/SortOrder';
import ScrollButton from '../Shared/Buttons/ScrollButton';
import BillViewDropdown from '../Shared/DropDowns/BillViewDropdown';
import SortDropdown from '../Shared/DropDowns/SortDropdown';
import BillList from '../Shared/Lists/BillList';
import Paginator from '../Shared/PageElements/Paginator';

const LEG_SORT_BILL_OPTIONS = [
  new SortOrder(SortBy.RELEVANCE, false),
  new SortOrder(SortBy.RECENT, false),
  new SortOrder(SortBy.RECENT, true),
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

const PAGE_SIZE = 25;

type BillSearchResultProps = {
  params: any;
  searching: boolean;
  setSearching: (arg: boolean) => void;
  query: any;
  setQuery: (arg: any) => void;
};

const BillSearchResult = (props: BillSearchResultProps): ReactElement => {
  const { params, searching, setSearching, query, setQuery } = props;

  const { data: results, isSuccess } = BillAPI.endpoints.searchBills.useQuery(params);

  const defaultOrder = query.keywords
    ? SortOrder.getBillSortOrderFromParam(SortByParam.RELEVANCE)
    : SortOrder.getBillSortOrderFromParam(SortByParam.LATEST);
  const selectedBillOrder = query.order
    ? SortOrder.getBillSortOrderFromParam(query.order)
    : defaultOrder;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (results) setSearching(false);
  }, [results]);

  const handleSortOrderChange = (billSortOrder: SortOrder): void => {
    if (selectedBillOrder && selectedBillOrder.equals(billSortOrder)) return;
    setSearching(true);
    setQuery({ order: billSortOrder.getQueryParam(), page: 1 });
  };

  const renderBillList = (): ReactElement => {
    if (!results) {
      return <></>;
    }
    if (results.count === 0) {
      return (
        <div className="container">
          <BillList bills={[]} />
        </div>
      );
    }
    const pages = { page: query.page, total: results.count, pageSize: PAGE_SIZE };
    return (
      <div className="container">
        {ref && ref.current && <ScrollButton div={ref.current} />}
        <BillList bills={results.data} />
        <Paginator onChange={(page) => setQuery({ page })} pages={pages} />
      </div>
    );
  };

  return (
    <>
      <div className="results-pane list">
        {results && results.count > 0 && !searching && isSuccess && (
          <div className="container result-count">
            <div className="row">
              <div className="col-lg-3 col-xs-12 result-total">
                {results.count} Results
              </div>
              <div
                className={`col-lg-7 col-xs-12`}
                style={{
                  paddingTop: '24px',
                  marginLeft: '60px',
                }}
              >
                <SortDropdown
                  setSortOrder={handleSortOrderChange}
                  sortOptions={LEG_SORT_BILL_OPTIONS}
                  sortOrder={selectedBillOrder || defaultOrder}
                />
              </div>
              <div className="view-dropdown col-lg-1 col-xs-12">
                <BillViewDropdown />
              </div>
            </div>
          </div>
        )}
        {isSuccess && renderBillList()}
      </div>
    </>
  );
};

export default BillSearchResult;
