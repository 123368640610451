// STATE SLICE
export type RequestState = {
  pendingState: PendingState;
  successState: SuccessState;
  failureState: FailureState;

  // Below used if specific request name not provided,
  // should be avoided as it could overwrite other states
  pending: boolean;
  success: boolean;
  failure?: Error;
};

// ACTIONS
export const REQUEST_SENT = 'request/sent';
export const REQUEST_SUCCESS = 'request/succeeded';
export const REQUEST_FAILURE = 'request/failed';
export const RESET_REQUEST = 'request/reset';

export type PendingState = { [name: string]: boolean };
export type SuccessState = { [name: string]: boolean };
export type FailureState = { [name: string]: Error | undefined };

export type RequestSentAction = {
  type: typeof REQUEST_SENT;
  name?: string;
};
export type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS;
  name?: string;
};
export type RequestFailureAction = {
  type: typeof REQUEST_FAILURE;
  name?: string;
  error?: Error;
};
export type ResetRequestAction = {
  type: typeof RESET_REQUEST;
  name?: string;
};

export type Action =
  | RequestSentAction
  | RequestSuccessAction
  | RequestFailureAction
  | ResetRequestAction;
