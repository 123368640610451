import { t } from 'i18next';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import UsersIcon from '../../../../components/svg/UsersIcon';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';

const WorkspaceSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;

  const getCardBody = (): ReactElement => {
    return (
      <>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/teams/members"
        >
          <h4 className="sub-item">Members</h4>
        </NavLink>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/teams/bills"
        >
          <h4 className="sub-item">Bills</h4>
        </NavLink>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/teams/tags"
        >
          <h4 className="sub-item">Tags</h4>
        </NavLink>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/teams/searches"
        >
          <h4 className="sub-item">Saved Searches</h4>
        </NavLink>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/teams/activity"
        >
          <h4 className="sub-item">Activity</h4>
        </NavLink>
      </>
    );
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      customGateTooltipContent={
        <Trans
          components={{
            workspaceLink: <a href={t('urls.teamWorkspaces')} target="_blank" />,
            gate: <p className="tooltip-header" />,
          }}
          i18nKey="featureGating.tooltipText.workspaceSidebar"
          key="featureGating.tooltipText.workspaceSidebar"
        />
      }
      eventKey="WORKSPACE"
      header="Workspace"
      icon={<UsersIcon />}
    />
  );
};

export default WorkspaceSidebarSection;
