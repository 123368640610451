import Paging from '@enview/interface/types/paging/Paging';
import { RegulationSearchParams } from '@enview/interface/types/regulations/RegulationSearchParams';
import {
  ArrayParam,
  DecodedValueMap,
  NumberParam,
  NumericArrayParam,
  QueryParamConfigMap,
  StringParam,
  withDefault,
} from 'use-query-params';

export const getQueryParamConfig = (jurisdictions: string[]): QueryParamConfigMap => {
  return {
    keywords: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    jurisdictions: withDefault(ArrayParam, jurisdictions),
    agencies: withDefault(NumericArrayParam, undefined),
    sortBy: withDefault(StringParam, undefined),
    sortDirection: withDefault(StringParam, undefined),
  };
};

export const buildParamsFromQuery = (
  query: DecodedValueMap<QueryParamConfigMap>,
): RegulationSearchParams => {
  return {
    criteria: {
      keywords: query.keywords,
      jurisdictionAbbrs: query.jurisdictions,
      agencies: query.agencies as number[],
    },
    paging: Paging.getPagingFromQuery({ page: query.page, pageSize: query.pageSize }),
    sorting: {
      orderColumn: query.sortBy,
      orderDirection: query.sortDirection,
    },
  };
};
