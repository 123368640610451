import { RefObject } from 'react';

// STATE SLICE
export type LineRefs = { [id: string]: RefObject<HTMLDivElement> };
export type CardRefs = { [id: string]: RefObject<HTMLDivElement> };

type BillListCacheData = {
  endpoint: string;
  params?: any;
};

export type BillViewState = {
  lineRefs?: LineRefs;
  cardRefs?: CardRefs;
  selectedCardId?: number;
  billSidebarCurrentTab?: string;
  billListCacheKeys: BillListCacheData[];
};

// ACTIONS
export const SET_LINE_REFS = 'billView/setLineRefs';
export const SET_CARD_REFS = 'billView/setCardRefs';
export const SET_SELECTED_CARD_ID = 'billView/setSelectedCardId';
export const SET_BILL_SIDEBAR_CURRENT_TAB = 'billView/setBillSidebarCurrentTab';
export const ADD_BILL_LIST_CACHE_KEY = 'billView/addBillListCacheKey';
export const REMOVE_BILL_LIST_CACHE_KEY = 'billView/removeBillListCacheKey';

export type SetLineRefsAction = {
  type: typeof SET_LINE_REFS;
  refs?: LineRefs;
};
export type SetCardRefsAction = {
  type: typeof SET_CARD_REFS;
  refs?: CardRefs;
};
export type SetSelectedCardIdAction = {
  type: typeof SET_SELECTED_CARD_ID;
  cardId?: number;
};
export type SetBillSidebarCurrentTabAction = {
  type: typeof SET_BILL_SIDEBAR_CURRENT_TAB;
  tab?: string;
};

export type AddBillListCacheKeyAction = {
  type: typeof ADD_BILL_LIST_CACHE_KEY;
  endpoint: string;
  params?: any;
};

export type RemoveBillListCacheKeyAction = {
  type: typeof REMOVE_BILL_LIST_CACHE_KEY;
  endpoint: string;
  params?: any;
};

export type Action =
  | SetLineRefsAction
  | SetCardRefsAction
  | SetSelectedCardIdAction
  | SetBillSidebarCurrentTabAction
  | AddBillListCacheKeyAction
  | RemoveBillListCacheKeyAction;
