import assign from 'lodash-es/assign';
import isEqual from 'lodash-es/isEqual';
import React, { RefObject } from 'react';
import { BillAnnotation } from '@enview/interface/types/bills/BillAnnotation';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import {
  ADD_BILL_LIST_CACHE_KEY,
  BillViewState,
  CardRefs,
  LineRefs,
  REMOVE_BILL_LIST_CACHE_KEY,
  SET_BILL_SIDEBAR_CURRENT_TAB,
  SET_CARD_REFS,
  SET_LINE_REFS,
  SET_SELECTED_CARD_ID,
} from './types';
import { Action, State, Thunk } from '../@types';

// REDUCER
export default function reducer(state: BillViewState, action: Action): BillViewState {
  switch (action.type) {
    case SET_LINE_REFS:
      return assign({}, state, {
        lineRefs: action.refs,
      });
    case SET_CARD_REFS:
      return assign({}, state, {
        cardRefs: action.refs,
      });
    case SET_SELECTED_CARD_ID:
      return assign({}, state, {
        selectedCardId: action.cardId,
      });
    case SET_BILL_SIDEBAR_CURRENT_TAB:
      return assign({}, state, {
        billSidebarCurrentTab: action.tab,
      });
    case ADD_BILL_LIST_CACHE_KEY:
      return assign({}, state, {
        billListCacheKeys: state.billListCacheKeys.concat({
          endpoint: action.endpoint,
          params: action.params,
        }),
      });
    case REMOVE_BILL_LIST_CACHE_KEY:
      return assign({}, state, {
        billListCacheKeys: state.billListCacheKeys.filter((data) => {
          return (
            data.endpoint !== action.endpoint || !isEqual(data.params, action.params)
          );
        }),
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const setLineRefs = (refs?: LineRefs): Action => {
  return {
    type: SET_LINE_REFS,
    refs,
  };
};

const setCardRefs = (refs?: CardRefs): Action => {
  return {
    type: SET_CARD_REFS,
    refs,
  };
};

export const setSelectedCardId = (cardId?: number): Action => {
  return {
    type: SET_SELECTED_CARD_ID,
    cardId,
  };
};

export const setBillSidebarCurrentTab = (tab?: string): Action => {
  return {
    type: SET_BILL_SIDEBAR_CURRENT_TAB,
    tab,
  };
};

export const addBillListCacheKey = (endpoint: string, params?: any): Action => {
  return { type: ADD_BILL_LIST_CACHE_KEY, endpoint, params };
};

export const removeBillListCacheKey = (endpoint: string, params?: any): Action => {
  return { type: REMOVE_BILL_LIST_CACHE_KEY, endpoint, params };
};

// THUNKS
export const setAnnotationLineRefs = (annotations?: BillAnnotation[]): Thunk => {
  return (dispatch) => {
    if (!annotations || annotations.length === 0) {
      return dispatch(setLineRefs(undefined));
    }
    const refs: LineRefs = {};
    annotations.forEach((annotation) => {
      refs[annotation.fromLine] = React.createRef();
    });
    return dispatch(setLineRefs(refs));
  };
};

export const setUserActionCardRefs = (actions?: UserAction[]): Thunk => {
  return (dispatch) => {
    if (!actions || actions.length === 0) {
      return dispatch(setCardRefs(undefined));
    }
    const refs: CardRefs = {};
    actions.forEach((action) => {
      refs[action.id] = React.createRef();
    });
    return dispatch(setCardRefs(refs));
  };
};

// SELECTORS
export const getLineRef = (
  state: State,
  key: string,
): RefObject<HTMLDivElement> | undefined => {
  return state.billView.lineRefs ? state.billView.lineRefs[key] : undefined;
};

export const getCardRef = (
  state: State,
  key: number,
): RefObject<HTMLDivElement> | undefined => {
  return state.billView.cardRefs ? state.billView.cardRefs[key] : undefined;
};
