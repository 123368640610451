/* eslint-disable react/jsx-max-depth */
import { Team } from '@enview/interface/types/Team';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { g as abilityGlossary } from '../../../config/ability';
import { checkPermission, openExportBillsModal } from '../../../dux';
import { Thunk } from '../../../dux/@types';
import { TeamBillGroup } from '../../../models/BillGroup';
import GateTooltip, { GateTooltipFeature } from '../../Shared/Tooltips/TooltipGate';

type ExportButtonSectionProps = {
  teams: Team[];
};

const ExportButtonSection = (props: ExportButtonSectionProps): ReactElement => {
  const { teams } = props;
  const dispatch = useDispatch();

  const canExport = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(abilityGlossary.EXPORT, abilityGlossary.BILL, true),
    );
  };

  const exportBillList = (): void => {
    if (canExport()) {
      const filters = teams.map((team) => new TeamBillGroup(team.id));
      dispatch(openExportBillsModal(filters));
    }
  };

  return (
    <>
      <div className="export-nav-group nav-group" id="sidebar-export-nav-group-button">
        <div className="export-wrapper">
          <button
            aria-disabled={!canExport()}
            className="btn btn-primary export-button"
            onClick={exportBillList}
            type="button"
          >
            <span style={{ flexGrow: 0.5 }}>Export</span>
            <span>
              <FontAwesomeIcon icon={faFileExport as IconProp} />
            </span>
          </button>
        </div>
      </div>
      {!canExport() && (
        <GateTooltip
          accountType="pro"
          anchorId="sidebar-export-nav-group-button"
          customContent={
            <Trans
              components={{
                exportLink: <a href={t('urls.exportData')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.exportSidebar"
              key="featureGating.tooltipText.exportSidebar"
            />
          }
          featureName={GateTooltipFeature.Export}
          place="top"
        />
      )}
    </>
  );
};

export default ExportButtonSection;
