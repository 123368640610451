import React, { ReactElement } from 'react';
import DropDownButton from '../../Shared/Buttons/DropDownButton';
import GateTooltip from '../../Shared/Tooltips/TooltipGate';

type GatedDropdownTargetButtonProps = {
  isDisplayed: boolean;
  label: string;
  onClick: () => void;
  selectedCount?: number;
  disabled?: boolean;
  customContent?: ReactElement;
  ineligibleJurisdictions?: boolean;
};

const GatedDropdownTargetButton = (
  props: GatedDropdownTargetButtonProps,
): ReactElement => {
  const {
    isDisplayed,
    label,
    onClick,
    selectedCount,
    disabled,
    customContent,
    ineligibleJurisdictions,
  } = props;

  // For the purpose of this component, assuming that filter dropdowns will be disabled for
  // the reason of feature-gating logged-out users
  const id = `${label.replace(/\s/g, '-').toLowerCase()}-filter`;
  return (
    <>
      <DropDownButton
        disabled={ineligibleJurisdictions ?? disabled}
        dropdownDisplayed={isDisplayed}
        id={id}
        label={label}
        onClick={onClick}
        selectedCount={selectedCount}
      />
      {disabled && (
        <GateTooltip
          accountType="pro"
          anchorId={id}
          customContent={customContent}
          place="bottom"
        />
      )}
    </>
  );
};

export default GatedDropdownTargetButton;
