import { ReactElement, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserActionAPI } from '../../../api';
import { closeReportProblemModal, requestSuccess, resetRequest } from '../../../dux';
import { State } from '../../../dux/@types';
import './Modal.scss';

export const REQUEST_REPORT_PROBLEM = 'RequestReportProblem';

const ReportProblemModal = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector((state: State) => state.modals.modalOpenReportProblem);
  const data = useSelector((state: State) => state.modals.modalReportProblemData);
  const [commentMessage, setCommentMessage] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const [sendFeedback, { isError }] =
    UserActionAPI.endpoints.sendFeedback.useMutation();

  const handleClose = (): void => {
    dispatch(closeReportProblemModal());
  };

  const handleSubmit = async (): Promise<void> => {
    if (data) {
      data.message = commentMessage;
      await sendFeedback(data).then(() => {
        if (isError) {
          setShowErrorMessage(true);
        } else {
          dispatch(requestSuccess(REQUEST_REPORT_PROBLEM));
          handleClose();
          setTimeout(() => dispatch(resetRequest(REQUEST_REPORT_PROBLEM)), 5000);
        }
      });
    }
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCommentMessage(event.target.value);
  };

  const commentForm = (
    <Form id="report-problem-modal-form">
      <Form.Group controlId="report-problem-comment-group">
        <Form.Label>{t('modals.reportProblem.commentLabel')}</Form.Label>
        <Form.Control
          as="textarea"
          onChange={handleMessageChange}
          placeholder={t('modals.reportProblem.commentPlaceholder')}
        />
      </Form.Group>
    </Form>
  );

  return (
    <Modal
      contentLabel="Modal"
      id="report-problem-modal"
      isOpen={isOpen}
      onHide={handleClose}
      show={isOpen}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title id="report-problem-modal">
          {t('modals.reportProblem.header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{commentForm}</Modal.Body>
      <Modal.Footer>
        {showErrorMessage && (
          <div className="flex-row alert alert-warning" role="alert">
            {t('modals.reportProblem.errorMessage')}
          </div>
        )}
        <Button
          className="btn btn-action"
          id="report-problem-submit-btn"
          onClick={handleSubmit}
        >
          {t('modals.reportProblem.submitButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportProblemModal;
