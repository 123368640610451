import React, { ReactElement, useEffect, useState } from 'react';
import './TextBox.scss';

type TextBoxProps = {
  id: string;
  className?: string;
  text?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  keyPressed?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const TextBox = (props: TextBoxProps): ReactElement => {
  const { text, placeholder, id, onChange, keyPressed, className } = props;

  const [textBoxText, setTextBoxText] = useState<string>();

  useEffect(() => {
    setTextBoxText(text);
  }, [text]);

  const textBoxOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextBoxText(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <input
      className={className}
      id={id}
      maxLength={100}
      name={id}
      onChange={textBoxOnChange}
      onKeyPress={keyPressed}
      placeholder={placeholder}
      type="text"
      value={textBoxText || ''}
    />
  );
};

export default TextBox;
