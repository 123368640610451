import { IDataTableColumn } from 'react-data-table-component';
import { DecodedValueMap, QueryParamConfig } from 'use-query-params';

export const getDefaultPage = (
  query: DecodedValueMap<{
    limit: QueryParamConfig<number | null | undefined, number>;
    offset: QueryParamConfig<number | null | undefined, number>;
  }>,
): number => {
  return Math.floor(query.offset / query.limit) + 1;
};

export const getSortForQuery = (
  column: IDataTableColumn,
  sortDirection: string,
): { orderColumn?: string; orderDirection: 'ASC' | 'DESC' } => {
  return {
    orderColumn: column.selector?.toString(),
    orderDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
  };
};

export const getPageForQuery = (
  page: number,
  query: DecodedValueMap<{
    limit: QueryParamConfig<number | null | undefined, number>;
  }>,
): { offset: number; limit: number } => {
  return { offset: (page - 1) * query.limit, limit: query.limit };
};
