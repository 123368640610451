import { Hearing } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';

type JurisdictionHearingStatusBadgeProps = {
  hearing: Hearing;
};

const JurisdictionHearingStatusBadge = (
  props: JurisdictionHearingStatusBadgeProps,
): ReactElement => {
  const { hearing } = props;
  const startDateTime = new Date(hearing.start);

  const startStatus = startDateTime <= new Date() ? 'past' : 'upcoming';

  return (
    <button
      className={`btn btn-sm btn-secondary toggle hearing-status-toggle ${startStatus}`}
      disabled
      type="button"
    >
      <span>{startStatus}</span>
    </button>
  );
};

export default JurisdictionHearingStatusBadge;
