import { StaffDirectorySearchParams } from '@enview/interface/src/types/persons/StaffDirectorySearchParams';
import Paging from '@enview/interface/types/paging/Paging';
import {
  DecodedValueMap,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  withDefault,
} from 'use-query-params';

export const getStaffQueryParamConfig = (): QueryParamConfigMap => {
  return {
    keywords: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
  };
};

export const buildStaffParamsFromQuery = (
  query: DecodedValueMap<QueryParamConfigMap>,
): StaffDirectorySearchParams => {
  return {
    criteria: {
      keywords: query.keywords,
    },
    paging: Paging.getPagingFromQuery({ page: query.page, pageSize: query.pageSize }),
  };
};
