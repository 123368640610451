import { Bill } from '@enview/interface/types/bills/Bill';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BillAPI, TagAPI } from '../../../api';
import { getOrgIdForTeamMode, getTeamModeTeam } from '../../../dux';
import { sortTags } from '../../../helpers/TagHelper';
import TextButton from '../Buttons/TextButton';
import { useUrlCreator } from '../../../helpers/UrlHelper';
import ValueBadge from '../Badges/ValueBadge';
import { Tag } from '@enview/interface/types/tags/Tag';

type BillTagContentProps = {
  billData: Bill | Bill[];
};

const BillTagContent = (props: BillTagContentProps): ReactElement => {
  const { billData } = props;
  const [moreTags, setMoreTags] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const viewMoreTags = moreTags
    ? 'Hide tags from other workspaces'
    : 'View tags from other workspaces';

  const team = useSelector(getTeamModeTeam);
  const orgId = useSelector(getOrgIdForTeamMode);

  // all tags in all organizations
  const { data: allTags } = TagAPI.endpoints.getTags.useQuery(undefined);
  const [untagBill] = BillAPI.endpoints.untagBill.useMutation();
  const [tagBill] = BillAPI.endpoints.tagBill.useMutation();

  // Bulk actions
  const [bulkUntagBills] = BillAPI.endpoints.bulkUntagBills.useMutation();
  const [bulkTagBills] = BillAPI.endpoints.bulkTagBills.useMutation();

  // all tags in the workspace
  const tags = useMemo(
    () => allTags?.filter((tag) => tag.teamId === team?.id),
    [team, allTags],
  );

  // all tags in this organization excluding current workspace
  const orgTags = useMemo(
    () =>
      allTags?.filter(
        (tag) => tag.team?.organizationId === orgId && !tags?.includes(tag),
      ),
    [team, allTags, orgId, tags],
  );

  // Filter tags based on search query
  const filteredTags = useMemo(() => {
    if (!searchQuery) return tags;
    return tags?.filter((tag) =>
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [tags, searchQuery]);

  const filteredOrgTags = useMemo(() => {
    if (!searchQuery) return orgTags;
    return orgTags?.filter((tag) =>
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [orgTags, searchQuery]);

  const sortedTags: Tag[] = filteredTags
    ? sortTags(filteredTags)
    : sortTags(tags || []);

  let sortedAndFilteredOrgTags: Tag[] = [];

  if (filteredOrgTags) {
    sortedAndFilteredOrgTags = [...filteredOrgTags].sort((a, b) => {
      const workspaceA = a.team?.name.toLowerCase() || '';
      const workspaceB = b.team?.name.toLowerCase() || '';
      if (workspaceA < workspaceB) return -1;
      if (workspaceA > workspaceB) return 1;

      const tagA = a.name.toLowerCase();
      const tagB = b.name.toLowerCase();
      if (tagA < tagB) return -1;
      if (tagA > tagB) return 1;

      return 0;
    });
  }

  const topOrgTags = useRef<null | HTMLDivElement>(null);

  const tagScroll = (): void => {
    if (topOrgTags && topOrgTags.current && moreTags) {
      topOrgTags.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(tagScroll, [moreTags]);
  const urlCreator = useUrlCreator();

  const openManageTags = (): void => {
    const tagsUrl = urlCreator(`/account/tags`);
    window.open(tagsUrl, '_blank');
  };

  const moreTagsButton = (
    <TextButton
      className="link-style ml-auto"
      label={viewMoreTags}
      onClick={() => {
        setMoreTags(!moreTags);
      }}
      style={{ display: 'block', paddingRight: 0 }}
    />
  );

  const openManageTagsButton = (
    <div className="d-flex flex-row">
      <TextButton
        className="link-style ml-auto"
        label="Manage Tags"
        onClick={() => openManageTags()}
        style={{ paddingRight: '8px' }}
      />
      <div>
        <FontAwesomeIcon
          color="#979797"
          icon={faExternalLinkAlt}
          onClick={() => openManageTags()}
          size="sm"
        />
      </div>
    </div>
  );

  const searchBar = (
    <div className="search-bar">
      <FontAwesomeIcon className="search-icon" icon={faSearch} />
      <input
        className="tag-search-input"
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search tags"
        type="text"
        value={searchQuery}
      />
    </div>
  );

  // Tag/Untag single bill
  if (!Array.isArray(billData)) {
    const bill = { ...billData };
    return (
      <div>
        {searchBar}
        <div className="tag-selector">
          {sortedTags &&
            [...sortedTags]
              .sort((a, b) => {
                const aChecked = !!bill.tags.find((x) => x.id === a.id);
                const bChecked = !!bill.tags.find((x) => x.id === b.id);
                if (bChecked && !aChecked) return 1;
                else if (aChecked && !bChecked) return -1;
                return 0;
              })
              .map((tag) => {
                const checked = !!bill.tags.find((x) => x.id === tag.id);
                return (
                  <div key={tag.id}>
                    <label className="d-flex align-items-center" htmlFor={`${tag.id}`}>
                      <input
                        checked={checked}
                        id={`${tag.id}`}
                        name={`${tag.id}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.checked
                            ? tagBill({ bill, tag })
                            : untagBill({ bill, tag })
                        }
                        type="checkbox"
                      />{' '}
                      <span className="label-text">{tag.name}</span>
                      {tag.isPublic && <ValueBadge size="xs" value="Public" />}
                    </label>
                  </div>
                );
              })}
          <div ref={topOrgTags} />
          {moreTags ? <hr /> : null}
          {moreTags &&
            [...sortedAndFilteredOrgTags]
              .sort((a, b) => {
                const aChecked = !!bill.tags.find((x) => x.id === a.id);
                const bChecked = !!bill.tags.find((x) => x.id === b.id);
                if (bChecked && !aChecked) return 1;
                else if (aChecked && !bChecked) return -1;
                return 0;
              })
              .map((tag) => {
                const checked = !!bill.tags.find((x) => x.id === tag.id);
                return (
                  <div key={tag.id}>
                    <label htmlFor={`${tag.id}`}>
                      <input
                        checked={checked}
                        id={`${tag.id}`}
                        name={`${tag.id}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.checked
                            ? tagBill({ bill, tag })
                            : untagBill({ bill, tag })
                        }
                        type="checkbox"
                      />{' '}
                      <span className="label-text">
                        {tag.name}{' '}
                        <span className="label-workspace">({tag.team?.name})</span>
                      </span>
                    </label>
                  </div>
                );
              })}
        </div>
        {moreTagsButton}
        {openManageTagsButton}
      </div>
    );
  } else {
    // Bulk Tag/Untag
    const bills = [...billData];
    return (
      <div>
        {searchBar}
        <div className="tag-selector">
          {sortedTags &&
            sortedTags.map((tag) => {
              const checked =
                bills?.length > 0 &&
                bills?.every(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              const striked =
                !checked &&
                bills?.length > 0 &&
                bills?.some(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              return (
                <div key={tag.id}>
                  <label htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? bulkTagBills({ bills, tag })
                          : bulkUntagBills({ bills, tag })
                      }
                      ref={(elem) => elem && (elem.indeterminate = striked)}
                      type="checkbox"
                    />
                    <span className="label-text">{tag.name}</span>
                  </label>
                </div>
              );
            })}
          <div ref={topOrgTags} />
          {moreTags ? <hr /> : null}
          {moreTags &&
            filteredOrgTags &&
            filteredOrgTags.map((tag) => {
              const checked =
                bills?.length > 0 &&
                bills?.every(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.every((x) => x.id === tag.id),
                );
              const striked =
                !checked &&
                bills?.length > 0 &&
                bills?.some(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              return (
                <div key={tag.id}>
                  <label htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? bulkTagBills({ bills, tag })
                          : bulkUntagBills({ bills, tag })
                      }
                      ref={(input) => {
                        if (input && striked) {
                          input.indeterminate = true;
                        }
                      }}
                      type="checkbox"
                    />{' '}
                    <span className="label-text">
                      {tag.name}{' '}
                      <span className="label-workspace">({tag.team?.name})</span>
                    </span>
                  </label>
                </div>
              );
            })}
        </div>
        {moreTagsButton}
        {openManageTagsButton}
      </div>
    );
  }
};

export default BillTagContent;
