export const SET_TEAM_ID = 'teamMode/setTeamId';
export const SET_ORG_ID = 'teamMode/setOrgId';

export type TeamModeState = {
  teamId: number;
  orgId?: number;
  userInitiatedLogOut?: boolean;
};

type SetTeamIdAction = {
  type: typeof SET_TEAM_ID;
  teamId: number;
};

type SetOrgIdAction = {
  type: typeof SET_ORG_ID;
  orgId?: number;
};

export type Action = SetTeamIdAction | SetOrgIdAction;
