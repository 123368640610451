import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import MagnifyingGlass from '../../../../components/svg/MagnifyingGlass';
import { State } from '../../../../dux/@types';
import featureFlags, { FrontendFeature } from '../../../../featureFlags';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';

const SearchSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;

  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );

  const canViewRegulations = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.RegulationsSearch,
    organizationUser,
  );

  const getCardBody = (): ReactElement => {
    return (
      <div>
        <NavLink
          activeClassName="active-nav-link"
          onClick={onNavClick}
          to="/legislative-tracking"
        >
          <h4 className="sub-item">Bills</h4>
        </NavLink>
        {canViewRegulations && (
          <NavLink
            activeClassName="active-nav-link"
            onClick={onNavClick}
            to="/regulations"
          >
            <h4 className="sub-item">Regulations</h4>
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      eventKey="SEARCH"
      header="Search"
      icon={<MagnifyingGlass height="18" width="18" />}
    />
  );
};

export default SearchSidebarSection;
