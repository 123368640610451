import React from 'react';

export default (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7114 18.3148L17.0014 14.6348C18.4415 12.8392 19.1389 10.5601 18.9502 8.26611C18.7615 5.97211 17.701 3.83759 15.9869 2.30145C14.2727 0.765313 12.0352 -0.0556832 9.73429 0.00727721C7.43341 0.0702376 5.24409 1.01237 3.61651 2.63995C1.98893 4.26753 1.0468 6.45685 0.98384 8.75773C0.920879 11.0586 1.74188 13.2962 3.27801 15.0103C4.81415 16.7245 6.94867 17.7849 9.24267 17.9736C11.5367 18.1623 13.8158 17.4649 15.6114 16.0248L19.2914 19.7048C19.3843 19.7985 19.4949 19.8729 19.6168 19.9237C19.7387 19.9745 19.8694 20.0006 20.0014 20.0006C20.1334 20.0006 20.2641 19.9745 20.3859 19.9237C20.5078 19.8729 20.6184 19.7985 20.7114 19.7048C20.8916 19.5183 20.9924 19.2691 20.9924 19.0098C20.9924 18.7505 20.8916 18.5013 20.7114 18.3148ZM10.0014 16.0248C8.6169 16.0248 7.26352 15.6143 6.11238 14.8451C4.96123 14.0759 4.06403 12.9827 3.53421 11.7036C3.0044 10.4245 2.86578 9.01704 3.13587 7.65917C3.40597 6.30131 4.07265 5.05403 5.05162 4.07506C6.03059 3.09609 7.27787 2.42941 8.63574 2.15931C9.9936 1.88921 11.4011 2.02784 12.6802 2.55765C13.9592 3.08746 15.0525 3.98467 15.8217 5.13582C16.5908 6.28696 17.0014 7.64034 17.0014 9.02481C17.0014 10.8813 16.2639 12.6618 14.9511 13.9746C13.6384 15.2873 11.8579 16.0248 10.0014 16.0248Z"
      fill="#27A4CC"
    />
  </svg>
);
