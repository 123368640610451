import { SearchResult } from '@enview/interface/types/BillSearch';
import { IPAuthorization, Organization } from '@enview/interface/types/Organization';
import OrganizationSearchCriteria from '@enview/interface/types/OrganizationSearchCriteria';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import base, { ApiTag } from './base';

const organizationAPI = base.injectEndpoints({
  endpoints: (build) => ({
    updateOrganizationName: build.mutation<
      OrganizationUser[],
      { orgId: number; name: string }
    >({
      query: ({ orgId, name }) => ({
        url: `/organization/${orgId}`,
        method: 'PUT',
        data: { name },
      }),
      invalidatesTags: (result, error, { orgId }) => [
        { type: ApiTag.ORGANIZATION_TAG, id: orgId },
      ],
    }),
    getOrganizationMembers: build.query<OrganizationUser[], number>({
      query: (orgId) => ({ url: `/organization/get-users/${orgId}`, method: 'GET' }),
      providesTags: (result, error, orgId) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: ApiTag.ORGANIZATION_USER_TAG, id }) as const,
              ),
              { type: ApiTag.ORGANIZATION_TAG, id: orgId },
            ]
          : [{ type: ApiTag.ORGANIZATION_TAG, id: orgId }],
    }),
    getOrganizationActiveUsers: build.query<
      { orgId: number; activeUserCount: number; seats: number },
      number
    >({
      query: (orgId) => ({ url: `organization/${orgId}/active-users`, method: 'GET' }),
    }),
    revokeMFA: build.mutation<void, { orgId: number; orgUserId: number }>({
      query: ({ orgId, orgUserId }) => ({
        url: `/organization/delete-mfa-for-user/${orgId}/${orgUserId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { orgId, orgUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
      ],
    }),
    getIPAuthorization: build.query<IPAuthorization, void>({
      query: () => ({
        url: `/ip-authorization/check`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: ApiTag.IP_AUTHORIZATION_TAG }] : []),
    }),
    getOrganizations: build.query<
      SearchResult<Organization>,
      OrganizationSearchCriteria
    >({
      query: (criteria) => ({
        url: '/v2/organizations',
        method: 'POST',
        data: criteria,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: ApiTag.ORGANIZATION_TAG, id }) as const,
              ),
              { type: ApiTag.ORGANIZATION_TAG },
            ]
          : [{ type: ApiTag.ORGANIZATION_TAG }],
    }),
  }),
  overrideExisting: false,
});

export default organizationAPI;
