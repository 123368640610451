import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { SignupType } from '../dux/RegistrationDux';
import { track } from './SegmentAnalytics';

enum AnalyticsEvent {
  USER_SIGNED_IN = 'Signed In',
  USER_SIGNED_UP = 'Signed Up',
  USER_SIGN_IN_FAILED = 'Sign-in failed',
  USER_SIGNED_OUT = 'Signed Out',
}

export const trackSignIn = (orgUser: OrganizationUser): void => {
  track(AnalyticsEvent.USER_SIGNED_IN, {
    username: orgUser.email,
  });
};

export const trackSignUp = (
  orgUser: OrganizationUser,
  accountType: SignupType,
): void => {
  track(AnalyticsEvent.USER_SIGNED_UP, {
    type: 'organic',
    email: orgUser.email,
    username: orgUser.email,
    account_type: accountType,
    ...(orgUser.firstName ? { first_name: orgUser.firstName } : {}),
    ...(orgUser.lastName ? { last_name: orgUser.lastName } : {}),
  });
};

export const trackSignInFailed = (): void => {
  track(AnalyticsEvent.USER_SIGN_IN_FAILED, {});
};

export const trackSignOut = (orgUser?: OrganizationUser): void => {
  track(AnalyticsEvent.USER_SIGNED_OUT, {
    username: orgUser?.email,
  });
};
