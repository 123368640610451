import { ReactElement } from 'react';

type NoBillResultsProps = {
  noBillsMessage?: string;
};

const NoBillResults = (props: NoBillResultsProps): ReactElement => {
  const { noBillsMessage } = props;
  return (
    <div className="card no-bills-found">
      <div className="card-body">
        <div className="alert alert-secondary" role="alert">
          {noBillsMessage || 'No eligible bills found'}
        </div>
      </div>
    </div>
  );
};

export default NoBillResults;
