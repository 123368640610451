import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { checkPermission } from '../../dux';
import { State, Thunk } from '../../dux/@types';
import { g as abilityGlossary } from '../../config/ability';
import featureFlags, { FrontendFeature } from '../../featureFlags';
import { desktopMediaQuery } from '../../helpers/mediaQueries';
import NavigationTab, { TabComponent } from '../Shared/NavigationTabs/NavigationTab';
import { Committee } from '@enview/interface/types/Committee';
import CommitteeMemberDirectory from './CommitteeMemberDirectory';
import CommitteeStaffDirectory from './CommitteeStaffDirectory';
import { trackViewedCommitteeStaffDirectory } from '../../analytics/CommitteeAnalytics';

enum Paths {
  MEMBERS = 'members',
  STAFF_DIRECTORY = 'staff-directory',
}

enum Tabs {
  MEMBERS = 'Members',
  STAFF_DIRECTORY = 'Staff Directory',
}

const DESKTOP_TABS = [Tabs.MEMBERS, Tabs.STAFF_DIRECTORY];

type CommitteeViewNavProps = {
  committee: Committee;
};

const CommitteeViewNav = (props: CommitteeViewNavProps): ReactElement => {
  const { committee } = props;
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const hasStaffDirectoryTabEnabled = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.CommitteeStaffDirectoryTab,
    orgUser,
  );
  const basePath = `/committee/${committee.id}/`;
  const activeTabPath = window.location.pathname.split('/').pop() || '';
  const [isDesktopScreen, setIsDesktopScreen] = useState(desktopMediaQuery.matches);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canViewStaffDirectoryTab = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(
        abilityGlossary.VIEW,
        abilityGlossary.COMMITTEE_STAFF_DIRECTORY,
        true,
      ),
    );
  };

  const handleResize = (): void => {
    setIsDesktopScreen(desktopMediaQuery.matches);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getTabPath = (tab: string): string => {
    switch (tab) {
      case Tabs.MEMBERS:
        return `${basePath}${Paths.MEMBERS}`;
      case Tabs.STAFF_DIRECTORY:
        return `${basePath}${Paths.STAFF_DIRECTORY}`;
      default:
        return `${basePath}`;
    }
  };

  const getActiveTab = (path: string): string => {
    switch (path) {
      case Paths.MEMBERS:
        return Tabs.MEMBERS;
      case Paths.STAFF_DIRECTORY:
        return Tabs.STAFF_DIRECTORY;
      default:
        return isDesktopScreen ? '' : Tabs.MEMBERS;
    }
  };

  const renderStaffDirectory = (): ReactElement => {
    return hasStaffDirectoryTabEnabled && canViewStaffDirectoryTab() ? (
      <CommitteeStaffDirectory committeeId={committee.id} />
    ) : (
      <Redirect to={`${basePath}${Paths.MEMBERS}`} />
    );
  };

  const renderMembers = (): ReactElement => (
    <CommitteeMemberDirectory committee={committee} />
  );

  const history = useHistory();

  const getTabs = (): (Tabs | TabComponent)[] => {
    // Hide new Staff Directory for all non-Plural users until the feature flag is flipped
    const tabs = hasStaffDirectoryTabEnabled ? DESKTOP_TABS : [Tabs.MEMBERS];

    const customContent: ReactElement = (
      <Trans
        components={{
          committeeStaffLink: <a href={t('urls.legislativeStaff')} target="_blank" />,
          gate: <p className="tooltip-header" />,
        }}
        i18nKey="featureGating.tooltipText.committeeStaffDirectory"
        key="featureGating.tooltipText.committeeStaffDirectory"
      />
    );
    return tabs.map((tab) =>
      tab == Tabs.STAFF_DIRECTORY
        ? {
            name: tab,
            component: <>{tab}</>,
            disabled: !hasStaffDirectoryTabEnabled || !canViewStaffDirectoryTab(),
            toolTipMessage: customContent,
          }
        : tab,
    );
  };

  const setCurrentTab = (tab: string): void => {
    if (tab === Tabs.STAFF_DIRECTORY)
      trackViewedCommitteeStaffDirectory(committee, orgUser);
    history.push(getTabPath(tab));
  };

  return (
    <>
      <NavigationTab
        currentTab={getActiveTab(activeTabPath)}
        setCurrentTab={setCurrentTab}
        tabs={getTabs()}
      >
        <Switch>
          <Route path={`${basePath}${Paths.MEMBERS}`} render={renderMembers} />
          <Route
            path={`${basePath}${Paths.STAFF_DIRECTORY}`}
            render={renderStaffDirectory}
          />
          {isDesktopScreen && (
            <Redirect from={basePath} to={`${basePath}${Paths.MEMBERS}`} />
          )}
        </Switch>
      </NavigationTab>
    </>
  );
};

export default CommitteeViewNav;
