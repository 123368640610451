import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import CommitteeView from '../scenes/Committee/CommitteeView';
import { resetLoginRequest } from '../dux';
import '../scenes/Shared/Search/Search.scss';
import featureFlags, { FrontendFeature } from '../featureFlags';
import JurisdictionPage from '../scenes/Jurisdictions/NewExplorer/JurisdictionPage';

const LegislativeTracking = lazy(() => import('../containers/LegislativeTracking'));
const AccountNav = lazy(() => import('../scenes/AccountView/AccountNav'));
const Teams = lazy(() => import('../scenes/TeamView/Teams.tsx'));
const Jurisdictions = lazy(() => import('../scenes/Jurisdictions/Jurisdictions.tsx'));
const JurisdictionDetails = lazy(() =>
  import('../scenes/Jurisdictions/JurisdictionDetails.tsx'),
);
const TaggedBills = lazy(() => import('../scenes/BillTags/TaggedBills'));
const AdminNav = lazy(() => import('../scenes/Admin/AdminNav'));
const PersonView = lazy(() => import('../scenes/Person/PersonView'));
const Regulation = lazy(() => import('../containers/RegulationTracking'));
const Notifications = lazy(() => import('../scenes/Notifications/Notifications'));
const MfaRegistration = lazy(() => import('../containers/MfaRegistration'));
const OrganizationSettings = lazy(() =>
  import('../scenes/OrganizationView/OrganizationSettings'),
);

const SentryRoute = Sentry.withSentryRouting(Route);

const LoggedInRouter = ({ children, loginRedirectUrl, activePath, orgUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginRedirectUrl) {
      history.push(loginRedirectUrl);
    }
    dispatch(resetLoginRequest());
  }, [dispatch, loginRedirectUrl]);

  return (
    <div className="flex-grow-1">
      <Switch>
        {/* Hiding /notifications until we make updates to it */}
        {/* <SentryRoute path="/notifications" component={Notifications} /> */}
        <Redirect from="/create-account/:token" to="/legislative-tracking" />
        <SentryRoute path="/legislative-tracking" component={LegislativeTracking} />
        <SentryRoute
          path="/teams/:teamId"
          render={(props) => {
            return <Teams urlTeamId={props.match.params.teamId} />;
          }}
        />
        <SentryRoute
          path="/teams"
          render={(props) => {
            return <Teams {...props} />;
          }}
        />
        <SentryRoute
          path="/jurisdictions/:jurisAbbr"
          render={(props) => {
            if (
              featureFlags.isFeatureEnabledForUser(
                FrontendFeature.JurisdictionOverviewRedesign,
                orgUser,
              )
            ) {
              return <JurisdictionPage jurisAbbr={props.match.params.jurisAbbr} />;
            }
            return <JurisdictionDetails jurisAbbr={props.match.params.jurisAbbr} />;
          }}
        />
        <SentryRoute
          path="/jurisdictions"
          render={(props) => {
            if (
              featureFlags.isFeatureEnabledForUser(
                FrontendFeature.JurisdictionOverviewRedesign,
                orgUser,
              )
            ) {
              return <JurisdictionPage />;
            }
            return <Jurisdictions {...props} />;
          }}
        />
        <SentryRoute
          path="/account"
          render={() => {
            return <AccountNav activePath={activePath} />;
          }}
        />
        <SentryRoute
          path="/admin"
          render={() => {
            return (
              <div className="page-wrapper">
                <AdminNav activePath={activePath} />
              </div>
            );
          }}
        />
        <SentryRoute
          path="/enable-mfa"
          render={(props) => {
            return (
              <div className="page-wrapper">
                <MfaRegistration {...props} />
              </div>
            );
          }}
        />
        <SentryRoute
          path="/tagged-bills/:tagId"
          render={(props) => <TaggedBills tagId={Number(props.match.params.tagId)} />}
        />
        <SentryRoute
          path="/person/:electedOfficialId"
          render={(props) => {
            return (
              <PersonView
                electedPositionId={props.match.params.electedOfficialId}
                key={props.match.params.electedOfficialId}
              />
            );
          }}
        />
        <SentryRoute
          path="/committee/:committeeId"
          render={(props) => {
            return <CommitteeView committeeId={props.match.params.committeeId} />;
          }}
        />
        {featureFlags.isFeatureEnabledForUser(
          FrontendFeature.RegulationsSearch,
          orgUser,
        ) && <SentryRoute path="/regulations" component={Regulation} />}
        {
          <SentryRoute
            path="/organization"
            render={() => {
              return <OrganizationSettings activePath={activePath} />;
            }}
          />
        }
        <Redirect from="/" to="/legislative-tracking" />
      </Switch>
      {children}
    </div>
  );
};

LoggedInRouter.propTypes = {
  children: PropTypes.node,
  loginRedirectUrl: PropTypes.string,
  orgUser: PropTypes.object,
};

export default LoggedInRouter;
