import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useCurrentUrl }from '../../helpers/UrlHelper';

type SEOHeadProps = {
  title: string;
  description: string;
  custom?: Record<string, string>;
};

export const SEOHead = (props: SEOHeadProps) => {
  const history = useHistory();

  const customKeys = props.custom ? props.custom : {};

  const fullTitle = props.title.length > 0 ? `${props.title} - Plural` : 'Plural';
  const imageUrl = `${window.location.origin}/favicon.ico`;
  // We might want to override this
  const pageUrl = useCurrentUrl();

    return (
        <Helmet>
        <title>{fullTitle}</title>
        { /* Standard metadata tags */ }
        <meta name='description' content={props.description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content="website" />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={props.description} />
        <meta property='og:image' content={imageUrl} />
        <meta property='og:url' content={pageUrl} />
        <meta property='og:site_name' content='Plural Policy' />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={props.description} />
        <meta name='twitter:image' content={imageUrl} />
        <meta name="twitter:creator" content="@pluralpolicy" />
        <meta name='twitter:site' content='@pluralpolicy' />
        { /* End Twitter tags */ }
        { /* Custom metadata tags */ }
        {
            Object.keys(customKeys).map((key) => {
                return <meta key={key} property={key} content={customKeys[key]} />;
            } )
        }
        { /* End custom metadata tags */ }
        {/* Structured data */}
        <script type='application/ld+json'>
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${fullTitle}",
              "description": "${props.description}",
              "url": "${pageUrl}",
              "image": "${imageUrl}"
              }
          `}
        </script>
        {/* End structured data */}
        </Helmet>
        )
};
