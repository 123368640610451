import { BillViewState } from './types';
import { mobileMediaQuery } from '../../helpers/mediaQueries';
import { BillSidebarTabs } from '../../helpers/BillHelper';

const initialBillViewState: BillViewState = {
  billSidebarCurrentTab: mobileMediaQuery.matches
    ? BillSidebarTabs.BILL_TEXT
    : BillSidebarTabs.DETAILS,
  billListCacheKeys: [],
};

export default initialBillViewState;
