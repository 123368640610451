import React, { FunctionComponent } from 'react';
import GateTooltip from '../Tooltips/TooltipGate';
import './NavigationTab.scss';

export interface TabComponent {
  name: string;
  component?: React.ReactElement;
  disabled?: boolean;
  toolTipMessage?: React.ReactElement | string;
}

type TabNavigationProps = {
  tabs: (string | TabComponent)[];
  currentTab: string;
  setCurrentTab: (tab: string) => void;
};

const TabNavigation: FunctionComponent<TabNavigationProps> = (props) => {
  const { children, currentTab, setCurrentTab, tabs } = props;
  const tabChangeHandler =
    (tab: string | TabComponent) => (e: React.SyntheticEvent) => {
      e.preventDefault();
      const isDisabledTab = typeof tab !== 'string' && tab?.disabled;
      if (tab && !isDisabledTab) {
        setCurrentTab(typeof tab === 'string' ? tab : tab.name);
      }
    };

  const getTabId = (tab: string | TabComponent): string => {
    let tabName;
    if (typeof tab === 'string') tabName = tab;
    else tabName = tab.name || '';

    return `nav-${tabName.toLowerCase().replace(/[^a-zA-Z]/g, '-')}`;
  };

  const getClassName = (tab: string | TabComponent, activeClass: string): string => {
    // Replace non-alphabet characters with dashes to match class names
    return `nav-item nav-link-flag ${activeClass} ${getTabId(tab)}`;
  };

  const renderTab: (tab: string | TabComponent) => React.ReactElement = (tab) => {
    if (typeof tab === 'string') {
      return <span>{tab}</span>;
    }
    return tab.component || <span>{tab.name}</span>;
  };

  const renderGateTooltip: (tab: string | TabComponent) => React.ReactElement | null = (
    tab,
  ) => {
    if (typeof tab === 'string') return <></>;
    return tab.disabled ? (
      <GateTooltip
        accountType="pro"
        anchorId={getTabId(tab)}
        customContent={tab.toolTipMessage}
        place="bottom"
      />
    ) : null;
  };

  return (
    <div className="nav-container">
      <nav className="nav nav-pills nav-fill">
        {tabs.map((tab) => {
          const isActiveTab =
            typeof tab === 'string' ? tab === currentTab : tab.name === currentTab;
          const activeClass = isActiveTab ? 'active' : '';
          return (
            <React.Fragment key={getTabId(tab)}>
              <a
                className={getClassName(tab, activeClass)}
                href="#"
                id={getTabId(tab)}
                key={getTabId(tab)}
                onClick={tabChangeHandler(tab)}
              >
                {renderTab(tab)}
              </a>
              {renderGateTooltip(tab)}
            </React.Fragment>
          );
        })}
      </nav>
      <div className="col-12 p-0">{children}</div>
    </div>
  );
};

export default TabNavigation;
