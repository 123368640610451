/* eslint-disable react/require-default-props */
import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';
import BillBulkActionsDropdown from '../DropDowns/BillBulkActionsDropdown';
import BillViewDropdown from '../DropDowns/BillViewDropdown';
import MultipleSelectDropDown, {
  OptionType,
} from '../DropDowns/MultipleSelectDropDown';
import BillActionFilterDropdown from '../../LegislativeTracking/SearchFilters/BillActionFilterDropdown';
import { SearchActionFilter } from '@enview/interface/types/Bill';
import BillStatusFilterDropdown from '../../LegislativeTracking/SearchFilters/BillStatusFilterDropdown';
import PartySponsorFilterDropdown from '../../LegislativeTracking/SearchFilters/PartySponsorFilterDropdown';
import { getStatusOptionsForJurisdictions } from '../../../helpers/BillSearchHelper';

export type BillTableFilterProperties = {
  filterLabel?: string;
  selectedFilters?: string[];
  filterOptions?: OptionType<string>[];
  setSelectedFilters?: (selection: string[]) => void;
  tagFilterOptions?: OptionType<string>[];
  selectedTags?: string[];
  setSelectedTags?: (tags: string[]) => void;
  actionFilter?: SearchActionFilter | undefined;
  setActionFilter?: (arg: SearchActionFilter | undefined) => void;
  statusFilters?: string[];
  setStatusFilters?: (filters: string[]) => void;
  partySponsorFilters?: string[];
  setPartySponsorFilters?: (filters: string[]) => void;
};

type BillTableHeaderProps = {
  results: { data: Bill[]; count: number };
  selectedRows: Bill[];
  showFilterProperties: boolean;
  filterProperties?: BillTableFilterProperties;
};
const BillTableHeader = (props: BillTableHeaderProps): ReactElement => {
  const { showFilterProperties, filterProperties, results, selectedRows } = props;
  const {
    filterLabel = 'Jurisdiction',
    selectedFilters = [],
    filterOptions = [],
    setSelectedFilters = () => {},
    tagFilterOptions,
    setSelectedTags,
    selectedTags,
    actionFilter,
    setActionFilter,
    statusFilters,
    setStatusFilters,
    partySponsorFilters,
    setPartySponsorFilters,
  } = filterProperties || {};

  const statusOptions = getStatusOptionsForJurisdictions(selectedFilters);

  return (
    <div className="row bill-table-header">
      {showFilterProperties && (
        <div className="jurisdiction-filter result-total">
          <div className="filter-group">
            <div className="total-count">
              <span>
                {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
              </span>
            </div>
            <div className="filter jurisdictions">
              <MultipleSelectDropDown
                currentSelectedValues={selectedFilters}
                onChange={(selectedFilterValues) => {
                  setSelectedFilters(selectedFilterValues);
                }}
                options={filterOptions}
                title={`Filter by ${filterLabel}`}
              />
            </div>
            {selectedTags && tagFilterOptions && setSelectedTags && (
              <div className="filter tagnames">
                <MultipleSelectDropDown
                  currentSelectedValues={selectedTags}
                  onChange={(selectedTagValues) => {
                    setSelectedTags(selectedTagValues);
                  }}
                  options={tagFilterOptions}
                  title={`Tag Names`}
                />
              </div>
            )}
            <BillActionFilterDropdown
              billAction={actionFilter}
              setBillAction={setActionFilter}
              setSearchChanged={(_b) => {}}
            />
            <BillStatusFilterDropdown
              handleStatusFiltersChange={
                setStatusFilters ? setStatusFilters : (_b) => {}
              }
              status={statusFilters ? statusFilters : []}
              statusOptions={statusOptions}
            />
            <PartySponsorFilterDropdown
              handleSponsorFiltersChange={setPartySponsorFilters}
              sponsorFilters={partySponsorFilters ? partySponsorFilters : []}
            />
          </div>
          <div className="actions-view-group">
            <BillBulkActionsDropdown bills={selectedRows} />
            <div className="view-dropdown dropdown-container">
              <BillViewDropdown />
            </div>
          </div>
        </div>
      )}

      {!showFilterProperties && (
        <>
          <div className="filtered-group col-lg-10 col-xs-12 result-total">
            <div className="result-dropdown-container">
              <div className="total-count">
                <span>
                  {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
                </span>
              </div>
              <BillBulkActionsDropdown bills={selectedRows} />
            </div>
          </div>
          <div
            className="view-dropdown col-lg-1 col-xs-12 dropdown-container"
            style={{ paddingLeft: '74px' }}
          >
            <BillViewDropdown />
          </div>
        </>
      )}
    </div>
  );
};

export default BillTableHeader;
