import { Organization } from './Organization';
import { OrganizationUser } from './OrganizationUser';

export enum TeamRole {
  MEMBER = 'member',
  ADMIN = 'admin',
  CREATOR = 'creator',
}

export interface Team {
  id: number;
  name: string;
  organizationId?: number;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  organization?: Organization;
  isExternal: boolean;
  totalBills?: number;
  members?: { role: TeamRole; organizationUser: OrganizationUser }[];
}

export interface TeamMembership {
  teamId: number;
  organizationUserId: number;
  createdAt: Date;
  updatedAt: Date;
  team: Team;
  role: TeamRole;
  id?: number;
  name: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
}
