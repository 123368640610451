import { InitializeFileUploadSchema, TeamFile } from '@enview/interface/interface';
import FileResult from '@enview/interface/types/FileResult';
import axios from 'axios';
import instance from '../config/axiosConfig';

export const deleteFile = async (fileId: string): Promise<any> => {
  return instance
    .delete(`/files/${fileId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error deleting user files');
      return error;
    });
};

export const uploadFile = async (
  file: any,
  sourceFeature: string,
  teams: number[],
): Promise<TeamFile> => {
  const contentType = file.mediaType || file.type || 'text/plain';
  const data = {
    contentType,
    fileName: file.name,
    fileSizeBytes: file.size,
    teamIds: teams,
    sourceFeature,
  };

  try {
    const initializeUploadResponse = await instance.post<InitializeFileUploadSchema>(
      '/files',
      data,
    );
    const { userFile, signedUrl } = initializeUploadResponse.data;
    await axios.put(signedUrl, file, {
      headers: { 'Content-Type': contentType },
    });
    return userFile;
  } catch (error) {
    console.log('Error saving user files', error);
    throw error;
  }
};

export const downloadFile = async (fileId: string): Promise<any> => {
  return instance
    .get(`/files/${fileId}/download`)
    .then((response) => {
      window.open(response.data.fileUrl);
    })
    .catch((error) => {
      console.error('Error with file download', error);
    });
};

export const downloadExportFile = async (
  teamIds: number[],
  properties: string[],
  tagIds?: number[],
): Promise<void> => {
  const data = { teamIds, tagIds, properties, exportFormat: 'csv' };
  return instance
    .post('/tracked-bills/export', data, {
      headers: {
        'x-utc-offset': new Date().getTimezoneOffset(),
      },
      responseType: 'blob',
    })
    .then((response) => {
      const fileName = FileResult.getFileName(response.headers['content-disposition']);
      const blob = new Blob([response.data]);
      downloadFileBlob(blob, fileName);
    });
};

export const downloadFileBlob = (blob: Blob, fileName: string): void => {
  // Create an anchor element and dispatch a click event to download file
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
