import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { checkPermission, logout } from '../../../../dux';
import UserIcon from '../../../Shared/Icons/UserIcon';
import { Link } from 'react-router-dom';
import { g as abilityGlossary } from '../../../../config/ability';

type UserIconDropdownProps = {
  organizationUser: OrganizationUser;
  isEnviewAdmin?: boolean;
  isOrgAdmin?: boolean;
};

const UserIconDropdown = (props: UserIconDropdownProps): ReactElement => {
  const { organizationUser, isEnviewAdmin, isOrgAdmin } = props;
  const dispatch = useDispatch();

  const canViewTags = () => {
    return dispatch(checkPermission(abilityGlossary.VIEW, abilityGlossary.TAGS, true));
  };

  const logoutUser = (e: any): void => {
    e.preventDefault();
    dispatch(logout());
  };

  const getIcon = (): ReactElement => {
    return (
      <>
        <span className="desktop-nav-item">
          <UserIcon
            firstName={organizationUser.firstName}
            lastName={organizationUser.lastName}
          />
        </span>
        <FontAwesomeIcon
          className="mobile-menu-icon"
          color="#979797"
          icon={faEllipsisV}
        />
      </>
    );
  };

  const getMenu = (): ReactElement => {
    /* Dropdown.item won't respect the base URL we set for react-router, so
			for in-app URLs we need to use <Link> and include the className 'dropdown-item'.
			Failure to do this will break URLs in prod where the app is served from /app
			*/
    return (
      <Dropdown.Menu
        className="user-icon-dropdown"
        style={{ minWidth: '12rem', right: 0 }}
      >
        <Link className="dropdown-item mobile-only" id="mobile-only-teams" to="/teams">
          Home
        </Link>
        <Link
          className="dropdown-item mobile-only"
          id="mobile-only-search"
          to="/legislative-tracking"
        >
          Search
        </Link>
        <Dropdown.Item
          className="mobile-only"
          href="https://help.pluralpolicy.com/"
          id="mobile-only-help"
          rel="noopener noreferrer"
          target="_blank"
        >
          Help
        </Dropdown.Item>
        {isEnviewAdmin && (
          <Link className="dropdown-item" id="admin-dashboard" to="/admin">
            Admin Dashboard
          </Link>
        )}
        <Link className="dropdown-item" id="account-settings" to="/account/settings">
          Account Settings
        </Link>
        {isOrgAdmin && (
          <Link className="dropdown-item" id="org-settings" to="/organization/members">
            Organization Settings
          </Link>
        )}
        <Link
          className="dropdown-item"
          id="notification-settings"
          to="/account/notifications"
        >
          Notification Settings
        </Link>
        {canViewTags() && (
          <Link className="dropdown-item" id="all-tags" to="/account/tags">
            All Tags
          </Link>
        )}
        <Dropdown.Item
          id="log-out"
          onClick={logoutUser}
          style={{ whiteSpace: 'nowrap' }}
        >
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  };

  return (
    <Dropdown as={NavItem} className="top-nav-item">
      <Dropdown.Toggle as={NavLink}>{getIcon()}</Dropdown.Toggle>
      {getMenu()}
    </Dropdown>
  );
};

export default UserIconDropdown;
