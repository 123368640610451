import React, { PropsWithChildren, ReactElement, useEffect, useRef } from 'react';

type CustomDropdownProps = {
  alignRight?: boolean;
  target: ReactElement;
  showDropdown: boolean;
  setShowDropdown: (arg: boolean) => void;
  width?: number;
};

const CustomDropdown = (
  props: PropsWithChildren<CustomDropdownProps>,
): ReactElement => {
  const {
    children,
    alignRight,
    target: targetButton,
    showDropdown,
    setShowDropdown,
    width,
  } = props;

  const activeClass = showDropdown ? 'show' : '';
  const style = width ? { width } : undefined;

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent): void => {
    if (ref && ref.current && ref.current.contains(e.target as Element)) {
      return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const alignClass = alignRight ? ' dropdown-menu-right' : '';

  return (
    <div className={`dropdown ${activeClass}`} ref={ref}>
      {targetButton}

      <div
        aria-labelledby="dropdownMenuButton"
        className={`dropdown-menu${alignClass} ${activeClass}`}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

CustomDropdown.defaultProps = {
  alignRight: false,
};

export default CustomDropdown;
