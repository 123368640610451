// STATE SLICE
export type NavState = {
  minimizeSidebar?: boolean;
  currentOpenSidebarEventKey?: string;
  previousOpenSidebarEventKey?: string;
};

// ACTIONS
export const SET_MINIMIZE_SIDEBAR = 'nav/setMinimizeSidebar';
export const SET_CURRENT_SIDEBAR_TOGGLE_SECTION = 'nav/setCurrentSidebarToggleSection';
export const SET_PREVIOUS_SIDEBAR_TOGGLE_SECTION =
  'nav/setPreviousSidebarToggleSection';

export type SetMinimizeSidebar = {
  type: typeof SET_MINIMIZE_SIDEBAR;
  minimize?: boolean;
};

export type SetCurrentSidebarToggleSection = {
  type: typeof SET_CURRENT_SIDEBAR_TOGGLE_SECTION;
  eventKey?: string;
};

export type SetPreviousSidebarToggleSection = {
  type: typeof SET_PREVIOUS_SIDEBAR_TOGGLE_SECTION;
  eventKey?: string;
};

export type Action =
  | SetMinimizeSidebar
  | SetCurrentSidebarToggleSection
  | SetPreviousSidebarToggleSection;
