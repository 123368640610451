import { Jurisdiction } from '@enview/interface/types/Jurisdiction';
import { Organization } from '@enview/interface/types/Organization';
import PolicyDataType from '@enview/interface/types/PolicyDataType';
import { Team } from '@enview/interface/types/Team';
import assign from 'lodash-es/assign';
import omit from 'lodash-es/omit';
import union from 'lodash-es/union';
import uniqBy from 'lodash-es/uniqBy';
import { TeamAPI } from '../../api';
import { Action, State, Thunk } from '../@types';
import { LOGOUT } from '../AuthenticationDux/types';
import { SET_ORG_ID, SET_TEAM_ID, TeamModeState } from './types';

// REDUCER
export default function reducer(state: TeamModeState, action: Action): TeamModeState {
  switch (action.type) {
    case SET_TEAM_ID:
      return assign({}, state, {
        teamId: action.teamId,
        userInitiatedLogOut: false,
      });
    case SET_ORG_ID:
      return assign({}, state, {
        orgId: action.orgId,
        userInitiatedLogOut: false,
      });
    case LOGOUT:
      return assign({}, omit(state, ['teamId', 'team', 'orgId']), {
        teamId: -1,
        userInitiatedLogOut: true,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const setTeamId = (teamId: number): Action => {
  return {
    type: SET_TEAM_ID,
    teamId,
  };
};

export const setOrgId = (orgId?: number): Action => {
  return {
    type: SET_ORG_ID,
    orgId,
  };
};

// SELECTORS
export const getTeamMode = (state: State): number => {
  return state.teamMode.teamId;
};

export const getTeamModeTeam = (state: State): Team | undefined => {
  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.select(undefined)(state);
  return teamMemberships?.find((tm) => tm.teamId === state.teamMode.teamId)?.team;
};

export const getOrgIdForTeamMode = (state: State): number | undefined => {
  return state.teamMode.orgId;
};

export const getOrgForTeamMode = (state: State): Organization | undefined => {
  return state.account.organizationUser?.organizations.find(
    (org) => org.id === state.teamMode.orgId,
  );
};

export const getJurisdictionsForTeamMode = (
  state: State,
  filterByPolicyDataType?: PolicyDataType,
): Jurisdiction[] => {
  const teamModeOrg = getOrgForTeamMode(state);
  if (teamModeOrg) return teamModeOrg.jurisdictions;
  const { organizationUser } = state.account;
  // TODO: Move this to a real list of all-US jurisdictions in the frontend, also figure out why we need to have this in 2 places
  if (!organizationUser || !organizationUser.organizations)
    return state.account.supportedJurisdictions || [];
  const orgJurisdictions = organizationUser.organizations.reduce(
    (jurArray: Jurisdiction[], org) => {
      return union(
        jurArray,
        org.jurisdictions.filter((j) => {
          if (filterByPolicyDataType == PolicyDataType.Regulation)
            return j.hasRegulationAccess;
          else if (filterByPolicyDataType == PolicyDataType.Bill)
            return j.hasBillAccess;
          else return true;
        }),
      );
    },
    [],
  );
  return uniqBy(orgJurisdictions, 'name');
};

// THUNKS
export const setTeamModeData = (): Thunk => {
  return (dispatch, getState) => {
    const { data: memberships } = TeamAPI.endpoints.getUsersTeamMemberships.select(
      undefined,
    )(getState());
    if (memberships) {
      const teamModeId = getState().teamMode.teamId;
      const currentTeam = memberships.find((m) => m.teamId === teamModeId)?.team;
      if (currentTeam) {
        dispatch(setOrgId(currentTeam?.organizationId));
      }
    }
  };
};

export const setTeamMode = (teamId: number): Thunk => {
  return (dispatch) => {
    dispatch(setTeamId(teamId));
    dispatch(setTeamModeData());
  };
};
