import assign from 'lodash-es/assign';
import {
  REQUEST_FAILURE,
  RESET_REQUEST,
  REQUEST_SUCCESS,
  REQUEST_SENT,
  RequestState,
} from './types';
import { Action, State } from '../@types';

// REDUCER
const handleNamedRequestAction = (
  name: string,
  state: RequestState,
  action: Action,
): RequestState => {
  const newPendingState = assign({}, state.pendingState);
  const newSuccessState = assign({}, state.successState);
  const newFailureState = assign({}, state.failureState);

  switch (action.type) {
    case REQUEST_SENT:
      newPendingState[name] = true;
      newSuccessState[name] = false;
      break;

    case REQUEST_SUCCESS:
      newPendingState[name] = false;
      newSuccessState[name] = true;
      break;

    case REQUEST_FAILURE:
      newPendingState[name] = false;
      newSuccessState[name] = false;
      newFailureState[name] = action.error;
      break;

    case RESET_REQUEST:
      newPendingState[name] = false;
      newSuccessState[name] = false;
      newFailureState[name] = undefined;
      break;

    default:
      break;
  }
  return assign({}, state, {
    pendingState: newPendingState,
    successState: newSuccessState,
    failureState: newFailureState,
  });
};

export default function reducer(state: RequestState, action: Action): RequestState {
  switch (action.type) {
    case REQUEST_SENT:
      if (action.name) {
        return handleNamedRequestAction(action.name, state, action);
      }
      return assign({}, state, {
        pending: true,
        success: false,
      });
    case REQUEST_SUCCESS:
      if (action.name) {
        return handleNamedRequestAction(action.name, state, action);
      }
      return assign({}, state, {
        pending: false,
        success: true,
      });
    case REQUEST_FAILURE:
      if (action.name) {
        return handleNamedRequestAction(action.name, state, action);
      }
      return assign({}, state, {
        pending: false,
        success: false,
        failure: action.error,
      });
    case RESET_REQUEST:
      if (action.name) {
        return handleNamedRequestAction(action.name, state, action);
      }
      return assign({}, state, {
        pending: false,
        success: false,
        failure: undefined,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS

export const requestSent = (name?: string): Action => {
  return {
    type: REQUEST_SENT,
    ...(name ? { name } : {}),
  };
};

export const requestSuccess = (name?: string, analytics?: unknown): Action => {
  return {
    type: REQUEST_SUCCESS,
    name,
    ...(analytics ? { meta: { analytics } } : {}),
  };
};

export const requestFailure = (name?: string, error?: Error): Action => {
  return {
    type: REQUEST_FAILURE,
    name,
    error,
  };
};

export const resetRequest = (name?: string): Action => {
  return {
    type: RESET_REQUEST,
    name,
  };
};

// SELECTORS

export const requestPending = (state: State, name?: string): boolean => {
  return name ? state.requests.pendingState[name] : state.requests.pending;
};

export const requestSucceeded = (state: State, name?: string): boolean => {
  return name ? state.requests.successState[name] : state.requests.success;
};

export const requestError = (state: State, name?: string): Error | undefined => {
  return name ? state.requests.failureState[name] : state.requests.failure;
};
