import React, { ReactElement, useEffect, useState } from 'react';
import {
  SearchActionFilter,
  SearchActionFilterType,
} from '@enview/interface/types/Bill';
import { OptionType } from '../../Shared/DropDowns/MultipleSelectDropDown';
import CustomDropdown from '../../Shared/DropDowns/CustomDropdown';
import DropDownButton from '../../Shared/Buttons/DropDownButton';
import SingleSelectForm from '../../Shared/Forms/SingleSelectForm';
import DayPickerInputDropdown from '../../Shared/Selectors/DayPickerInputDropdown';

type BillActionFilterProps = {
  billAction: SearchActionFilter | undefined;
  setBillAction?: (arg: SearchActionFilter | undefined) => void;
  showActionFilter: boolean;
  setShowActionFilter: (bool: boolean) => void;
  setSearchChanged: (bool: boolean) => void;
};

const actionOptions: OptionType<SearchActionFilterType>[] = [
  { label: 'First Action', value: SearchActionFilterType.FIRST_ACTION },
  { label: 'Latest Action', value: SearchActionFilterType.LATEST_ACTION },
  { label: 'Any Action', value: SearchActionFilterType.ANY_ACTION },
  { label: 'Introduction', value: SearchActionFilterType.INTRODUCTION },
  { label: 'Passage', value: SearchActionFilterType.PASSAGE },
  { label: 'Amendment', value: SearchActionFilterType.AMENDMENT },
];

const BillActionFilter = (props: BillActionFilterProps): ReactElement => {
  const {
    billAction,
    setBillAction,
    showActionFilter,
    setShowActionFilter,
    setSearchChanged,
  } = props;

  const [actionType, setActionType] = useState(
    billAction ? billAction.type : undefined,
  );
  const [fromDate, setFromDate] = useState(
    billAction ? billAction.fromDate : undefined,
  );
  const [toDate, setToDate] = useState(billAction ? billAction.toDate : undefined);

  // Dropdown display states
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showFromDatePicker, setShowFromDatePicker] = useState(false);
  const [showToDatePicker, setShowToDatePicker] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (!billAction && (actionType || fromDate || toDate || errorMessage)) {
      setActionType(undefined);
      setFromDate(undefined);
      setToDate(undefined);
      setErrorMessage(undefined);
    }

    // re-set all billAction filters when props change, namely after pressing the browser back button
    if (billAction) {
      if (billAction.type !== actionType) {
        setActionType(billAction.type);
      }
      if (billAction.fromDate !== fromDate) {
        setFromDate(billAction.fromDate);
      }
      if (billAction.toDate !== toDate) {
        setToDate(billAction.toDate);
      }
    }
  }, [billAction]);

  useEffect(() => {
    if (!showActionFilter) setErrorMessage(undefined);
  }, [showActionFilter]);

  const handleSave = (): void => {
    if (!actionType) {
      setErrorMessage('Please select a bill action.');
      return;
    }
    if (!fromDate) {
      setErrorMessage('Please select a valid start date.');
      return;
    }
    if (!toDate) {
      setErrorMessage('Please select a valid end date.');
      return;
    }
    if (fromDate > toDate) {
      // TODO: ask product to confirm error messages
      setErrorMessage('Please select an end date later than the start date.');
      return;
    }
    const newAction = { type: actionType, fromDate, toDate };
    setErrorMessage(undefined);
    if (setBillAction) setBillAction(newAction);
    setShowActionFilter(false);
    setSearchChanged(true);
  };

  const handleClearButton = (): void => {
    if (billAction) {
      setSearchChanged(true);
    }
    if (setBillAction) setBillAction(undefined);
    setActionType(undefined);
    setFromDate(undefined);
    setToDate(undefined);
    setErrorMessage(undefined);
  };

  const dropdownTargetButton = (
    isDisplayed: boolean,
    label: string,
    onClick: () => void,
    icon?: ReactElement,
  ): ReactElement => (
    <DropDownButton
      dropdownDisplayed={isDisplayed}
      icon={icon}
      id={`${label.replace(/\s/g, '-').toLowerCase()}-filter`}
      label={label}
      onClick={onClick}
    />
  );

  const actionTypeLabel =
    actionOptions.find((option) => option.value === actionType)?.label || 'Bill Action';
  const fromDateLabel = fromDate ? fromDate.toLocaleDateString() : 'Start';
  const toDateLabel = toDate ? toDate.toLocaleDateString() : 'End';

  return (
    <>
      <div className="bill-action-filter">
        <h3>BILL ACTION</h3>

        <CustomDropdown
          setShowDropdown={setShowTypeFilter}
          showDropdown={showTypeFilter}
          target={dropdownTargetButton(showTypeFilter, actionTypeLabel, () =>
            setShowTypeFilter(!showTypeFilter),
          )}
        >
          <div className="adv-search-selector">
            <SingleSelectForm
              currentSelection={actionType}
              options={actionOptions}
              singleColumn
              updateSelection={(type) => {
                setActionType(type);
                setShowTypeFilter(false);
              }}
            />
          </div>
        </CustomDropdown>
        <h3 style={{ marginTop: '24px' }}>DATES</h3>
        <div className="row date-pickers">
          <DayPickerInputDropdown
            label={fromDateLabel}
            selectedDate={fromDate}
            setSelectedDate={setFromDate}
            setShowDropdown={setShowFromDatePicker}
            showDropdown={showFromDatePicker}
          />
          <span className="divider" />
          <DayPickerInputDropdown
            label={toDateLabel}
            selectedDate={toDate}
            setSelectedDate={setToDate}
            setShowDropdown={setShowToDatePicker}
            showDropdown={showToDatePicker}
          />
        </div>
        {errorMessage ? <div className="error">{errorMessage}</div> : <></>}
      </div>

      <div className="control-buttons">
        <button className="btn btn-action" onClick={() => handleSave()} type="submit">
          Save
        </button>
        <button
          className="btn btn-text"
          id="select-control-clear-all"
          onClick={handleClearButton}
          type="button"
        >
          <span>Clear All</span>
        </button>
      </div>
    </>
  );
};

export default BillActionFilter;
