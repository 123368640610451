import { g } from '../ability';

// TODO: currently copied format from proUserAbilities.js, so this list must be modified for this tier
// in later tickets!
export default function defineStarterUserAbilities(can) {
  can([g.EDIT], g.BILL_ANNOTATION);
  can([g.VIEW, g.CREATE], g.TEAM);
  can([g.VIEW], g.BILL_ANNOTATION_TOOLTIP);
  can([g.VIEW, g.CREATE], g.BILL_ANNOTATION);
  can([g.VIEW], g.TRACK_BILL_CONFIRM);
  can([g.VIEW], g.BILL_SEARCH);
  can([g.TRACK, g.TAG, g.VIEW], g.BILL);
  can([g.VIEW], g.TAGS);
  can([g.VIEW], g.TABULAR_BILL_LIST);
  can([g.VIEW], g.SIDEBAR_SEARCH);
  can([g.VIEW], g.SIDEBAR_EXPLORE);
  can([g.VIEW], g.SIDEBAR_WORKSPACE);
  can([g.VIEW], g.SIDEBAR_BILLS);
  can([g.VIEW], g.SIDEBAR_TAGS);
  can([g.VIEW], g.SIDEBAR_SEARCHES);
  can([g.VIEW], g.SIDEBAR_SEARCH_NAV_GROUP);
  can([g.VIEW], g.COMMITTEE);
  can([g.VIEW], g.BILL_SEARCH_ACTION_FILTER);
  can([g.VIEW], g.BILL_SEARCH_TRACKED_FILTER);
  can([g.VIEW], g.JURISDICTION_RESOURCES);
  can([g.CREATE], g.BILL_SHORTNAME);
  can([g.VIEW, g.EDIT], g.ADV_NOTIFICATION_SETTINGS);
  can([g.EDIT], g.ORG_SELECTOR);
  can([g.VIEW], g.EXPLORE);
  can([g.CREATE, g.EDIT, g.VIEW], g.SAVED_SEARCHES);
  can([g.SEND], g.BILL);
  can([g.VIEW], g.BILL_SUMMARY);
}
