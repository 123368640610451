import assign from 'lodash-es/assign';
import { Action, State } from '../@types';
import {
  NavState,
  SET_CURRENT_SIDEBAR_TOGGLE_SECTION,
  SET_MINIMIZE_SIDEBAR,
  SET_PREVIOUS_SIDEBAR_TOGGLE_SECTION,
} from './types';

// REDUCER
export default function reducer(state: NavState, action: Action): NavState {
  switch (action.type) {
    case SET_MINIMIZE_SIDEBAR:
      return assign({}, state, {
        minimizeSidebar: action.minimize,
      });
    case SET_CURRENT_SIDEBAR_TOGGLE_SECTION:
      return assign({}, state, {
        currentOpenSidebarEventKey: action.eventKey,
      });
    case SET_PREVIOUS_SIDEBAR_TOGGLE_SECTION:
      return assign({}, state, {
        previousOpenSidebarEventKey: action.eventKey,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
export const setMinimizeSidebar = (minimize?: boolean): Action => {
  return {
    type: SET_MINIMIZE_SIDEBAR,
    minimize,
  };
};

export const setCurrentSidebarToggleSection = (eventKey?: string): Action => {
  return {
    type: SET_CURRENT_SIDEBAR_TOGGLE_SECTION,
    eventKey,
  };
};

export const setPreviousSidebarToggleSection = (eventKey?: string): Action => {
  return {
    type: SET_PREVIOUS_SIDEBAR_TOGGLE_SECTION,
    eventKey,
  };
};

// SELECTORS
export const getCurrentSidebarToggleSection = (state: State): string | undefined => {
  return state.navigation.currentOpenSidebarEventKey;
};

export const getPreviousSidebarToggleSection = (state: State): string | undefined => {
  return state.navigation.previousOpenSidebarEventKey;
};
