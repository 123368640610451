import React, { ReactElement } from 'react';

const CompassIcon = (): ReactElement => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 25 26"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3553 13.8618C13.8281 13.3859 13.8281 12.6141 13.3553 12.1382C12.8831 11.6624 12.1169 11.6624 11.6447 12.1382C11.1719 12.6141 11.1719 13.3859 11.6447 13.8618C12.1169 14.3376 12.8831 14.3376 13.3553 13.8618ZM17.7137 6.34766C17.5998 6.34766 17.4864 6.36645 17.3755 6.40656L9.56804 9.24269C9.19052 9.3798 8.90776 9.66469 8.77167 10.045L5.95665 17.9116C5.67188 18.7073 6.47732 19.6518 7.2873 19.6518C7.40121 19.6518 7.51462 19.633 7.6255 19.5929L15.433 16.7568C15.8105 16.6197 16.0932 16.3348 16.2293 15.9545L19.0444 8.08844C19.3286 7.2927 18.5237 6.34766 17.7137 6.34766ZM14.7576 15.2745L7.69607 17.8395L10.2419 10.7245L17.3039 8.15953L14.7576 15.2745ZM12.5 0.40625C5.59627 0.40625 0 6.04449 0 13C0 19.9555 5.59627 25.5938 12.5 25.5938C19.4037 25.5938 25 19.9555 25 13C25 6.04449 19.4037 0.40625 12.5 0.40625ZM12.5 23.9688C6.49698 23.9688 1.6129 19.048 1.6129 13C1.6129 6.95195 6.49698 2.03125 12.5 2.03125C18.503 2.03125 23.3871 6.95195 23.3871 13C23.3871 19.048 18.503 23.9688 12.5 23.9688Z"
      fill="#27A4CC"
    />
  </svg>
);

export default CompassIcon;
