import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import {
  getAccountSetupData,
  loadAccountSetupDataError,
  loadAccountSetupDataSucceeded,
  loadOrgUserError,
  loadOrgUserSucceeded,
  resetAccountSetupDataUpdate,
  resetOrgUserUpdate,
  updateOrganizationUser,
} from '../../dux';
import { State } from '../../dux/@types';
import STATES from '../../helpers/states';
import Account from '../Shared/Forms/Account/Account';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

export enum ProfileSettingsSection {
  ABOUT_ME = 'About me',
  ABOUT_MY_WORK = 'About my work',
}

const ProfileSettings = () => {
  // Needs upgrade to at least react-hook-form@^7.0.0
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const accountSetupData = useSelector(
    (state: State) => state.account.accountSetupData,
  );
  const prevOrganizationUser = useRef<OrganizationUser>();

  // Hide some settings if the user is only a member of read-only organizations
  const hasWriteAccess =
    organizationUser &&
    organizationUser.organizations.filter((o) => o.hasReadOnlyAccess === false).length >
      0;

  const loadingOrgUserError = useSelector(loadOrgUserError);
  const loadingOrgUserSuccess = useSelector(loadOrgUserSucceeded);
  const loadingAccountSetupDataError = useSelector(loadAccountSetupDataError);
  const loadingAccountSetupDataSuccess = useSelector(loadAccountSetupDataSucceeded);
  const loadingError = loadingOrgUserError || loadingAccountSetupDataError;

  const aboutMeRef = useRef<HTMLDivElement>(null);
  const aboutMyWorkRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const getStates = (): OptionType<string>[] => {
    return Object.keys(STATES).map((key: string) => {
      return {
        label: STATES[key],
        value: key,
      };
    });
  };

  const setValues = useCallback(
    (orgUser: OrganizationUser): void => {
      setValue('firstName', orgUser.firstName);
      setValue('lastName', orgUser.lastName);
      setValue('email', orgUser.email);
      setValue('city', orgUser.city);
      setValue('state', orgUser.state);
    },
    [setValue],
  );

  const scrollToMessage = (ref: React.RefObject<HTMLDivElement>): void => {
    if (ref && ref.current !== null) ref.current.scrollIntoView();
  };

  useEffect(() => {
    if (loadingOrgUserSuccess) setTimeout(() => dispatch(resetOrgUserUpdate()), 5000);
    if (loadingAccountSetupDataSuccess)
      setTimeout(() => dispatch(resetAccountSetupDataUpdate()), 5000);
  }, [dispatch, loadingOrgUserSuccess, loadingAccountSetupDataSuccess]);

  useEffect(() => {
    if (
      organizationUser &&
      (!prevOrganizationUser.current ||
        organizationUser.updatedAt !== prevOrganizationUser.current.updatedAt)
    ) {
      dispatch(getAccountSetupData());
      setValues(organizationUser);
    }
    prevOrganizationUser.current = organizationUser;
  }, [dispatch, organizationUser, setValues]);

  if (!organizationUser) {
    return <div className="loading">Loading...</div>;
  }

  const onSubmit = (data: Partial<OrganizationUser>): void => {
    dispatch(updateOrganizationUser(data, organizationUser));
  };

  const alertClass = loadingError ? 'alert alert-warning' : 'alert alert-success';
  const messageAboutMe = loadingOrgUserError
    ? `${loadingOrgUserError.message}`
    : 'About me updated';
  const messageAboutMyWork = loadingAccountSetupDataError
    ? `${loadingAccountSetupDataError.message}`
    : 'About my work updated';
  const showMessageAboutMe = loadingOrgUserSuccess || loadingOrgUserError;
  const showMessageAboutMyWork =
    loadingAccountSetupDataSuccess || loadingAccountSetupDataError;
  if (showMessageAboutMe) scrollToMessage(aboutMeRef);
  else if (showMessageAboutMyWork) scrollToMessage(aboutMyWorkRef);

  return (
    <div className="registration" style={{ maxWidth: '600px' }}>
      <div className="about-me" ref={aboutMeRef}>
        <div className="form-section-header">{ProfileSettingsSection.ABOUT_ME}</div>

        {showMessageAboutMe ? (
          <div className={alertClass} role="alert">
            {messageAboutMe}
          </div>
        ) : undefined}

        <form className="form registration-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="field-header">First name</div>
          <input
            className="form-control form-field"
            name="firstName"
            placeholder="First name"
            ref={register({ required: true })}
            type="text"
          />
          {errors.firstName && (
            <div className="error alert alert-danger">Please fill out this field.</div>
          )}

          <div className="field-header">Last name</div>
          <input
            className="form-control form-field"
            name="lastName"
            placeholder="Last name"
            ref={register({ required: true })}
            type="text"
          />
          {errors.lastName && (
            <div className="error alert alert-danger">Please fill out this field.</div>
          )}

          <div className="field-header">Work email</div>
          <input
            className="form-control form-field"
            name="email"
            placeholder="Work email"
            ref={register({ required: true })}
            type="text"
          />
          {errors.email && (
            <div className="error alert alert-danger">Please fill out this field.</div>
          )}

          <div className="field-header">Location</div>
          <div className="row">
            <input
              className="form-control form-field"
              name="city"
              placeholder="City"
              ref={register({ required: true })}
              type="text"
            />

            <select
              className="form-control form-field"
              name="state"
              ref={register({ required: true })}
            >
              <option className="inactive" disabled value="">
                State
              </option>
              {getStates()
                .filter((state) => state.label !== 'US Congress')
                .map((state: OptionType<string>) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
            </select>
            {(errors.city && (
              <div className="error alert alert-danger">
                Please fill out this field.
              </div>
            )) ||
              (errors.state && (
                <div className="error alert alert-danger">
                  Please select an item from the list.
                </div>
              ))}

            <div className="field-header">Password</div>
            <input
              className="form-control form-field"
              name="password"
              placeholder="Password (at least 8 characters)"
              ref={register({
                required: 'Please complete this field.',
                minLength: {
                  value: 8,
                  message: 'Password must be a minimum of 8 characters.',
                },
              })}
              type="password"
            />
            {errors.password && (
              <div className="error alert alert-danger">{errors.password.message}</div>
            )}

            <input
              className="form-control form-field"
              name="passwordConfirm"
              placeholder="Confirm password"
              ref={register({
                validate: (value) => value === watch('password'),
              })}
              type="password"
            />

            {errors.passwordConfirm && (
              <div className="error alert alert-danger">Passwords do not match.</div>
            )}
          </div>

          <div className="submit">
            <button className="btn btn-primary w-100" id="account-submit" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
      {hasWriteAccess && (
        <div className="about-my-work" ref={aboutMyWorkRef}>
          <div className="form-section-header">
            {ProfileSettingsSection.ABOUT_MY_WORK}
          </div>

          {showMessageAboutMyWork ? (
            <div className={alertClass} role="alert">
              {messageAboutMyWork}
            </div>
          ) : undefined}

          <Account accountSetupData={accountSetupData} />
        </div>
      )}
    </div>
  );
};

export default ProfileSettings;
