import React from 'react';
import Image from './Image';

const ROOT_URL = process.env.PUBLIC_URL || '';

export default () => (
  <Image
    id="enview-logo"
    className="enview-logo"
    fileName='Plural_Logo_Primary_FullColor.png'
    alt="Logo for Plural"
  />
);
