import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { resetCreateTeam } from '../../../../dux';
import CreateEditTeam from '../../../TeamView/CreateEditTeam';
import ModalCard from '../ModalCard';

type CreateTeamModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

const CreateTeamModal = (props: CreateTeamModalProps): ReactElement => {
  const { show, setShow } = props;
  const dispatch = useDispatch();

  return (
    <ModalCard
      closeModal={() => {
        dispatch(resetCreateTeam());
        setShow(false);
      }}
      content={
        <CreateEditTeam
          closeModal={() => {
            dispatch(resetCreateTeam());
            setShow(false);
          }}
          isTeamCreation
        />
      }
      show={show}
      title="Create Workspace"
    />
  );
};

export default CreateTeamModal;
