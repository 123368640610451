import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { page } from './SegmentAnalytics';
import { track } from './SegmentAnalytics';

const categoryName = (path: string): string => {
  const segments = path.split('/');
  return segments.length > 1 ? segments[1] : 'General';
};

const getJurisdiction = (path: string): string => {
  const segments = path.split('/');
  return segments.length > 2 ? segments[2] : '';
};

enum AnalyticsEvent {
  TOOLTIP_GATE_OPENED = 'Tooltip Gate Opened',
}

const trackPageViewed = (
  activePath: string,
  pathname: string,
  prevPath: string,
): void => {
  const params = {
    jurisdiction: '',
    fromPath: '',
  };
  if (prevPath) {
    params.fromPath = prevPath;
  }
  if (activePath.includes('legislative-tracking/tagged-bills')) {
    page('Bills', 'Bill Tag', params);
  } else if (activePath.indexOf('/jurisdictions/') >= 0) {
    params.jurisdiction = getJurisdiction(pathname);
    page('Jurisdiction', 'Details', params);
  } else if (activePath !== '/') {
    page(categoryName(pathname), '', params);
  }
};

export const trackTooltipGate = (
  tooltipFeatureName: string,
  orgUser?: OrganizationUser,
): void => {
  const params = {
    tooltipFeatureName,
    orgUserEmail: orgUser?.email,
    orgUserId: orgUser?.id,
  };
  track(AnalyticsEvent.TOOLTIP_GATE_OPENED, params);
};

export default trackPageViewed;
