import { useHistory, useLocation } from 'react-router-dom';

/* Re-usable hooks for generating complete application URLs in the frontend.
 Generating accurate URLs is surprisingly tricky, because they have to account for both
 the subdomain (e.g. app-dev.pluralpolicy.com) and a potential root path (pluralpolicy.com/app).
 Most app routing tasks can use the standard react-router components, but in cases where we need to dynamically
 generate a URL to particular content as a string, these hooks help you do it correctly.

 Local testing note: The PUBLIC_URL environment variable can be used to confirm that urls with a root
 subfolder are working, like `PUBLIC_URL=/app yarn run start-local`
*/

// Hook that returns the complete URL for the current page in the app
export const useCurrentUrl = (): string => {
  const history = useHistory();
  const location = useLocation();
  const pageUrl = createApplicationUrl(history, location.pathname);
  return pageUrl;
};

// Hook that returns a function that will generate a URL for the passed-in pathname,
// using the proper subdomain and base URL folder.
export const useUrlCreator = (): ((pathname: string) => string) => {
  const history = useHistory();
  return (pathname: string) => createApplicationUrl(history, pathname);
};

// Utility function for generating complete app URLs from the passed-in path
const createApplicationUrl = (
  history: ReturnType<typeof useHistory>,
  pathname: string,
): string => {
  return `${window.location.origin}${history.createHref({
    pathname: pathname,
  })}`;
};
