import React from 'react';

export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.99602C17.9898 5.57931 14.4087 2.00002 9.992 2.00002C5.57528 2.00002 1.99421 5.57931 1.992 9.99602C1.98979 14.4127 5.56729 17.9956 9.984 18ZM11 16H9V14H11V16ZM11 13H9C8.9684 11.6977 9.64614 10.4808 10.77 9.82202C11.43 9.31602 12 8.88002 12 8.00002C12 6.89545 11.1046 6.00002 10 6.00002C8.89543 6.00002 8 6.89545 8 8.00002H6V7.91002C6.01608 6.48096 6.79333 5.16902 8.03897 4.46842C9.28461 3.76781 10.8094 3.78496 12.039 4.51342C13.2685 5.24187 14.0161 6.57096 14 8.00002C13.9284 9.07904 13.3497 10.0603 12.44 10.645C11.6177 11.1612 11.0847 12.0328 11 13Z"
      fill="#1C1C1C"
    />
  </svg>
);
