import { FeedbackType } from '@enview/interface/types/actions/FeedbackData';
import Staff from '@enview/interface/types/persons/Staff';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'use-query-params';
import { PersonAPI } from '../../api';
import { getTeamMode, openReportProblemModal, requestSucceeded } from '../../dux';
import { State } from '../../dux/@types';
import { buildStaffParamsFromQuery } from '../../helpers/PeopleSearchHelper';
import { getQueryParamConfig } from '../../helpers/RegulationSearchHelper';
import CustomToggleSelectDropDown from '../Shared/DropDowns/CustomToggleSelectDropDown';
import { REQUEST_REPORT_PROBLEM } from '../Shared/Modals/ReportProblemModal';
import { NoResultsPage } from '../Shared/NoResults';
import StaffCards from '../Shared/Staff/StaffCards';
import './../Shared/Staff/Staff.scss';
import './CommitteeView.scss';

type CommitteeStaffDirectoryProps = {
  committeeId: number;
};

const CommitteeStaffDirectory = (props: CommitteeStaffDirectoryProps): ReactElement => {
  const { committeeId } = props;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [query, setQuery] = useQueryParams(getQueryParamConfig([]));

  const params = useMemo(() => {
    return buildStaffParamsFromQuery(query);
  }, [query]);

  const { data: staffData, isError } = PersonAPI.endpoints.getCommitteeStaff.useQuery({
    committeeId,
    params,
  });

  const [searching, setSearching] = useState(true);

  useEffect(() => {
    if (staffData || isError) setSearching(false);
  }, [staffData, isError]);

  const dispatch = useDispatch();

  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const teamModeId = useSelector(getTeamMode);
  const showSuccessMessage = useSelector((state: State) =>
    requestSucceeded(state, REQUEST_REPORT_PROBLEM),
  );

  const overflowMenu = (staffMember: Staff): ReactElement => (
    <CustomToggleSelectDropDown
      alignRight
      options={[
        {
          label: t('modals.reportProblem.header'),
          value: t('modals.reportProblem.header'),
          onSelect: (event: React.SyntheticEvent<Element, Event>) =>
            dispatch(
              openReportProblemModal({
                feedbackType: FeedbackType.ReportProblem,
                elementId: (event.target as HTMLDivElement).id,
                organizationUserId: organizationUser?.id ?? 0,
                username: organizationUser?.email,
                teamId: teamModeId,
                url: window.location.href,
                metadata: {
                  dataType: t('committees.staff.header'),
                  staffName: staffMember?.name,
                  staffId: staffMember?.id,
                  committeeId: committeeId,
                },
              }),
            ),
        },
      ]}
      toggle={<FontAwesomeIcon icon={faEllipsisV} />}
    />
  );

  return (
    <>
      <div className="committee-staff search-container default-search">
        {showSuccessMessage && (
          <div className="alert alert-success" role="alert">
            {t('feedback.submitSuccessMessage')}
          </div>
        )}
        {isError && (
          <div className="alert alert-warning" role="alert">
            {t('error.genericErrorOccurred')}
          </div>
        )}
        <h2>{t('committees.staff.header')}</h2>
        {searching && (
          <div className="table-spinner-overlay">
            <Spinner animation="border" role="status" />
          </div>
        )}
        <div className="staff-grid">
          <StaffCards
            overflowMenu={overflowMenu}
            page={NoResultsPage.Committee}
            searching={searching}
            staffData={staffData}
          />
        </div>
      </div>
    </>
  );
};

export default CommitteeStaffDirectory;
