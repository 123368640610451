import { TeamRole } from '@enview/interface/types/Team';
import { t } from 'i18next';
import { ReactElement, RefObject, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PlacesType } from 'react-tooltip';
import { TeamAPI } from '../../../../api';
import Can from '../../../../components/Can';
import Plus from '../../../../components/svg/Plus';
import { g as abilityGlossary } from '../../../../config/ability';
import { getTeamMode, setTeamMode } from '../../../../dux';
import { State } from '../../../../dux/@types';
import { getOrgIdForTeamMode } from '../../../../dux/TeamModeDux';
import SingleSelectDropDown, {
  OptionType,
} from '../../../Shared/DropDowns/SingleSelectDropDown';
import CreateTeamModal from '../../../Shared/Modals/UserAction/CreateTeamModal';
import GateTooltip, { GateTooltipFeature } from '../../../Shared/Tooltips/TooltipGate';

const NO_ORG_TEXT = 'Personal';

const OrgSidebarSection = (props: {
  orgDropdownPosition: number;
  orgDropdownRef: RefObject<HTMLDivElement>;
  workspaceDropdownRef: RefObject<HTMLDivElement>;
  workspaceDropdownPosition: number;
}): ReactElement => {
  const {
    orgDropdownPosition,
    orgDropdownRef,
    workspaceDropdownPosition,
    workspaceDropdownRef,
  } = props;
  const dispatch = useDispatch();
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const orgId = useSelector(getOrgIdForTeamMode);
  const teamId = useSelector(getTeamMode);

  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [tooltipPlacement, setTooltipPlacement] = useState<PlacesType>('right');

  /* If we log out and back in (without refreshing the browser), our list of teams never gets
  re-fetched, presumably because rtk-query is caching it? Regardless, refetchOnMountOrArgChange
  will cause the /teams endpoint to be called every time this component mounts. This is either
  a genius solution using available tools or a hacky workaround, and I'm not sure which
  */
  const { data: teamMemberships } = TeamAPI.endpoints.getUsersTeamMemberships.useQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );

  const organization =
    organizationUser && orgId
      ? organizationUser.organizations.find((org) => org.id === orgId)
      : undefined;

  const teams = useMemo(
    () => (teamMemberships || []).map((tm) => tm.team),
    [teamMemberships],
  );

  const orgOptions = useMemo(
    () =>
      [
        {
          label: NO_ORG_TEXT,
          value: -1,
        },
      ].concat(
        organizationUser
          ? organizationUser.organizations
              .filter((org) => org.hasReadOnlyAccess === false)
              .sort((org1, org2) => org1.name.localeCompare(org2.name))
              .map((org) => ({
                label: org.name,
                value: org.id,
              }))
          : [],
      ),
    [organizationUser, teams, orgId],
  );

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newPlace = screenWidth <= 768 ? 'top' : 'right';
      setTooltipPlacement(newPlace as PlacesType);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const canCreateTeams = useMemo(() => {
    if (!organizationUser || !organization) return false;
    if (
      organizationUser.roles.find(
        (role) => role.organizationId === organization.id && role.name === 'org-admin',
      )
    )
      return true;
    return !!(teamMemberships || []).find(
      (tm) => tm.role !== TeamRole.MEMBER && tm.team.organizationId === organization.id,
    );
  }, [organization, organizationUser, teamMemberships]);

  const teamsOptions = useMemo(() => {
    const options: OptionType<number>[] = teams
      .filter(
        (team) =>
          (!organization && !team.organizationId) ||
          team.organizationId === organization?.id,
      )
      .sort((team1, team2) => team1.name.localeCompare(team2.name))
      .map((team) => ({
        label: team.name.toUpperCase(),
        value: team.id,
      }));
    if (canCreateTeams) {
      options.push({
        label: 'NEW WORKSPACE',
        value: 0,
        icon: <Plus />,
      });
    }
    return options;
  }, [canCreateTeams, teams, organization, orgId]);

  const isLoggedOut = !organizationUser?.organizations;

  const getSelectedOrgValue = (): number => {
    if (isLoggedOut || !orgId) return -1;
    return orgId;
  };

  // Each org has a Default Team ID which should be picked from the list of options
  // when switching to that org (also on first load)
  const orgDefaultTeamID = teamsOptions?.find(
    (team) => team.value === organization?.defaultTeamId,
  )?.value;

  const getSelectedTeamID = (): number => {
    // If our selected teamID is available in the list of options, use that
    if (teamId && teamsOptions.filter((team) => team.value === teamId).length > 0)
      return teamId;
    // Fall back to the org's default team ID
    else if (orgDefaultTeamID) return orgDefaultTeamID;
    // Fall back to the first option in the list
    else if (teamsOptions && teamsOptions[0]) return teamsOptions[0].value;
    // Fall back to a dummy value
    else return -1;
  };

  const onOrgSelectionChange = (orgOption: OptionType<number>): void => {
    let selectedTeam = null;
    // Personal workspace will have a sentinel value in the org selector
    if (orgOption.value === -1) {
      selectedTeam = teams.find((team) => !team.organizationId);
    } else {
      selectedTeam = teams.find((team) => team.organizationId === orgOption.value);
    }
    if (!selectedTeam) return;
    dispatch(setTeamMode(selectedTeam.id));
  };

  const orgSwitcher = (can: boolean): ReactElement => (
    <div className="org-switcher">
      <h3>Organization</h3>
      <SingleSelectDropDown
        defaultLabel="Current Organization"
        disabled={!can}
        dropdownPosition={orgDropdownPosition}
        id="org-switcher-dropdown"
        onSelect={onOrgSelectionChange}
        options={orgOptions}
        selectedValue={getSelectedOrgValue()}
      />
      {!can && (
        <GateTooltip
          accountType="pro"
          anchorId="org-switcher-dropdown"
          customContent={
            <Trans
              components={{
                orgLink: <a href={t('urls.personalWorkspace')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.orgSwitcher"
              key="featureGating.tooltipText.orgSwitcher"
            />
          }
          featureName={GateTooltipFeature.OrganizationSwitcher}
          place={tooltipPlacement}
        />
      )}
    </div>
  );

  const teamModeSwitcher = (can: boolean): ReactElement => (
    <div className="team-mode-switcher" ref={workspaceDropdownRef}>
      <h3>Workspace</h3>
      <SingleSelectDropDown
        defaultLabel="Current Team"
        disabled={!can}
        dropdownPosition={workspaceDropdownPosition}
        id="team-switcher-dropdown"
        onSelect={(teamOption) => {
          if (teamOption.value === 0) {
            setShowCreateTeamModal(true);
            return;
          }
          dispatch(setTeamMode(teamOption.value));
        }}
        options={teamsOptions}
        selectedValue={getSelectedTeamID()}
      />
      {!can && (
        <GateTooltip
          accountType="pro"
          anchorId="team-switcher-dropdown"
          customContent={
            <Trans
              components={{
                teamLink: <a href={t('urls.teamWorkspaces')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.teamSwitcher"
              key="featureGating.tooltipText.teamSwitcher"
            />
          }
          featureName={GateTooltipFeature.TeamSwitcher}
          place={tooltipPlacement}
        />
      )}
    </div>
  );

  return (
    <div className="org-sidebar-section nav-group" ref={orgDropdownRef}>
      <Can I={abilityGlossary.EDIT} a={abilityGlossary.ORG_SELECTOR} passThrough>
        {(can: boolean) => <>{orgSwitcher(can)}</>}
      </Can>
      <Can I={abilityGlossary.EDIT} a={abilityGlossary.WORKSPACE_SELECTOR} passThrough>
        {(can: boolean) => <>{teamModeSwitcher(can)}</>}
      </Can>

      <CreateTeamModal setShow={setShowCreateTeamModal} show={showCreateTeamModal} />
    </div>
  );
};

export default OrgSidebarSection;
