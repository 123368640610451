import JurisdictionAbbreviationMap from '@enview/interface/types/JurisdictionAbbreviationMap';
import { ReactElement } from 'react';
import MapBox from '../../MapBox/MapBox';
import JurisdictionResourceInfo from './JurisdictionResourceInfo';

type JurisdictionOverviewCardProps = {
  jurisAbbr: keyof typeof JurisdictionAbbreviationMap;
};

const JurisdictionOverviewCard = (
  props: JurisdictionOverviewCardProps,
): ReactElement => {
  const { jurisAbbr } = props;
  const jurisdictionName = JurisdictionAbbreviationMap[jurisAbbr];

  return (
    <div className="jurisdiction-overview-card">
      <h1>{jurisdictionName} Legislature</h1>
      <div className="jurisdiction-map-info">
        <MapBox jurisAbbr={jurisAbbr} />
        <JurisdictionResourceInfo jurisAbbr={jurisAbbr} />
      </div>
    </div>
  );
};

export default JurisdictionOverviewCard;
