import { Bill } from '@enview/interface/types/bills/Bill';
import {
  faChartLine as TrackingIcon,
  faChevronRight as ChevronRightIcon,
  faPencilAlt as PencilIcon,
  faTag as TagIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import NestedDropdown from 'react-multilevel-dropdown';
import ArrowDown from '../../../components/svg/ArrowDown';
import BillTagContent from '../BillTagging/BillTagContent';
import BulkTrackingContent from '../BillTracking/BulkTrackingContent';

type BillBulkActionsDropdownProps = {
  bills: Bill[];
};

const BillBulkActionsDropdown = (props: BillBulkActionsDropdownProps): ReactElement => {
  const { bills } = props;

  return (
    <NestedDropdown
      buttonClassName="dropdown-button dropdown-toggle bulk-dropdown-button"
      isDisabled={!bills || bills.length < 1}
      menuClassName="menu-item"
      position="right"
      title={
        <>
          <FontAwesomeIcon className="dropdown-icon" icon={PencilIcon} />
          <span className="bulk-dropdown-text">Bulk Actions</span>
          <ArrowDown className="dropdown-arrow" />
        </>
      }
      wrapperClassName="dropdown bulk-actions-dropdown"
    >
      <NestedDropdown.Item>
        <FontAwesomeIcon className="dropdown-icon" icon={TrackingIcon} />
        <span className="dropdown-item-text">Tracking</span>
        <FontAwesomeIcon
          className="dropdown-item-icon right-icon"
          icon={ChevronRightIcon}
        />
        <NestedDropdown.Submenu position="right">
          <NestedDropdown.Item className="submenu-item">
            <BulkTrackingContent bills={bills} />
          </NestedDropdown.Item>
        </NestedDropdown.Submenu>
      </NestedDropdown.Item>
      <NestedDropdown.Item>
        <FontAwesomeIcon className="dropdown-item-icon" icon={TagIcon} />
        <span className="dropdown-item-text">Tags</span>
        <FontAwesomeIcon
          className="dropdown-item-icon right-icon"
          icon={ChevronRightIcon}
        />
        <NestedDropdown.Submenu position="right">
          <NestedDropdown.Item className="submenu-item">
            <BillTagContent billData={bills} />
          </NestedDropdown.Item>
        </NestedDropdown.Submenu>
      </NestedDropdown.Item>
    </NestedDropdown>
  );
};

export default BillBulkActionsDropdown;
