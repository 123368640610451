/** A list of statuses that are intended to represent every "state" that a piece
 * of legislation can be in as part of the legislative process */
export enum BillStatus {
  /** Designates that a bill has been introduced into the legislative process.
   * Different jurisdictions have different processes by which bills are filed,
   * pre-filed, introduced, etc */
  INTRODUCED = 'introduced',
  /** Designates that a bill has been voted on and passed by the upper
   * legislative chamber of the given Jurisdiction */
  PASSED_UPPER = 'passed_upper',
  /** Designates that a bill has been voted on and passed by the lower
   * legislative chamber of the given Jurisdiction */
  PASSED_LOWER = 'passed_lower',
  /** Designates that a bill has been passed by all necessary legislative
   * chambers in the given Jurisdiction */
  PASSED = 'passed',
  /** Designates that the bill has been signed by the Governor and will take
   * effect at the date specified in the bill */
  GOVERNOR = 'governor',
  /** Designates that the bill has become law. This generally implies passage by
   * all legislative chambers and being signed by the governor of the
   * Jurisdiction */
  LAW = 'law',

  /* The following are statuses are added b/c they're needed for Nigerian bills */
  FIRST_READING = 'reading-1',
  SECOND_READING = 'reading-2',
  THIRD_READING = 'reading-3',
  REFERRAL_COMMITTEE = 'referral-committee',
  REPORTED_OUT_OF_COMMITTEE = 'reported-out-of-committee',
  CONCURRENCE = 'concurrence',
  WITHDRAWAL = 'withdrawal',
  VETOED = 'executive-veto',
}

/** An enumeration of available types of legislative actions on which we may
 * want to filter during bill search. Used as part of SearchActionFilter
 */
export enum SearchActionFilterType {
  /** Designates that the given SearchActionFilter should apply to the
   * chronologically-first action that we have recorded for a bill */
  FIRST_ACTION = 'first action',
  /** Designates that the given SearchActionFilter should apply to the
   * chronologically-last action that we have recorded for a bill */
  LATEST_ACTION = 'latest action',
  /** Designates that the given SearchActionFilter should apply any legislative
   * action recorded in our database for the given bills */
  ANY_ACTION = 'any action',
  /** Designates that the given SearchActionFilter should apply to legislative
   * actions that we have categorized as a bill introduction */
  INTRODUCTION = 'introduction',
  /** Designates that the given SearchActionFilter should apply to legislative
   * actions that we have categorized as a referral to a committee */
  COMMITTEE_REFERRAL = 'referral-committee',
  /** Designates that the given SearchActionFilter should apply to legislative
   * actions that we have categorized as passage of the bill */
  PASSAGE = 'passage',
  /** This “Amendment” option, similar to “Introduction” or “Passage” targets
   * bills with Action History items indicating an amendment is added to the bill */
  AMENDMENT = 'amendment',
}

// Set of possible Bill Status types display in the Plural
export interface BillStatusType {
  law: string | null;
  passed: string | null;
  governor: string | null;
  introduced: string | null;
  passedLower: string | null;
  passedUpper: string | null;
  firstReading: string | null;
  secondReading: string | null;
  thirdReading: string | null;
  referralCommittee: string | null;
  reportedOutOfCommittee: string | null;
  concurrence: string | null;
  // Withdrawal only used in Nigeria
  withdrawal: string | null;
  vetoed: string | null;
}

/** A set of related criteria that apply as a filter during bill search. If a
 * BillSearchCriteria has a defined SearchActionFilter, the intent of the search
 * is to return only bills for which we have recorded a qualifying legislative
 * action during the date range defined by fromDate and toDate
 */
export interface SearchActionFilter {
  /** The type of action to use when evaluating this action filter during search
   * */
  type: SearchActionFilterType;
  /** Start of the date range in which to look for a qualifying action for bills
   * being searched */
  fromDate: Date;
  /** End of the date range in which to look for a qualifying action for bills
   * being searched */
  toDate: Date;
}

/** An enumeration of string Legislative Types corresponding to known values for
 * the `bills.legislative_type` column in the Enview postgres database. This is
 * not necessarily an exhaustive list of types of legislation, and not all types
 * are found in bills from all the Jurisdictions in the Enview database
 */
export enum LegislativeType {
  BILL = 'bill',
  REPEAL_BILL = 'repeal bill',
  PROPOSED_BILL = 'proposed bill',
  APPROPRIATION = 'appropriation',
  RESOLUTION = 'resolution',
  JOINT_RESOLUTION = 'joint resolution',
  CONCURRENT_RESOLUTION = 'concurrent resolution',
  CONSTITUTIONAL_AMENDMENT = 'constitutional amendment',
  MEMORIAL = 'memorial',
  JOINT_MEMORIAL = 'joint memorial',
  CONCURRENT_MEMORIAL = 'concurrent memorial',
  PETITION = 'petition',
  APPOINTMENT = 'appointment',
  MEMORANDUM = 'memorandum',
  COMMEMORATION = 'commemoration',
  PROCLAMATION = 'proclamation',
  REMONSTRATION = 'remonstration',
  NOMINATION = 'nomination',
  STUDY_REQUEST = 'study request',
  CONCURRENT_STUDY_REQUEST = 'concurrent study request',
  CONTRACT = 'contract',
  CLAIM = 'claim',
  ORDER = 'order',
  CONCURRENT_ORDER = 'concurrent order',
  ORDINANCE = 'ordinance',
  MOTION = 'motion',
  BILL_OF_ADDRESS = 'bill of address',
  OTHER = 'other',
}
/** This list of Legislative Types will be grouped together as an "Other" option
 * in the Enview UI */
export const otherLegislativeTypeSelection = [
  LegislativeType.BILL_OF_ADDRESS,
  LegislativeType.CLAIM,
  LegislativeType.COMMEMORATION,
  LegislativeType.CONCURRENT_MEMORIAL,
  LegislativeType.CONCURRENT_ORDER,
  LegislativeType.CONCURRENT_STUDY_REQUEST,
  LegislativeType.CONSTITUTIONAL_AMENDMENT,
  LegislativeType.CONTRACT,
  LegislativeType.JOINT_MEMORIAL,
  LegislativeType.MEMORANDUM,
  LegislativeType.MEMORIAL,
  LegislativeType.MOTION,
  LegislativeType.NOMINATION,
  LegislativeType.ORDER,
  LegislativeType.ORDINANCE,
  LegislativeType.PETITION,
  LegislativeType.REMONSTRATION,
  LegislativeType.REPEAL_BILL,
  LegislativeType.STUDY_REQUEST,
];
