import {
  OrganizationUser,
  UserInvitation,
} from '@enview/interface/types/OrganizationUser';
import { TeamMembership } from '@enview/interface/types/Team';
import { TeamInvitation } from '../../models/Team';

export const SET_INVITATIONS = 'team/setInvitations';
export const CLEAR_INVITATION_LINKS = 'team/clearInvitationLinks';
export const SET_PENDING_TEAM_MEMBERS = 'team/setPendingTeamMembers';
export const SET_PENDING_TEAM_INVITES = 'team/setPendingTeamInvites';
export const REMOVE_PENDING_TEAM_INVITATION = 'team/removePendingTeamInvitation';
export const SET_TEAM_MEMBERS = 'team/setTeamMembers';
export const ADD_ORG_USER_TEAM_MEMBERSHIP = 'team/addOrgUserTeamMembership';

export type TeamState = {
  userInvites?: UserInvitation[];
  pendingTeamMembers?: Partial<TeamMembership>[];
  pendingTeamInvitations?: TeamInvitation[];
  teamMembers?: TeamMembership[];
  teamOrgUsers?: OrganizationUser[];
};

type SetInvitationsAction = {
  type: typeof SET_INVITATIONS;
  userInvites: UserInvitation[];
};

type ClearInvitationLinksAction = {
  type: typeof CLEAR_INVITATION_LINKS;
};

type SetPendingTeamMembersAction = {
  type: typeof SET_PENDING_TEAM_MEMBERS;
  pendingTeamMembers: Partial<TeamMembership>[];
};

type SetPendingTeamInvitesAction = {
  type: typeof SET_PENDING_TEAM_INVITES;
  pendingTeamInvitations: TeamInvitation[];
};

type RemovePendingTeamInvitationAction = {
  type: typeof REMOVE_PENDING_TEAM_INVITATION;
  teamId: number;
};

type SetTeamMembersAction = {
  type: typeof SET_TEAM_MEMBERS;
  teamMembers: TeamMembership[];
  teamOrgUsers: OrganizationUser[];
};

type AddOrgUserTeamMembershipAction = {
  type: typeof ADD_ORG_USER_TEAM_MEMBERSHIP;
  teamId: number;
};

export type Action =
  | SetInvitationsAction
  | ClearInvitationLinksAction
  | SetPendingTeamMembersAction
  | SetPendingTeamInvitesAction
  | RemovePendingTeamInvitationAction
  | SetTeamMembersAction
  | AddOrgUserTeamMembershipAction;
