import { RichTextNode } from '../actions/UserAction';

export enum PositionType {
  SUPPORT = 'Support',
  OPPOSE = 'Oppose',
  NO_POSITION = '',
}

export interface PositionStatement {
  id: number;
  orgId: number;
  billId: string;
  positionType: PositionType;
  positionStatement: string;
  statementJson?: RichTextNode;
  isPublic?: boolean;
  isArchived?: boolean;
  createdAt: Date;
  updatedAt: Date;
}
