import { ReactElement } from 'react';

export enum NoResultsType {
  VoteHistory = 'Vote History',
  Regulations = 'Regulations',
  Default = 'Results',
  Staff = 'Staff',
}

export enum NoResultsPage {
  Committee = 'committee',
  Legislator = 'legislator',
  Regulations = 'regulations',
}

type NoResultsProps = {
  type: NoResultsType;
  page?: NoResultsPage;
  icon: ReactElement;
  message?: ReactElement | string;
};

const NoResults = (props: NoResultsProps): ReactElement => {
  const { type, icon, message, page } = props;
  const noResultsMessage =
    message ??
    `We were not able to find any ${type.toLocaleLowerCase()} for this ${page}`;
  return (
    <div className="card no-results">
      <div className="no-results-content">
        <div className="no-results-icon">{icon}</div>
        <span className="no-results-title">No {type.toString()}</span>
        <div className="no-results-message">
          <span>{noResultsMessage}</span>
        </div>
      </div>
    </div>
  );
};

export default NoResults;
