import { ReactElement, useState } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import * as BillAnalytics from '../../../analytics/BillAnalytics';
import PulseIcon from '../../../components/svg/PulseIcon';
import QuestionMarkCircle from '../../../components/svg/QuestionMarkCircle';
import './Icon.scss';

type FeedbackIndicatorProps = {
  billId: string;
  place: PlacesType;
  type: string;
  tooltipDescription: string;
  infoLink: string;
  className?: string;
  originalBillVersion?: number;
  recommendedBillId?: string;
  recommendedBillVersion?: number;
  icon?: ReactElement;
};

const FeedbackIndicator = (props: FeedbackIndicatorProps): ReactElement => {
  const {
    billId,
    place,
    type,
    infoLink,
    tooltipDescription,
    originalBillVersion,
    recommendedBillId,
    recommendedBillVersion,
    className,
    icon,
  } = props;

  const [showThankYou, setShowThankYou] = useState(false);

  const sendAnalytics = (isHelpful: boolean): void => {
    if (type === 'momentum') {
      BillAnalytics.trackMomentumFeedback(billId, isHelpful);
    } else if (
      type === 'identical' &&
      originalBillVersion &&
      recommendedBillId &&
      recommendedBillVersion
    ) {
      BillAnalytics.trackIdenticalBillFeedback(
        // switching orders so that main bill on bill detail page is listed as the 'original bill id' on amplitude
        recommendedBillId,
        originalBillVersion,
        billId,
        recommendedBillVersion,
        isHelpful,
      );
    }
    setShowThankYou(true);
  };

  const question =
    type === 'momentum' ? 'IS THIS PREDICTION HELPFUL?' : 'IS THIS HELPFUL?';

  const tooltipContent = (): ReactElement => (
    <div className="momentum-tooltip-content">
      <p>
        {tooltipDescription}&nbsp;
        <a href={infoLink} rel="noopener noreferrer" target="_blank">
          this article
        </a>
        .
      </p>
      <br />
      <p>{question}</p>
      {showThankYou ? (
        <p>Thank you!</p>
      ) : (
        <p>
          <button
            className="btn-text p-0"
            onClick={() => sendAnalytics(true)}
            type="button"
          >
            Yes
          </button>
          <span className="p-2">/</span>
          <button
            className="btn-text p-0"
            onClick={() => sendAnalytics(false)}
            type="button"
          >
            No
          </button>
        </p>
      )}
    </div>
  );

  const getIcon = (): ReactElement => {
    if (icon) return icon;
    return type === 'momentum' ? <PulseIcon /> : <QuestionMarkCircle />;
  };

  return (
    <>
      <span
        className={className || 'momentum-indicator'}
        data-for={`momentum-${billId}`}
        data-tooltip-place={place}
        id={`momentum-${billId}`}
      >
        {getIcon()}
      </span>
      <ReactTooltip
        anchorId={`momentum-${billId}`}
        className="momentum-tooltip"
        clickable
        delayHide={600}
        id={`momentum-${billId}`}
        place={place}
      >
        {tooltipContent()}
      </ReactTooltip>
    </>
  );
};

export default FeedbackIndicator;
