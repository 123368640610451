import { UserInvitation } from '@enview/interface/types/OrganizationUser';
import { OnboardingSetupData } from '../../models/Onboarding';
import { OnboardingStage } from '../../scenes/Registration/RegistrationHelper';

export const SET_USER_INVITE = 'registration/setUserInvite';
export const SET_ONBOARDING_SETUP = 'registration/setUserOnboardingSetup';

export type RegistrationState = {
  userInvite?: UserInvitation;
  onboardingStage?: OnboardingStage;
  onboardingSetupData?: OnboardingSetupData;
};

type SetUserInviteAction = {
  type: typeof SET_USER_INVITE;
  userInvite: UserInvitation;
};

type SetOnboardingSetupAction = {
  type: typeof SET_ONBOARDING_SETUP;
  onboardingStage: OnboardingStage;
  onboardingSetupData: OnboardingSetupData;
};

export type Action = SetUserInviteAction | SetOnboardingSetupAction;
