import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query';
import React, { ReactElement, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import USAMap from 'react-usa-map';
import { BillAPI, JurisdictionAPI } from '../../api';
import Image from '../../components/Image';
import { g as abilityGlossary } from '../../config/ability';
import { checkPermission } from '../../dux';
import { Thunk } from '../../dux/@types';
import { getJurisdictionsForTeamMode, getTeamModeTeam } from '../../dux/TeamModeDux';
import STATES from '../../helpers/states';
import GatedFullScreenTakeover from '../Shared/Overlays/GatedFullScreenTakeover';
import Header from '../Shared/PageElements/Header';
import JurisdictionPopup from './JurisdictionPopup';
import './Jurisdictions.scss';
import { SEOHead } from '../../components/head/SEOHead';

// These need to match related values in Jurisdictions.scss
const POPUP_WIDTH = 264;
const POPUP_ARROW_WIDTH = 10;
const POPUP_ARROW_OFFSET = 30;
const BUTTON_WIDTH = 37;

// Gradient steps generated using https://colordesigner.io/gradient-generator
const MAP_COLORS: { [key: string]: string } = {
  grey: '#eaeaea',
  blue_1: '#b4e2f1',
  blue_2: '#aadcec',
  blue_3: '#a1d5e8',
  blue_4: '#97cfe3',
  blue_5: '#8ec9df',
  blue_6: '#84c3da',
  blue_7: '#7abdd6',
  blue_8: '#70b7d1',
  blue_9: '#66b0cd',
  blue_10: '#5caac9',
  blue_11: '#51a4c5',
  blue_12: '#459ec0',
  blue_13: '#3798bc',
  blue_14: '#2892b8',
  blue_15: '#0f8cb4',
};

interface StateMap {
  [key: string]: {
    abbr: string;
    name: string;
    hasAccess: boolean;
    billCount: number;
    fill: string;
  };
}
const FULL_SCREEN_MODAL_HEADER = 'Upgrade your account to use the Explorer tool';

const Jurisdictions = (): ReactElement => {
  const [selectedJuris, setSelectedJuris] = useState<string | SkipToken>(skipToken);

  const {
    data: jurisReferenceInfo,
    isFetching,
    isError,
  } = JurisdictionAPI.endpoints.getReferenceInfo.useQuery(selectedJuris);

  const activeTeam = useSelector(getTeamModeTeam);
  const { data: trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined);
  const dispatch = useDispatch();

  const canViewExplore = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.EXPLORE, true),
    );
  };

  const supportedJurisdictions = useSelector(getJurisdictionsForTeamMode);

  const states: StateMap = {};

  let maxBillCount = 0;

  // Create an object to pass to USAMap that describes the fill color for each state
  if (activeTeam && trackedBills && Array.isArray(trackedBills)) {
    Object.keys(STATES).forEach((key) => {
      states[key] = {
        abbr: key.toLowerCase(),
        name: STATES[key],
        hasAccess:
          !!supportedJurisdictions &&
          supportedJurisdictions.filter(
            (j) => j.abbreviation.toLowerCase() === key.toLowerCase(),
          ).length > 0,
        billCount: trackedBills.filter(
          (bill) =>
            bill.session.state.abbreviation.toUpperCase() === key &&
            bill.teams &&
            bill.teams.filter((team) => team.id === activeTeam.id).length > 0,
        ).length,
        fill: MAP_COLORS.grey,
      };
      if (states[key].billCount > maxBillCount) {
        maxBillCount = states[key].billCount;
      }
    });

    Object.keys(states).forEach((key) => {
      const count = states[key].billCount;
      // Leave jurisdictions that we don't have access to the default grey
      if (states[key].hasAccess) {
        // 15 available colors, use "1" (lightest blue) for 0 bills
        if (count === 0) {
          states[key].fill = MAP_COLORS.blue_1;
        } else {
          // scale the remaining 14 colors to the max number of bills tracked in any state
          const fillFactor = Math.ceil((count / maxBillCount) * 14) + 1;
          const fillIndex = `blue_${fillFactor.toString()}`;
          states[key].fill = MAP_COLORS[fillIndex];
        }
      }
    });
  }

  const popupRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const resetSelectedJurisdiction = (): void => {
    document
      .querySelectorAll('.jurisdictions-view path, .jurisdictions-view circle')
      .forEach((path) => path.classList.remove('selected'));
  };
  const containerClickHandler = (event: React.SyntheticEvent<Element>): void => {
    if (!(event.target instanceof Element)) {
      return;
    }
    if (popupRef.current) {
      if (
        event.target.tagName.toLowerCase() !== 'path' &&
        event.target.tagName.toLowerCase() !== 'button' &&
        event.target.tagName.toLowerCase() !== 'circle' &&
        event.target.id !== 'explorer-popup-container' &&
        !event.target.matches('#explorer-popup-container *')
      ) {
        popupRef.current.style.display = 'none';
        resetSelectedJurisdiction();
      }
    }
  };
  const getRightmostPoint = (path: SVGPathElement): DOMPoint => {
    let rmPoint = path.getPointAtLength(0);
    for (let i = 0; i < path.getTotalLength(); i += 1) {
      if (path.getPointAtLength(i).x > rmPoint.x) {
        rmPoint = path.getPointAtLength(i);
      }
    }
    return rmPoint;
  };
  const getLeftmostPoint = (path: SVGPathElement): DOMPoint => {
    let lmPoint = path.getPointAtLength(0);
    for (let i = 0; i < path.getTotalLength(); i += 1) {
      if (path.getPointAtLength(i).x < lmPoint.x) {
        lmPoint = path.getPointAtLength(i);
      }
    }
    return lmPoint;
  };
  const mapHandler = (event: React.SyntheticEvent<SVGPathElement>): void => {
    const jurisAbbr = event.currentTarget.dataset.name as string;
    // set CSS classes to highlight the selected state's outline
    resetSelectedJurisdiction();
    event.currentTarget.classList.add('selected');
    // setState on the selected jurisdiction to drive the popup data
    setSelectedJuris(jurisAbbr);
    if (popupRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tgtRect = event.currentTarget.getBoundingClientRect();

      // Pretty hacky: since the primary layout is scrollable (independent of window/body/document), we need
      // to get that div's scroll position to position our dialog box
      let vScrollOffset = 0;
      const primaryLayout = document.querySelectorAll('div.primary-layout-content');
      // Even hackier: if there's a site-banner active (e.g. invitation message), it shifts everything
      // in a way that does not show up in getBoundingClientRect()
      const siteBanner = document.querySelectorAll('div.site-banner');
      if (primaryLayout && primaryLayout.length > 0) {
        vScrollOffset =
          primaryLayout[0].scrollTop -
          (siteBanner && siteBanner[0].childElementCount > 0 ? 92 : 0);
      }

      let left = 0;
      let top = tgtRect.top - containerRect.top;

      // Popup placement! This gets fun
      //
      // If we have a mobile-sized screen, place the popup on the bottom-left of the bounding rectangle
      // of the state. Currently does not trigger the pointer-arrow effect in CSS
      if (document.documentElement.clientWidth < 576) {
        left = tgtRect.left - containerRect.left;
        top = tgtRect.bottom - containerRect.top;
        popupRef.current.className = 'bottom';
        // bigger screens: If the popup would fit to the right of the state (SVG Path) within
        // the containing box, place it there, offset by the width of the pointing arrow.
        // If it would not fit on the right, place it on the left, with the same offset.
        // In both cases, set the Y-position to match the left-or-right-most point of the SVG path
      } else if (tgtRect.right + POPUP_WIDTH > document.documentElement.clientWidth) {
        left = tgtRect.left - containerRect.left - POPUP_WIDTH - POPUP_ARROW_WIDTH;
        top =
          getLeftmostPoint(event.currentTarget).y + containerRect.top + vScrollOffset;
        popupRef.current.className = 'left';
      } else {
        left = tgtRect.left + tgtRect.width - containerRect.left + POPUP_ARROW_WIDTH;
        top =
          getRightmostPoint(event.currentTarget).y + containerRect.top + vScrollOffset;
        popupRef.current.className = 'right';
      }
      popupRef.current.style.top = `${top.toString()}px`;
      popupRef.current.style.left = `${left.toString()}px`;
      popupRef.current.style.display = 'block';
    }
  };
  const buttonHandler = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    const jurisAbbr = event.currentTarget.dataset.jurisabbr;
    if (jurisAbbr && popupRef.current && containerRef.current) {
      setSelectedJuris(jurisAbbr);
      resetSelectedJurisdiction();
      const containerRect = containerRef.current.getBoundingClientRect();
      const tgtRect = event.currentTarget.getBoundingClientRect();

      const left =
        tgtRect.left + tgtRect.width - containerRect.left + POPUP_ARROW_WIDTH;
      const top =
        tgtRect.top - containerRect.top - POPUP_ARROW_OFFSET + BUTTON_WIDTH / 2;
      popupRef.current.className = 'right';
      popupRef.current.style.top = `${top.toString()}px`;
      popupRef.current.style.left = `${left.toString()}px`;
      popupRef.current.style.display = 'block';
    }
  };
  let juris = null;
  if (selectedJuris !== skipToken) {
    juris = states[selectedJuris];
  }

  if (!canViewExplore()) {
    return <GatedFullScreenTakeover modalHeader={FULL_SCREEN_MODAL_HEADER} />;
  }

  return (
    <div
      className="jurisdictions-view"
      onClick={containerClickHandler}
      onKeyDown={containerClickHandler}
      ref={containerRef}
      role="presentation"
      style={{ position: 'relative' }}
    >
      <SEOHead description={`Jurisdiction Explorer`} title={`Explorer`} />
      <Header title="Explorer / Jurisdictions" />
      <div className="map-container">
        <USAMap customize={states} onClick={mapHandler} />
      </div>
      <JurisdictionPopup
        billCount={juris?.billCount}
        content={jurisReferenceInfo}
        hasAccess={!!juris && juris.hasAccess}
        isError={isError}
        isFetching={isFetching}
        popupRef={popupRef}
      />
      <div className="addtl-jurisdictions-container">
        <div>
          <button
            className="btn btn-sm btn-secondary"
            data-jurisabbr="USA"
            onClick={buttonHandler}
            type="button"
          >
            🏛️ US Congress
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            data-jurisabbr="DC"
            onClick={buttonHandler}
            type="button"
          >
            <Image
              alt="Flag of Washington, D.C."
              fileName="washington-dc-flag.png"
              height={15}
            />
            &nbsp;&nbsp;District of Columbia
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            data-jurisabbr="PR"
            onClick={buttonHandler}
            type="button"
          >
            🇵🇷 Puerto Rico
          </button>
        </div>
      </div>
    </div>
  );
};

export default Jurisdictions;
