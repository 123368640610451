import axios from 'axios';
import assign from 'lodash-es/assign';
import { getAuthHeader, getSessionId } from '../services/LocalStorageService';

const baseURL = process.env.REACT_APP_API_URL;
const instance = axios.create({ baseURL });
instance.interceptors.request.use((config) => {
  const updatedConfig = assign({}, config);
  const authHeader = getAuthHeader();
  if (!updatedConfig.headers.Authorization && authHeader) {
    updatedConfig.headers.Authorization = authHeader;
  }
  const sessionId = getSessionId();
  if (sessionId) {
    updatedConfig.headers['x-transaction-id'] = sessionId;
  }
  // This is required to support cross sub-domain cookies.
  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
  updatedConfig.withCredentials = true;
  return updatedConfig;
});
export default instance;
