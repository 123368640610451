import { Organization } from '@enview/interface/types/Organization';
import { track, page } from './SegmentAnalytics';

enum AnalyticsEvent {
  ORG_USER_REMOVED = 'Removed user from an organization',
  ORG_USER_SUSPENDED = 'Suspended user from an organization',
  ORG_USER_ROLE_UPDATED = "Changed an organization user's permissions",
  VIEWED_ORG_PAGE = 'Viewed Org Settings Page',
}

export const trackOrgUserRemoved = (targetUserId: number, orgId: number): void => {
  track(AnalyticsEvent.ORG_USER_REMOVED, {
    targetUserId,
    orgId,
  });
};

export const trackOrgUserSuspended = (
  targetUserId: number,
  orgId: number,
  isSuspended: boolean,
): void => {
  track(AnalyticsEvent.ORG_USER_SUSPENDED, {
    targetUserId,
    orgId,
    isSuspended,
  });
};

export const trackOrgUserRoleUpdated = (
  targetUserId: number,
  orgId: number,
  orgRole: string,
): void => {
  track(AnalyticsEvent.ORG_USER_ROLE_UPDATED, {
    targetUserId,
    orgId,
    orgRole,
  });
};

export const trackViewedOrgPage = (org: Organization): void => {
  page('Org Settings', AnalyticsEvent.VIEWED_ORG_PAGE, {
    orgName: org.name,
  });
};
