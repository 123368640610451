import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import React, { ReactElement, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../../components/svg/CloseIcon';
import { closeSavedSearchAlertModal } from '../../../dux';
import { State } from '../../../dux/@types';

export const SavedSearchAlertModal = (): ReactElement | null => {
  const [isFadingOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const isOpen = useSelector((state: State) => state.modals.modalOpenSavedSearchAlert);
  const isSubscribed = useSelector(
    (state: State) => state.modals.modalOpenSavedSearchAlertSubscribed,
  );
  const isError = useSelector(
    (state: State) => state.modals.modalOpenSavedSearchAlertError,
  );
  const close = (): void => {
    dispatch(closeSavedSearchAlertModal());
  };

  const alertErrTemplate = <p>An error occurred. Please try again later.</p>;
  const alertState = isSubscribed ? 'on' : 'off';
  const alertSuccessTemplate = (
    <>
      <p>You have successfully turned {alertState} alerts for your saved search.</p>
      {isSubscribed && (
        <p>
          Set the frequency of your alerts <Link to="/account/notifications">here</Link>
          .
        </p>
      )}
    </>
  );
  useEffect(() => {
    setTimeout(() => setFadeOut(true), 6000);
    setTimeout(() => close(), 7000);
  });

  return isOpen ? (
    <div className={isFadingOut ? 'confirmation-popup fade-out' : 'confirmation-popup'}>
      <div>
        <CloseIcon className="float-right" onClick={close} />
      </div>
      <FontAwesomeIcon className="check-icon" color="#1AA08F" icon={faCheckCircle} />
      <h3 className="title">{isError ? 'Error' : 'Success'}</h3>
      {isError ? alertErrTemplate : alertSuccessTemplate}
    </div>
  ) : null;
};

export default SavedSearchAlertModal;
