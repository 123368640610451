import React from 'react';
import * as Sentry from '@sentry/react';
import RootLayout from './scenes/RootLayout';
import { SEOHead } from './components/head/SEOHead';

const App = () => (
  <Sentry.ErrorBoundary>
    {/*<head> info that will get overridden by components, useful to show "Plural" title in case
    we are missing a Helmet/SEOHead component in a child*/}
    <SEOHead description={`Plural`} title={``} />
    <RootLayout />
  </Sentry.ErrorBoundary>
);

export default Sentry.withProfiler(App);
