import { Committee } from '@enview/interface/types/Committee';
import base, { ApiTag } from './base';

const committeeAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getCommittee: build.query<Committee, number>({
      query: (param) => ({ url: `/committees/${param}`, method: 'GET' }),
      providesTags: (result) => [{ type: ApiTag.COMMITTEE_TAG, id: result?.id }],
    }),
    getCommitteesByJurisdiction: build.query<Committee[], string>({
      query: (jurisAbbr) => ({
        url: `/committees?jurisdiction=${jurisAbbr.toLowerCase()}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});
export default committeeAPI;
