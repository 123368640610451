import React, { ReactElement, useState } from 'react';
import CustomDropdown from '../../Shared/DropDowns/CustomDropdown';
import MultiSelectForm from '../../Shared/Forms/MultiSelectForm';
import DropDownButton from '../../Shared/Buttons/DropDownButton';

type PartySponsorFilterDropdownProps = {
  sponsorFilters: string[];
  handleSponsorFiltersChange?: (sponsorFilters: string[]) => void;
};

const PartySponsorFilterDropdown = (
  props: PartySponsorFilterDropdownProps,
): ReactElement => {
  const { sponsorFilters, handleSponsorFiltersChange } = props;

  const [showSponsorFilter, setShowSponsorFilter] = useState(false);

  const selectedCount = sponsorFilters.length;

  const options = [
    { label: 'Democratic Bill Sponsors', value: 'democrat' },
    { label: 'Republican Bill Sponsors', value: 'republican' },
  ];

  return (
    <div className="adv-filter filter bill-sponsors">
      <CustomDropdown
        setShowDropdown={setShowSponsorFilter}
        showDropdown={showSponsorFilter}
        target={
          <DropDownButton
            dropdownDisplayed={showSponsorFilter}
            id="sponsor-filter"
            label={'Sponsors'}
            onClick={() => {
              setShowSponsorFilter(!showSponsorFilter);
            }}
            selectedCount={selectedCount}
          />
        }
      >
        <div className="adv-search-selector">
          <MultiSelectForm
            currentSelection={sponsorFilters}
            options={options}
            showClearAll
            showSelectAll
            showSelectButtonsBottom
            singleColumn
            updateSelection={(filters) => {
              if (handleSponsorFiltersChange) handleSponsorFiltersChange(filters);
            }}
          />
        </div>
      </CustomDropdown>
    </div>
  );
};

export default PartySponsorFilterDropdown;
