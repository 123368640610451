import React, { FunctionComponent } from 'react';
import './Icon.scss';

type CircleIconProps = {
  className?: string;
  size?: number;
  color?: string;
};

const DEFAULT_SIZE = 40;

const styleForSize = (size: number) => {
  return {
    flex: `0 0 ${size}px`,
    height: `${size}px`,
    width: `${size}px`,
    fontSize: `${size * 0.4}px`,
    lineHeight: `${size - 3}px`,
  };
};
const CircleIcon: FunctionComponent<CircleIconProps> = (props) => {
  const { className, size, color, children } = props;
  const style: { [property: string]: string } = styleForSize(size || DEFAULT_SIZE);
  if (color) {
    style.color = color;
    style.borderColor = color;
  }
  return (
    <div className={`custom-icon${className ? ` ${className}` : ''}`} style={style}>
      {children}
    </div>
  );
};

export default CircleIcon;
