const calculateDaysRemaining = (startDay: string | Date): number => {
  const startDate = typeof startDay === 'string' ? new Date(startDay) : startDay;
  const currentDate = new Date();

  const timeDifference = currentDate.getTime() - startDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  const daysRemaining = Math.max(15 - daysDifference, 0);

  return daysRemaining;
};

export default calculateDaysRemaining;
