/* eslint-disable react/require-default-props */
import { ReactElement } from 'react';
import Modal from 'react-modal';
import Stars from '../../../components/svg/Stars';
import './Overlays.scss';
import { useTranslation } from 'react-i18next';

type GatedFullScreenTakeoverProps = {
  modalHeader?: string;
  modalBodyText?: string;
  modalButton?: ReactElement;
  blurImage?: boolean;
};

const GatedFullScreenTakeover = (props: GatedFullScreenTakeoverProps): ReactElement => {
  const { t } = useTranslation();
  const { modalHeader, modalBodyText, modalButton, blurImage } = props;
  const scheduleDemoUrl = t('urls.scheduleDemo');
  const upgradeUrl = t('urls.pricingPage');
  return (
    <div className="layout-takeover">
      <div
        className={`fullscreen-background-image default-background ${
          blurImage ? 'blur-element' : ''
        }`}
      />
      <Modal
        className="d-flex flex-column react-modal"
        contentLabel="Modal"
        isOpen
        overlayClassName="fullscreen-modal-overlay"
      >
        <div className="overlay-icon">
          <Stars />
        </div>
        <div className="overlay-header">
          {modalHeader ?? t('featureGating.fullScreenHeader')}
        </div>
        <div className="overlay-body">
          {modalBodyText ?? t('featureGating.fullScreenBodyText')}
        </div>
        <div className="overlay-button-group">
          {
            modalButton ?? (
              <>
                <a href={scheduleDemoUrl} rel="noopener noreferrer" target="_blank">
                  <button className="btn btn-white demo-button" type="button">
                    {t('primaryLayout.requestDemo')}
                  </button>
                </a>
                <a href={upgradeUrl} rel="noopener noreferrer" target="_blank">
                  <button className="btn btn-primary" type="button">
                    {t('primaryLayout.upgrade')}
                  </button>
                </a>
              </>
            )
            // )
          }
        </div>
      </Modal>
    </div>
  );
};

export default GatedFullScreenTakeover;
