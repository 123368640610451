import { ReactElement, useEffect, useRef, useState } from 'react';
import CloseIcon from '../../../components/svg/CloseIcon';
import DropDownButton from '../Buttons/DropDownButton';
import TextButton from '../Buttons/TextButton';
import './DropDown.scss';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';
import { useTranslation, Trans } from 'react-i18next';

export type OptionType<T> = {
  value: T;
  label: string;
  section?: SectionHeader;
  key?: string;
  isDisabled?: boolean;
};

export type SectionHeader = string;

type MultipleSelectDropDownProps<T> = {
  title: string;
  currentSelectedValues: T[];
  options: OptionType<T>[];
  alignRight: boolean;
  sections?: SectionHeader[];
  onChange: (selectedOptions: T[]) => void;
  equals: (a: T, b: T) => boolean;
  description?: string;
  columnCount?: number;
  id?: string;
  useTagsAsLabel?: boolean;
  hideCount?: boolean;
};

const DESELECT_ALL = 'Clear All';

const MultipleSelectDropDown = <T extends any>(
  props: MultipleSelectDropDownProps<T>,
): ReactElement => {
  const {
    title,
    currentSelectedValues,
    options,
    alignRight,
    sections,
    onChange,
    equals,
    description,
    columnCount,
    id,
    useTagsAsLabel,
    hideCount,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [displayed, setDisplayed] = useState(false);
  const [selectedValues, setSelectedValues] = useState<T[]>(currentSelectedValues);
  const [filterCount, setFilterCount] = useState(currentSelectedValues.length);
  const [showTooltipGate, setShowTooltipGate] = useState<boolean>(false);
  const { t } = useTranslation();
  const showClass = displayed ? ' show' : '';
  const selectedOptions = options.filter((option) =>
    selectedValues.includes(option.value),
  );
  const tagsLabel =
    selectedOptions.length > 0 && useTagsAsLabel ? (
      <>
        {selectedOptions.map((o) => (
          <span key={o.label}>{o.label}</span>
        ))}
      </>
    ) : undefined;

  const handleClickOutside = (e: MouseEvent): void => {
    if (ref && ref.current && ref.current.contains(e.target as Element)) {
      return;
    }
    setDisplayed(false);
    setShowTooltipGate(false);
  };

  useEffect(() => {
    if (displayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setSelectedValues(currentSelectedValues);
      setShowTooltipGate(false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayed]);

  useEffect(() => {
    setSelectedValues(currentSelectedValues);
  }, [currentSelectedValues]);

  const onSelectionChange = (option: OptionType<T>, selected: boolean): void => {
    let newSelectedValues: T[] = selectedValues;

    if (selected && option.isDisabled) {
      setShowTooltipGate(true);
    } else if (selected) {
      newSelectedValues = [...newSelectedValues, option.value];
      setShowTooltipGate(false);
    } else {
      newSelectedValues = selectedValues.filter((v) => !equals(v, option.value));
      setShowTooltipGate(false);
    }
    setSelectedValues(newSelectedValues);
  };

  const renderClearAllFilters = (disabled: boolean) => {
    return (
      <TextButton
        className="bold clear-selection"
        label={DESELECT_ALL}
        onClick={() => {
          setDisplayed(false);
          if (!disabled) {
            setSelectedValues([]);
            onChange([]);
            setFilterCount(0);
          }
        }}
      />
    );
  };

  const renderOption = (option: OptionType<T>, index: number): ReactElement => {
    const checked = !!selectedValues.find((v) => equals(v, option.value));
    const optionId = `dropdown-multi-${index}-${option.key || option.label}`.replace(
      /\s/g,
      '-',
    ); // use id for anchor

    return (
      <div
        className={
          option.isDisabled
            ? 'dropdown-multi-item disabled-option'
            : 'dropdown-multi-item'
        }
        key={optionId}
        onClick={() => onSelectionChange(option, !checked)}
      >
        <input checked={checked} id={optionId} readOnly type="checkbox" />
        <label>{option.label}</label>
      </div>
    );
  };

  const renderOptions = (optionsToRender: OptionType<T>[]): ReactElement[] => {
    return optionsToRender.map((option, index) => {
      return renderOption(option, index);
    });
  };

  const renderOptionsWithSections = (): ReactElement => {
    const optionsWithoutSections: OptionType<T>[] = [];
    const sectionOptionMap: { [label: string]: OptionType<T>[] } = {};
    // First section for options without a section provided
    sections!.forEach((section) => {
      sectionOptionMap[section] = [];
    });
    options.forEach((option) => {
      if (!option.section || !sectionOptionMap[option.section]) {
        optionsWithoutSections.push(option);
      } else {
        sectionOptionMap[option.section].push(option);
      }
    });

    return (
      <>
        {renderOptions(optionsWithoutSections)}
        {sections!.map((section) => {
          return (
            <>
              <div className="section-header" key={section}>
                {section}
              </div>
              {renderOptions(sectionOptionMap[section])}
            </>
          );
        })}
      </>
    );
  };

  const saveSelection = (): void => {
    onChange(selectedValues);
    setFilterCount(selectedValues.length);
    setDisplayed(false);
  };

  const alignClass = alignRight ? ' dropdown-menu-right' : '';
  return (
    <div className={`dropdown${showClass}`} ref={ref}>
      <DropDownButton
        dropdownDisplayed={displayed}
        id={id}
        label={title}
        onClick={() => setDisplayed(!displayed)}
        selectedCount={hideCount ? undefined : filterCount}
        tagsLabel={tagsLabel}
      />
      <div
        aria-labelledby="dropdownMenuButton"
        className={`dropdown-menu multi-select${alignClass}${showClass}`}
      >
        <div className="flex-row d-flex">
          <div className="title">{title}</div>
          <CloseIcon
            className="align-self-center"
            onClick={() => {
              setDisplayed(false);
            }}
          />
        </div>
        {description ? <div className="description">{description}</div> : ''}

        <div className="flex-column" id="popover-anchor" style={{ columnCount }}>
          {!sections && renderOptions(options)}
          {sections && renderOptionsWithSections()}
        </div>
        <div className="flex-row dropdown-divider" />
        <div className="dropdown-footer flex-row" style={{ whiteSpace: 'nowrap' }}>
          <button
            className="btn btn-action dropdown-save-button"
            id={`dropdown-save-button-${title.replace(/ /g, '-')}`}
            onClick={saveSelection}
            type="button"
          >
            Save
          </button>
          {renderClearAllFilters(selectedValues.length === 0)}
        </div>
      </div>
      {showTooltipGate && displayed && (
        <GateTooltip
          accountType="pro"
          anchorId={'popover-anchor'}
          customContent={
            <Trans
              components={{
                tagLink: <a href={t('urls.viewHearings')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.viewHearings"
              key="featureGating.tooltipText.viewHearings"
            />
          }
          featureName={GateTooltipFeature.ViewHearings}
          isOpen={showTooltipGate}
          key={'popover-anchor-show'}
          place="bottom"
        />
      )}
    </div>
  );
};

MultipleSelectDropDown.defaultProps = {
  alignRight: false,
  equals: (a: any, b: any) => a === b,
};

export default MultipleSelectDropDown;
