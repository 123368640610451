import React, { CSSProperties, ReactElement } from 'react';
import './Button.scss';
import '../DropDowns/DropDown.scss';
import { Link } from 'react-router-dom';
import CustomToggleSelectDropDown, {
  CustomToggleDropdownOption,
} from '../DropDowns/CustomToggleSelectDropDown';
import ArrowDown from '../../../components/svg/ArrowDown';

type SplitButtonWithDropDownProps = {
  buttonLabel: string;
  id?: string;
  isDisabled: boolean;
  options: CustomToggleDropdownOption[];
  isTitleStyle: boolean;
  onButtonClicked?: (e: React.SyntheticEvent) => void;
  link?: string;
  selectedValue?: string | number | boolean;
  isToggled?: boolean;
  toggledOnStyle?: CSSProperties;
  dropDownTriggerId?: string;
  resizeDropdown: boolean;
};

const SplitButtonWithDropDown = (props: SplitButtonWithDropDownProps): ReactElement => {
  const {
    buttonLabel,
    isDisabled,
    options,
    isTitleStyle,
    onButtonClicked,
    link,
    selectedValue,
    isToggled,
    toggledOnStyle,
    dropDownTriggerId,
    resizeDropdown,
    id,
  } = props;
  const styleClass = isTitleStyle ? 'title-style ' : '';
  let label = buttonLabel;
  if (selectedValue) {
    const selectedOption = options.find((option) => option.value === selectedValue);
    if (selectedOption) {
      label = selectedOption.label;
    }
  }
  return (
    <div className={`split-button-dropdown ${styleClass}d-flex flex-row`} id={id}>
      {/* We still need button click events to fire for the feature-gating tooltip,
      so we can't use the `disabled` property and instead have to change CSS and click events ourselves
      */}
      <button
        aria-disabled={isDisabled}
        className={`btn split-button ${isDisabled ? 'disabled' : ''}`}
        onClick={isDisabled ? () => {} : onButtonClicked}
        style={isToggled && toggledOnStyle ? toggledOnStyle : {}}
        type="button"
      >
        {link ? (
          <Link className="hidden-link" to={link}>
            {label}
          </Link>
        ) : (
          label
        )}
      </button>
      <CustomToggleSelectDropDown
        alignRight
        id={dropDownTriggerId}
        isDisabled={isDisabled}
        options={options}
        resizeDropdown={resizeDropdown}
        selectedValue={selectedValue}
        style={isToggled && toggledOnStyle ? toggledOnStyle : {}}
        toggle={
          <ArrowDown
            color={isToggled && toggledOnStyle ? toggledOnStyle.color : undefined}
          />
        }
      />
    </div>
  );
};

SplitButtonWithDropDown.defaultProps = {
  isDisabled: false,
  isTitleStyle: false,
  resizeDropdown: false,
};

export default SplitButtonWithDropDown;
