import { OptionType } from '../scenes/Shared/DropDowns/MultipleSelectDropDown';

export const orderFilterOptions = (
  options: OptionType<string>[],
): OptionType<string>[] => {
  const collator = new Intl.Collator('en');
  return options.sort((a, b) => {
    return collator.compare(`${a.label}`, `${b.label}`);
  });
};
