import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextBox from '../TextBoxes/TextBox';
import './DropDown.scss';

export type CustomToggleDropdownOption = {
  label: string;
  value: string | number | boolean;
  icon?: ReactElement;
  isTextBox?: boolean;
  textBoxValue?: string;
  onSelect: (e: React.SyntheticEvent) => void;
  onSaveText?: (text: string) => void;
};

type CustomToggleSelectDropDownProps = {
  id?: string;
  isDisabled?: boolean;
  toggle: ReactElement;
  selectedValue?: string | number | boolean;
  options: CustomToggleDropdownOption[];
  style?: CSSProperties;
  alignRight: boolean;
  resizeDropdown: boolean;
  onClickEvent?: (args?: any) => void;
};

const ENTER_KEY = 'Enter';
const CLICK_EVENT = 'click';

const CustomToggleSelectDropDown = (
  props: CustomToggleSelectDropDownProps,
): ReactElement => {
  const {
    alignRight,
    id,
    isDisabled,
    options,
    resizeDropdown,
    style,
    toggle,
    onClickEvent,
  } = props;
  const node: any = useRef();
  const [displayed, setDisplayed] = useState(false);
  const [textBoxContent, setTextBoxContent] = useState('');
  const [topPos, setTopPos] = useState(0);
  const showClass = displayed ? ' show' : '';
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent): void => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }
    setDisplayed(false);
  };

  const saveText = (option: CustomToggleDropdownOption): void => {
    if (option.onSaveText) {
      option.onSaveText(textBoxContent);
      setTextBoxContent('');
    }
  };

  useEffect(() => {
    if (displayed) {
      if (resizeDropdown && ref.current) {
        setTopPos(ref.current.getBoundingClientRect().top);
      }
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayed, resizeDropdown]);

  const selectHandlerFactory =
    (option: CustomToggleDropdownOption) => (e: React.SyntheticEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.key === ENTER_KEY || e.type === CLICK_EVENT) {
        saveText(option);
        setDisplayed(false);
        option.onSelect(e);
      }
    };

  const renderOption = (
    option: CustomToggleDropdownOption,
    dropDownProps: CustomToggleSelectDropDownProps,
  ): ReactElement => {
    const indicator = dropDownProps.selectedValue === option.value;
    const value = `dropdown-single-${option.value}`;
    const key = option.label.replace(' ', '-');

    // TODO: swap <a> tags with href="#t" for radio buttons
    if (option.isTextBox) {
      return (
        <Link className="dropdown-item text-input" key={key} to="#t">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className="dropdown-item-icon"
            htmlFor={value}
            onClick={selectHandlerFactory(option)}
          >
            {option.icon}
          </label>
          <TextBox
            className="custom-textbox borderless-textbox"
            id={option.label.toLowerCase().replace(' ', '-')}
            keyPressed={selectHandlerFactory(option)}
            onChange={(e: any) => setTextBoxContent(e.target.value)}
            placeholder={option.label}
            text={option.textBoxValue}
          />
        </Link>
      );
    }
    return (
      <a
        className={`dropdown-item ${indicator ? 'checked' : ''}`}
        href="#t"
        key={key}
        onClick={selectHandlerFactory(option)}
      >
        {option.icon && <div className="dropdown-item-icon">{option.icon}</div>}
        <div className="dropdown-item-text">{option.label}</div>
      </a>
    );
  };

  const alignClass = alignRight ? ' dropdown-menu-right' : '';
  return (
    <div
      className={`dropdown custom-toggle-dropdown d-flex ${isDisabled && 'disabled'}`}
      id={id}
      ref={node}
      style={style}
    >
      <button
        aria-expanded={displayed}
        aria-haspopup="true"
        className="btn-no-styles custom-toggle-dropdown-trigger"
        data-toggle="dropdown"
        disabled={isDisabled}
        onClick={() => {
          if (onClickEvent) {
            onClickEvent();
          }
          setDisplayed(!displayed);
        }}
        type="button"
      >
        {toggle}
      </button>
      {options?.length > 0 && (
        <div
          aria-labelledby="dropdownMenuButton"
          className={`dropdown-menu${alignClass}${showClass}`}
          ref={ref}
          style={resizeDropdown ? { maxHeight: `calc(100vh - 20px - ${topPos}px` } : {}}
        >
          {options.map((option) => renderOption(option, props))}
        </div>
      )}
    </div>
  );
};

CustomToggleSelectDropDown.defaultProps = {
  alignRight: false,
  isDisabled: false,
  resizeDropdown: false,
} as Partial<CustomToggleSelectDropDownProps>;

export default CustomToggleSelectDropDown;
