import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Team } from '@enview/interface/types/Team';
import { track } from './SegmentAnalytics';

enum AnalyticsEvent {
  FILTER_BILLS = 'Filter Workspace Bills',
  INVITE_USER = 'Invite User',
  WORKSPACE_CREATED = 'Workspace Created',
  WORKSPACE_UPDATED = 'Workspace Updated',
  WORKSPACE_DELETED = 'Workspace Deleted',
  WORKSPACE_USER_UPDATED = "Changed user's workspace permissions",
  WORKSPACE_USER_REMOVED = 'Removed user from a workspace',
  SAVED_SEARCH_EDITED = 'User edited a saved search',
  SAVED_SEARCH_SUBSCRIPTION_EDITED = 'User edited a saved search subscription',
}

export const trackFilterWorkspaceBills = (
  values: string[],
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.FILTER_BILLS, {
    username: orgUser ? orgUser.email : 'Not logged in',
    teamId,
    filter: values.join(', '),
  });
};

export const trackInviteUser = (
  isEnviewUser: boolean,
  inviteeEmail: string,
  inviteeRole?: string,
): void => {
  track(AnalyticsEvent.INVITE_USER, {
    inviteeEmail,
    inviteeRole: inviteeRole || 'n/a',
    isEnviewUser,
    invitationType: inviteeRole ? 'Workspace' : 'Organization',
  });
};

export const trackWorkspaceCreated = (
  team: Partial<Team>,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.WORKSPACE_CREATED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    workspace: team.name,
    enviewUnite: team.isExternal,
  });
};

export const trackWorkspaceEdited = (
  team: Partial<Team>,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.WORKSPACE_UPDATED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    workspace: team.name,
    enviewUnite: team.isExternal,
  });
};

export const trackWorkspaceDeleted = (
  teamId: number,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.WORKSPACE_DELETED, {
    username: orgUser ? orgUser.email : 'Not logged in',
    deletedWorkspaceId: teamId,
  });
};

export const trackWorkspaceUserUpdated = (
  userId: number,
  teamId: number,
  userRole: string,
): void => {
  track(AnalyticsEvent.WORKSPACE_USER_UPDATED, {
    userId,
    teamId,
    userRole,
  });
};

export const trackWorkspaceUserRemoved = (userId: number, teamId: number): void => {
  track(AnalyticsEvent.WORKSPACE_USER_REMOVED, {
    userId,
    teamId,
  });
};

export const trackSavedSearchUpdated = (
  user: OrganizationUser,
  teamId: number,
): void => {
  track(AnalyticsEvent.SAVED_SEARCH_EDITED, {
    username: user.email,
    teamId,
  });
};

export const trackSavedSearchSubscriptionUpdated = (
  user: OrganizationUser,
  teamId: number,
  savedSearchId: string,
  savedSearchSubscriptionUpdate: { isFavorited?: boolean; isSubscribed?: boolean },
): void => {
  track(AnalyticsEvent.SAVED_SEARCH_SUBSCRIPTION_EDITED, {
    username: user.email,
    teamId,
    savedSearchId,
    isFavorited: savedSearchSubscriptionUpdate.isFavorited,
    isSubscribed: savedSearchSubscriptionUpdate.isSubscribed,
  });
};
