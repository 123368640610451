import { SearchResult } from '@enview/interface/types/BillSearch';
import Staff from '@enview/interface/types/persons/Staff';
import StaffPosition from '@enview/interface/types/persons/StaffPosition';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import _ from 'lodash-es';
import { ReactElement } from 'react';
import NoResults, { NoResultsPage, NoResultsType } from '../NoResults';
import ExpandableCard from '../PageElements/ExpandableCard';
import PersonImage from '../PersonImage';

type StaffCardsProps = {
  overflowMenu: (staffData: Staff) => ReactElement;
  staffData: SearchResult<Staff> | undefined;
  page: NoResultsPage;
  searching?: boolean;
};

const StaffCards = (props: StaffCardsProps): ReactElement => {
  const { staffData, overflowMenu, page, searching } = props;

  const staffTitle = (staffMember: Staff): string => {
    // I want to use toSorted() but React complains about it at runtime
    const positions = [...staffMember.staffPositions];
    const uniquePositionTitlesSorted = _.uniq(
      positions
        .filter((position) => position.isActive)
        .sort((a: StaffPosition, b: StaffPosition) => {
          if (!a.endDate && !b.endDate) {
            return 0;
          } else if (!a.endDate) {
            return -1;
          } else if (!b.endDate) {
            return 1;
          } else return b.endDate < a.endDate ? -1 : 1;
        })
        .map((position) => position.title),
    );
    return (
      uniquePositionTitlesSorted?.join(', ') || t('legislators.staff.defaultStaffTitle')
    );
  };

  if (searching) {
    return <></>;
  }

  if (!staffData || staffData.count === 0) {
    return (
      <NoResults
        icon={<FontAwesomeIcon icon={faChalkboardTeacher} size="lg" />}
        page={page}
        type={NoResultsType.Staff}
      />
    );
  }

  const cards = staffData?.data.map((staffMember: Staff) => {
    const staffImage = <PersonImage person={staffMember} size={60} />;
    const uniqueStaffEmails = _.uniq(
      staffMember.staffPositions
        .flatMap((position) => position.email)
        .filter((email) => !!email),
    );
    const emailElements = uniqueStaffEmails.map((email) => (
      <div key={`staff-email-link-${email}`}>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    ));
    const staffCardData = {
      [t('legislators.staff.email')]:
        <div>{emailElements}</div> || t('legislators.staff.noValue'),
      [t('legislators.staff.phone')]:
        _.uniq(
          staffMember.staffPositions
            .flatMap((position) => position.phone)
            .filter((phone) => !!phone),
        )?.join(', ') || t('legislators.staff.noValue'),
      [t('legislators.staff.policyAreas')]:
        _.uniq(
          staffMember.staffPositions.flatMap(
            (position) => position.legislativeIssues?.map((issue) => issue.name),
          ),
        )?.join(', ') || t('legislators.staff.noValue'),
    };
    return (
      <ExpandableCard
        data={staffCardData}
        image={staffImage}
        key={`staff-card-${staffMember.id ?? 0}`}
        overflowMenu={overflowMenu(staffMember)}
        tagLabel={staffTitle(staffMember)}
        title={staffMember.name}
      />
    );
  });

  return <>{cards}</>;
};

export default StaffCards;
