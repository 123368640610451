import React from 'react';

export default (props) => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.44707 3.54035L5.13233 0.307153C4.71246 -0.102384 4.04951 -0.102384 3.62964 0.307153L0.314901 3.54035C-0.104967 3.94988 -0.104967 4.59652 0.314901 5.00606C0.734768 5.4156 1.39772 5.4156 1.81758 5.00606L3.32027 3.54035V14.9643C3.32027 15.5247 3.78433 15.9989 4.38098 15.9989C4.95554 15.9989 5.4417 15.5463 5.4417 14.9643V3.54035L6.94439 5.00606C7.14327 5.20005 7.43055 5.30783 7.69573 5.30783C7.96091 5.30783 8.24819 5.20005 8.44707 5.00606C8.84484 4.59652 8.84484 3.93049 8.44707 3.54035Z"
        fill="#27A4CC"
      />
      <path
        d="M13.7287 2.05355H19.9383C20.5129 2.05355 20.999 1.6009 20.999 1.01893C20.999 0.436952 20.535 0.00585938 19.9383 0.00585938H13.7287C13.1541 0.00585938 12.668 0.458507 12.668 1.04048C12.668 1.62246 13.132 2.05355 13.7287 2.05355Z"
        fill="#27A4CC"
      />
      <path
        d="M19.939 13.9102H9.4865C8.91194 13.9102 8.42578 14.3628 8.42578 14.9448C8.42578 15.5268 8.88984 15.9794 9.4865 15.9794H19.939C20.5135 15.9794 20.9997 15.5268 20.9997 14.9448C20.9997 14.3628 20.5378 13.9102 19.939 13.9102Z"
        fill="#27A4CC"
      />
      <path
        d="M19.9388 9.27539H10.9006C10.326 9.27539 9.83984 9.72804 9.83984 10.31C9.83984 10.8704 10.3039 11.3446 10.9006 11.3446H19.9388C20.5133 11.3446 20.9995 10.892 20.9995 10.31C20.9995 9.72804 20.5376 9.27539 19.9388 9.27539Z"
        fill="#27A4CC"
      />
      <path
        d="M19.9385 4.63867H12.3146C11.7401 4.63867 11.2539 5.09132 11.2539 5.67329C11.2539 6.25527 11.718 6.70792 12.3146 6.70792H19.9385C20.5131 6.70792 20.9993 6.25527 20.9993 5.67329C20.9993 5.09132 20.5374 4.63867 19.9385 4.63867Z"
        fill="#27A4CC"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="21" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
