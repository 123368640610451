import React, { useState } from 'react';
import { translateBillStatus } from '@enview/interface/utils/Language';
import { Bill } from '@enview/interface/types/bills/Bill';
import EmailListForm from '../Forms/EmailListForm';
import { BillAPI } from '../../../api';

type EmailBillPopoverProps = {
  bill: Bill;
  onDone: () => void;
};

const EmailBillForm = (props: EmailBillPopoverProps) => {
  const { bill, onDone } = props;
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [emailBill, { isError, isLoading, isSuccess }] =
    BillAPI.endpoints.emailBill.useMutation();

  const getSentBillMessage = () => {
    if (showSentMessage) {
      if (isLoading) {
        return <span className="mt-2 p-2 font-italic">Bill Sending</span>;
      }
      if (isSuccess) {
        return <span className="mt-2 p-2 font-italic">Bill Sent!</span>;
      }
      if (isError) {
        return (
          <span className="alert-warning rounded mt-2 p-2 font-italic">
            Error sending bill. Try again!
          </span>
        );
      }
    }
    return null;
  };

  const onShareBill = (emails: string[]) => {
    const billURL = { billURL: window.location.href };
    const billTextInfo = {
      ...bill,
      status: translateBillStatus(bill.status, bill.state),
      ...billURL,
    };
    emailBill({
      data: {
        billId: bill.id,
        emails,
        billTextInfo,
      },
      bill,
    });
    setShowSentMessage(true);
    onDone();
  };

  return (
    <div className="d-flex flex-column">
      <p className="popover-confirm-body">Send this bill via email</p>
      <EmailListForm
        initialEmails={['']}
        onSubmit={onShareBill}
        submitText="Share Bill"
      />
      {getSentBillMessage()}
    </div>
  );
};

export default EmailBillForm;
