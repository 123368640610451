import { Person } from '@enview/interface/types/Person';
import startCase from 'lodash-es/startCase';
import { ReactElement, useMemo, useState } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import { convertPartyToPartyLabel } from '../../helpers/CommitteesHelper';
import { SortBy, SortOrder } from '../../models/SortOrder';
import MultipleSelectDropDown, {
  OptionType,
} from '../Shared/DropDowns/MultipleSelectDropDown';
import SortDropdown from '../Shared/DropDowns/SortDropdown';
import GridCard from '../Shared/PageElements/GridCard';
import PersonImage from '../Shared/PersonImage';
import './CommitteeView.scss';

type LegislatorCardViewProps = {
  legislators: Person[] | undefined;
};

const LEGISLATOR_SORT_OPTIONS = [
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

const getParty = (person: Person | undefined): string => {
  if (!person || !person.electedPositions || person.electedPositions.length === 0) {
    return '';
  }
  return person.electedPositions[0].party;
};

const getChamber = (person: Person | undefined): string => {
  if (!person || !person.electedPositions || person.electedPositions.length === 0) {
    return '';
  }
  return person.electedPositions[0].chamber?.name || '';
};

const sortPersons = (persons: Person[], sortOrder: SortOrder): Person[] => {
  const copy = [...persons];
  if (sortOrder.sortBy === SortBy.ALPHANUMERICAL && sortOrder.isReversed) {
    return copy.sort(
      (a, b) => -1 * (a.familyName || a.name).localeCompare(b.familyName || b.name),
    );
  }

  return copy.sort((a, b) =>
    (a.familyName || a.name).localeCompare(b.familyName || b.name),
  );
};

const applyFilters = (
  legislators: Person[],
  partyFilters: string[],
  chamberFilters: string[],
): Person[] => {
  let filteredList = legislators;
  if (partyFilters.length > 0) {
    filteredList = filteredList.filter((person) =>
      partyFilters.includes(getParty(person)),
    );
  }
  if (chamberFilters.length > 0) {
    filteredList = filteredList.filter((person) =>
      chamberFilters.includes(getChamber(person)),
    );
  }
  return filteredList;
};

const LegislatorCardView = (props: LegislatorCardViewProps): ReactElement => {
  const { legislators } = props;
  const [partyFilters, setPartyFilters] = useState<string[]>([]);
  const [chamberFilters, setChamberFilters] = useState<string[]>([]);
  const [selectedSortOrder, setSelectedSortOrder] = useState(
    LEGISLATOR_SORT_OPTIONS[0],
  );

  const partyCounts: { [party: string]: number } = useMemo(() => {
    const counts: { [party: string]: number } = {};
    if (legislators) {
      legislators.forEach((person: Person) => {
        const party = getParty(person);
        counts[party] = counts[party] ? counts[party] + 1 : 1;
      });
    }
    return counts;
  }, [legislators]);

  const partyFilterOptions: OptionType<string>[] = useMemo(() => {
    return Object.keys(partyCounts).map((party) => {
      return { value: party, label: convertPartyToPartyLabel(party, false) };
    });
  }, [partyCounts]);

  const chamberFilterOptions: OptionType<string>[] = useMemo(() => {
    const options = new Array<OptionType<string>>();
    if (!legislators) return options;
    const chambers = new Set<string>();
    legislators.forEach((person) => {
      const chamber = getChamber(person);
      if (chamber && !chambers.has(chamber)) {
        options.push({ value: chamber, label: chamber });
        chambers.add(chamber);
      }
    });
    return options;
  }, [legislators]);

  const filteredLegislators = legislators
    ? applyFilters(legislators, partyFilters, chamberFilters)
    : legislators;

  const sortedLegislators = filteredLegislators
    ? sortPersons(filteredLegislators, selectedSortOrder)
    : [];

  return (
    <div className="container-fluid committee-view p-0">
      {!legislators && (
        <>
          <TopBarProgress />
          <div className="message-card">Loading...</div>
        </>
      )}
      {legislators && (
        <>
          <div className="committee-header">
            <div className="stat-wrapper">
              <div className="stat">
                <div className="stat-number">{legislators.length}</div>
                <div className="stat-title">Members</div>
              </div>
              {Object.keys(partyCounts).map((key) => (
                <div className="stat" key={key}>
                  <div className="stat-number">{partyCounts[key]}</div>
                  <div className="stat-title">
                    {convertPartyToPartyLabel(key, partyCounts[key] > 1)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="filters d-flex flex-row">
              <div className="filter" style={{ flexBasis: 0 }}>
                <MultipleSelectDropDown
                  currentSelectedValues={partyFilters}
                  onChange={setPartyFilters}
                  options={partyFilterOptions}
                  title="Party"
                />
              </div>

              <div className="filter" style={{ flexBasis: 0 }}>
                <MultipleSelectDropDown
                  currentSelectedValues={chamberFilters}
                  onChange={setChamberFilters}
                  options={chamberFilterOptions}
                  title="Chamber"
                />
              </div>

              <div className="filter flex-grow-1" style={{ flexBasis: 0 }}>
                <SortDropdown
                  setSortOrder={setSelectedSortOrder}
                  sortOptions={LEGISLATOR_SORT_OPTIONS}
                  sortOrder={selectedSortOrder}
                />
              </div>
            </div>
            {sortedLegislators && (
              <div className="committee-grid">
                {sortedLegislators.map((person: Person) => {
                  const position =
                    person.electedPositions && person.electedPositions.length > 0
                      ? person.electedPositions[0]
                      : null;
                  return (
                    <GridCard
                      data={{
                        TITLE: position ? position.role : '(none)',
                        CHAMBER: position?.chamber?.name || '',
                        ROW: {
                          DISTRICT: position?.district || '',
                          PARTY: position?.party
                            ? convertPartyToPartyLabel(position.party, false)
                            : '',
                        },
                      }}
                      image={<PersonImage person={person} size={56} />}
                      key={person.id}
                      link={`/person/${person.id}`}
                      tagLabel={
                        position?.role && position.role.toLowerCase() !== 'member'
                          ? startCase(position.role.toLowerCase())
                          : undefined
                      }
                      title={person.name}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LegislatorCardView;
