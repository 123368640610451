import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, IconDefinition, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { SavedSearch } from '@enview/interface/types/BillSearch';
import { TeamAPI } from '../../api';
import { SavedSearchAlertModal }  from '../../scenes/Shared/Modals/SavedSearchAlertsModal';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
import { openSavedSearchAlertModal } from '../../dux';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';

type SavedSearchAlertBellProps = {
  savedSearch: SavedSearch;
  teamId: number;
  orgUser: OrganizationUser;
  className?: string;
  showModalOnAdd?: boolean;
};

export const SavedSearchAlertBell = (props: SavedSearchAlertBellProps) => {
  const [updateSavedSearch, { isError }] = TeamAPI.endpoints.updateSavedSearchAlerts.useMutation();
  const [runSavedSearch] = TeamAPI.endpoints.runSavedSearch.useMutation();
  const dispatch = useDispatch();
  const classes = `btn-text ${props.className}`;
  const { id, subscribedToByUserIds: usersSubscriptions } = props.savedSearch;
  const subscribedUserIds = usersSubscriptions
    ?.filter(
      (userSearch: { isSubscribed: boolean | undefined }) => userSearch.isSubscribed
    )
    ?.map((user: { userId: number }) => user.userId);
  const [isSubscribed, setSubscribed] = useState(subscribedUserIds?.includes(props.orgUser?.id) ?? false);
  const bellIcon = isSubscribed ? faBell : faBellSlash;

  async function updateSearch() {
    const update = {
      isSubscribed: !isSubscribed
    }
    if (props.teamId && props.orgUser?.id) {
      await updateSavedSearch({
        teamId: props.teamId,
        savedSearchId: id,
        orgUser: props.orgUser,
        savedSearchUserSubscriptionUpdate: update
      }).then(async () => {
        setSubscribed(update.isSubscribed);
        dispatch(openSavedSearchAlertModal(update.isSubscribed, isError))
        // If user has subscribed, run saved search now so results can be saved and compared against in the future
        if (update.isSubscribed) {
          await runSavedSearch({
            teamId: props.teamId,
            savedSearchId: id,
            userId: props.orgUser.id
          });
        }
      });
    }
  }

    return (
      <div>
        <button
          className={classes}
          onClick= {updateSearch}
          type="button">
          <FontAwesomeIcon color="#27A4CC" icon={bellIcon as IconDefinition} />
        </button>
      </div>
    );
};
