/* eslint-disable @typescript-eslint/lines-between-class-members */
import { SearchPagination } from '../BillSearch';
import PagingAll from './PagingAll';
import PagingDefault from './PagingDefault';
import { PagingOptions } from './PagingOptions';

export default class Paging implements SearchPagination {
  limit: number;
  offset?: number | undefined;

  constructor(limit: number, offset?: number) {
    this.limit = limit;
    this.offset = offset;
  }

  public static getPagingFromQuery(query: { page: number; pageSize?: number }): Paging {
    const limit = query.pageSize ?? 25;
    return new Paging(limit, (query.page - 1) * limit);
  }

  public static getDefault(paging: PagingOptions): Paging {
    switch (paging) {
      case PagingOptions.All:
        return new PagingAll();
      default:
        return new PagingDefault();
    }
  }
}
