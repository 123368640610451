import extend from 'lodash-es/extend';
import { State } from './@types';
import initialAccountState from './AccountDux/state';
import initialBillViewState from './BillViewDux/state';
import initialModalState from './ModalDux/state';
import initialNavState from './NavDux/state';
import initialLoadingState from './RequestDux/state';
import initialTeamModeState from './TeamModeDux/state';

const initialState: State = extend(
  {},
  { account: initialAccountState },
  { billView: initialBillViewState },
  { modals: initialModalState },
  { requests: initialLoadingState },
  { teamMode: initialTeamModeState },
  { navigation: initialNavState },
);

export default initialState;
