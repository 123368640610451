import { ReactElement } from 'react';
import './Badges.scss';

type ValueBadgeProps = {
  value: number | string;
  fillColor?: string;
  outlineColor?: string;
  textColor?: string;
  noOutline?: boolean;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

const ValueBadge = (props: ValueBadgeProps): ReactElement => {
  const {
    size = 'sm',
    value,
    fillColor,
    outlineColor,
    textColor,
    noOutline,
    className = '',
  } = props;

  return (
    <span
      className={`value-badge ${size} ${className}`}
      style={{
        backgroundColor: fillColor ? fillColor : 'rgba(199, 246, 240, 0.5)',
        color: textColor ? textColor : '#1AA08F',
        border: noOutline || !outlineColor ? 'none' : `1px solid ${outlineColor}`,
      }}
    >
      {value}
    </span>
  );
};

export default ValueBadge;
