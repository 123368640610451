import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Tag } from '@enview/interface/types/tags/Tag';
import MultiSelectForm, { MultiSelectOption } from '../../Forms/MultiSelectForm';
import { State } from '../../../../dux/@types';
import { BillGroup, TagBillGroup, TeamBillGroup } from '../../../../models/BillGroup';
import { TagAPI } from '../../../../api';

const MY_BILLS_LABEL = 'My Bills';
const TEAM_SECTION_LABEL = 'Workspaces';
export const TAG_SECTION_LABEL = 'Tags';

const createBillGroupOptions = (
  organizationUser: OrganizationUser,
  tags: Tag[],
): MultiSelectOption<BillGroup>[] => {
  const teams = organizationUser.teamMemberships
    .filter((tm) => tm.teamId !== organizationUser.teamId)
    .map((tm) => tm.team);
  const options: MultiSelectOption<BillGroup>[] = [
    {
      value: new TeamBillGroup(organizationUser.teamId),
      label: MY_BILLS_LABEL,
    },
  ];
  options.push(
    ...teams.map((team) => {
      return {
        value: new TeamBillGroup(team.id),
        label: team.name,
        section: TEAM_SECTION_LABEL,
      };
    }),
  );
  options.push(
    ...tags.map((t) => {
      return {
        value: new TagBillGroup(t.id),
        label: t.name,
        section: TAG_SECTION_LABEL,
      };
    }),
  );
  return options;
};

type ExportBillGroupSelectionProps = {
  selectionChanged: (selection: BillGroup[]) => void;
  currentSelection?: BillGroup[];
};

const ExportBillGroupSelection = (
  props: ExportBillGroupSelectionProps,
): ReactElement => {
  const { selectionChanged, currentSelection } = props;

  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const { data: tags } = TagAPI.endpoints.getTags.useQuery(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const billGroupOptions = createBillGroupOptions(organizationUser!, tags || []);

  return (
    <MultiSelectForm
      containerHeight={400}
      currentSelection={currentSelection}
      equals={(a: BillGroup, b: BillGroup) => BillGroup.equals(a, b)}
      options={billGroupOptions}
      sections={[TEAM_SECTION_LABEL, TAG_SECTION_LABEL]}
      showSelectAll
      updateSelection={selectionChanged}
    />
  );
};

export default ExportBillGroupSelection;
