import { RequestState } from './types';

const initialRequestState: RequestState = {
  failure: undefined,
  failureState: {},
  pending: false,
  pendingState: {},
  success: false,
  successState: {},
};

export default initialRequestState;
