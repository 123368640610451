import { Committee } from '@enview/interface/types/Committee';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { page } from './SegmentAnalytics';

enum AnalyticsEvent {
  VIEWED_COMMITTEE_STAFF_DIRECTORY = 'Viewed Committee Staff Directory',
}

export const trackViewedCommitteeStaffDirectory = (
  committee: Committee,
  orgUser?: OrganizationUser,
): void => {
  page('Committee', AnalyticsEvent.VIEWED_COMMITTEE_STAFF_DIRECTORY, {
    email: orgUser?.email,
    committeeName: committee.name,
    committeeId: committee.id,
  });
};
