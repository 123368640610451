import React, { ReactElement, useState } from 'react';
import { SearchActionFilter } from '@enview/interface/types/Bill';
import CustomDropdown from '../../Shared/DropDowns/CustomDropdown';
import GatedDropdownTargetButton from './GatedDropdownTargetButton';
import { g as abilityGlossary } from '../../../config/ability';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkPermission } from '../../../dux';
import BillActionFilter from './BillActionFilter';
import '../../Shared/Search/BillTableFilters.scss';

type BillActionFilterDropdownProps = {
  billAction: SearchActionFilter | undefined;
  setBillAction?: (arg: SearchActionFilter | undefined) => void;
  setSearchChanged: (bool: boolean) => void;
};

const BillActionFilterDropdown = (
  props: BillActionFilterDropdownProps,
): ReactElement => {
  const { billAction, setBillAction, setSearchChanged } = props;
  const dispatch = useDispatch();
  const billActionCount = billAction ? 1 : undefined;

  const [showActionFilter, setShowActionFilter] = useState(false);
  const canViewActionFilter = () => {
    return dispatch(
      checkPermission(
        abilityGlossary.VIEW,
        abilityGlossary.BILL_SEARCH_ACTION_FILTER,
        true,
      ),
    );
  };
  return (
    <div className="adv-filter bill-action filter">
      <CustomDropdown
        setShowDropdown={setShowActionFilter}
        showDropdown={showActionFilter}
        target={
          <GatedDropdownTargetButton
            customContent={
              <Trans
                components={{
                  actionLink: <a href={t('urls.advancedSearchTips')} target="_blank" />,
                  gate: <p className="tooltip-header" />,
                }}
                i18nKey="featureGating.tooltipText.billActions"
                key="featureGating.tooltipText.billActions"
              />
            }
            disabled={!canViewActionFilter()}
            isDisplayed={showActionFilter}
            label={t('buttons.search.billAction')}
            onClick={() => setShowActionFilter(!showActionFilter)}
            selectedCount={billActionCount}
          ></GatedDropdownTargetButton>
        }
      >
        <div className="action-dropdown">
          <BillActionFilter
            billAction={billAction}
            setBillAction={setBillAction}
            setSearchChanged={setSearchChanged}
            setShowActionFilter={setShowActionFilter}
            showActionFilter={showActionFilter}
          />
        </div>
      </CustomDropdown>
    </div>
  );
};

export default BillActionFilterDropdown;
