import {
  SignupData,
  OrganizationUser,
  UserInvitation,
} from '@enview/interface/types/OrganizationUser';
import assign from 'lodash-es/assign';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import { authenticate, setAuthErrorMessage } from '..';
import * as Analytics from '../../analytics/AccountAnalytics';
import instance from '../../config/axiosConfig';
import { createAuthErrorMessage } from '../../helpers/AuthHelper';
import { OnboardingSetupData, OnboardingUserData } from '../../models/Onboarding';
import { OnboardingStage } from '../../scenes/Registration/RegistrationHelper';
import { persistStateInLocalStorage } from '../../services/LocalStorageService';
import { Action, State, Thunk } from '../@types';
import { setOrganizationUser } from '../AccountDux';
import {
  requestError,
  requestFailure,
  requestSent,
  requestSucceeded,
  requestSuccess,
} from '../RequestDux';
import { RegistrationState, SET_ONBOARDING_SETUP, SET_USER_INVITE } from './types';

export enum SignupType {
  LINK = 'link',
}

// REQUEST NAMES
export const VERIFY_ONBOARDING_INVITE = 'VerifyOnboardingInvite';

// REDUCER
export default function reducer(
  state: RegistrationState,
  action: Action,
): RegistrationState {
  switch (action.type) {
    case SET_USER_INVITE:
      return assign({}, state, {
        userInvite: action.userInvite,
      });
    case SET_ONBOARDING_SETUP:
      return assign({}, state, {
        onboardingStage: action.onboardingStage,
        onboardingSetupData: action.onboardingSetupData,
      });
    default:
      return state || {};
  }
}

// SELECTORS
export const verifyInviteSucceeded = (state: State): boolean => {
  return requestSucceeded(state, VERIFY_ONBOARDING_INVITE);
};

export const verifyInviteError = (state: State): Error | undefined => {
  return requestError(state, VERIFY_ONBOARDING_INVITE);
};

// ACTION CREATORS
export const setUserInvite = (userInvite: UserInvitation): Action => {
  return {
    type: SET_USER_INVITE,
    userInvite,
  };
};

const setOnboardingSetupDataForStage = (
  onboardingStage: OnboardingStage,
  onboardingSetupData: OnboardingSetupData,
): Action => {
  return {
    type: SET_ONBOARDING_SETUP,
    onboardingStage,
    onboardingSetupData,
  };
};

// THUNKS
export const signUpWithLink = (formData: any): Thunk => {
  persistStateInLocalStorage({
    authentication: { authToken: formData.authorizationCode },
  });
  return (dispatch) => {
    instance
      .post('/onboarding/organization-users', formData)
      .then((res) => {
        dispatch(
          authenticate(res.data.email, formData.password, undefined, SignupType.LINK),
        );
      })
      .catch((error) => {
        console.error('Error with link sign up.', error);
      });
  };
};

export const trialSignUp = (formData: SignupData): Thunk => {
  persistStateInLocalStorage({
    authentication: { authToken: formData.authorizationCode },
  });
  return (dispatch) => {
    instance
      .post('/onboarding/trial', formData)
      .then((res) => {
        Analytics.trackTrialSignUpComplete(formData);
        dispatch(
          authenticate(res.data.email, formData.password, undefined, SignupType.LINK),
        );
      })
      .catch(() => {
        Analytics.trackTrialRequestError({ step: 2, ...formData });
      });
  };
};

export const verifyOnboardingInvite = (authToken: string): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(VERIFY_ONBOARDING_INVITE));
    persistStateInLocalStorage({ authentication: { authToken } });
    instance
      .get('/onboarding/signup-invite')
      .then((res) => {
        dispatch(requestSuccess(VERIFY_ONBOARDING_INVITE));
        dispatch(setUserInvite(res.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          let message;
          if (error.response.data.message === 'email already in use') {
            message =
              'This email is already in use. Please click "Back to Login" to log in, or contact support@pluralpolicy.com.';
          } else {
            message =
              'This registration link has expired. Please click “Back to Login” to log in, or contact support@pluralpolicy.com.';
          }
          dispatch(requestFailure(VERIFY_ONBOARDING_INVITE, { ...error, message }));
          dispatch(setAuthErrorMessage(message));
        } else {
          dispatch(requestFailure(VERIFY_ONBOARDING_INVITE, error));
        }
      });
  };
};

export const getUserOnboardingStage = (
  action?: 'current' | 'previous',
  stage?: string,
): Thunk => {
  return (dispatch) => {
    const query = [];
    if (action) {
      query.push(`action=${action}`);
    }
    if (stage) {
      query.push(`onboardingStage=${stage}`);
    }
    instance
      .get(`/onboarding?${query.join('&')}`)
      .then((res) => {
        const { data } = res;
        dispatch(setOnboardingSetupDataForStage(data.state, data.data));
      })
      .catch((error) => {
        console.error('Error getting registration state', error);
        const message = createAuthErrorMessage(error);
        dispatch(setAuthErrorMessage(message));
      });
  };
};

export const getPreviousUserOnboardingStage = (stage?: OnboardingStage): Thunk => {
  return (dispatch) => {
    dispatch(getUserOnboardingStage('previous', stage));
  };
};

export const updateUserOnboarding = (
  onboardingStage: OnboardingStage,
  formData: OnboardingUserData,
  prevOrgUser?: OrganizationUser,
): Thunk => {
  return (dispatch) => {
    const payload = { onboardingStage, ...formData };
    instance
      .put(`/onboarding/next-step`, payload)
      .then((res) => {
        const { data } = res;
        dispatch(setOnboardingSetupDataForStage(data.state, data.data));
        const { orgUser } = data.data;
        dispatch(setOrganizationUser(orgUser));
        if (onboardingStage === OnboardingStage.ACCOUNT) {
          Analytics.trackOnboardingAccountComplete(orgUser);
        } else if (
          onboardingStage === OnboardingStage.NOTIFICATION &&
          prevOrgUser &&
          !isEqual(
            omit(orgUser.settings, ['updatedAt']),
            omit(prevOrgUser.settings, ['updatedAt']),
          )
        ) {
          Analytics.trackNotificationSettingUpdated(orgUser);
        }
      })
      .catch((error) => {
        console.error('Error updating registration', error);
        const message = createAuthErrorMessage(error);
        dispatch(setAuthErrorMessage(message));
      });
  };
};
