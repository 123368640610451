import { JurisdictionWithAccess } from './JurisdictionWithAccess';
import { Team } from './Team';

export const STARTER_ORG_DEFAULT_SEATS = 5;

export enum AccountType {
  Pro = 'pro',
  Free = 'free',
  Trial = 'trial',
  Starter = 'basic',
  FreeLegacy = 'free-legacy',
  Demo = 'demo',
}

export type OrganizationAccount = {
  id: number;
  seats: number;
  authorizationCode: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Organization = {
  id: number;
  name: string;
  organizationAccountId?: number;
  defaultTeamId: number;
  suspended?: boolean;
  accountStatus: AccountType;
  mfaRequired?: boolean;
  organizationAccount: OrganizationAccount;
  team?: Team;
  jurisdictions: JurisdictionWithAccess[];
  createdAt: string;
  updatedAt: string;
  accountStatusUpdatedAt: Date;
  users: number;
  hasReadOnlyAccess: boolean;
};

export type IPAuthorization = {
  id: number;
  cidrBlock: string;
  organization: Organization;
  hasReadOnlyAccess?: boolean;
};
