import React from 'react';

export default (props) => (
  <svg
    onClick={props.onClick ? props.onClick : undefined}
    className="edit-icon"
    width="14px"
    height="14px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>{props.title || 'Edit'}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-987.000000, -303.000000)">
        <g transform="translate(984.000000, 300.000000)">
          <g>
            <path
              d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
              id="Shape"
              fill="#979797"
            />
            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
