import React, { ReactElement } from 'react';
import Logo from '../../components/Logo';
import './PrimaryView.scss';

const NavigationBar = (props: { logoLinkHref?: string }): ReactElement => {
  const { logoLinkHref } = props;
  // TODO: use this file for future NavBar refactors,
  // conditionally render based on what user should see
  // (logged in vs logged out vs public)
  return (
    <nav className="d-flex flex-row ce-nav">
      <div className="branding">
        {logoLinkHref ? (
          <a href={logoLinkHref}>
            <Logo />
          </a>
        ) : (
          <Logo />
        )}
      </div>
      <div className="help-link">
        <a href="https://help.pluralpolicy.com/" rel="noreferrer">
          Help
        </a>
      </div>
    </nav>
  );
};

export default NavigationBar;
