import { Bill } from '@enview/interface/types/bills/Bill';
import { faFile } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BillAPI } from '../../../../api';
import { getTeamMode, getTeamModeTeam } from '../../../../dux';
import { getBillAlias, getBillTitle } from '../../../../helpers/BillHelper';
import { SortBy, SortOrder } from '../../../../models/SortOrder';
import { sortBillsBySortLabel } from '../../../../utils';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';

const BILL_DETAILS_PATH = '/legislative-tracking/bill/details/';
const BILL_SORT_ORDER = new SortOrder(SortBy.ALPHANUMERICAL, false);
const NO_TRACKED_BILLS_MESSAGE = 'No tracked bills';

const BillsSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;
  const teamModeId = useSelector(getTeamMode);
  const team = useSelector(getTeamModeTeam);
  const { data: trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined);

  const trackedBillsByTeam = useMemo<{ [teamId: number]: Bill[] }>(() => {
    if (!trackedBills) return {};
    const trackedBillsData: { [teamId: number]: Bill[] } = {};
    trackedBills.forEach((bill: Bill) => {
      if (!bill.teams) return;
      bill.teams.forEach((t) => {
        if (!trackedBillsData[t.id]) {
          trackedBillsData[t.id] = [bill];
        } else {
          trackedBillsData[t.id].push(bill);
        }
      });
    });
    return trackedBillsData;
  }, [trackedBills]);
  const bills = (team ? trackedBillsByTeam[team.id] : []) || [];

  const sortedBills: Bill[] = sortBillsBySortLabel(bills, BILL_SORT_ORDER);
  const mappedBills =
    sortedBills.length === 0 ? (
      <div className="tracked-bill-empty">{NO_TRACKED_BILLS_MESSAGE}</div>
    ) : (
      sortedBills.map((bill) => {
        const { session, number, userAliases } = bill;
        const billAlias = getBillAlias(userAliases, teamModeId);
        const title = getBillTitle(session, number, billAlias);
        const billName = billAlias
          ? `${bill.number} (${billAlias.alias})`
          : bill.number;
        const tooltip = `${title} - ${bill.name}${
          billAlias ? ` (${billAlias.alias})` : ''
        }`;
        const { state } = bill.session;
        return (
          <div key={bill.id}>
            <NavLink
              activeClassName="active-nav-link"
              className="d-flex sub-item"
              onClick={onNavClick}
              to={`${BILL_DETAILS_PATH}${bill.id}`}
            >
              <span id={bill.id}>
                <span>
                  {state.abbreviation === 'usa'
                    ? 'FED'
                    : state.abbreviation.toUpperCase()}
                </span>
                <span>&nbsp;</span>
                <span>{billName}</span>
                <br />
              </span>
              <ReactTooltip
                anchorId={bill.id}
                className="link-tooltip tooltip-content"
                delayShow={200}
                place="right"
              >
                {tooltip}
              </ReactTooltip>
            </NavLink>
          </div>
        );
      })
    );

  const getCardBody = (): ReactElement => {
    return <>{mappedBills}</>;
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      eventKey="BILLS"
      header="Bills"
      icon={<FontAwesomeIcon icon={faFile as IconProp} />}
    />
  );
};

export default BillsSidebarSection;
