import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as common from './locales/en.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common,
  },
} as const;

void i18next.use(initReactI18next).init({
  lng: 'en', // TODO: Get from browser or language selector
  defaultNS,
  resources,
  returnNull: false,
});

export default i18next;
