import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '../../../components/svg/CloseIcon';
import './Page.scss';

type ExpandableGridCardProps = {
  image: ReactElement;
  title: string | ReactElement;
  data: {
    [label: string]:
      | (string | ReactElement)
      | { [label: string]: string | ReactElement };
  };
  onClose?: () => void;
  overflowMenu?: ReactElement;
  tagLabel?: string;
  link?: string;
};

const ExpandableGridCard = (props: ExpandableGridCardProps): ReactElement => {
  const { image, title, data, onClose, overflowMenu, tagLabel, link } = props;
  const [expanded, setExpanded] = useState(false);
  const [showViewMoreLink, setShowViewMoreLink] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current) {
      const contentHeight = cardRef.current.scrollHeight;
      setShowViewMoreLink(contentHeight > 450);
    }
  }, [data, cardRef]);

  const cardClass = `expandable-card ${expanded ? 'expanded' : ''}`;

  const getTopRightContent = (): ReactElement => {
    if (overflowMenu) {
      return <div className="row ml-auto overflow-menu">{overflowMenu}</div>;
    }
    if (onClose && !tagLabel) {
      return (
        <div className="row" onClick={onClose} style={{ marginTop: '-10px' }}>
          <CloseIcon className="ml-auto" />
        </div>
      );
    }
    return <></>;
  };

  const getRightHandTagLabel = (): ReactElement => {
    if (tagLabel && !overflowMenu) {
      return <div className="grid-card-tag">{tagLabel}</div>;
    }
    return <></>;
  };

  const getOverflowTagLabel = (): ReactElement => {
    if (overflowMenu && tagLabel)
      return (
        <div className="row">
          <div className="overflow-col col">
            <div className="grid-card-tag">{tagLabel}</div>
          </div>
        </div>
      );
    return <></>;
  };

  const viewMoreText = useMemo(() => {
    if (expanded)
      return (
        <>
          {t('buttons.viewLessText')} <FontAwesomeIcon icon={faCaretUp} />
        </>
      );
    return (
      <>
        {t('buttons.viewMoreText')} <FontAwesomeIcon icon={faCaretDown} />
      </>
    );
  }, [expanded]);

  return (
    <div className="grid-card card expandable-card-container">
      <div className={`card-content ${cardClass}`} ref={cardRef}>
        {showViewMoreLink && !expanded && <div className="overlay-fade"></div>}
        {getTopRightContent()}
        <div className="row m-0">
          <div className="mr-auto">{image}</div>
          <div>{getRightHandTagLabel()}</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card-name">
              {link ? (
                <Link to={{ pathname: link, state: { fromPath: location.pathname } }}>
                  {title}
                </Link>
              ) : (
                <div>{title}</div>
              )}
            </div>
          </div>
        </div>
        {getOverflowTagLabel()}
        {Object.entries(data).map(([rowLabel, rowValue]) => (
          <div className="row" key={rowLabel}>
            {typeof rowValue === 'string' ? (
              <div className="col">
                <div className="title card-label">{rowLabel.toUpperCase()}</div>
                <div className="card-value">{rowValue}</div>
              </div>
            ) : (
              <div className="col">
                <div className="title card-label">{rowLabel.toUpperCase()}</div>
                <div className="card-value">{rowValue}</div>
              </div>
            )}
          </div>
        ))}
      </div>
      {showViewMoreLink && (
        <div className="expandable-card-footer">
          <a href="#" onClick={() => setExpanded(!expanded)}>
            <span>{viewMoreText}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default ExpandableGridCard;
