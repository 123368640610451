import { UserSettings } from '../OrganizationUser';
import BillSearchView from '../bills/BillSearchView';

export enum WorkspaceNotifications {
  BILL_REAL_TIME = 'realTimeBillNotification',
  BILL_DAILY = 'dailyBillNotification',
  BILL_WEEKLY = 'weeklyBillNotification',
  SEARCH_HOURLY = 'hourlySearchNotification',
  SEARCH_DAILY = 'dailySearchNotification',
  SEARCH_WEEKLY = 'weeklySearchNotification',
  COLLABORATION = 'collaborationNotification',
  MENTION = 'mentionNotification',
}

export interface UserWorkspaceSettings {
  organizationUserId: number;
  teamId: number;
  billSearchView?: BillSearchView;
  [WorkspaceNotifications.BILL_REAL_TIME]?: boolean | null;
  [WorkspaceNotifications.BILL_DAILY]?: boolean | null;
  [WorkspaceNotifications.BILL_WEEKLY]?: boolean | null;
  [WorkspaceNotifications.SEARCH_HOURLY]?: boolean | null;
  [WorkspaceNotifications.SEARCH_DAILY]?: boolean | null;
  [WorkspaceNotifications.SEARCH_WEEKLY]?: boolean | null;
  [WorkspaceNotifications.COLLABORATION]?: boolean | null;
  [WorkspaceNotifications.MENTION]?: boolean | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AllNotificationSettingsResponse {
  userSettings: UserSettings;
  userWorkspaceSettings: UserWorkspaceSettings[];
}
