import { Jurisdiction } from '@enview/interface/types/Jurisdiction';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { PolicyArea } from '@enview/interface/types/PolicyArea';
import BillSearchView from '@enview/interface/types/bills/BillSearchView';
import { AccountSetupData } from '../../models/Onboarding';
import { PagerMeta } from '../../models/PagerMeta';

// ACTIONS
export const SET_SUPPORTED_JURISDICTIONS = 'account/setSupportedJurisdictions';
export const SET_ORGANIZATION_USER = 'account/setOrganizationUser';
export const SET_ORGANIZATION_USERS = 'account/setOrganizationUsers';
export const SET_ARBITRARY_ORGANIZATION_USER = 'account/setArbitraryOrganizationUser';
export const REMOVE_ORGANIZATION_USER = 'account/removeOrganizationUser';
export const SET_ACCOUNT_SETUP_DATA = 'account/setAccountSetupData';
export const SET_NOTIFICATIONS = 'account/setNotifications';
export const SET_BILL_SEARCH_VIEW = 'account/setBillSearchView';

export type AccountState = {
  organizationUser?: OrganizationUser;
  organizationUsers?: OrganizationUser[];
  supportedJurisdictions?: Jurisdiction[];
  accountSetupData?: AccountSetupData;
  notifications: {
    data: any[];
    meta: PagerMeta;
  };
  billSearchView?: BillSearchView;
};

type SetSupportedJurisdictions = {
  type: typeof SET_SUPPORTED_JURISDICTIONS;
  jurisdictions: Jurisdiction[];
};

type RemoveOrganizationUser = {
  type: typeof REMOVE_ORGANIZATION_USER;
  organizationUserId: number;
};

type SetOrganizationUser = {
  type: typeof SET_ORGANIZATION_USER;
  organizationUser: OrganizationUser;
  policyAreas?: PolicyArea[];
};

type SetArbitraryOrganizationUser = {
  type: typeof SET_ARBITRARY_ORGANIZATION_USER;
  organizationUser: OrganizationUser;
};

type SetOrganizationUsers = {
  type: typeof SET_ORGANIZATION_USERS;
  organizationUsers: OrganizationUser[];
};

type SetAccountSetupData = {
  type: typeof SET_ACCOUNT_SETUP_DATA;
  accountSetupData: AccountSetupData;
};

type SetNotifications = {
  type: typeof SET_NOTIFICATIONS;
  notifications: { data: []; meta: PagerMeta };
};

type SetBillSearchView = {
  type: typeof SET_BILL_SEARCH_VIEW;
  billSearchView: BillSearchView;
};

export type Action =
  | SetSupportedJurisdictions
  | SetOrganizationUser
  | SetArbitraryOrganizationUser
  | RemoveOrganizationUser
  | SetOrganizationUsers
  | SetAccountSetupData
  | SetNotifications
  | SetBillSearchView;
