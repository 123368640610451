import { SearchResult } from '@enview/interface/types/BillSearch';
import { Person } from '@enview/interface/types/Person';
import Staff from '@enview/interface/types/persons/Staff';
import { StaffDirectorySearchParams } from '@enview/interface/types/persons/StaffDirectorySearchParams';
import PersonVote from '@enview/interface/types/votes/PersonVote';
import queryString from 'query-string';
import { SortByParam } from '../models/SortOrder';
import base, { ApiTag } from './base';

const personAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getPerson: build.query<Person, number>({
      query: (param) => ({ url: `/persons/${param}`, method: 'GET' }),
      providesTags: (result) => [{ type: ApiTag.PERSON_TAG, id: result?.id }],
    }),
    getPersonsByJurisdiction: build.query<Person[], string>({
      query: (jurisAbbr) => ({
        url: `/persons?jurisdiction=${jurisAbbr}`,
        method: 'GET',
      }),
    }),
    getPersonVotes: build.query<
      SearchResult<PersonVote>,
      {
        personId: number;
        searchParams: {
          sessionIds?: number[] | number;
          includeCurrentSessions?: boolean;
          jurisdiction?: string;
          sortOrder?: SortByParam;
          pagination: {
            page: number;
            pageSize: number;
          };
        };
      }
    >({
      query: (params) => ({
        url: `/persons/${params.personId}/votes?${queryString.stringify({
          sessionIds: getSessionIds(params.searchParams.sessionIds),
          includeCurrentSessions: params.searchParams.includeCurrentSessions,
          jurisdiction: params.searchParams.jurisdiction,
          sortByParam: params.searchParams.sortOrder,
          limit: params.searchParams.pagination.pageSize,
          offset: getOffset(params.searchParams.pagination),
        })}`,
        method: 'GET',
      }),
    }),
    getPersonStaff: build.query<
      SearchResult<Staff>,
      {
        personId: number;
        params: StaffDirectorySearchParams;
      }
    >({
      query: (args) => ({
        url: `/persons/${args.personId}/staff?${queryString.stringify({
          keywords: args.params.criteria.keywords,
          // TODO: Add pagination as necessary
          limit: args.params?.paging?.limit,
          offset: args.params?.paging?.offset,
        })}`,
        method: 'GET',
      }),
    }),
    getCommitteeStaff: build.query<
      SearchResult<Staff>,
      {
        committeeId: number;
        params: StaffDirectorySearchParams;
      }
    >({
      query: (args) => ({
        url: `/committees/${args.committeeId}/staff?${queryString.stringify({
          // keywords: args.params.criteria.keywords,
          limit: args.params?.paging?.limit,
          offset: args.params?.paging?.offset,
        })}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export default personAPI;

function getSessionIds(sessionIds?: number[] | number): number[] | number | undefined {
  if (Array.isArray(sessionIds)) {
    return [...sessionIds].filter((id) => !!id && id > 0);
  }
  return sessionIds && sessionIds > 0 ? sessionIds : undefined;
}

function getOffset(pagination: { page: number; pageSize: number }): number {
  if (pagination.page && pagination.pageSize) {
    return (pagination.page - 1) * pagination.pageSize;
  }
  return 0;
}
