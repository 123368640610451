import React, { ReactElement } from 'react';
import './Page.scss';
import { PagerMeta } from '../../../models/PagerMeta';

type PaginatorProps = {
  pages: PagerMeta;
  onChange: (page: number) => void;
};

const Paginator = (props: PaginatorProps): ReactElement => {
  const { pages, onChange } = props;
  const { total, pageSize, page } = pages;

  if (total < pageSize) {
    return <></>;
  }
  const count = Math.ceil(total / pageSize);
  const min = Math.max(1, page - 4);
  const max = Math.min(page + 4, count);
  const res = [];
  let first;
  let last;
  if (min > 1)
    first = (
      <li className="page-item">
        <button className="page-link" onClick={() => onChange(1)} type="button">
          First
        </button>
      </li>
    );
  if (max < count)
    last = (
      <li className="page-item">
        <button className="page-link" onClick={() => onChange(count)} type="button">
          Last
        </button>
      </li>
    );
  for (let i = min; i <= max; i += 1) {
    res.push(
      <li className={`page-item${i === page ? ' disabled' : ''}`} key={i}>
        <button className="page-link" onClick={() => onChange(i)} type="button">
          {i}
        </button>
      </li>,
    );
  }
  return (
    <div className="row justify-content-center">
      <ul className="pagination">
        {first}
        <li className={`page-item${page === 1 ? ' disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => onChange(page - 1)}
            type="button"
          >
            Previous
          </button>
        </li>
        {res}
        <li className={`page-item${page === count ? ' disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => onChange(page + 1)}
            type="button"
          >
            Next
          </button>
        </li>
        {last}
      </ul>
    </div>
  );
};
export default Paginator;
