import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { track } from './SegmentAnalytics';

const RESOURCE_LINK_CLICK = 'Explorer Resource Link Clicked';

const trackResourceLinkClick = (
  jurisdiction: string,
  category: string,
  url: string,
  source: string,
  orgUser?: OrganizationUser,
): void => {
  track(RESOURCE_LINK_CLICK, {
    category,
    url,
    state: jurisdiction,
    jurisdiction,
    source,
    username: orgUser ? orgUser.email : 'Not Logged in',
  });
};

export default trackResourceLinkClick;
