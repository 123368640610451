import { AccountType } from '@enview/interface/types/Organization';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QuestionMarkCircle from '../../../../components/svg/QuestionMarkCircle';
import { determineTopUserAccountStatus } from '../../../../config/ability';
import { getOrgForTeamMode } from '../../../../dux';
import calculateDaysRemaining from '../../../../helpers/AccountHelper';
import UserIconDropdown from './UserIconDropdown';

type TopNavRightContentProps = {
  organizationUser?: OrganizationUser;
  isEnviewAdmin?: boolean;
  isOrgAdmin?: boolean;
};

const TopNavRightContent = (props: TopNavRightContentProps): ReactElement => {
  const { organizationUser, isEnviewAdmin, isOrgAdmin } = props;
  const teamModeOrg = useSelector(getOrgForTeamMode);
  const { t } = useTranslation();

  // There's probably a better way to deal with a link that conditionally needs to go
  // to an external URL or an internal route
  const WrapInLink = (
    userStatus: string | null,
    childNode: ReactElement,
  ): ReactElement => {
    if (userStatus === AccountType.Free || userStatus === AccountType.Starter) {
      return (
        <a href={t('urls.pricingPage')} target="_blank">
          {childNode}
        </a>
      );
    } else {
      return <Link to="/create-account">{childNode}</Link>;
    }
  };

  const getFreeStarterLoggedOutButtons = (userStatus: string | null): ReactElement => {
    return (
      <>
        <span className="top-nav-item desktop-nav-item">
          {userStatus !== AccountType.Starter && (
            <a href={t('urls.scheduleDemo')} rel="noopener noreferrer" target="_blank">
              <button className="btn btn-white demo-button" type="button">
                {t('primaryLayout.requestDemo')}
              </button>
            </a>
          )}
        </span>
        <span className="top-nav-item desktop-nav-item">
          {WrapInLink(
            userStatus,
            <button className="btn btn-orange" type="button">
              {userStatus
                ? t('primaryLayout.upgrade')
                : t('primaryLayout.createAccount')}
            </button>,
          )}
        </span>
      </>
    );
  };

  const getFreeTrialButtons = (): ReactElement => {
    const daysRemaining = !teamModeOrg
      ? 0
      : calculateDaysRemaining(teamModeOrg.accountStatusUpdatedAt);
    return (
      <>
        <span className="top-nav-item desktop-nav-item trial-message">
          <Trans
            i18nKey="primaryLayout.daysRemaining"
            values={{ daysRemaining: daysRemaining }}
          />
        </span>
        <span className="top-nav-item desktop-nav-item">
          <a href={t('urls.scheduleDemo')} rel="noopener noreferrer" target="_blank">
            <button className="btn btn-orange demo-button" type="button">
              {t('primaryLayout.contactSales')}
            </button>
          </a>
        </span>
      </>
    );
  };

  const getNavActionButtonGroup = (): ReactElement => {
    const userStatus = determineTopUserAccountStatus(organizationUser);
    if (
      !userStatus ||
      userStatus == AccountType.Free ||
      userStatus == AccountType.FreeLegacy ||
      userStatus == AccountType.Starter
    )
      return getFreeStarterLoggedOutButtons(userStatus);
    else if (userStatus == AccountType.Trial) return getFreeTrialButtons();
    else return <></>;
  };

  return (
    <div className="top-right-nav right-nav">
      {getNavActionButtonGroup()}
      <span className="nav-icon top-nav-item desktop-nav-item help-icon">
        <a href={t('urls.helpSite')} rel="noopener noreferrer" target="_blank">
          <QuestionMarkCircle />
        </a>
      </span>
      {organizationUser && (
        <UserIconDropdown
          isEnviewAdmin={isEnviewAdmin}
          isOrgAdmin={isOrgAdmin}
          organizationUser={organizationUser}
        />
      )}
      {!organizationUser && (
        <Link className="top-nav-link" to="/login">
          {t('primaryLayout.logIn')}
        </Link>
      )}
    </div>
  );
};

export default TopNavRightContent;
