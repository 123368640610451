import React from 'react';

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1858_833)">
      <path
        d="M14.4342 0C14.2551 0 14.072 0.034375 13.8907 0.11L0.897921 6.10656C-0.601141 6.80625 -0.101453 9.005 1.49761 9.005H6.9948V14.5022C6.9948 15.44 7.75136 16 8.51792 16C9.05855 16 9.60386 15.7219 9.89323 15.1019L15.8901 2.10875C16.3142 1.09031 15.4417 0 14.4342 0ZM8.49386 14.5022V7.50594H6.9948L1.51105 7.52812C1.50261 7.51656 1.49636 7.48594 1.52605 7.46781L14.4389 1.50812C14.4579 1.52094 14.4789 1.54156 14.4917 1.56062L8.49386 14.5022Z"
        fill="#27A4CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1858_833">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
