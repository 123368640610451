import React from 'react';
import Modal from 'react-modal';

import './Modal.scss';
import CloseIcon from '../../../components/svg/CloseIcon';

type ModalCardProps = {
  title: string;
  show: boolean;
  content: React.ReactElement;
  closeModal?: () => void;
};

const ModalCard = (props: ModalCardProps) => {
  const { closeModal, title, content, show } = props;

  return (
    <Modal
      className="react-modal"
      closeTimeoutMS={100}
      contentLabel="Modal"
      isOpen={show}
      onAfterOpen={() => {}}
      onRequestClose={() => closeModal}
      overlayClassName="react-modal-overlay"
    >
      <div className="content">
        <div className="flex-row">
          {closeModal && (
            <CloseIcon className="float-right" fill="#7F8FA4" onClick={closeModal} />
          )}
        </div>
        <div className="flex-row title">{title}</div>
        {content}
      </div>
    </Modal>
  );
};

export default ModalCard;
