const getMapboxToken = (): string | null | undefined => {
  const token = document
    ?.querySelector('[property="mapbox-token"]')
    ?.getAttribute('content');

  // Return undefined/null if not found
  // This will cause the map to not render locally but won't break browser tests
  return token;
};

export default getMapboxToken;
