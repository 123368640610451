import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { OptionType } from '../DropDowns/SingleSelectDropDown';

export type SingleSelectOption<T> = {
  label: string;
  value: T;
  section?: SectionHeader;
};

export type SectionHeader = string;

type SingleSelectFormProps<T> = {
  currentSelection: T;
  options: SingleSelectOption<T>[];
  singleColumn?: boolean;
  updateSelection: (values: T) => void;
};

const SingleSelectForm = <T extends any>(
  props: SingleSelectFormProps<T>,
): ReactElement => {
  const { currentSelection, options, singleColumn, updateSelection } = props;
  const columnStyle =
    singleColumn || options.length < 10 ? { columnWidth: 'revert' } : {};

  const [selectedValue, setSelectedValue] = useState(currentSelection);

  useEffect(() => {
    setSelectedValue(currentSelection);
  }, [currentSelection]);

  const handleRadioButtonEvent = (
    event: ChangeEvent<HTMLInputElement>,
    option: OptionType<T>,
  ): void => {
    const newSelectedOption = option.value;
    setSelectedValue(newSelectedOption);
    updateSelection(newSelectedOption);
  };

  return (
    <div className="d-flex flex-column multi-select-form">
      <div className="options" style={columnStyle}>
        {options.map((option, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="option" key={`${option.label}-${index}`}>
              <input
                checked={selectedValue === option.value}
                className="checkbox"
                id={`single-select-form-${option.label}`}
                name={option.label}
                onChange={(e) => handleRadioButtonEvent(e, option)}
                type="radio"
              />
              <label
                className="option-label"
                htmlFor={`single-select-form-${option.label}`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleSelectForm;
