import { Jurisdiction } from '@enview/interface/types/Jurisdiction';
import JurisdictionAbbreviationMap from '@enview/interface/types/JurisdictionAbbreviationMap';
import { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getJurisdictionsForTeamMode } from '../../../dux';
import { OptionType } from '../../Shared/DropDowns/SingleSelectDropDown';

type JurisdictionPageDropdownProps = {
  jurisAbbr?: keyof typeof JurisdictionAbbreviationMap;
};

const JurisdictionPageDropdown = (
  props: JurisdictionPageDropdownProps,
): ReactElement => {
  const { jurisAbbr } = props;
  const orgJurisdictions = useSelector(getJurisdictionsForTeamMode);
  const history = useHistory();
  const { t } = useTranslation();

  const getJurisdictionOptions = (): OptionType<Jurisdiction>[] => {
    const options = orgJurisdictions
      .filter(
        (jurisdiction) =>
          !jurisdiction.abbreviation.includes('ng') &&
          !jurisdiction.abbreviation.includes('za'),
      )
      .map((jurisdiction) => ({
        label: jurisdiction.name,
        value: jurisdiction,
        id: jurisdiction.abbreviation,
      }));
    return options.sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleJurisdictionChange = (value: string): void => {
    history.push(t('jurisdictions.urls.pathPrefix', { jurisAbbr: value }));
  };

  const getCurrentJurisdiction = (): Jurisdiction | undefined => {
    const selected = orgJurisdictions.find(
      (jurisdiction) => jurisdiction.abbreviation.toUpperCase() === jurisAbbr,
    );
    return selected;
  };

  return (
    <div className="jurisdiction-page-dropdown">
      <div className="trapezoidal-banner">
        <h5>Jurisdiction:</h5>
        <Form.Group>
          <Form.Control
            as="select"
            className="jursidiction-select"
            custom
            defaultValue={getCurrentJurisdiction()?.abbreviation}
            onChange={(event) => handleJurisdictionChange(event.target.value)}
          >
            <option key={'default'} value="">
              {t('jurisdictions.dropdown.defaultText')}
            </option>
            {getJurisdictionOptions().map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    </div>
  );
};

export default JurisdictionPageDropdown;
