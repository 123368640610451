import { BillStatus } from '../types/Bill';

export const translateBillStatus = (
  status: BillStatus | null,
  jurisdictionAbbreviation: string,
): string => {
  let translatedStatus: BillStatus;
  if (status === null) {
    translatedStatus = BillStatus.INTRODUCED; // when in doubt, fall back to introduced
  } else {
    translatedStatus = status;
  }
  // TODO in the future we should pass in jurisdiciton, use jurisdiction-aware chamber name strings
  const map = {
    [BillStatus.LAW]: 'Became Law',
    [BillStatus.GOVERNOR]: ['za', 'ng', 'usa', 'dc'].includes(jurisdictionAbbreviation)
      ? 'Executive Signature'
      : 'Signed by Governor',
    [BillStatus.INTRODUCED]: 'Introduced',
    [BillStatus.PASSED]: 'Passed',
    [BillStatus.PASSED_LOWER]: 'Passed Lower', // TODO see above
    [BillStatus.PASSED_UPPER]: 'Passed Upper', // TODO see above
    [BillStatus.FIRST_READING]: 'First Reading',
    [BillStatus.SECOND_READING]: 'Second Reading',
    [BillStatus.THIRD_READING]: 'Third Reading',
    [BillStatus.REFERRAL_COMMITTEE]: 'Referred to Committee',
    [BillStatus.REPORTED_OUT_OF_COMMITTEE]: 'Reported out of Committee',
    [BillStatus.CONCURRENCE]: 'Sent for Concurrence',
    [BillStatus.WITHDRAWAL]: 'Withdrawn',
    [BillStatus.VETOED]: 'Vetoed',
  };
  if (map[translatedStatus]) {
    return map[translatedStatus];
  }
  // TODO: throw any kind of error here due to unexpected status?
  return translatedStatus;
};

// These strings are used to evaluate which actions are "Key Actions"
// in Illinois. The matching logic is based on "starts with", IE does the
// given string begin with one of these key-action strings
export const IllinoisKeyActions = [
  'First Reading',
  'Second Reading',
  'Third Reading - Passed',
  'Third Reading - Short Debate - Passed',
  'Item/Reduction Veto Stands',
  'Pursuant to Senate Rule 3-9(b) / Referred to Assignments',
  'Public Act',
  'Rule 19(a) / Re-referred to Rules Committee',
  'Total Veto Stands',
  'Bill Dead',
  'Rule 3-9(a) / Re-referred to Assignments',
  'Rule 19(b) / Re-referred to Rules Committee',
];
