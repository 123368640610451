import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PlacesType } from 'react-tooltip';
import ArrowDown from '../../../components/svg/ArrowDown';
import { g as abilityGlossary } from '../../../config/ability';
import { checkPermission, getCurrentSidebarToggleSection } from '../../../dux';
import { Thunk } from '../../../dux/@types';
import {
  getPreviousSidebarToggleSection,
  setCurrentSidebarToggleSection,
  setPreviousSidebarToggleSection,
} from '../../../dux/NavDux';
import GateTooltip from '../../Shared/Tooltips/TooltipGate';

type NavSidebarSectionProps = {
  header: string;
  icon: ReactElement;
  eventKey: string;
  cardBody?: ReactElement;
  customGateTooltipContent?: ReactElement | string;
};

const NavSidebarSection = (props: NavSidebarSectionProps): ReactElement => {
  const { header, icon, eventKey, cardBody, customGateTooltipContent } = props;
  const dispatch = useDispatch();
  const currentEventKey = useSelector(getCurrentSidebarToggleSection);
  const previousEventKey = useSelector(getPreviousSidebarToggleSection);
  const [closeSameSection, setCloseSameSection] = useState(false);
  const [tooltipPlacement, setTooltipPlacement] = useState<PlacesType>('right');
  const sidebarEventKey = `SIDEBAR_${eventKey ?? 0}`;

  const canViewSection = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(
        abilityGlossary.VIEW,
        abilityGlossary[sidebarEventKey as keyof typeof abilityGlossary],
        true,
      ),
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newPlace = screenWidth <= 768 ? 'top' : 'right';
      setTooltipPlacement(newPlace as PlacesType);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAccordionToggle = (key: string): void => {
    dispatch(setPreviousSidebarToggleSection(currentEventKey));
    dispatch(setCurrentSidebarToggleSection(key));

    if (currentEventKey === key && !closeSameSection) setCloseSameSection(true);
    else setCloseSameSection(false);
  };

  const classes = useMemo(() => {
    let result = '';
    const selectedSameSection =
      previousEventKey &&
      currentEventKey &&
      previousEventKey === currentEventKey &&
      previousEventKey === eventKey;
    const closeSection = previousEventKey && previousEventKey === eventKey;
    const openSection = currentEventKey && currentEventKey === eventKey;

    if (selectedSameSection) {
      result = closeSameSection ? 'accordion-close' : 'accordion-open';
    } else if (closeSection) {
      result = 'accordion-close';
    } else if (openSection) {
      result = 'accordion-open';
    }

    return `${!canViewSection() ? 'disabled' : ''} ${result}`;
  }, [currentEventKey, previousEventKey, eventKey, closeSameSection, canViewSection]);

  return (
    <span id={sidebarEventKey}>
      <Card className={classes}>
        <Accordion.Toggle
          as={Card.Header}
          className="d-flex flex-row"
          eventKey={eventKey}
          onClick={() => handleAccordionToggle(eventKey)}
        >
          <span className="sidebar-icon">{icon}</span>
          <h2 className="title">{header}</h2>
          <span className="sidebar-caret">{<ArrowDown />}</span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{cardBody}</Card.Body>
        </Accordion.Collapse>
      </Card>
      {!canViewSection() && (
        <GateTooltip
          accountType="pro"
          anchorId={sidebarEventKey}
          customContent={customGateTooltipContent}
          place={tooltipPlacement}
        />
      )}
    </span>
  );
};

export default NavSidebarSection;
