import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../Form.scss';
import '../../../Registration/Registration.scss';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { updateAccountSetupData, updateUserOnboarding } from '../../../../dux';
import {
  AccountSetupData,
  AccountOnboardingUserData,
} from '../../../../models/Onboarding';
import MultipleSelectDropDown from '../../DropDowns/MultipleSelectDropDown';
import SingleSelectDropDown from '../../DropDowns/SingleSelectDropDown';
import {
  getHelpLevelNeededOptions,
  getLegislativeWorkExperienceOptions,
  getOrganizationTypesOptions,
  getPolicyAreasOptions,
  getReasonsForUsingEnviewOptions,
  OnboardingStage,
} from '../../../Registration/RegistrationHelper';

type AccountOnboardingProps = {
  accountSetupData?: AccountSetupData;
  isOnboarding?: boolean;
};

const ACCOUNT_FORM_LABELS = {
  jobTitle: {
    fieldName: 'jobTitle',
  },
  organizationType: {
    fieldName: 'organizationType',
    title: 'Organization type',
    description:
      'How would you best describe the organization you track policy for? This will help Plural get you started in the best way.',
  },
  policyAreas: {
    fieldName: 'policyAreaIds',
    title: 'Issues',
    description:
      'We can send you recommended bills to view based on your preferences. You can update this in your account settings.',
  },
  reasonsForUsingEnview: {
    fieldName: 'reasonsForUsingEnview',
    title: 'Your goals',
    description:
      'How will you be using Plural? This will help us point you in the right direction.',
  },
  helpLevelNeeded: {
    fieldName: 'helpLevelNeeded',
  },
  legislativeWorkExperience: {
    fieldName: 'legislativeWorkExperience',
  },
};

const ERROR_MULTI_SELECT = 'Please choose at least one option.';
const ERROR_SINGLE_SELECT = 'Please choose one option.';

const Account = (props: AccountOnboardingProps) => {
  const { accountSetupData, isOnboarding } = props;
  // Needs upgrade to at least react-hook-form@^7.0.0
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, setValue, errors, setError, clearErrors } = useForm();
  const orgUser = accountSetupData && accountSetupData.orgUser;
  const prevOrgUser = useRef<OrganizationUser>();

  const [organizationType, setOrganizationType] = useState<string>('');
  const [legislativeWorkExperience, setLegislativeWorkExperience] =
    useState<string>('');
  const [helpLevelNeeded, setHelpLevelNeeded] = useState<string>('');
  const [policyAreaIds, setPolicyAreaIds] = useState<number[]>([]);
  const [reasonsForUsingEnview, setReasonsForUsingEnview] = useState<string[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    register(ACCOUNT_FORM_LABELS.organizationType.fieldName, {
      required: ERROR_SINGLE_SELECT,
    });
    register(ACCOUNT_FORM_LABELS.policyAreas.fieldName, {
      required: ERROR_MULTI_SELECT,
    });
    register(ACCOUNT_FORM_LABELS.reasonsForUsingEnview.fieldName, {
      required: ERROR_MULTI_SELECT,
    });
    register(ACCOUNT_FORM_LABELS.helpLevelNeeded.fieldName, {
      required: ERROR_SINGLE_SELECT,
    });
    register(ACCOUNT_FORM_LABELS.legislativeWorkExperience.fieldName, {
      required: ERROR_SINGLE_SELECT,
    });
  }, [register]);

  const onSubmit = (data: AccountOnboardingUserData) => {
    if (isOnboarding) {
      dispatch(updateUserOnboarding(OnboardingStage.ACCOUNT, data));
    } else if (orgUser) {
      dispatch(updateAccountSetupData(data, orgUser));
    }
  };

  const setSingleSelectValue = useCallback(
    (name: string, value: string) => {
      setValue(name, value);
      clearErrors(name);
      switch (name) {
        case ACCOUNT_FORM_LABELS.organizationType.fieldName:
          setOrganizationType(value);
          break;
        case ACCOUNT_FORM_LABELS.legislativeWorkExperience.fieldName:
          setLegislativeWorkExperience(value);
          break;
        case ACCOUNT_FORM_LABELS.helpLevelNeeded.fieldName:
          setHelpLevelNeeded(value);
          break;
        default:
      }
    },
    [setValue, clearErrors],
  );

  const setMultiSelectValue = useCallback(
    (name: string, selectedOptions: any[]) => {
      setValue(name, selectedOptions);
      if (selectedOptions.length >= 1) clearErrors(name);
      else
        setError(name, {
          type: 'required',
          message: 'Please choose at least one option.',
        });

      switch (name) {
        case ACCOUNT_FORM_LABELS.policyAreas.fieldName:
          setPolicyAreaIds(selectedOptions);
          break;
        case ACCOUNT_FORM_LABELS.reasonsForUsingEnview.fieldName:
          setReasonsForUsingEnview(selectedOptions);
          break;
        default:
      }
    },
    [setValue, setError, clearErrors],
  );

  useEffect(() => {
    if (
      orgUser &&
      (!prevOrgUser.current || orgUser.updatedAt !== prevOrgUser.current.updatedAt)
    ) {
      setValue(ACCOUNT_FORM_LABELS.jobTitle.fieldName, orgUser.jobTitle);
      setSingleSelectValue(
        ACCOUNT_FORM_LABELS.organizationType.fieldName,
        orgUser.organizationType,
      );
      if (orgUser.policyAreas.length > 0) {
        const userPolicyAreaIds = orgUser.policyAreas.map(
          (policyArea) => policyArea.id,
        );
        setMultiSelectValue(
          ACCOUNT_FORM_LABELS.policyAreas.fieldName,
          userPolicyAreaIds,
        );
      }
      const { additionalData } = orgUser.userRegistration;
      if (
        additionalData &&
        additionalData.reasonsForUsingEnview &&
        additionalData.reasonsForUsingEnview.length > 0
      ) {
        setMultiSelectValue(
          ACCOUNT_FORM_LABELS.reasonsForUsingEnview.fieldName,
          additionalData.reasonsForUsingEnview,
        );
      }
      if (orgUser.userRegistration.helpLevelNeeded) {
        setSingleSelectValue(
          ACCOUNT_FORM_LABELS.helpLevelNeeded.fieldName,
          helpLevelNeeded,
        );
      }
      if (orgUser.userRegistration.legislativeWorkExperience) {
        setSingleSelectValue(
          ACCOUNT_FORM_LABELS.legislativeWorkExperience.fieldName,
          legislativeWorkExperience,
        );
      }
    }
    prevOrgUser.current = orgUser;
  }, [orgUser, setValue, setMultiSelectValue, setSingleSelectValue]);

  const displayError = (name: string) => {
    return errors[name] ? (
      <div className="error alert alert-danger">{errors[name].message}</div>
    ) : (
      ''
    );
  };

  if (!accountSetupData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form className="form registration-form" onSubmit={handleSubmit(onSubmit)}>
        {!isOnboarding && orgUser ? (
          <>
            <div className="field-header">
              {`Organization${orgUser.organizations.length > 1 ? 's' : ''}`}
            </div>
            {orgUser.organizations.map((org) => (
              <input
                className="form-control form-field"
                name="organizationName"
                placeholder="Organization name"
                readOnly
                type="text"
                value={org.name}
              />
            ))}
          </>
        ) : (
          ''
        )}
        <div className="field-header">What is your role?</div>
        <div>
          <input
            className="form-control form-field"
            name={ACCOUNT_FORM_LABELS.jobTitle.fieldName}
            placeholder="Job title"
            ref={register({ required: true })}
            type="text"
          />
        </div>
        <SingleSelectDropDown
          defaultLabel="Organization type"
          description={ACCOUNT_FORM_LABELS.organizationType.description}
          id="organization-type"
          onSelect={(selectedOption) =>
            setSingleSelectValue(
              ACCOUNT_FORM_LABELS.organizationType.fieldName,
              selectedOption.value,
            )
          }
          options={getOrganizationTypesOptions(accountSetupData.organizationTypes)}
          selectedValue={organizationType}
          title={ACCOUNT_FORM_LABELS.organizationType.title}
        />
        {(errors[ACCOUNT_FORM_LABELS.jobTitle.fieldName] ||
          errors[ACCOUNT_FORM_LABELS.organizationType.fieldName]) && (
          <div className="error alert alert-danger">Please complete both fields.</div>
        )}

        <div className="field-header">Which issues do you monitor?</div>
        <MultipleSelectDropDown
          columnCount={2}
          currentSelectedValues={policyAreaIds}
          description={ACCOUNT_FORM_LABELS.policyAreas.description}
          hideCount
          id="policy-areas"
          onChange={(selectedOptions: number[]) => {
            setMultiSelectValue(
              ACCOUNT_FORM_LABELS.policyAreas.fieldName,
              selectedOptions,
            );
          }}
          options={getPolicyAreasOptions(accountSetupData.policyAreas)}
          title={ACCOUNT_FORM_LABELS.policyAreas.title}
          useTagsAsLabel
        />
        {displayError(ACCOUNT_FORM_LABELS.policyAreas.fieldName)}

        <div className="field-header">What are you hoping to use Plural for?</div>
        <MultipleSelectDropDown
          currentSelectedValues={reasonsForUsingEnview}
          description={ACCOUNT_FORM_LABELS.reasonsForUsingEnview.description}
          hideCount
          id="reasons-for-using"
          onChange={(selectedOptions: string[]) =>
            setMultiSelectValue(
              ACCOUNT_FORM_LABELS.reasonsForUsingEnview.fieldName,
              selectedOptions,
            )
          }
          options={getReasonsForUsingEnviewOptions(
            accountSetupData.reasonsForUsingEnview,
          )}
          title={ACCOUNT_FORM_LABELS.reasonsForUsingEnview.title}
          useTagsAsLabel
        />
        {displayError(ACCOUNT_FORM_LABELS.reasonsForUsingEnview.fieldName)}

        <div className="field-header">
          How do you prefer to get started with Plural?
        </div>
        <SingleSelectDropDown
          defaultLabel="Select one"
          id="help-level"
          onSelect={(selectedOption) =>
            setSingleSelectValue(
              ACCOUNT_FORM_LABELS.helpLevelNeeded.fieldName,
              selectedOption.value,
            )
          }
          options={getHelpLevelNeededOptions(accountSetupData.helpLevelNeeded)}
          selectedValue={helpLevelNeeded}
        />
        {displayError(ACCOUNT_FORM_LABELS.helpLevelNeeded.fieldName)}

        <div className="field-header">
          How would you describe your level of experience with tracking legislation?
        </div>
        <SingleSelectDropDown
          defaultLabel="Select one"
          id="legislative-experience"
          onSelect={(selectedOption) =>
            setSingleSelectValue(
              ACCOUNT_FORM_LABELS.legislativeWorkExperience.fieldName,
              selectedOption.value,
            )
          }
          options={getLegislativeWorkExperienceOptions(
            accountSetupData.legislativeWorkExperience,
          )}
          selectedValue={legislativeWorkExperience}
        />
        {displayError(ACCOUNT_FORM_LABELS.legislativeWorkExperience.fieldName)}

        <div className="submit">
          <button className="btn btn-primary w-100" id="account-submit" type="submit">
            {isOnboarding ? 'Next' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Account;
