import {
  Acknowledgement,
  SessionBillDataQualityReport,
} from '@enview/interface/types/BillDataQuality';
import { JurisdictionWithAccounts } from '@enview/interface/types/Jurisdiction';
import { AccountType, Organization } from '@enview/interface/types/Organization';
import {
  OrganizationUser,
  UserInvitation,
} from '@enview/interface/types/OrganizationUser';
import { PolicyArea } from '@enview/interface/types/PolicyArea';
import assign from 'lodash-es/assign';
import moment from 'moment';
import { getOrganizationUsers } from '..';
import { groupOrganization } from '../../analytics/AccountAnalytics';
import instance from '../../config/axiosConfig';
import {
  Duration,
  MissingBillTime,
  MissingBillTimeResponseJSON,
} from '../../models/BillDataQuality';
import { OrgActions } from '../../scenes/Admin/AdminHelper';
import { JurisdictionAccessCheckboxState } from '../../scenes/Shared/Selectors/AdminJurisdictionSelector';
import { Action, State, Thunk } from '../@types';
import {
  requestError,
  requestFailure,
  requestSent,
  requestSucceeded,
  requestSuccess,
  resetRequest,
} from '../RequestDux';
import { setInvitations } from '../TeamDux';
import {
  AdminState,
  CLEAR_MISSING_BILL_TIMES_REPORT,
  CLEAR_RESET_PASSWORD_LINK,
  CLEAR_USER_MASQUERADE_LINK,
  EXPORT_USERS_TO_AIRTABLE,
  SET_BDQ_DEFECT_ACK,
  SET_BDQ_REPORT,
  SET_JURISDICTIONS_WITH_ACCOUNT_ACCESS,
  SET_MISSING_BILL_TIMES_REPORT,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION_CREATED,
  SET_PENDING_INVITES,
  SET_RESET_PASSWORD_LINK,
  SET_SELECTED_ORGANIZATION,
  SET_SITE_WIDE_POLICY_AREAS,
  SET_USER_MASQUERADE_LINK,
} from './types';

export const LOAD_UPDATED_ORGANIZATIONS = 'LoadUpdatedOrganizations';
export const SEND_USER_INVITE = 'SendUserInvite';
export const GET_USER_MASQUERADE_LINK = 'GetUserMasqueradeLink';
export const GET_RESET_PASSWORD_LINK = 'GetResetPasswordLink';

// REDUCER
export default function reducer(state: AdminState, action: Action): AdminState {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return assign({}, state, {
        organizations: action.organizations,
      });
    case SET_ORGANIZATION_CREATED:
      return assign({}, state, {
        organizationCreated: action.organizationCreated,
      });
    case SET_SELECTED_ORGANIZATION:
      return assign({}, state, {
        selectedOrganization: action.selectedOrganization,
      });
    case SET_PENDING_INVITES:
      return assign({}, state, {
        pendingInvites: action.pendingInvites,
      });
    case SET_MISSING_BILL_TIMES_REPORT:
      return assign({}, state, {
        missingBillTimesReport: action.missingBillTimesReport,
        sourceScrapeRunTimes: action.sourceScrapeRunTimes,
        verificationTimes: action.verificationTimes,
      });
    case CLEAR_MISSING_BILL_TIMES_REPORT:
      return assign({}, state, {
        missingBillTimesReport: [],
      });
    case SET_USER_MASQUERADE_LINK:
      return assign({}, state, {
        masqueradeLink: action.masqueradeLink,
      });
    case CLEAR_USER_MASQUERADE_LINK:
      return assign({}, state, {
        masqueradeLink: undefined,
      });
    case SET_RESET_PASSWORD_LINK:
      return assign({}, state, {
        resetPasswordLink: action.resetPasswordLink,
      });
    case CLEAR_RESET_PASSWORD_LINK:
      return assign({}, state, {
        resetPasswordLink: undefined,
      });
    case SET_BDQ_REPORT:
      return assign({}, state, {
        bdqReports: action.bdqReports,
        bdqAcknowledgements: action.bdqAcknowledgements,
      });
    case SET_BDQ_DEFECT_ACK:
      return {
        ...state,
        bdqAcknowledgements: [...state.bdqAcknowledgements, action.acknowledgement],
      };
    case SET_JURISDICTIONS_WITH_ACCOUNT_ACCESS:
      return assign({}, state, {
        jurisdictionsWithAccountAccess: action.jurisdictionsWithAccountAccess,
      });
    case SET_SITE_WIDE_POLICY_AREAS:
      return assign({}, state, {
        siteWidePolicyAreas: action.policyAreas,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const setOrganizations = (organizations: Organization[]): Action => {
  return {
    type: SET_ORGANIZATIONS,
    organizations,
  };
};

const setOrganizationCreated = (organizationCreated: {
  organizationId: number;
  userInvite: string;
}): Action => {
  return {
    type: SET_ORGANIZATION_CREATED,
    organizationCreated,
  };
};

const setSelectedOrganization = (selectedOrg: Organization): Action => {
  return {
    type: SET_SELECTED_ORGANIZATION,
    selectedOrganization: selectedOrg,
  };
};

const setPendingInvites = (pendingInvites: UserInvitation[]): Action => {
  return {
    type: SET_PENDING_INVITES,
    pendingInvites,
  };
};

const setMissingBillTimesReport = (
  missingBillTimesReport: MissingBillTime[],
  sourceScrapeRunTimes: Duration[],
  verificationTimes: Duration[],
): Action => {
  return {
    type: SET_MISSING_BILL_TIMES_REPORT,
    missingBillTimesReport,
    sourceScrapeRunTimes,
    verificationTimes,
  };
};

export const clearMissingBillTimesReport = (): Action => {
  return {
    type: CLEAR_MISSING_BILL_TIMES_REPORT,
  };
};

export const setMasqueradeLink = (masqueradeLink: string): Action => {
  return {
    type: SET_USER_MASQUERADE_LINK,
    masqueradeLink,
  };
};

const clearUserMasqueradeLink = (): Action => {
  return {
    type: CLEAR_USER_MASQUERADE_LINK,
  };
};

export const setResetPasswordLink = (resetPasswordLink: string): Action => {
  return {
    type: SET_RESET_PASSWORD_LINK,
    resetPasswordLink,
  };
};

const clearResetPasswordLink = (): Action => {
  return {
    type: CLEAR_RESET_PASSWORD_LINK,
  };
};

const setBDQReport = (
  bdqReports: SessionBillDataQualityReport[],
  bdqAcknowledgements: Acknowledgement[],
): Action => {
  return {
    type: SET_BDQ_REPORT,
    bdqReports,
    bdqAcknowledgements,
  };
};

const setCreatedDefectAcknowledgement = (acknowledgement: Acknowledgement): Action => {
  return {
    type: SET_BDQ_DEFECT_ACK,
    acknowledgement,
  };
};

const setSiteWidePolicyAreas = (policyAreas: PolicyArea[]): Action => {
  return {
    type: SET_SITE_WIDE_POLICY_AREAS,
    policyAreas,
  };
};

const setJurisdictionsWithAccountAccess = (
  jurisdictions: JurisdictionWithAccounts[],
): Action => {
  return {
    type: SET_JURISDICTIONS_WITH_ACCOUNT_ACCESS,
    jurisdictionsWithAccountAccess: jurisdictions,
  };
};

// SELECTORS
export const loadOrgsSucceeded = (state: State): boolean => {
  return requestSucceeded(state, LOAD_UPDATED_ORGANIZATIONS);
};
export const sendInviteSucceeded = (state: State): boolean => {
  return requestSucceeded(state, SEND_USER_INVITE);
};
export const loadOrgsError = (state: State): Error | undefined => {
  return requestError(state, LOAD_UPDATED_ORGANIZATIONS);
};
export const sendInviteError = (state: State): Error | undefined => {
  return requestError(state, SEND_USER_INVITE);
};
export const getMasqueradeLinkError = (state: State): Error | undefined => {
  return requestError(state, GET_USER_MASQUERADE_LINK);
};
export const getResetPasswordLinkError = (state: State): Error | undefined => {
  return requestError(state, GET_RESET_PASSWORD_LINK);
};

// THUNKS
export const getOrganizations = (
  accountType?: string,
  status?: string,
  jurisdictionAccess?: string,
): Thunk => {
  return async (dispatch) => {
    const query: string[] = [];

    if (accountType) {
      query.push(`account=${accountType}`);
    }
    if (status) {
      query.push(`status=${status}`);
    }

    if (jurisdictionAccess && jurisdictionAccess !== 'All') {
      query.push(`jurisdictionAccess=${jurisdictionAccess}`);
    }

    await instance.get(`/organizations?${query.join('&')}`).then((orgRes) => {
      dispatch(setOrganizations(orgRes.data));
    });
  };
};

export const updateOrganizationJurisdictions = (
  jurisdictions: string[] | JurisdictionAccessCheckboxState[],
  organizationId: number,
): Thunk => {
  const payload = {
    jurisdictions,
  };
  return async (dispatch) => {
    dispatch(requestSent(LOAD_UPDATED_ORGANIZATIONS));
    await instance
      .put(`/organizations/${organizationId}/jurisdictions`, payload)
      .then(() => {
        dispatch(requestSuccess(LOAD_UPDATED_ORGANIZATIONS));
      })
      .catch((error) => {
        dispatch(requestFailure(LOAD_UPDATED_ORGANIZATIONS, error));
      });
  };
};

export const getJurisdictionsWithAccounts = (): Thunk => {
  return (dispatch) => {
    instance
      .get('/supported-jurisdictions?withAccounts=true')
      .then((jurisdictions) => {
        dispatch(setJurisdictionsWithAccountAccess(jurisdictions.data));
      })
      .catch((error) => {
        console.error('Error with getSupportedJurisdictions API call', error);
      });
  };
};

export const createOrganization = (
  payload: {
    organizationName: string;
    email: string;
    emailConfirm: string;
    seats: number;
    accountStatus: string;
    enableOidcSso?: boolean;
    oidcClientId?: string;
    oidcClientSecret?: string;
    oidcTenantId?: string;
    organizationDomain?: string;
  },
  jurisdictions?: string[],
): Thunk => {
  const newOrganization = {
    name: payload.organizationName,
    email: payload.email,
    accountStatus: payload.accountStatus,
    seats: payload.seats,
    enableOidcSso: payload.enableOidcSso,
    oidcSsoDto: {
      clientId: payload.oidcClientId,
      clientSecret: payload.oidcClientSecret,
      tenantId: payload.oidcTenantId,
      domain: payload.organizationDomain,
    },
  };
  return async (dispatch) => {
    await instance.post('/organizations', newOrganization).then((response) => {
      if (jurisdictions)
        dispatch(updateOrganizationJurisdictions(jurisdictions, response.data.org.id));
      dispatch(
        setOrganizationCreated({
          organizationId: response.data.org.id,
          userInvite: response.data.userInvite.url,
        }),
      );
    });
  };
};

export const updateOrganizationStatus = (
  action: OrgActions,
  organizations: Organization[],
): Thunk => {
  return async (dispatch) => {
    dispatch(requestSent(LOAD_UPDATED_ORGANIZATIONS));
    try {
      if (action === OrgActions.DELETE) {
        // For Permanent/hard delete
        await Promise.all(
          organizations.map((org: Organization) => {
            const organizationId = org.id;
            const authCode = org.organizationAccount.authorizationCode;
            return instance.delete(`/organizations/${organizationId}/${authCode}`);
          }),
        );
        dispatch(requestSuccess(LOAD_UPDATED_ORGANIZATIONS));
      } else {
        let isSuspended: boolean | undefined;
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        let orgStatus: AccountType | undefined;
        if (action === OrgActions.SUSPEND) {
          isSuspended = true;
        }
        if (action === OrgActions.ACTIVATE) {
          isSuspended = false;
        }
        if (action === OrgActions.CONVERT_PRO) {
          orgStatus = AccountType.Pro;
        }
        if (action === OrgActions.CONVERT_TRIAL) {
          orgStatus = AccountType.Trial;
        }
        if (action === OrgActions.CONVERT_FREE) {
          orgStatus = AccountType.Free;
        }
        if (action === OrgActions.CONVERT_STARTER) {
          orgStatus = AccountType.Starter;
        }
        await Promise.all(
          organizations.map((org: Organization) => {
            const organizationId = org.id;
            const data = {
              suspended: isSuspended,
              accountStatus: orgStatus,
              name: org.name,
            };
            return instance.put(`/organizations/${organizationId}`, data).then(() =>
              groupOrganization([
                {
                  ...org,
                  ...(orgStatus ? { accountStatus: orgStatus } : {}),
                  ...(isSuspended !== undefined ? { suspended: isSuspended } : {}),
                },
              ]),
            );
          }),
        );
        dispatch(requestSuccess(LOAD_UPDATED_ORGANIZATIONS));
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(requestFailure(LOAD_UPDATED_ORGANIZATIONS, error));
      }
    }
  };
};

export const updateOrganization = (organization: Partial<Organization>): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(LOAD_UPDATED_ORGANIZATIONS));
    instance
      .put(`/organizations/${organization.id}`, organization)
      .then(() => {
        dispatch(requestSuccess(LOAD_UPDATED_ORGANIZATIONS));
      })
      .catch((error) => {
        dispatch(requestFailure(LOAD_UPDATED_ORGANIZATIONS, error));
        console.error('Error with updateOrganization API call', error);
      });
  };
};

export const updateOrganizationUsersStatus = (
  action: OrgActions,
  orgUsers: OrganizationUser[],
  orgId?: number,
  jurisdictionAccess?: string,
  status?: string,
  notifications?: string,
): Thunk => {
  return async (dispatch) => {
    if (action === OrgActions.DELETE) {
      // For Permanent/hard delete
      await Promise.all(
        orgUsers.map((user: OrganizationUser) =>
          instance.delete(`/organization-users/${user.id}`),
        ),
      );
      dispatch(getOrganizationUsers(orgId, jurisdictionAccess, status, notifications));
    } else {
      const isSuspended = action === OrgActions.SUSPEND;
      await Promise.all(
        orgUsers.map(async (user: OrganizationUser) => {
          const data = { email: user.email, suspended: isSuspended };
          await instance.put(`/organization-users/${user.id}`, data);
        }),
      );
      dispatch(getOrganizationUsers(orgId, jurisdictionAccess, status, notifications));
    }
  };
};

export const getOrganizationById = (organizationId: number): Thunk => {
  return async (dispatch) => {
    await instance.get(`/organization/${organizationId}`).then((response) => {
      dispatch(setSelectedOrganization(response.data));
    });
  };
};

export const getPendingInvites = (orgId: number): Thunk => {
  return async (dispatch) => {
    await instance
      .get(`onboarding/pending-invites?organizationId=${orgId}`)
      .then((response) => {
        dispatch(setPendingInvites(response.data));
      });
  };
};

export const sendInvitation = (email: string, organizationId: number): Thunk => {
  const payload = {
    email,
    organizationId,
  };
  return (dispatch) => {
    dispatch(requestSent(SEND_USER_INVITE));
    instance
      .post('onboarding/signup-invite', payload)
      .then((res) => {
        dispatch(setInvitations([res.data]));
        dispatch(requestSuccess(SEND_USER_INVITE));
      })
      .catch((error) => {
        dispatch(requestFailure(SEND_USER_INVITE, error));
      });
  };
};

export const resetOrgsUpdate = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(LOAD_UPDATED_ORGANIZATIONS));
  };
};

export const resetSendInvites = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(SEND_USER_INVITE));
  };
};

export const getMissingBillTimesReport = (sessionId: number): Thunk => {
  return (dispatch) => {
    return instance
      .get(`reports/bills/missing-time/${sessionId}`)
      .then((res: { data: MissingBillTimeResponseJSON }) => {
        // using moment to parse dates because the string format of sourceScrapeRunTimes is different vs others
        // and causes unexpected timezone shift
        const missingBillTimes: MissingBillTime[] = res.data.missingBillTimes.map(
          (mbt) => ({
            ...mbt,
            identifiedAt: moment.utc(mbt.identifiedAt).toDate(),
            ingestedAt: mbt.ingestedAt ? moment.utc(mbt.ingestedAt).toDate() : null,
          }),
        );
        const sourceScrapeRunTimes: Duration[] = res.data.sourceScrapeRunTimes
          ? res.data.sourceScrapeRunTimes.map((duration) => ({
              start: moment.utc(duration.start).toDate(),
              end: moment.utc(duration.end).toDate(),
            }))
          : [];
        const verificationTimes: Duration[] = res.data.verificationTimes.map(
          (duration) => ({
            start: moment.utc(duration.start).toDate(),
            end: moment.utc(duration.end).toDate(),
          }),
        );
        dispatch(
          setMissingBillTimesReport(
            missingBillTimes,
            sourceScrapeRunTimes,
            verificationTimes,
          ),
        );
      });
  };
};

export const getUserMasqueradeLink = (email: string): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(GET_USER_MASQUERADE_LINK));
    return instance
      .get(`masquerade-link/${email}`)
      .then((res) => {
        dispatch(setMasqueradeLink(res.data));
        dispatch(requestSuccess(GET_USER_MASQUERADE_LINK));
      })
      .catch((error) => {
        dispatch(requestFailure(GET_USER_MASQUERADE_LINK, error));
      });
  };
};

export const resetUserMasqueradeRequest = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(GET_USER_MASQUERADE_LINK));
    return dispatch(clearUserMasqueradeLink());
  };
};

export const getResetPasswordLink = (orgUser: OrganizationUser): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(GET_RESET_PASSWORD_LINK));
    return instance
      .get(`/forgot-password/link/${orgUser.id}`)
      .then((res) => {
        dispatch(setResetPasswordLink(res.data));
        dispatch(requestSuccess(GET_RESET_PASSWORD_LINK));
      })
      .catch((error) => {
        dispatch(requestFailure(GET_RESET_PASSWORD_LINK, error));
      });
  };
};

export const resetResetPasswordRequest = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(GET_RESET_PASSWORD_LINK));
    return dispatch(clearResetPasswordLink());
  };
};

export const getBillDataQualityReports = (): Thunk => async (dispatch) => {
  const response = await instance.get('/reports/bills/data-quality');
  const { reports, acknowledgements } = response.data;
  dispatch(setBDQReport(reports, acknowledgements));
};

export const createDefectAcknowledgement =
  (acknowledgement: Acknowledgement): Thunk =>
  async (dispatch) => {
    const response = await instance.post(
      '/reports/defects/acknowledgements',
      acknowledgement,
    );
    dispatch(setCreatedDefectAcknowledgement(response.data));
  };

export const getSiteWidePolicyAreas = (): Thunk => {
  return async (dispatch) => {
    await instance.get('/policy-areas').then((response) => {
      dispatch(setSiteWidePolicyAreas(response.data));
    });
  };
};

export const deleteCommitteePolicyArea =
  (policyAreaId: string, committeeId: string): Thunk =>
  async (dispatch) => {
    await instance
      .delete(`/policy-areas/${policyAreaId}/committee/${committeeId}`)
      .then(() => {
        dispatch(getSiteWidePolicyAreas());
      });
  };

export const addPolicyArea =
  (name: string, description: string): Thunk =>
  async (dispatch) => {
    await instance.post('/policy-areas', { name, description }).then(() => {
      dispatch(getSiteWidePolicyAreas());
    });
  };

export const deletePolicyArea =
  (policyAreaId: number): Thunk =>
  async (dispatch) => {
    await instance.delete(`/policy-areas/${policyAreaId}`).then(() => {
      dispatch(getSiteWidePolicyAreas());
    });
  };

export const exportUsersToAirtable = (): Thunk => async (dispatch) => {
  await instance.post(`/organization-users/export-to-airtable`).then(() => {
    dispatch(requestSuccess(EXPORT_USERS_TO_AIRTABLE));
  });
};
