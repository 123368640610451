import { Readable } from 'stream';

export default class FileResult {
  contentType: string;
  contentDisposition: string;
  data?: string | Readable;

  constructor(contentType: string, fileName: string, data?: string | Readable) {
    this.contentType = contentType;
    this.contentDisposition = `attachment;filename="${fileName}"`;
    this.data = data;
  }

  public static getFileName(contentDisposition: string): string {
    const regExFileName = /filename="(?<filename>.*)"/;
    const result = regExFileName.exec(contentDisposition)?.groups?.filename;
    if (!result)
      throw new Error(`Filename not found in content string ${contentDisposition}`);
    return result;
  }
}
