/* eslint-disable react/jsx-max-depth */
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './styles/scss/index.scss';
import { QueryParamProvider } from 'use-query-params';
import { createBrowserHistory } from 'history';
import App from './App';
import { unregister } from './registerServiceWorker';
import store from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const ROOT_URL = process.env.PUBLIC_URL || '/';
const environment = process.env.REACT_APP_ENV;
const history = createBrowserHistory({
  basename: ROOT_URL,
});
const SentryRoute = Sentry.withSentryRouting(Route);

if (environment === 'prod' || environment === 'dev' || environment === 'local') {
  Sentry.init({
    environment,
    dsn: 'https://d368816b71024c13b5de3ef7146de0bc@o469199.ingest.sentry.io/5507575',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <Router basename={ROOT_URL}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <I18nextProvider i18n={i18n}>
            <SentryRoute component={App} path="/" />
          </I18nextProvider>
        </QueryParamProvider>
      </Router>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);
// UNregister service worker
unregister();
