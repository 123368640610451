/* eslint-disable import/no-cycle */
import extend from 'lodash-es/extend';
import isEmpty from 'lodash-es/isEmpty';
import * as Sentry from '@sentry/react';
import { createTracker } from 'redux-segment';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {
  AccountReducer,
  AdminReducer,
  AuthenticationReducer,
  BillViewReducer,
  ExportReducer,
  RequestReducer,
  ModalReducer,
  RegistrationReducer,
  TeamReducer,
  TeamModeReducer,
  TeamSelectorReducer,
  NavReducer,
  initialState,
} from './dux';
import { BaseAPI } from './api';
import { persistStateInLocalStorage } from './services/LocalStorageService';
import { LOGOUT } from './dux/AuthenticationDux/types';

//  TODO: When store is converted to .ts export type AppDispatch = typeof store.dispatch;
// https://react-redux.js.org/using-react-redux/static-typing#typing-the-usedispatch-hook

const loadState = () => {
  let stateString;
  let state = {};
  const sessionStorageStateString = window.sessionStorage.getItem('state');
  const localStateString = window.localStorage.getItem('state');
  if (sessionStorageStateString && !isEmpty(JSON.parse(sessionStorageStateString))) {
    stateString = sessionStorageStateString;
  } else if (localStateString && !isEmpty(JSON.parse(localStateString))) {
    stateString = localStateString;
  }
  if (stateString) {
    state = JSON.parse(stateString);
  }
  return extend(state, initialState, {
    teamMode: {
      teamId: state.teamMode?.teamId,
      orgId: state.teamMode?.orgId,
    },
  });
};

const tracker = createTracker();
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const dynamicMiddleWareCompose = {
  development: composeWithDevTools(
    applyMiddleware(thunkMiddleware, BaseAPI.middleware, tracker),
  ),
  production: compose(
    applyMiddleware(thunkMiddleware, BaseAPI.middleware, tracker),
    sentryReduxEnhancer,
  ),
};

const reducer = combineReducers({
  api: BaseAPI.reducer,
  account: AccountReducer,
  admin: AdminReducer,
  authentication: AuthenticationReducer,
  billView: BillViewReducer,
  exports: ExportReducer,
  modals: ModalReducer,
  requests: RequestReducer,
  registrations: RegistrationReducer,
  teams: TeamReducer,
  teamMode: TeamModeReducer,
  teamSelectors: TeamSelectorReducer,
  navigation: NavReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  let newState = state;
  if (action.type === LOGOUT) {
    newState = initialState;
  }

  return reducer(newState, action);
};

const store = createStore(
  rootReducer,
  loadState(),
  dynamicMiddleWareCompose[process.env.NODE_ENV],
);

store.subscribe(() => {
  persistStateInLocalStorage(store.getState());
});

export default store;
