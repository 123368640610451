import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';
import { BillAPI } from '../../../api';
import Can from '../../../components/Can';
import { g as abilityGlossary } from '../../../config/ability';
import BillTagButton from './BillTagButton';
import TagIndicator from './TagIndicator';

type BillTagsProps = {
  bill: Bill;
  hasStatusIndicator?: boolean;
  showPlaceholderText?: boolean;
};

const BillTags = (props: BillTagsProps): ReactElement => {
  const { bill, hasStatusIndicator, showPlaceholderText } = props;
  const [untagBill] = BillAPI.endpoints.untagBill.useMutation();

  return (
    <Can I={abilityGlossary.TAG} a={abilityGlossary.BILL} passThrough>
      {(can: boolean) => (
        <div
          className="d-flex flex-wrap"
          onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
          style={{ lineHeight: '20px', marginTop: '6px', width: '100%' }}
        >
          <BillTagButton
            bill={bill}
            hasStatusIndicator={hasStatusIndicator}
            isDisabled={!can}
            showPlaceholderText={showPlaceholderText}
          />
          {can &&
            bill.tags.map((tag) => (
              <TagIndicator
                key={tag.id}
                onRemove={() => untagBill({ bill, tag })}
                tag={tag}
              />
            ))}
        </div>
      )}
    </Can>
  );
};

export default BillTags;
