import { PolicyArea } from '@enview/interface/types/PolicyArea';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

export enum OnboardingStage {
  ACCOUNT = 'ACCOUNT',
  NOTIFICATION = 'NOTIFICATION',
  COMPLETE = 'COMPLETE',
}

export const getOrganizationTypesOptions = (
  orgTypes: string[],
): OptionType<string>[] => {
  if (!orgTypes) return [];
  return orgTypes.map((orgType) => {
    return {
      label: orgType,
      value: orgType,
    };
  });
};

export const getPolicyAreasOptions = (
  policyAreas: PolicyArea[],
): OptionType<number>[] => {
  if (!policyAreas) return [];
  const policyAreaOptions = policyAreas.map((policyArea) => {
    return {
      label: policyArea.name,
      value: policyArea.id,
    };
  });
  return policyAreaOptions;
};

export const getReasonsForUsingEnviewOptions = (
  reasonsForUsingEnview: string[],
): OptionType<string>[] => {
  if (!reasonsForUsingEnview) return [];
  return reasonsForUsingEnview.map((reason) => {
    return {
      label: reason,
      value: reason,
    };
  });
};

export const getLegislativeWorkExperienceOptions = (legislativeWorkExperience: {
  [key: string]: string;
}): OptionType<string>[] => {
  if (!legislativeWorkExperience) return [];
  return Object.keys(legislativeWorkExperience).map((value) => {
    const label = legislativeWorkExperience[value];
    return {
      label,
      value,
    };
  });
};

export const getHelpLevelNeededOptions = (helpLevelNeeded: {
  [key: string]: string;
}): OptionType<string>[] => {
  if (!helpLevelNeeded) return [];
  return Object.keys(helpLevelNeeded).map((value) => {
    const label = helpLevelNeeded[value];
    return {
      label,
      value,
    };
  });
};
