import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { track, page } from './SegmentAnalytics';

enum AnalyticsEvent {
  TAG_CREATED = 'Tag Created',
  TAG_UPDATED = 'Tag Updated',
  TAG_DELETED = 'Tag Deleted',
  TAG_PUBLISHED = 'Tag Published',
  TAG_UNPUBLISHED = 'Tag Unpublished',
}

const isTagShared = (teamId: number, orgUser?: OrganizationUser): boolean => {
  return !!(orgUser && orgUser.teamId !== teamId);
};

export const trackTagCreated = (
  tagName: string,
  teamId: number,
  description?: string,
  isPublic?: boolean,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.TAG_CREATED, {
    tagName,
    description,
    isShared: isTagShared(teamId, orgUser),
    teamIds: teamId,
    isPublic,
    username: orgUser ? orgUser.email : 'Not logged in',
    url: window.location.pathname,
  });
};

export const trackTagDeleted = (
  tagName: string,
  teamId: number,
  description?: string,
  isPublic?: boolean,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.TAG_DELETED, {
    tagName,
    description,
    isShared: isTagShared(teamId, orgUser),
    teamIds: teamId,
    isPublic,
    username: orgUser ? orgUser.email : 'Not logged in',
    url: window.location.pathname,
  });
};

export const trackTagUpdated = (
  tagName: string,
  teamId: number,
  description?: string,
  isPublic?: boolean,
  orgUser?: OrganizationUser,
): void => {
  track(AnalyticsEvent.TAG_UPDATED, {
    tagName,
    description,
    isShared: isTagShared(teamId, orgUser),
    teamIds: teamId,
    isPublic,
    username: orgUser ? orgUser.email : 'Not logged in',
    url: window.location.pathname,
  });
};

export const trackTagPublished = (
  tagName: string,
  teamId: number,
  description?: string,
  isPublic?: boolean,
  orgUser?: OrganizationUser,
): void => {
  const event = isPublic
    ? AnalyticsEvent.TAG_PUBLISHED
    : AnalyticsEvent.TAG_UNPUBLISHED;
  track(event, {
    tagName,
    description,
    isShared: isTagShared(teamId, orgUser),
    teamIds: teamId,
    isPublic,
    username: orgUser ? orgUser.email : 'Not logged in',
    url: window.location.pathname,
  });
};

export const trackViewedAllTags = (orgUser?: OrganizationUser): void => {
  page('User', `All Tags`, {
    username: orgUser ? orgUser.email : 'Not logged in',
    url: window.location.pathname,
  });
};
